import PageLayout from "../../../components/layout/page";
import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ClientList from "./clientList";
import AgencyList from "../agencyReport/agencyList";
import LandlordList from "../landlordReport/landlordList";

export default function LedgerList() {
  const [tab, setTab] = useState("client");
  const handleTabChange = (tabName: string) => {
    setTab(tabName);
  };

  return (
    <PageLayout
      item={<></>}
      View={
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={tab === "client" ? "active" : ""}
                onClick={() => handleTabChange("client")}
              >
                Client
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={tab === "agency" ? "active" : ""}
                onClick={() => handleTabChange("agency")}
              >
                Agency
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={tab === "landlord" ? "active" : ""}
                onClick={() => handleTabChange("landlord")}
              >
                Landlord
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tab}>
            <TabPane className="ledger-tabs" tabId={"client"}>
              <ClientList />
            </TabPane>
            <TabPane className="ledger-tabs" tabId={"agency"}>
              <AgencyList />
            </TabPane>
            <TabPane className="ledger-tabs" tabId={"landlord"}>
              <LandlordList />
            </TabPane>
          </TabContent>
        </>
      }
    />
  );
}
