import * as Yup from "yup";
import {
  AGENCY_REQUIRED,
  AMOUNT_REQUIRED,
  BANK_NAME_REQUIRED,
  BOOKING_NO_REQUIRED,
  CHEQUE_NO_REQUIRED,
  CONTACT_PERSON_REQUIRED,
  PAYMENT_DATE_REQUIRED,
  PAYMENT_MODE_REQUIRED,
  PERSON_NAME_REQUIRED,
} from "../../constant/message";

export const validationSchema = Yup.object().shape({
  bookingNo: Yup.string().required(BOOKING_NO_REQUIRED),
  personName: Yup.string().required(PERSON_NAME_REQUIRED),
  paymentDate: Yup.string().required(PAYMENT_DATE_REQUIRED),
  amount: Yup.string().required(AMOUNT_REQUIRED),
  paymentMode: Yup.string()
    .oneOf(["cash", "cheque"], "Value should be cash or cheque")
    .required(PAYMENT_MODE_REQUIRED),
  bankName: Yup.string().test(
    "bankName-required",
    BANK_NAME_REQUIRED,
    function (value) {
      const { paymentMode } = this.parent;
      return (
        paymentMode !== "cheque" ||
        (value && value.trim().length > 0 ? true : false)
      );
    }
  ),
  chequeNo: Yup.string().test(
    "chequeNo-required",
    CHEQUE_NO_REQUIRED,
    function (value) {
      const { paymentMode } = this.parent;
      return (
        paymentMode !== "cheque" ||
        (value && value.trim().length > 0 ? true : false)
      );
    }
  ),
});
