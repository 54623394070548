import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { ActionButton } from "../../../components/buttons/actionButtons";
import { DELETE, UPDATE } from "../../../constant/commonConst";
import { confirmBox } from "../../../components/toast";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "name",
      title: "Route Id",
      dataType: DataType.String,
    },
    {
      key: "startLatitude",
      title: "Start Latitude",
      dataType: DataType.String,
    },
    {
      key: "endLatitude",
      title: "End Latitude",
      dataType: DataType.String,
    },
    {
      key: "startLongitude",
      title: "Start Longitude",
      dataType: DataType.String,
    },
    {
      key: "endLongitude",
      title: "End Longitude",
      dataType: DataType.String,
    },
    {
      key: "",
      title: "",
      component: ActionButton,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            name: row.data.name,
            startLatitude: row.data.startLatitude,
            endLatitude: row.data.endLatitude, 
            startLongitude: row.data.startLongitude, 
            endLongitude: row.data.endLongitude, 
            startLandmarkDescription: row.data.startLandmarkDescription,
            endLandmarkDescription: row.data.endLandmarkDescription,
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox("you want to delete this route?");
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
