import { Button } from "reactstrap";
import PageLayout from "../../../components/layout/page";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import { useApi } from "../../../helper/apiHook";
import { AddUpdateItem } from "./addUpdate";
import { downloadIcon, imageIcon, plus, uploadIcon } from "../../../constant/icons";
import { columns } from "./columns";
import { DataView } from "../../../components/table/table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiHelper from "./helper";
import ModalView from "../../../components/modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { invokeApi } from "../../../helper/api";
import { GET, POST } from "../../../constant/commonConst";
import { message } from "../../../components/toast";

interface FormValues {
  file: File | null; // Explicitly define the type for the file
}

export const MediaItemsApi = (
  paging?: boolean,
  dataLoad: any = {},
  body: any = {}
) => {
  return { ...useApi("/mediaItems", paging, body, dataLoad) };
};

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required("An Excel file is required"),
});

export default function MediaItems() {
  const api = MediaItemsApi(true);
  const AddUpdate = useAddUpdate();
  const { stateList } = apiHelper();
  useEffect(() => {
    api.mutate();
  }, []);

  const [isModal, setIsModal] = useState(false);
  const handleClose = () => setIsModal(false);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const exportToExcel = async () => {
    try {
      const response = await invokeApi(
        GET,
        '/mediaItems/downloadZip',
        undefined,
        undefined,
        { responseType: "blob" }
      );

      if (response instanceof Blob) {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MediaItemsTemplate.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('No valid Blob response:', response);
      }
    } catch (error) {
      console.error("Error downloading the zip file:", error);
    }
  };


  return AddUpdate.viewAddUpdate ? (
    <AddUpdateItem
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      value={AddUpdate.currentValue}
    />
  ) : (
    <>
      <PageLayout
        item={
          <>
            {" "}
            <Button color="secondary" onClick={() => setIsModal(true)}>
              {plus.icon}Upload Excel
            </Button>
            <Button color="secondary" onClick={exportToExcel}>
              {downloadIcon.icon}Download Template
            </Button>
            <Button
              color="secondary"
              onClick={() =>
                AddUpdate.openAddEditFrom({
                  name: "",
                  stateId:
                    stateList.find((x: any) => x.label === "Gujarat")?.value ||
                    null,
                  cityId: null,
                  zoneId: null,
                  locationId: null,
                  latitude: null,
                  agencyId: null,
                  longitude: null,
                  landlordId: null,
                  mediaTypeId: null,
                  mediaLitId: null,
                  mediaSeriesId: null,
                  width: null,
                  height: null,
                  qty: 1,
                  BalanceQty: null,
                  IsActivate: true,
                  landlordInfo: [],
                  image: [],
                })
              }
            >
              {plus.icon}Add New
            </Button>
          </>
        }
        View={
          <DataView
            columns={columns({
              api,
              openAddEditFrom: AddUpdate.openAddEditFrom,
              view: navigate,
            })}
            rows={api.data}
            updatePagination={api.updatePagination}
            currentPage={api.currentPage}
            rowCount={api.rowCount}
            limit={api.limit}
          />
        }
      />
      <ModalView
        modalView={isModal}
        modalName={"Upload Excel"}
        onModalDismiss={handleClose}
      >
        <Formik<FormValues>
          initialValues={{ file: null }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (!values.file) return;

            const formData = new FormData();
            formData.append("file", values.file);

            try {
              const res = await invokeApi(POST, "/mediaItems/import", formData);
              const { data } = res?.data || {};

              if (data?.length > 0) {
                const errors = data.errors;
                if (!errors) {
                  message("success", "File uploaded successfully!");
                } else {
                  message("success", `File uploaded with ${errors}`);
                }
                resetForm();
                handleClose();
                window.location.reload();
              } else {
                message("error", res.data.errors || "An unknown error occurred.");
              }
            } catch (error) {
              message("error", "Failed to upload file. Please try again.");
              console.error("Error uploading file:", error);
            }
          }}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form>
              <div className="dropzone mb-2">
                <label className="dropzone-container" htmlFor="file">
                  <div className="file-icon">
                   

                    {values.file ? (
                      <>
                      <div className="excel-file-icons">
                       <div className="excel-file-icon">
                       <svg className="excel-file-svg w-100" width="257" height="257" viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M208.812 67.4621H160.625C159.773 67.4621 158.956 67.1237 158.353 66.5212C157.751 65.9187 157.412 65.1016 157.412 64.2496V16.0621C157.412 15.2101 157.751 14.393 158.353 13.7905C158.956 13.1881 159.773 12.8496 160.625 12.8496C161.477 12.8496 162.294 13.1881 162.896 13.7905C163.499 14.393 163.837 15.2101 163.837 16.0621V61.0371H208.812C209.664 61.0371 210.481 61.3756 211.084 61.978C211.686 62.5805 212.025 63.3976 212.025 64.2496C212.025 65.1016 211.686 65.9187 211.084 66.5212C210.481 67.1237 209.664 67.4621 208.812 67.4621Z" fill="currentcolor"/>
                         <path d="M208.812 99.4779C207.96 99.4779 207.143 99.1394 206.541 98.537C205.938 97.9345 205.6 97.1174 205.6 96.2654V65.5796L159.295 19.2746H64.2499C60.8418 19.2746 57.5734 20.6285 55.1635 23.0383C52.7537 25.4481 51.3999 28.7166 51.3999 32.1246V95.1988C51.3999 96.0509 51.0614 96.868 50.4589 97.4704C49.8565 98.0729 49.0394 98.4113 48.1874 98.4113C47.3353 98.4113 46.5182 98.0729 45.9158 97.4704C45.3133 96.868 44.9749 96.0509 44.9749 95.1988V32.1246C44.9749 27.0126 47.0056 22.1099 50.6204 18.4951C54.2351 14.8804 59.1378 12.8496 64.2499 12.8496H160.625C161.047 12.8489 161.465 12.9313 161.855 13.0923C162.246 13.2532 162.6 13.4895 162.899 13.7877L211.087 61.9752C211.385 62.2741 211.621 62.6288 211.782 63.019C211.943 63.4093 212.026 63.8275 212.025 64.2496V96.2654C212.025 96.6873 211.942 97.105 211.78 97.4948C211.619 97.8845 211.382 98.2387 211.084 98.537C210.786 98.8353 210.431 99.0719 210.042 99.2334C209.652 99.3948 209.234 99.4779 208.812 99.4779Z" fill="currentcolor"/>
                         <path d="M192.75 244.15H64.2499C59.1378 244.15 54.2351 242.119 50.6204 238.504C47.0056 234.889 44.9749 229.987 44.9749 224.875V176.687C44.9749 175.835 45.3133 175.018 45.9158 174.416C46.5182 173.813 47.3353 173.475 48.1874 173.475C49.0394 173.475 49.8565 173.813 50.4589 174.416C51.0614 175.018 51.3999 175.835 51.3999 176.687V224.875C51.3999 228.283 52.7537 231.551 55.1635 233.961C57.5734 236.371 60.8418 237.725 64.2499 237.725H192.75C196.158 237.725 199.426 236.371 201.836 233.961C204.246 231.551 205.6 228.283 205.6 224.875V176.687C205.6 175.835 205.938 175.018 206.541 174.416C207.143 173.813 207.96 173.475 208.812 173.475C209.664 173.475 210.481 173.813 211.084 174.416C211.686 175.018 212.025 175.835 212.025 176.687V224.875C212.025 229.987 209.994 234.889 206.379 238.504C202.765 242.119 197.862 244.15 192.75 244.15Z" fill="currentcolor"/>
                         <path d="M176.687 179.9H80.3124C79.4603 179.9 78.6432 179.561 78.0408 178.959C77.4383 178.356 77.0999 177.539 77.0999 176.687C77.0999 175.835 77.4383 175.018 78.0408 174.416C78.6432 173.813 79.4603 173.475 80.3124 173.475H176.687C177.539 173.475 178.356 173.813 178.959 174.416C179.561 175.018 179.9 175.835 179.9 176.687C179.9 177.539 179.561 178.356 178.959 178.959C178.356 179.561 177.539 179.9 176.687 179.9Z" fill="currentcolor"/>
                         <path d="M128.5 212.025H80.3124C79.4603 212.025 78.6432 211.686 78.0408 211.084C77.4383 210.481 77.0999 209.664 77.0999 208.812C77.0999 207.96 77.4383 207.143 78.0408 206.541C78.6432 205.938 79.4603 205.6 80.3124 205.6H128.5C129.352 205.6 130.169 205.938 130.771 206.541C131.374 207.143 131.712 207.96 131.712 208.812C131.712 209.664 131.374 210.481 130.771 211.084C130.169 211.686 129.352 212.025 128.5 212.025Z" fill="currentcolor"/>
                         <path d="M208.812 179.9H48.1874C43.0753 179.9 38.1726 177.869 34.5579 174.254C30.9431 170.639 28.9124 165.737 28.9124 160.625V111.261C28.9124 106.149 30.9431 101.247 34.5579 97.6318C38.1726 94.0171 43.0753 91.9863 48.1874 91.9863H48.3159L208.941 93.0529C214.027 93.1002 218.89 95.15 222.475 98.758C226.06 102.366 228.079 107.242 228.094 112.328V160.625C228.094 163.156 227.595 165.663 226.626 168.002C225.657 170.341 224.237 172.466 222.446 174.256C220.655 176.046 218.53 177.466 216.191 178.434C213.851 179.403 211.344 179.9 208.812 179.9ZM48.1874 98.4113C44.7793 98.4113 41.5109 99.7652 39.101 102.175C36.6912 104.585 35.3374 107.853 35.3374 111.261V160.625C35.3374 164.033 36.6912 167.301 39.101 169.711C41.5109 172.121 44.7793 173.475 48.1874 173.475H208.812C212.22 173.475 215.489 172.121 217.899 169.711C220.309 167.301 221.662 164.033 221.662 160.625V112.328C221.652 108.936 220.306 105.685 217.915 103.28C215.524 100.874 212.281 99.5083 208.889 99.4779L48.2645 98.4113H48.1874Z" fill="currentcolor"/>
                         <path d="M97.9426 151.629H75.6799V117.371H97.7177V122.466H81.8544V131.75H94.6016V136.717H81.8544V146.303H97.9169L97.9426 151.629Z" fill="currentcolor"/>
                         <path d="M122.19 151.63H115.65L110.278 142.397L104.419 151.63H99.4973L107.94 138.581L100.635 126.283H107.188L111.351 133.736L116.093 126.283H120.886L113.722 137.276L122.19 151.63Z" fill="currentcolor"/>
                         <path d="M139.679 142.319L144.601 142.82C143.963 145.999 142.733 148.354 140.913 149.888C139.051 151.422 136.694 152.228 134.282 152.156C130.783 152.156 128.093 150.871 126.213 148.301C124.275 145.568 123.284 142.276 123.392 138.927C123.392 135.11 124.42 131.956 126.476 129.463C127.465 128.245 128.723 127.273 130.151 126.624C131.579 125.975 133.139 125.667 134.706 125.723C140.228 125.723 143.515 128.634 144.569 134.455L139.647 135.13C139.219 131.986 137.671 130.414 135.002 130.414C134.122 130.373 133.249 130.596 132.496 131.054C131.743 131.512 131.144 132.184 130.774 132.984C129.847 134.902 129.395 137.015 129.457 139.145C129.457 141.715 129.913 143.69 130.826 145.069C131.225 145.727 131.793 146.267 132.471 146.633C133.148 146.999 133.911 147.178 134.681 147.151C137.195 147.138 138.861 145.527 139.679 142.319Z" fill="currentcolor"/>
                         <path d="M165.122 143.547L170.262 144.247C169.611 146.514 168.237 148.506 166.35 149.92C164.235 151.466 161.662 152.253 159.044 152.156C155.532 152.156 152.705 150.976 150.563 148.616C148.422 146.256 147.351 143.061 147.351 139.03C147.351 135.201 148.422 132.031 150.563 129.521C152.705 127.011 155.633 125.756 159.346 125.756C162.961 125.756 165.746 126.994 167.699 129.47C169.652 131.945 170.639 135.124 170.661 139.004V139.647H153.307C153.265 141.045 153.429 142.441 153.795 143.791C154.147 144.87 154.819 145.816 155.723 146.502C156.765 147.296 158.05 147.705 159.359 147.659C162.203 147.663 164.124 146.292 165.122 143.547ZM164.769 135.965C164.826 134.309 164.236 132.696 163.124 131.468C162.615 130.93 162.004 130.5 161.326 130.202C160.649 129.905 159.918 129.746 159.178 129.735C158.438 129.724 157.703 129.862 157.017 130.139C156.331 130.417 155.707 130.829 155.183 131.352C154.05 132.617 153.393 134.237 153.326 135.933L164.769 135.965Z" fill="currentcolor"/>
                         <path d="M181.641 151.629H175.916V117.371H181.641V151.629Z" fill="currentcolor"/>
                       </svg>
                       </div>
                      <span>{(values.file.name)}</span>
                      <button className="delete-excel">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5001 4H1.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M7.5 9L8 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M12.5 9L12 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M4.5 4C4.55588 4 4.58382 4 4.60915 3.99936C5.43259 3.97849 6.15902 3.45491 6.43922 2.68032C6.44784 2.65649 6.45667 2.62999 6.47434 2.57697L6.57143 2.28571C6.65431 2.03708 6.69575 1.91276 6.75071 1.8072C6.97001 1.38607 7.37574 1.09364 7.84461 1.01877C7.96213 1 8.09317 1 8.35526 1H11.6447C11.9068 1 12.0379 1 12.1554 1.01877C12.6243 1.09364 13.03 1.38607 13.2493 1.8072C13.3043 1.91276 13.3457 2.03708 13.4286 2.28571L13.5257 2.57697C13.5433 2.62992 13.5522 2.65651 13.5608 2.68032C13.841 3.45491 14.5674 3.97849 15.3909 3.99936C15.4162 4 15.4441 4 15.5 4" stroke="currentColor" stroke-width="1.5"></path><path d="M16.3735 13.3991C16.1965 16.054 16.108 17.3815 15.243 18.1907C14.378 19 13.0476 19 10.3868 19H9.6134C6.9526 19 5.6222 19 4.75719 18.1907C3.89218 17.3815 3.80368 16.054 3.62669 13.3991L3.16675 6.5M16.8334 6.5L16.2974 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg>
                      </button>
                      </div>

                      
                      </>
                    ) : (
                      uploadIcon.icon
                    )}
                  </div>
                  <div className="dropzone-title">
                    {!values.file && (
                      <>
                        Drag and drop your Excel File or
                        <span className="browse"> browse</span> your Excel File
                      </>
                    )}
                  </div>

                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  onChange={(event) => {
                    if (event.currentTarget.files) {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }
                  }}
                  accept=".xlsx, .xls"
                />
                {errors.file && touched.file ? (
                  <div style={{ color: "red" }}>{errors.file}</div>
                ) : null}
              </div>
              <div className="d-flex justify-content-end">
                <Button color="secondary" solid type="submit">
                  Submit
                </Button>
              </div>


            </Form>
          )}
        </Formik>
      </ModalView>
    </>
  );
}
