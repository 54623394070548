import React, { useEffect } from "react";
import { loginSchema } from "./validation";
import { ForgotValues } from "./type";
import { Formik, Form } from "formik";
import { useAuth } from "../../../components/auth";
import { useNavigate } from "react-router";
import { getValueByKey } from "../../../utils/generalUtils";
import TextBox from "../../../components/common/textBox";
import { Button, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { leftArrow, leftLongArrow, leftSquareArrow } from "../../../constant/icons";

const initialValues: ForgotValues = {
  email: "",
};

const ForgotForm: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const token = getValueByKey("token");
  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, [token]);
  const handleSubmit = async (values: ForgotValues) => {
    try {
      await auth.forgot(values);
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
      <Form className="form-horizontal form-login row">
            <TextBox
              id={"email"}
              fieldName={"email"}
              placeholder={"Enter email"}
              className={"form-control"}
              type={"text"}
              labelName={"Email"}
            />
            <Col lg="12">
              <Button color="primary" block className="w-100" type="submit" disabled={isSubmitting}>
                {"Forgot"}
              </Button>
            </Col>
            <Col lg="12" className="text-center">
                <Link to="/forgot" className="mt-3 mx-auto back-signin">
                  {leftLongArrow.icon} Back to Sign in
                </Link>
              </Col>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotForm;
