import PageLayout from "../../components/layout/page";
import Report from "../Receipt/report";

export default function ReceiptReport() {
    return (
        <PageLayout className="dashboard-inner-page"
            View={
                <>
                    <Report />
                </>
            }
        />
    );
}
