import React from "react";
import { Button, Card, Col, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import moment from "moment";
import { crossIcon } from "../../../constant/icons";
import { ClientApi } from "../../masters/client";
import Dropdown from "../../../components/common/dropdown";
import { UserAPI } from "../../user";

const BookingFilter = (props: any) => {
  const {
    filterValues = {},
    setFilterValues,
    onCloseFilter,
    quotation_id = [],
  } = props;
  const clientApi = ClientApi(false);
  // Extract unique person names for the filter dropdown
  const personList = Array.isArray(clientApi?.data?.data)
    ? clientApi?.data?.data.map((name: any) => ({
        value: name?.id,
        label: name?.contactPerson,
      }))
    : [];
  const userData = UserAPI(false);

  let userList =
    userData?.data?.data?.length > 0
      ? userData?.data.data?.map((item: any) => {
          return { value: item.id, label: item.name, data: item };
        })
      : [];
  let status = [
    { value: true, label: "Active" },
    { value: false, label: "Deactive" },
  ];
  // Formik's onSubmit handler
  const handleSubmit = (values: any) => {
    setFilterValues({
      ...values,
      fromDate: values.fromDate
        ? moment(values.fromDate).format("DD-MM-YYYY")
        : null,
      toDate: values.toDate ? moment(values.toDate).format("DD-MM-YYYY") : null,
    });
    onCloseFilter();
  };
  return (
    <Card className="filter-box">
      <div className="d-flex justify-content-between">
        <h3>Filters</h3>
        <Button className="cancel_filter" color="link" onClick={onCloseFilter}>
          {crossIcon.icon}
        </Button>
      </div>
      <Formik
        initialValues={{
          client_id: filterValues.client_id || [],
          quotation_id: filterValues?.quotation_id || [],
          fromDate: filterValues?.fromDate
            ? moment(filterValues?.fromDate, "DD-MM-YYYY").toDate()
            : null,
          toDate: filterValues?.toDate
            ? moment(filterValues?.toDate, "DD-MM-YYYY").toDate()
            : null,
          userId: filterValues?.userId || "",
          status: "",
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <Form className="form-row">
              <Col className="mb-3">
                <label htmlFor="client_id">Client Name</label>
                <ReactSelect
                  name="client_id"
                  onChange={(selectedOptions: any) => {
                    const selectedValues = selectedOptions
                      ? selectedOptions.map((option: any) => option.value)
                      : [];
                    setFieldValue("client_id", selectedValues);
                  }}
                  isMulti={true}
                  options={personList}
                  value={personList.filter((option: any) =>
                    values.client_id.includes(option.value)
                  )}
                />
              </Col>
              <Col>
                <Label className="form-label">From Available Date</Label>
                <Field
                  component={DatePicker}
                  placeholderText={"DD/MM/YYYY"}
                  dateFormat={"dd/MM/yyyy"}
                  className="form-control"
                  menuPortalTarget={document.body}
                  selected={values?.fromDate}
                  onChange={(selectedDate: any) => {
                    setFieldValue("fromDate", selectedDate);
                  }}
                  isClearable={true}
                  // minDate={moment().toDate()}
                />
              </Col>
              <Col>
                <Label className="form-label">To Available Date</Label>
                <Field
                  component={DatePicker}
                  placeholderText={"DD/MM/YYYY"}
                  dateFormat={"dd/MM/yyyy"}
                  className="form-control"
                  menuPortalTarget={document.body}
                  selected={values?.toDate}
                  onChange={(selectedDate: any) => {
                    setFieldValue("toDate", selectedDate);
                  }}
                  isClearable={true}
                  // minDate={moment().toDate()}
                />
              </Col>
              <Col className="mb-3">
                <Dropdown
                  fieldName="userId"
                  labelName={"Booking By"}
                  options={userList}
                  setFieldValue={setFieldValue}
                  values={values}
                  isClearable={true}
                  selectedValue={values.userId}
                />
              </Col>
              <Col className="mb-3">
                <Dropdown
                  fieldName="status"
                  labelName={"Status"}
                  options={status}
                  setFieldValue={setFieldValue}
                  values={values}
                  isClearable={true}
                  selectedValue={values.status}
                />
              </Col>
              <Col md={12} className="mt-3">
                <Button type="submit" color="primary">
                  Apply Filters
                </Button>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default BookingFilter;
