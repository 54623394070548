import { DataType } from "ka-table";
import { DELETE, GENRATE, UPDATE } from "../../constant/commonConst";
import { confirmBox } from "../../components/toast";
import { TableColumn } from "../../components/table/table";
import { getParshData } from "../../utils/generalUtils";
import { ActionButtonView, StatusChangeView, StatusDropDown } from "./action";
import { Code } from "../../components/buttons/code";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "leadNo",
      title: "Lead No",
      dataType: DataType.Number,
      component: Code,
      visible: true,
    },
    {
      key: "leadBy",
      title: "Lead By",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.leadByUser?.name;
      },
    },
    {
      key: "contactPerson",
      title: "Contact Person Name",
      dataType: DataType.String,
    },
    // {
    //     key: 'client.businessName',
    //     title: 'business Name',
    //     dataType: DataType.String
    // },
    {
      key: "contactPersonEmailId",
      title: "Email Address",
      dataType: DataType.String,
    },
    {
      key: "contactPersonMobileNo",
      title: "Mobile No.",
      dataType: DataType.String,
    },
    {
      key: "status",
      title: "status",
      hideHeaderFilter: false,
      dataType: DataType.String,
      component: StatusDropDown,
      // component: StatusChangeView,
      callBack: async (row) => {
        const response = await confirmBox(
          "Do you want to change the status to close?"
        );
        if (response) {
          actions.api.status({
            id: row.rowData.id,
            status: row.data,
          });
          //  actions.api.status(row.id);
        }
      },
      style: {
        textAlign: "center",
        cursor: "pointer",
      },
    },
    {
      key: "edit",
      title: "",
      hideHeaderFilter: false,
      component: ActionButtonView,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            ClientId: row.data.clientId,
            // agencyId: row.data.agencyId,
            name: row.data.name,
            description: row.data.description,
            // mediaDescription: getParshData(row.data.mediaDescription),
            contactPerson: row.data.contactPerson,
            leadBy: row.data.leadBy || null,
            contactPersonEmailId: row.data.contactPersonEmailId || null,
            contactPersonMobileNo: row.data.contactPersonMobileNo,
            // contactPersonDesignation: row.data.contactPersonDesignation,
            // leadBy: row.data.leadBy,
            // status: row.data.status,
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox("you want to delete this lead?");
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        } else if (row.status === GENRATE) {
          actions.handlaFollowUp(row.data.id);
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
