import { Button, Card, Col, Input, Label } from "reactstrap"
import TextBox from "../../../components/common/textBox"
import { Field, Form, Formik } from "formik"
import { crossIcon } from "../../../constant/icons"
import DatePicker from "react-datepicker"
import ReactSelect from "react-select"
import { useMemo, useState } from "react"
import Dashboard from ".."
import { CityApi } from "../../masters/city"
import Client, { ClientApi } from "../../masters/client"
import { getDate } from "react-datepicker/dist/date_utils"
import moment from "moment"
import Landloard from "./landloard"
import { LandlordApi } from "../../masters/landlord"
import { useApi } from "../../../helper/apiHook"
import { AgencyApi } from "../../masters/agency"

export const DashboardFilter = (prop: any) => {
    const [minValue, setMinValue] = useState(prop.filtervalue?.minRate?prop.filtervalue?.minRate:10);
    const [maxValue, setMaxValue] = useState(prop.filtervalue?.maxRate?prop.filtervalue?.maxRate:50000);
    const [isFilterVisible, setFilterVisible] = useState(true); // State to manage form visibility

    const handleCloseFilter = () => {
        setFilterVisible(false); // Hide the filter form
    };
    const handleMinChange = (e: any) => {
        const value = Math.min(Number(e.target.value));
        setMinValue(value);
    };

    const handleMaxChange = (e: any) => {
        const value = Math.max(Number(e.target.value));
        setMaxValue(value);
    };
    const clientApiData = ClientApi(false);
    let clientApiDataList: any = useMemo(
        () =>
            clientApiData?.data?.data?.length > 0
                ? clientApiData.data?.data?.map((item: any) => {
                    return { value: item.id, label: item.contactPerson };
                })
                : [],
        [clientApiData]
    );

    const agencyApiData = AgencyApi(false);
    let agencyApiDataList: any = useMemo(
        () =>
            agencyApiData?.data?.data?.length > 0
                ? agencyApiData.data?.data?.map((item: any) => {
                    return { value: item.id, label: item.name };
                })
                : [],
        [agencyApiData]
    );
    const landLoardApiData = LandlordApi(false);
    let landLoardApiDataList: any = useMemo(
        () =>
            landLoardApiData?.data?.data?.length > 0
                ? landLoardApiData.data?.data?.map((item: any) => {
                    return { value: item.id, label: item.name };
                })
                : [],
        [landLoardApiData]
    );
    // Formik's onSubmit handler
    const handleSubmit = (values: any) => {
        // Construct the filter data
        prop.setFilterValue({
            ...prop.filtervalue, forDashbord: true,
            dashboardTab: prop.tabValue,
            cityIds: values?.cityIds?.map(Number),
            mediaTypes: values?.mediaTypes?.map(Number),
            mediaTypesLit: values?.mediaTypesLit?.map(Number),
            clientIds: values?.clientIds?.map(Number),
            agencyIds: values?.agencyIds?.map(Number),
            fromDate:    values?.fromDate? moment(values?.fromDate).format('YYYY-MM-DD') : null,
            toDate: values?.toDate? moment(values?.toDate).format('YYYY-MM-DD') : null,
            mediaStatus: values?.mediaStatus,
            width: values?.width?.map(Number),
            height: values?.height?.map(Number),
            landloardTypeIds: values?.landloardTypeIds?.map(Number),
            minRate: minValue>10?minValue:undefined,
            maxRate: maxValue<50000?maxValue:undefined
        })
        prop.setFilter(false)

    };

    return (
        <Card className="filter-box">
            <div className="d-flex justify-content-between">
                <h3>Filters</h3>
              
                <Button
                     className="cancel_filter"
                     color="link"
                     onClick={prop.onCloseFilter} // Call the passed function
                 >
                     {crossIcon.icon}
                 </Button>
                 </div>
            <Formik initialValues={prop.filtervalue} onSubmit={handleSubmit} enableReinitialize>
                {({ setFieldValue, values ,resetForm}) => (
                    
             
                    <Form className="form-row">
                        <Col md={3} className="mb-3">
                            <label htmlFor="cityIds">City</label>
                            <ReactSelect
                                name="cityIds"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                    setFieldValue("cityIds", selectedValues);
                                    // setSelectedCityId(selectedValues);
                                }}
                                isMulti={true}
                                options={prop.cityList}
                                value={
                                    values.cityIds?.length > 0 &&
                                    prop.cityList?.filter((option: any) =>
                                        values.cityIds?.includes(option.value)
                                    )
                                }
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <label htmlFor="cityIds">Select MediaType</label>
                            <ReactSelect
                                name="mediaTypes"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                    setFieldValue("mediaTypes", selectedValues);
                                    // setSelectedMediaTypeId(selectedValues);
                                }}
                                isMulti={true}
                                options={prop.mediaApiDataList}
                                value={
                                    values.mediaTypes?.length > 0 &&
                                    prop.mediaApiDataList?.filter((option: any) =>
                                        values.mediaTypes?.includes(option.value)
                                    )
                                }
                            />
                        </Col>
                        <Col md={3} className="mb-3">

                            <label htmlFor="cityIds">Select Media LIT</label>
                            <ReactSelect
                                name="mediaTypesLit"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                    setFieldValue("mediaTypesLit", selectedValues);
                                    // setSelectedMediaTypeLITId(selectedValues);
                                }}
                                isMulti={true}
                                options={prop.mediaLitApiDataList}
                                value={
                                    values.mediaTypesLit?.length > 0 &&
                                    prop.mediaLitApiDataList?.filter((option: any) =>
                                        values.mediaTypesLit?.includes(option.value)
                                    )
                                }
                            />


                        </Col>
                        <Col md={3}>

                            <label htmlFor="cityIds">Select Client Name</label>
                            <ReactSelect
                                name="Select Client Name"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                    setFieldValue("clientIds", selectedValues);
                                    // setSelectedClientIds(selectedValues);
                                }}
                                isMulti={true}
                                options={clientApiDataList}
                                value={
                                    values.clientIds?.length > 0 &&
                                    clientApiDataList?.filter((option: any) =>
                                        values.clientIds?.includes(option.value)
                                    )
                                }
                            />
                        </Col>
                        <Col md={3}>

                            <label htmlFor="cityIds">Select Agency</label>
                            <ReactSelect
                                name="Select Agency"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                    setFieldValue("agencyIds", selectedValues);
                                    // setSelectedAgencyIds(selectedValues);
                                }}
                                isMulti={true}
                                options={agencyApiDataList}
                                value={
                                    values.agencyIds?.length > 0 &&
                                    agencyApiDataList?.filter((option: any) =>
                                        values.agencyIds?.includes(option.value)
                                    )
                                }
                            />
                        </Col>
                        <Col md={3}>
                            <Label className="form-label">
                                From Available Date
                            </Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values.fromDate}
                                onChange={(selectedDate: any) => {
                                    // setSelectedFromDate(selectedDate);
                                    setFieldValue("fromDate", selectedDate);
                                }}
                                minDate={moment().toDate()}

                            />

                        </Col>
                        <Col md={3}>
                            <Label className="form-label">
                                To Available Date
                            </Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values.toDate}
                                onChange={(selectedDate: any) => {
                                    // setSelectedToDate(selectedDate);
                                    setFieldValue("toDate", selectedDate);
                                }}
                                minDate={moment().toDate()}
                            />
                        </Col>

                        <Col md={2}>

                            <label htmlFor="cityIds">Select Media Status</label>
                            <ReactSelect
                                options={prop.mediaStatusOptions}
                                onChange={(option: any) => {
                                    setFieldValue("mediaStatus", option.value);
                                    // setSelectedMediaStatusId(option.value);
                                }}
                                value={prop.mediaStatusOptions?.filter((option: any) => {
                                    return option.value === values.mediaStatus
                                })
                                }
                            />


                        </Col>
                        <Col md={2} className="mt-2">
                            <label htmlFor="cityIds">Select width</label>
                            <ReactSelect
                                name="Select width"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                    setFieldValue("width", selectedValues);
                                    // setselectedWidth(selectedValues);
                                }}
                                isMulti={true}
                                options={prop.setWidth}
                                value={
                                    values.width?.length > 0 &&
                                    prop.setWidth?.filter((option: any) =>
                                        values.width?.includes(option.value)
                                    )
                                }
                            />




                        </Col>
                        <Col md={2} className="mt-2">
                            <label htmlFor="cityIds">Select height</label>
                            <ReactSelect
                                name="Select height"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                    setFieldValue("height", selectedValues);
                                    // setselectedHeight(selectedValues);
                                }}
                                isMulti={true}
                                options={prop.setHeight}
                                value={
                                    values.height?.length > 0 &&
                                    prop.setHeight?.filter((option: any) =>
                                        values.height?.includes(option.value)
                                    )
                                }
                            />


                        </Col>
                        <Col md={2} className="mt-2" >

                            <label htmlFor="cityIds">Select Landlord</label>
                            <ReactSelect
                                name="Select Landlord Type"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                    setFieldValue("landloardTypeIds", selectedValues);
                                    // setSelectedLandlordTypeLITId(selectedValues);
                                }}
                                isMulti={true}
                                options={landLoardApiDataList}
                                value={
                                    values.landloardTypeIds?.length > 0 &&
                                    landLoardApiDataList?.filter((option: any) =>
                                        values.landloardTypeIds?.includes(option.value)
                                    )
                                }
                            />


                        </Col>
                        <Col md={4} className="mt-2">
                            <div className="double_range_slider_box">
                                <div className="minvalue">
                                    <span>Agency Card Min Rate</span>
                                    <input
                                        type="number"
                                        className="input-min"
                                        min="10"
                                        max="50000"
                                        value={minValue}

                                        onChange={handleMinChange}
                                        
                                    />
                                </div>
                                <div className="double_range_slider">
                                    <div className="slider_container">
                                        <span className="range_track" id="range_track"></span>
                                        <input
                                            type="range"
                                            className="min"
                                            min="10"
                                            max="50000"
                                            value={minValue}
                                            step="1"
                                            onChange={handleMinChange}
                                           
                                        />
                                        <input
                                            type="range"
                                            className="max"
                                            min="10"
                                            max="50000"
                                            value={maxValue}
                                            step="1"
                                            onChange={handleMaxChange}
                                        />
                                    </div>
                                </div>
                                <div className="maxvalue">
                                    <span>Agency Card Max Rate</span>
                                    <input
                                        type="number"
                                        className="input-max"
                                        min="10"
                                        max="50000"
                                        value={maxValue}
                                        onChange={handleMaxChange}


                                    />
                                </div>
                            </div>

                        </Col>
                        <Col className="align-self-center mt-2">
                            <div className="btn-box">
                                <Button
                                    color="secondary"
                                    className="me-2"
                                    onClick={() => {
                                        resetForm({ values: [] });
                                        setMinValue(10);
                                        setMaxValue(50000);
                                      }}
                                    outline
                                
                                >
                                    Clear
                                </Button>
                                <Button
                                    color="secondary"
                                >
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Form>
                                    )}
            </Formik>
        </Card>
    )
}