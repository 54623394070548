import useSWR from "swr";
import { fetcher, invokeApi } from "./api";
import { useState } from "react";
import { serverRequest } from "./types";
import { useAuth } from "../components/auth";
import { DELETE, GET, PATCH, POST, PUT } from "../constant/commonConst";
import { useLoader } from "../components/loader";

export interface useApiType {
  data: any;
  isLoading: boolean;
  error: any;
  rowCount: any;
  create: (value: any) => Promise<any>;
  updateValue: (value: any, id: String | number) => void;
  removeValue: (id: String | number) => void;
  status: (value: any) => void;
  GetValue: (url: string, body?: object) => any;
  serverRequest: (props: serverRequest) => any;
}
interface paginationType {
  skip: number;
  take: Number;
  limit?: Number;
  pageNo?: Number;
}
// interface useApIType {
//   route: string;
//   IsPagination?: boolean;
//   body?: any;
//   dataLoad?: any;
//   isDataLoading?: boolean;
// }

export const useApi = (
  route: string,
  IsPagination: boolean = false,
  body: any = {},
  dataLoad: any = {},
  isDataLoading: boolean = false
) => {
  const auth = useAuth();
  const loading = useLoader();

  const routeLink = `${route}`;
  const [pagination, setPagination] = useState<paginationType>({
    skip: 0,
    take: 10,
    limit: 10,
    pageNo: 0,
  });
  let bodyOfRequest = { ...body };

  if (IsPagination) {
    bodyOfRequest = { ...bodyOfRequest, ...pagination };
  }
  const { data, error, isLoading, mutate,isValidating } = useSWR<any, Error>(
    auth.user && !isDataLoading ? [routeLink, bodyOfRequest] : null,
    () => fetcher(routeLink, bodyOfRequest, loading),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      ...dataLoad,
    }
  );

  let dataList: any = [];
  if (IsPagination && data && data.data?.length > 0) {
    dataList = data.data;
  } else {
    dataList = data;
  }

  const dataRefresh = (result: any) => {
    if (result?.status) {
      mutate();
    }
    return result;
  };
  const updatePagination = () => {
    function updatePageLimit(pageLimit: number = 10) {
      setPagination({
        ...pagination,
        skip:
          pageLimit *
          (pagination.pageNo === undefined ? 0 : (pagination.pageNo as number)),
        take: pageLimit,
        limit: pageLimit,
      });
    }
    function updateCurrentPageNo(currentPageNo: number = 0) {
      setPagination({
        ...pagination,
        skip:
          (pagination.limit === undefined ? 0 : (pagination.limit as number)) *
          currentPageNo,
        take: pagination.limit === undefined ? 0 : (pagination.limit as number),
        pageNo: currentPageNo,
      });
    }
    function getCurrentPageNo(): number {
      return pagination.pageNo === undefined
        ? 0
        : (pagination.pageNo as number);
    }
    function getTotalPage(): number {
      if (data && data.length > 0) {
        let totalPage = Math.ceil(
          data?.total /
            (pagination.limit === undefined ? 0 : (pagination.limit as number))
        );
        if (totalPage < 1) totalPage = 1;
        return totalPage;
      } else {
        return 1;
      }
    }
    return {
      updatePageLimit,
      updateCurrentPageNo,
      getCurrentPageNo,
      getTotalPage,
    };
  };

  const status = async (values: any) => {
    return await invokeApi(PATCH, `${routeLink}`, values).then(dataRefresh);
  };
  const create = async (values: any): Promise<any> => {
    return await invokeApi(POST, routeLink, values).then(dataRefresh);
  };
  const updateValue = async (value: any) => {
    return await invokeApi(PUT, routeLink, value).then(dataRefresh);
  };
  const removeValue = async (id: String | number) => {
    return await invokeApi(DELETE, `${routeLink}/${id}`).then(dataRefresh);
  };

  return {
    data: dataList,
    isLoading,
    error,
    rowCount:
      dataList === undefined || data === undefined
        ? 0
        : IsPagination && data.data?.length > 0
        ? data?.total
        : dataList.count,
    currentPage:
      dataList === undefined || data === undefined
        ? 0
        : IsPagination && data.data?.length > 0
        ? data?.pageNo
        : 0,
    limit:
      dataList === undefined || data === undefined
        ? 0
        : IsPagination && data.data?.length > 0
        ? data?.limit
        : 0,
    create,
    updateValue,
    removeValue,
    status,
    // GetValue,
    mutate,
    updatePagination,
    isValidating
    // serverRequest,
  };
};
