import { useState } from "react";
import { detailInfo, viewIcon } from "../../constant/icons";
import { Button, UncontrolledTooltip } from "reactstrap";
import { VIEW } from "../../constant/commonConst";

const DetailControl = (props: any) => {
  const { column, rowData } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>
      <Button
        color="link"
        onClick={() => {
            column.callBack({ data: rowData, status: VIEW });
        }}
        className="text-info"
        id="detailsTooltip"
      >
        {viewIcon.icon}
      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="detailsTooltip"
        toggle={toggleTooltip}
      >
        Details
      </UncontrolledTooltip>
    </>
  );
};

export { DetailControl };
