import PageLayout from "../../components/layout/page";
import Report from "../printing/report";

export default function PrintingReport() {
    return (
        <PageLayout className="dashboard-inner-page"
            View={
                <>
                    <Report />
                </>
            }
        />
    );
}
export {}