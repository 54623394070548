import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { ActionButtonView } from "../../../components/buttons/actionButtons";
import { DELETE, UPDATE, VIEW } from "../../../constant/commonConst";
import { confirmBox, message } from "../../../components/toast";
import { StatusDropDown } from "./helper"
export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "name",
      title: "Media Id",
      dataType: DataType.String,
      valueConvert: (value: any, row: any) => {
        if (row?.routeId > 0) {
          return row?.route?.name;
        }
        return value;
      },
    },
    {
      key: "state.name",
      title: "State",
      dataType: DataType.String,
    },
    {
      key: "city.name",
      title: "city",
      dataType: DataType.String,
    },
    {
      key: "zone",
      title: "zone",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.zone?.name;
      },
    },
    {
      key: "location",
      title: "location",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.location?.name;
      },
    },
    {
      key: "status",
      title: "status",
      hideHeaderFilter: false,
      dataType: DataType.String,
      component: StatusDropDown,
      callBack: async (row) => {
        if (row.rowData.status === "Booked"|| row.data === "Booked") {
          return message("error", "Cannot update status to booked")
        }
        actions.api.status({
          id: parseInt(row.rowData.id),
          status: row.data,
        });
      },
      style: {
        textAlign: "center",
        cursor: "pointer",
      },
    },
    {
      key: "",
      title: "",
      component: ActionButtonView,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            name: row.data.name,
            stateId: row.data.stateId,
            cityId: row.data.cityId,
            zoneId: row.data.zoneId,
            locationId: row.data.locationId,
            routeId: row.data.routeId,
            direction: row.data.direction,
            latitude: row.data.latitude,
            longitude: row.data.longitude,
            landlordId: row.data.landlordId,
            mediaTypeId: row.data.mediaTypeId,
            mediaType: row.data.mediaType.type,
            mediaLitId: row.data.mediaLitId,
            imageURL: row.data.imageURL,
            mediaSeriesId: row.data.mediaSeriesId,
            width: row.data.width,
            agencyId: row.data.agencyId,
            rateAgency: row.data.rateAgency,
            height: row.data.height,
            qty: row.data.qty || 1,
            BalanceQty: row.data.BalanceQty,
            IsActivate: row.data.IsActivate,
            image: row.data.image ? JSON.parse(row.data.image) : [],
            landlordInfo: row.data.mediaItemLandlord,
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox(
            "you want to delete this media item?"
          );
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        } else if (row.status === VIEW) {
          actions.view(`/mediaItem/${row.data.id}`);
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
