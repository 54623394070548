import PageLayout from "../../../components/layout/page";
import { DataView } from "../../../components/table/table";
import { useApi } from "../../../helper/apiHook";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import { AddUpdateIndustry } from "./addUpdate";
import { Button } from "reactstrap";
import { plus } from "../../../constant/icons";
import { columns } from "./columns";
import { useEffect } from "react";
import ModalView from "../../../components/modal";

export const MediaSeriesApi = (paging?:boolean, dataLoad: any = {}) => {
  return { ...useApi("/mediaSeries",paging, undefined, dataLoad) };
};

export default function Series() {
  const api = MediaSeriesApi(true);
  useEffect(() => {
    api.mutate();
  }, []);
  const AddUpdate = useAddUpdate();
  return (
    <>
      <PageLayout
        item={
          <Button
            color="secondary"
            onClick={() =>
              AddUpdate.openAddEditFrom({
                name: "",
                description: "",
              })
            }
          >
            {plus.icon}Add New
          </Button>
        }
        View={
          <DataView
            columns={columns({
              api,
              openAddEditFrom: AddUpdate.openAddEditFrom,
            })}
            rows={api.data}
            currentPage={api.currentPage}
            rowCount={api.rowCount}
            limit={api.limit}
            updatePagination={api.updatePagination}
          />
        }
      />{" "}
      {AddUpdate.viewAddUpdate && (
        <ModalView
          modalView={AddUpdate.viewAddUpdate}
          modalName={
            AddUpdate.currentValue?.id ? "Edit Media Series" : "Add Media Series"
          }
          onModalDismiss={AddUpdate.closeAddEditFrom}
        >
          <AddUpdateIndustry
            closeAddUpdate={AddUpdate.closeAddEditFrom}
            api={api}
            value={AddUpdate.currentValue}
          />
        </ModalView>
      )}
    </>
  );
}
