import { DataType } from "ka-table";
import { ActionButton } from "../../../components/buttons/actionButtons";
import { TableColumn } from "../../../components/table/table";
import { confirmBox } from "../../../components/toast";
import { DELETE, UPDATE } from "../../../constant/commonConst";
export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "name",
      title: "Name",
      dataType: DataType.String,
    },
    {
      key: "rate",
      title: "Rate PER SQ.FT",
      dataType: DataType.String,
    },

    {
      key: "edit",
      title: "",
      hideHeaderFilter: false,
      component: ActionButton,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            name: row.data.name,
            rate: row.data.rate,
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox("you want to delete this state?");
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
