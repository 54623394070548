import React, { useEffect, useState } from "react";
import {
  TabContent,
  Col,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Button,
  Badge,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import { Formik } from "formik";
import ImageUpload from "../masters/items/imageInput";
import { useUpload } from "../masters/items/helper";
import { crossIcon } from "../../constant/icons";
import ProtectedImage from "../../components/common/image";
import { API_URL, invokeApi } from "../../helper/api";
import { GET } from "../../constant/commonConst";
import moment from "moment";

const ViewMounting = (props: any) => {
  const { value } = props;
  const imageUpload = value?.mountingShot ? JSON.parse(value.mountingShot) : "";
  const mountingImage = value?.mountingImages
    ? JSON.parse(value.mountingImages)
    : "";
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [image, setImage] = useState();
  useEffect(() => {
    const fetchImages = async () => {
      const urls: string[] = [];

      for (const image of imageUpload) {
        if (image?.url) {
          try {
            const response = await invokeApi(
              GET,
              API_URL + image.url,
              undefined,
              undefined,
              {
                responseType: "blob", // Ensure we get the response as a Blob
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result as string;
              urls.push(base64String); // Add the Base64 string to the array
              // Once all images are processed, update the state
              if (urls.length === imageUpload.length) {
                setImageUrls(urls);
              }
            };
            reader.readAsDataURL(response); // Convert Blob to Base64
          } catch (error) {
            console.log("Error fetching image:", error);
          }
        }
      }
      if (mountingImage) {
        try {
          const response = await invokeApi(
            GET,
            API_URL + mountingImage.url,
            undefined,
            undefined,
            {
              responseType: "blob",
            }
          );

          const reader: any = new FileReader();
          reader.onloadend = () => {
            setImage(reader.result); // Set the Base64 string as image URL
          };
          reader.readAsDataURL(response); // Convert Blob to Base64
        } catch (error) {
          console.log("Error fetching image:", error);
        }
      }
    };

    fetchImages();
  }, [value.mountingShot]);
  return (
    <div className="printing-view">
      <div className="booking-num">
        <p>Booking No:</p>
        <Badge color="black" className="badge-num">
          {value.bookingNo}
        </Badge>
      </div>

      <div>
        {/* <Row className="mb-3">
          <Col>
            Display posterImageUrl if available
            {value.posterImageUrl ? (
              <a href="#">
                <img className="view-image" src={value.posterImageUrl} alt="Poster" />
              </a>
            ) : (
              <p>No Poster Image</p>
            )}
          </Col>

          <Col>
            <Card className="multiple-url">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Mounting Shots:</h5>
              </div>

              Display mountingImages
              <ol className="creative-url">
                {value.mountingImages && (
                  <li>
                    <a href={JSON.parse(value.mountingImages).url} target="_blank">
                      Mounting Image
                    </a>
                  </li>
                )}
              </ol>
            </Card>
          </Col>
        </Row> */}

        <Row className="mb-3">
          <Col>
            <Card>
              <ul className="poster-list">
                <li>
                  <div className="poster-list-item">
                    <span>Task No:</span>
                    <strong>
                      <Badge className="badges">{value.taskNo}</Badge>
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Booking ID:</span>
                    <strong>{value.bookingNo}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Mounting Time:</span>
                    <strong>{value.mountingTime}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>From Date:</span>
                    <strong>
                      {moment(value.fromDate).format("DD/MM/YYYY")}
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>To Date:</span>
                    <strong>
                      {value.toDate
                        ? moment(value.toDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Assign To:</span>
                    <strong>
                      {value.assignTo !== null ? value.assignTo : "N/A"}
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Assign By:</span>
                    <strong>
                      {value.assignTo !== null ? value.assignTo : "N/A"}
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Status:</span>
                    <strong>
                      <Badge className="badge bg-success rounded-pill">
                        {value.status !== null ? value.status : "N/A"}
                      </Badge>
                    </strong>
                  </div>
                </li>
                {/* <li>
                  <div className="poster-list-item">
                    <span>Mounting Shots:</span>
                    <strong>
                      <ol>
                        {console.log(value, "JSON.parse(value.mountingShot)")}
                        {value.mountingShot &&
                          JSON.parse(value.mountingShot).map((shot: any, index: number) => (
                            <li key={index}>
                              <a href={shot.url} target="_blank">
                                {shot.tagname}
                              </a>
                            </li>
                          ))}
                      </ol>
                    </strong>
                  </div>
                </li> */}
                <li>
                  <div className="poster-list-item">
                    <span>Media Name:</span>
                    <strong>{value.mediaItem?.name || "N/A"}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Media Quantity:</span>
                    <strong>{value.mediaItem?.qty || "N/A"}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Media Dimensions:</span>
                    <strong>
                      {value.mediaItem?.height} x {value.mediaItem?.width}
                    </strong>
                  </div>
                </li>
                {/* <li>
                  <div className="poster-list-item">
                    <span>Route Coordinates:</span>
                    <strong>
                      Start: ({value.mediaItem.route.startLatitude}, {value.mediaItem.route.startLongitude})
                      <br />
                      End: ({value.mediaItem.route.endLatitude}, {value.mediaItem.route.endLongitude})
                    </strong>
                  </div>
                </li> */}
              </ul>
            </Card>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Card className="mb-3">
              <CardBody>
                <div className="">
                  <h5>Mounting Shots:</h5>
                  <Row className="mounting-shot-image">
                    {imageUrls.map((url: any) => (
                      <Col className="mb-2" md={3}>
                        <div className="mounting-images">
                          <img src={url} />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="creative-images mt-2">
                  <h5>Creative image:</h5>
                  <Row className="mounting-shot-image">
                    <Col className="mb-2" md={3}>
                      <div className="mounting-images">
                        {image && <img src={image} />}
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export { ViewMounting };

export const UploadImage = (props: any) => {
  const [activeTab, setActiveTab] = useState("2");
  const imageUpload = useUpload(1);
  const { rowData, uploadImage, api, closeAddUpdate, uploadImageApi }: any =
    props;
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const JSONparseCreativeUrl = uploadImage?.mountingImages
    ? JSON.parse(uploadImage?.mountingImages)
    : "";
  useEffect(() => {
    imageUpload.setImagePreviews(
      JSONparseCreativeUrl?.url
        ? [
            {
              path: JSONparseCreativeUrl?.url,
              isPrimary: false,
              id: "undefined",
            },
          ]
        : []
    );
  }, [JSONparseCreativeUrl?.url]);
  return (
    <div className="media-upload-modal">
      <Nav tabs>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            URL Upload
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Image Upload
          </NavLink>
        </NavItem>
      </Nav>
      <Formik
        initialValues={{ url: "" }}
        onSubmit={(values) => {
          const formdata = new FormData();
          const value: any = {
            deleteImage: JSONparseCreativeUrl?.url?.substring(1),
            id: uploadImage?.id ? parseInt(uploadImage.id) : "",
          };
          if (imageUpload.imagePreviews[0]?.id !== "undefined") {
            formdata.append(
              `${imageUpload.imagePreviews[0]?.id}`,
              imageUpload.selectedImages[0]
            );
          }

          for (const key in value) {
            if (value[key] !== null && value[key] !== undefined) {
              formdata.append(key, value[key]);
            }
          }
          api.updateValue(formdata).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
              uploadImageApi.mutate();
            }
          });
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <TabContent
              activeTab={activeTab}
              className="booking-master-imagebox"
            >
              {/* <TabPane tabId="1">
                <FormGroup noMargin>
                  <Label for="url">URL</Label>
                  <Input
                    type="url"
                    name="url"
                    id="url"
                    placeholder="Enter URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.url}
                  />
                </FormGroup>

              </TabPane> */}
              <TabPane tabId="2">
                <FormGroup noMargin>
                  {/* <Label for="url">Upload Image</Label> */}
                  {imageUpload.imagePreviews?.length === 0 && (
                    <ImageUpload
                      imageUpload={imageUpload}
                      accept={"image/*"}
                      isDisabled={imageUpload.selectedImages?.length >= 4}
                    />
                  )}{" "}
                  {imageUpload.imagePreviews.map((preview: any, index: any) => (
                    <div
                      key={index}
                      className="images-item"
                      style={{
                        backgroundColor: preview.isPrimary ? "#52a9ff" : "",
                      }}
                    >
                      {preview?.path ? (
                        <>
                          <ProtectedImage value={preview?.path} />
                        </>
                      ) : (
                        <img
                          src={preview.url}
                          alt={`preview-${index}`}
                          onClick={() =>
                            imageUpload.handleSetPrimaryImage(index)
                          }
                          width={25}
                          height={25}
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                          }}
                        />
                      )}

                      <button
                        className="remove-box"
                        onClick={() => imageUpload.handleRemoveImage(index)}
                        title="Remove"
                      >
                        {crossIcon.icon}
                      </button>
                    </div>
                  ))}
                </FormGroup>
              </TabPane>
            </TabContent>
            <div className="d-flex justify-content-end">
              <Button type="submit">
                {activeTab === "1" ? "Submit URL" : "Submit"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
