import * as Yup from 'yup';
import * as message from '../../../constant/message';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(message.ROUTE_REQUIRED),
  startLatitude: Yup.number().required(message.START_LATITUDE_REQUIRED),
  endLatitude: Yup.number().required(message.END_LATITUDE_REQUIRED),
  startLongitude: Yup.number().required(message.START_LONGITUDE_REQUIRED),
  endLongitude: Yup.number().required(message.END_LONGITUDE_REQUIRED),
  // startLandmarkDescription: Yup.string().required(message.START_LANDMARK_REQUIRED),
  // endLandmarkDescription: Yup.string().required(message.END_LANDMARK_REQUIRED),
});
