import { useState } from "react";
import { Button, Tooltip, } from "reactstrap";
import { detailInfo } from "../../constant/icons";
interface TooltipProps {
    description: string;
    id: number
}
export function TooltipItem(props: TooltipProps) {
    const { description, id } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <div className="zone-detail-btn">
            <a className="zone-detail-icon" id={'Tooltip-' + id}>
                {detailInfo.icon} Zone Detail
            </a>
            <Tooltip
                placement={"bottom"}
                isOpen={tooltipOpen}
                target={'Tooltip-' + id}
                toggle={toggle}
            >
                {description}
            </Tooltip>
        </div>
    );
}
