import * as Yup from "yup";
import {
  AGENCY_REQUIRED,
  AMOUNT_REQUIRED,
  BANK_NAME_REQUIRED,
  BOOKING_NO_REQUIRED,
  CHEQUE_NO_REQUIRED,
  CONTACT_PERSON_REQUIRED,
  LANDLORD_AGENCY_ID_REQUIRED,
  PAYMENT_DATE_REQUIRED,
  PAYMENT_MODE_REQUIRED,
  PERSON_NAME_REQUIRED,
  VENDOR_TYPE_REQUIRED,
} from "../../constant/message";

export const validationSchema = Yup.object().shape({
  vendor_type: Yup.string().required(VENDOR_TYPE_REQUIRED),
  landlord_agency_id: Yup.string().required(LANDLORD_AGENCY_ID_REQUIRED),
  personName: Yup.string().required(PERSON_NAME_REQUIRED),
  paymentDate: Yup.string().required(PAYMENT_DATE_REQUIRED),
  amount: Yup.string().required(AMOUNT_REQUIRED),
});
