import { Button, Card, Col, Row } from "reactstrap";
import { Form, Formik } from "formik";
import TextBox from "../../../components/common/textBox";
import Dropdown from "../../../components/common/dropdown";
import { CityApi } from "../city";
import { StateApi } from "../state";
import { validationSchema } from "./validation";

const AddUpdateAgency = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const stateData = StateApi(false, {revalidateIfStale: true});
  const cityData = CityApi(false, {revalidateIfStale: true});
  let stateList =
    stateData?.data?.data?.length > 0
      ? stateData.data.data.map((item: any) => {
          return { value: item.id, label: item.name };
        })
      : [];
  let cityList =
    cityData?.data?.data?.length > 0
      ? cityData?.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
      : [];

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={value}
      onSubmit={(values: any, actions: any) => {
        if (values.id) {
          api.updateValue(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        } else {
          api.create(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="form-main agency-form">
          <Card>
            <h3>Agency Detail</h3>
            <Row>
              <Col md={4} className="mb-3">
                <TextBox
                  fieldName="name"
                  placeholder="Agency name"
                  className="form-control"
                  type="text"
                  isRequired={true}
                  labelName="Agency name"
                  value={value.name}
                />
              </Col>
              <Col md={4} className="mb-3">
                <TextBox
                  fieldName="alias"
                  placeholder="for ex: CMD"
                  className="form-control"
                  type="text"
                  isRequired={true}
                  labelName="Alias"
                  value={value.GSTINNo}
                />
              </Col>
              <Col md={4} className="mb-3">
                <TextBox
                  fieldName="GSTINNo"
                  placeholder="00-XXXXX0000X-0X0"
                  className="form-control"
                  type="text"
                  labelName="GSTIN No"
                  value={value.GSTINNo}
                />
              </Col>
              <Col md={4} className="mb-3">
                <TextBox
                  fieldName="PANNo"
                  placeholder="ABCDE1234F"
                  className="form-control"
                  type="text"
                  labelName="PAN No"
                  value={value.PANNo}
                />
              </Col>
              <Col md={4}>
                <TextBox
                  fieldName="websiteLink"
                  placeholder="https://example.com"
                  className="form-control"
                  type="text"
                  labelName="Website Link"
                  value={value.websiteLink}
                />
              </Col>
              <Col md={4}>
                <Dropdown
                  fieldName="stateId"
                  labelName="State"
                  options={stateList}
                  setFieldValue={setFieldValue}
                  values={values}
                  selectedValue={values.stateId}
                />
              </Col>
              <Col md={4}>
                <Dropdown
                  fieldName="cityId"
                  labelName="City"
                  options={cityList}
                  setFieldValue={setFieldValue}
                  values={values}
                  selectedValue={values.cityId}
                />
              </Col>
            </Row>
          </Card>
          <Card>
            <h3>Agency Contact Person</h3>
            <Row>
              <Col>
                <TextBox
                  fieldName="contactPerson"
                  placeholder="Contact person"
                  className="form-control"
                  type="text"
                  labelName="Contact Person"
                  value={value.contactPerson}
                />
              </Col>
              <Col>
                <TextBox
                  fieldName="contactEmail"
                  placeholder="name@example.com"
                  className="form-control"
                  type="email"
                  labelName="Contact Person Email"
                  value={value.contactEmail}
                />
              </Col>
              <Col>
                <TextBox
                  fieldName="contactMobile"
                  placeholder="(000) 000-0000"
                  className="form-control"
                  type="text"
                  labelName="Contact Person Mobile No"
                  value={value.contactMobile}
                />
              </Col>              
              <Col lg={12} className="mt-3">
                <TextBox
                  fieldName="address"
                  placeholder="Enter address"
                  className="form-control"
                  type="textarea"
                  rows="4"
                  labelName="Address"
                  value={value.address}
                />
              </Col>
            </Row>     
          </Card>     
          <div className="d-flex justify-content-end gap-2">
            <Button
              color="secondary"
              outline
              type="button"
              onClick={() => closeAddUpdate()}
            >
              Cancel
            </Button>
            <Button color="secondary" solid type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export { AddUpdateAgency };
