
import AppMainLogo from "../../assets/images/login_bg_05.jpg";
import ResetPasswordImage from "../../assets/images/login_bg_05.jpg";
import ForgotPasswordImage from "../../assets/images/login_bg_05.jpg";


const LogInTitle = "sign in"
const LogInSubTitle = "Enter your login credentials to access your account."


const ResetPasswordTitle = "reset password"
const ResetPasswordSubTitle = "Set the new password for your account so you can login."


const ForgotPasswordTitle = "forgot password"
const ForgotPasswordSubTitle = "Enter your email address below and we'll send you password reset link."
const GOOGLE_MAPS_API_KEY ="AIzaSyCRiOhv-8F7NUHE22gm9zres6rVFwlkXEE" //"AIzaSyDxRAJxNrTXZu-dIm2Jhm1oACbhvBNh8wE";
const GOOGLE_API = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`
export { AppMainLogo, ResetPasswordImage, ForgotPasswordTitle, ForgotPasswordSubTitle, ForgotPasswordImage, GOOGLE_API, GOOGLE_MAPS_API_KEY, LogInTitle, LogInSubTitle, ResetPasswordTitle, ResetPasswordSubTitle }