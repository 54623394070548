import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import { DataView } from "../../../components/table/table";
import { useApi } from "../../../helper/apiHook";
import { LeadReportFilter } from "./filter";
import { Button } from "reactstrap";
import { downloadIcon, filterIcon } from "../../../constant/icons";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { getFormattedDate } from "../../../utils/dateUtils";

export default function ClientList() {
  const [filterValues, setFilterValues] = useState<any>({});
  const [filter, setFilter] = useState<boolean>(false);
  const api = useApi("/clients/report", true, filterValues);
  useEffect(() => {
    api.mutate();
  }, []);

  const handleShowFilter = () => {
    setFilter(!filter);
  };

  const exportToExcel = async () => {
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define the headers
    const headers = [
      { header: "Business Name", key: "businessName", width: 30 },
      { header: "Booking Date", key: "bookingDate", width: 25 },
      { header: "Booking No", key: "bookingNo", width: 20 },
      { header: "Total Amount", key: "totalAmount", width: 20 },
      { header: "Received Amount", key: "receivedAmount", width: 20 },
      { header: "Balance", key: "balance", width: 20 },
    ];

    // Add the header row with styles
    worksheet.columns = headers;

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White text
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "000000" }, // Black background
      };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Map your API data
    const data = api.data.map((item: any) => ({
      businessName: item.businessName || "",
      bookingDate: getFormattedDate(item.bookingDate),
      bookingNo: item.bookingNo || "",
      totalAmount: item.totalAmount || 0,
      receivedAmount: item.recivedAmount || 0,
      balance: item.balance || 0,
    }));
    // Add the data rows
    data.forEach((item: any) => {
      worksheet.addRow(item);
    });
    // Generate buffer for Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    // Create a Blob and trigger the download
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `ledger_Report_${new Date().toISOString().slice(0, 10)}.xlsx`);
  };

  return (
    <>
      <div
        className="d-flex justify-content-end align-items-end"
        style={{ paddingRight: "1rem" }}
      >
        <div className="head-btn-box">
          <Button
            color="secondary"
            type="button"
            outline
            onClick={exportToExcel}
          >
            {downloadIcon.icon}
            Download
          </Button>
          <Button
            color="secondary"
            type="button"
            outline
            onClick={handleShowFilter}
          >
            {filterIcon.icon}
            Filter
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "3rem" }}>
        <DataView
          columns={columns({
            api,
          })}
          rows={api.data}
          updatePagination={api.updatePagination}
          currentPage={api.currentPage}
          rowCount={api.rowCount}
          limit={api.limit}
        />
        {filter && (
          <LeadReportFilter
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            onCloseFilter={handleShowFilter}
          />
        )}
      </div>
    </>
  );
}
