import { useEffect } from "react";
import { Button } from "reactstrap";
import PageLayout from "../../../components/layout/page";
import ModalView from "../../../components/modal";
import { DataView } from "../../../components/table/table";
import { plus } from "../../../constant/icons";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import { useApi } from "../../../helper/apiHook";
import { AddUpdateMaterial } from "./addUpdate";
import { columns } from "./columns";

export const MaterialApi = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/material", paging, undefined, dataLoad) };
};


export default function Material() {
  const api = MaterialApi(true);
  const AddUpdate = useAddUpdate();
  useEffect(() => {
    api.mutate();
}, []);
  return (
    <>
      <PageLayout
        item={
          <Button
            color="secondary"
            onClick={() =>
              AddUpdate.openAddEditFrom({
                name: "",
                rate:""
              })
            }
          >
            {plus.icon}Add New
          </Button>
        }
        View={
          <>
            <DataView
              columns={columns({ api,openAddEditFrom: AddUpdate.openAddEditFrom, })}
              rows={api.data}
              updatePagination={api.updatePagination}
              currentPage={api.currentPage}
              rowCount={api.rowCount}
              limit={api.limit}
            />
          </>
        }
        />
        {AddUpdate.viewAddUpdate && (
          <ModalView
              modalView={AddUpdate.viewAddUpdate}
              modalName={AddUpdate.currentValue?.id ? "Edit Material" : "Add Material"}
              onModalDismiss={AddUpdate.closeAddEditFrom}
              modalsize={"lg"}
          >
              <AddUpdateMaterial
                  closeAddUpdate={AddUpdate.closeAddEditFrom}
                  api={api}
                  value={AddUpdate.currentValue}
              />
          </ModalView>
      )}
    </>
  );
}
