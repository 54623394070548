import { ClientApi } from "../masters/client";


export default function apiHelper() {

  const clientData = ClientApi(false, { revalidateIfStale: true });

  let clientList = clientData?.data?.data?.length > 0 ? clientData?.data.data?.map((item: any) => {
    return { value: item.id, label: item.businessName, data: item }
  }) : [];
  return {
    clientList,
    clientData
  };
}