import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./auth";

export const RequireAuth = () => {
  const auth: any = useAuth();
  let role = localStorage.getItem("role") ? localStorage?.getItem("role") : "";

  if (!auth.user && !auth.token) {
  return <Navigate to="/" />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};
