import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { ActionButton } from "../../../components/buttons/actionButtons";
import { DELETE, UPDATE } from "../../../constant/commonConst";
import { confirmBox } from "../../../components/toast";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "name",
      title: "Name",
      dataType: DataType.String,
    },
    // {
    //   key: "zone?.name",
    //   title: "Zone",
    //   dataType: DataType.String,
    // },
    {
      key: "city.name",
      title: "City",
      dataType: DataType.String,
    }, {
      key: "latitude",
      title: "Latitude",
      dataType: DataType.String,
    }, {
      key: "longitude",
      title: "Longitude",
      dataType: DataType.String,
    },
    {
      key: "",
      title: "",
      component: ActionButton,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            name: row.data.name,
            cityId: row.data.cityId,
            zoneId: row.data.zoneId,
            longitude: row.data.longitude,
            latitude: row.data.latitude,
            city: row.data.city?.name || null,
            zone: row.data.city?.zone || null,
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox("you want to delete this location?");
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
