import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";
import {
  Badge,
  Button,
  DropdownItem,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
} from "reactstrap";
import { arrowdown, edit, routeIcon, uploadIcon } from "../../constant/icons";
import DatePicker from "react-datepicker";
import { UPDATE } from "../../constant/commonConst";
import { useState } from "react";
import ProtectedImage from "../../components/common/image";
import CustomDropDown from "../../components/common/dropdown";
import { Field, Form, Formik } from "formik";
import { UserAPI } from "../user";
import { MountingApi } from ".";
import { getDate } from "../../utils/dateUtils";

export const DetailsButton = (props: any) => {
  const { dispatch, isDetailsRowShown, rowKeyValue } = props;
  return (
    <Button
      className={
        isDetailsRowShown ? "hide-btn collapse-btn" : "show-btn collapse-btn"
      }
      onClick={() => {
        dispatch(
          isDetailsRowShown
            ? hideDetailsRow(rowKeyValue)
            : showDetailsRow(rowKeyValue)
        );
      }}
    >
      {/* {isDetailsRowShown ? "Hide" : "Show"} */}
      {arrowdown.icon}
    </Button>
  );
};
export const UploadButton = (props: any) => {
  const { column, rowData } = props;
  const parsemountingImages = rowData?.mountingImages ? JSON.parse(rowData?.mountingImages) : "";
  return (
    <>
      {parsemountingImages?.url ? (
        <div className="table-img-box">
          <ProtectedImage
            callBack={() =>
              parsemountingImages?.isDisabled ? null : column.callBack(rowData)
            }
            value={parsemountingImages?.url}
          />

          <div className="overlay-icon" onClick={() => parsemountingImages?.isDisabled ? null : column.callBack(rowData)}>
            {edit.icon}
          </div>

        </div>

      ) : (
        <Button
          color="secondary"
          outline
          onClick={() => column.callBack(rowData)}
        >
          {uploadIcon.icon} Upload Creative
        </Button>
      )}
    </>
  );
};

export const DateField = (props: any) => {
  const { column, rowData } = props;
  const api = MountingApi(true);

  return (
    <Formik
      initialValues={{ fromDate: props?.rowData?.fromDate }}
      onSubmit={(values) => {
        const requestBody = {
          id: props?.rowData?.id,
          fromDate: values.fromDate,
        };
        api.updateValue(requestBody).then((result) => {
          if (result.status) {
            props.column.callBack({ data: result, status: UPDATE })
          }
        });
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div style={{ display: "flex", width: "100%", height: '100%' }}>
              <Field
                component={DatePicker}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                placeholderText={"DD/MM/YYYY"}
                popperPlacement="bottom-end"
                disabled={props?.rowData?.status == "mounted"}
                popperModifiers={[
                  {
                    name: "myModifier",
                    fn(state: any) {
                      // Do something with the state
                      return state;
                    },
                  },
                ]}
                dateFormat={"dd/MM/yyyy"}
                className="form-control"
                name="fromDate"
                selected={getDate(values.fromDate)}
                onChange={(date: any) => {
                  setFieldValue("fromDate", date);
                  handleSubmit();
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export const StatusDropDown = (props: any) => {
  const { column, rowData } = props;
  const statusOptions = [
    {
      label: "Waiting for creative",
      value: "waiting-for-creative",
      color: "warning",
    },
    {
      label: "Mounting pending",
      value: "mounting-pending",
      color: "primary",
    },
    { label: "Mounted", value: "mounted", color: "success" },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleSelect = (status: any) => {
    column.callBack({ data: status.value, rowData, status: UPDATE });
    setDropdownOpen(false);
  };
  return (
    <>
      <Dropdown
        className="status-dropdown"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret className="text-dark">
          <Badge
            color={
              statusOptions.find((option) => option.value === rowData.status)
                ?.color
            }
            className="text-dark"
          >
            {
              statusOptions.find((option) => option.value === rowData.status)
                ?.label
            }
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {statusOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => {
                handleSelect(option);
              }}
            >
              <Badge color={option.color} className="text-dark">
                {option.label}
              </Badge>
            </DropdownItem>
          ))}{" "}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export const AssignUserDropDown = (props: any) => {
  const userList = UserAPI(false);
  const api = MountingApi(true);
  const convertOptions =
    userList?.data?.data &&
    userList?.data?.data?.length > 0 &&
    userList?.data?.data.map((user: any) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  const parseMountingImages = props?.rowData?.mountingImages ? JSON.parse(props?.rowData?.mountingImages) : null;
  return (
    <Formik
      initialValues={{ assignTo: props?.rowData?.assignTo }}
      onSubmit={(values) => {
        const requestBody = {
          id: props?.rowData?.id,
          assignTo: values.assignTo,
        };
        api.updateValue(requestBody).then((result) => {
          if (result.status) {
            props.column.callBack({ data: result, status: UPDATE })
          }
        });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <CustomDropDown
              fieldName="assignTo"
              options={convertOptions}
              setFieldValue={setFieldValue}
              callback={handleSubmit}
              values={values.assignTo}
              selectedValue={values.assignTo}
              disabled={!parseMountingImages}
              gorupClass={"no-label"}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const LocationUrl = (props: any) => {
  const { rowData } = props;
  let locationUrl;
  if (rowData?.mediaItem?.routeId) {
    locationUrl = `https://www.google.com/maps/dir/?api=1&origin=${rowData?.mediaItem?.route?.startLatitude},${rowData?.mediaItem?.route?.startLongitude}&destination=${rowData?.mediaItem?.route?.endLatitude},${rowData?.mediaItem?.route?.endLongitude}&travelmode=driving`
  } else {
    locationUrl = `https://www.google.com/maps?q=${rowData?.mediaItem?.latitude},${rowData?.mediaItem?.longitude}`
  }
  return <div className="media-route-box"><a href={locationUrl} target="_blank" style={{ color: "black" }} className='route-link' rel="noopener noreferrer">{routeIcon.icon}</a></div>
}

export const Status = (props: any) => {
  const { rowData } = props
  const statusOptions = [
    {
      label: "Waiting for creative",
      value: "waiting-for-creative",
      color: "warning",
    },
    {
      label: "Mounting pending",
      value: "mounting-pending",
      color: "primary",
    },
    { label: "Mounted", value: "mounted", color: "success" },
  ];

  return <Badge className="rounded-pill text-dark status-badge"
    // color={rowData?.status ? "success":"warning"}
    color={
      statusOptions.find((option) => option.value === rowData.status)
        ?.color
    }
  >
    {/* {rowData?.status ? "Done":"Pending"} */}
    {rowData?.status}
  </Badge>;
};