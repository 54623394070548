import React, { useState, useEffect, useMemo } from "react";
import PageLayout from "../../components/layout/page";

export default function Report() {
  return (
    <PageLayout className="dashboard-inner-page"
      View={
        <>
           hello report
        </>
      }
    />
  );
}
