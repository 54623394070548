import { Button, Col } from "reactstrap";
import { Form, Formik } from "formik";
import TextBox from "../../components/common/textBox";
import { RoleAPI } from "../role";
import Dropdown from "../../components/common/dropdown";
import { validationSchema } from "./validation";

const AddUpdateUser = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const role = RoleAPI(false, { revalidateIfStale: true });

  let roleList =
    role?.data?.length > 0
      ? role.data?.map((item: any) => {
        return { value: item.id, label: item.name };
      })
      : [];

  return (
    <Formik
      validationSchema={() => validationSchema(value?.isUpdate)}
      initialValues={value}
      onSubmit={(values: any, actions: any) => {
        if (values.id) {
          if (values.isUpdate) {
            delete values.isUpdate;
            api.updateValue(values).then((result: any) => {
              if (result?.status) {
                closeAddUpdate();
              }
            });
          }
        } else {
          api.create(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {({ setFieldValue, values, errors }) => {
        return <Form className="form-main form-row">
          <Col lg="6">
            <TextBox
              fieldName={"name"}
              placeholder={"Enter name"}
              className={"form-control"}
              type={"text"}
              labelName={"Name"}
              value={value}
            />
          </Col>
          <Col lg="6">
            <Dropdown
              fieldName="roleId"
              labelName="Role"
              options={roleList}
              setFieldValue={setFieldValue}
              values={values}
              selectedValue={values.roleId}
            />
          </Col>
          <Col lg="6">
            <TextBox
              fieldName={"emailId"}
              placeholder={"name@example.com"}
              className={"form-control"}
              type={"email"}
              labelName={"Email"}
              value={value}
            />
          </Col>
          <Col lg="6">
            <TextBox
              fieldName={"mobileNo"}
              placeholder={"Enter mobile number"}
              className={"form-control"}
              type={"tel"}
              labelName={"Mobile Number"}
              value={value}
            />
          </Col>

          <Col lg="6">
            <TextBox
              fieldName={"userName"}
              placeholder={"Enter username"}
              className={"form-control"}
              type={"text"}
              labelName={"Username"}
            // isDisabled
            />
          </Col>
          {
            !values?.id ? <Col lg="6">
              <TextBox
                fieldName={"password"}
                placeholder={"Enter password"}
                className={"form-control"}
                type={"password"}
                labelName={"Password"}
                value={value}
              />
            </Col> : null
          }

          <Col lg="12" className="d-flex justify-content-center gap-2">
            <Button
              color="secondary"
              type="button"
              outline
              onClick={() => closeAddUpdate()}
            >
              Cancel
            </Button>
            <Button color="secondary" type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </Col>
        </Form>
      }}
    </Formik>
  );
};
export { AddUpdateUser };
