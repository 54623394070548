import { Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { AppLogo } from "../../constant/logo";
import { GetMenuList } from "../../constant/menus";

export const Aside = () => {
  const MenuList = GetMenuList();

  return (
    <aside className="sidenav">
      <div className="sidenav-logo">
        <Link to={"href"}>
          {AppLogo.main}
          {/* {AppLogo.small} */}
        </Link>
      </div>
      {/* <Form>
            <Field name="Search" type="Search" className="search-dashboard" />
        </Form> */}
      <Nav vertical className="nav-main">
        {MenuList}
      </Nav>
    </aside>
  );
};
