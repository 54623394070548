import { Badge, Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import { Form, Formik } from "formik";
import { validationSchema } from "./validation";
import TextBox from "../../../components/common/textBox";
import SearchLocationInput, {
  loadScript,
} from "../../../helper/searchGoogleHelper";
import { useEffect, useRef, useState } from "react";
import StartLatLon from "./component/startLatLon";
import EndLatLon from "./component/endLatLon";
import { GOOGLE_API } from "../../auth/constant";
import { StateApi } from "../state";
import { CityApi } from "../city";
import GoogleMapsLink from "./component/GoogleMapsLink ";
import { useApi } from "../../../helper/apiHook";

const AddUpdateRoute = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [selectedEndLocation, setSelectedEndLocation] = useState<any>();
  const [queryStart, setQueryStart] = useState<string>("");
  const [queryEnd, setQueryEnd] = useState<string>("");
  const autoCompleteRefStart = useRef<HTMLInputElement>(null);
  const autoCompleteRefEnd = useRef<HTMLInputElement>(null);
  const handleScriptLoad = (
    updateQuery: React.Dispatch<React.SetStateAction<string>>,
    autoCompleteRef: React.RefObject<HTMLInputElement>,
    dataFor: string
  ) => {
    if (autoCompleteRef.current) {
      let autoComplete: any;
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
          componentRestrictions: { country: "IN" },
        }
      );

      autoComplete.addListener("place_changed", () => {
        handlePlaceSelect(updateQuery, autoComplete, dataFor);
      });
    }
  };
  const handlePlaceSelect = async (
    updateQuery: React.Dispatch<React.SetStateAction<string>>,
    autoComplete: any,
    dataFor: string
  ) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject?.formatted_address || "";
    updateQuery(query);
    if (dataFor === "start") {
      setSelectedLocation(addressObject);
    } else {
      setSelectedEndLocation(addressObject);
    }
  };
  useEffect(() => {
    loadScript(GOOGLE_API, () => {
      handleScriptLoad(setQueryStart, autoCompleteRefStart, "start");
      handleScriptLoad(setQueryEnd, autoCompleteRefEnd, "end");
    });
  }, []);
  const cityData = CityApi(false, { revalidateIfStale: true });
  const stateData = StateApi(false, { revalidateIfStale: true });
  const routeId = useApi("/routes/id", false, undefined, {
    revalidateIfStale: true,
  });
  const handleSubmit = async (values: any, actions: any) => {
    const state = stateData.data?.data?.find(
      (state: any) =>
        state.name?.toLowerCase() === values.stateId?.toLowerCase()
    );
    let cityId;

    // Check if city already exists
    if (cityData?.data?.data?.length > 0) {
      const foundCity = cityData.data.data.find(
        (city: any) =>
          city.name.trim().toLowerCase() === values.cityId.toLowerCase()
      );
      if (foundCity) {
        cityId = foundCity.id;
      }
    }
    // If city doesn't exist, create it
    if (!cityId) {
      const createdId: any = await cityData.create({
        name: values.cityId,
        stateId: state?.id,
      });
      if (createdId?.data) {
        cityId = createdId.data;
      }
    }
    let value = {
      ...values,
      stateId: state?.id,
      // zoneId: typeof values.zoneId === "number" ? location.zone : null,
      cityId: cityId || null,
    };
    if (cityId) {
      if (values?.id) {
        api.updateValue({ ...value, id: values.id }).then((result: any) => {
          if (result?.status) {
            closeAddUpdate();
          }
        });
      } else {
        api.create(value).then((result: any) => {
          if (result?.status) {
            closeAddUpdate(result?.data);
          }
        });
      }
    }
  };
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      initialValues={{
        ...value,
        name: value?.name
          ? value.name
          : routeId?.data?.length > 0
          ? routeId.data[0]
          : "",
      }}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form className="form-main">
          <TextBox
            fieldName={"name"}
            placeholder={"Route Id"}
            className={"form-control"}
            type={"text"}
            labelName={"Route Id"}
            value={value}
            isRequired={true}
            isDisabled={props?.viewDisabled}
          />
          <div className="route-form">
            <Card>
              <h3>Start Point</h3>
              <TextBox
                fieldName={"startLandmarkDescription"}
                placeholder={"Enter start landmark description"}
                className={"form-control"}
                type={"textarea"}
                labelName={"Start Landmark Description"}
                value={value.startLandmarkDescription}
                isRequired={true}
                rows={"5"}
                isDisabled={props?.viewDisabled}
              />
              <StartLatLon
                id={"startLocation"}
                setFieldValue={setFieldValue}
                values={values}
                fieldName="Start Location"
                autoCompleteRef={autoCompleteRefStart}
                setQuery={setQueryStart}
                query={queryStart}
                selectedLocation={selectedLocation}
                disabled={props?.viewDisabled}
              />
            </Card>
            <Card>
              <h3>End Point</h3>
              <TextBox
                fieldName={"endLandmarkDescription"}
                placeholder={"Enter end landmark description"}
                className={"form-control"}
                type={"textarea"}
                labelName={"End Landmark Description"}
                value={value.endLandmarkDescription}
                rows={"5"}
                isRequired={true}
                isDisabled={props?.viewDisabled}
              />
              <EndLatLon
                setFieldValue={setFieldValue}
                values={values}
                autoCompleteRef={autoCompleteRefEnd}
                setQuery={setQueryEnd}
                selectedLocation={selectedEndLocation}
                query={queryEnd}
                fieldName="End Location"
                disabled={props?.viewDisabled}
              />
            </Card>
          </div>

          {values.startLatitude && values.endLatitude && (
            <GoogleMapsLink
              startLat={values.startLatitude}
              startLng={values.startLongitude}
              endLat={values.endLatitude}
              endLng={values.endLongitude}
            />
          )}
          {
            !props.viewDisabled  ?   <Col lg="12" className="d-flex justify-content-end gap-2 mt-3">
            <Button
              color="secondary"
              outline
              type="button"
              onClick={() => closeAddUpdate()}
            >
              Cancel
            </Button>
            <Button color="secondary" solid type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </Col>:null
          }
       
        </Form>
      )}
    </Formik>
  );
};
export { AddUpdateRoute };
