import { useEffect, useState } from "react";
import { useUpload } from "../../masters/items/helper";
import {
  Container,
  TabContent,
  // Col,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { Field, FieldArray, Formik } from "formik";
import ImageUpload from "../../masters/items/imageInput";
import ProtectedImage from "../../../components/common/image";
import { crossIcon, deleteIcon, plus } from "../../../constant/icons";
import { useApi } from "../../../helper/apiHook";

export const UploadImage = (props: any) => {
  const { api, closeAddUpdate, rowData, uploadImage,uploadImageApi }: any = props;
  const [activeTab, setActiveTab] = useState("1");
  const imageUpload = useUpload(1);
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const JSONparseCreativeUrl = JSON.parse(uploadImage?.creativeUrl)
  const getimageFromCreativeUrl = JSONparseCreativeUrl && JSONparseCreativeUrl.length > 0 && JSONparseCreativeUrl.find((creativeurl:any)=>creativeurl?.tagname == "image");
  const createCreativeUrl = JSONparseCreativeUrl ? JSONparseCreativeUrl.filter((uploadimage:{tagname:string;url:string})=>uploadimage.tagname !== "image"): [""];
  const intitialValue = {
    creativeUrl: createCreativeUrl.map((mapelement:any)=>mapelement?.url),
    uploadimage:getimageFromCreativeUrl?.url
  }
  useEffect(()=>{
    imageUpload.setImagePreviews(getimageFromCreativeUrl?.url ? [{path:getimageFromCreativeUrl?.url,isPrimary:false,id:"undefined"}]:[])
  },[getimageFromCreativeUrl?.url])
  return (
    <div className="media-upload-modal">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            URL Upload
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Image Upload
          </NavLink>
        </NavItem>
      </Nav>
      <Formik
        initialValues={intitialValue}
        onSubmit={(values) => {
          const creativeUrlFormat = values.creativeUrl.map((url:string,index:number)=>{
            return {
              tagname:`crop${index+1}`,
              url
            }
          })
          const formdata = new FormData();
          const creativeUrlFormatDuplicate = [...creativeUrlFormat];
          if(imageUpload.imagePreviews[0]?.id == "undefined"){
            creativeUrlFormatDuplicate.push({
              tagname:"image",
              url:values.uploadimage
            })
          }
          const findTaskNo = uploadImageApi.data && uploadImageApi.data.length > 0 && uploadImageApi.data.find((element:any)=>element.id == uploadImage.id);
          const value:any = {
            ...values,
            taskNo:findTaskNo?.taskNo,
            deleteImage:imageUpload?.imagePreviews && imageUpload?.imagePreviews.length > 0 && imageUpload?.imagePreviews[0].url?.startsWith("blob") ? getimageFromCreativeUrl?.url:"",
            creativeUrl: JSON.stringify(creativeUrlFormatDuplicate),
            bookingId: rowData?.bookingId ? rowData.bookingId : "",
            id: uploadImage?.id ? parseInt(uploadImage.id) : "",
          };
          if (imageUpload.imagePreviews[0]?.id !== "undefined") {
            formdata.append(`${imageUpload.imagePreviews[0]?.id}`, imageUpload.selectedImages[0]);
          }
          delete value.uploadimage;
          for (const key in value) {
            if (value[key] !== null && value[key] !== undefined) {
              formdata.append(key, value[key]);
            }
          }
          api.updateValue(formdata).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
              uploadImageApi.mutate();            
            }
          });
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => {
         return  <Form onSubmit={handleSubmit}>
            <TabContent activeTab={activeTab} className="booking-master-imagebox">
              <TabPane tabId="1">
                <FieldArray name="creativeUrl">
                  {({ insert, remove }) => (
                    <FormGroup>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem", alignItems: "center" }}>
                        <Label for="creativeUrl">Creative Urls</Label>
                        <Button
                          color="secondary"
                          type="button"
                          onClick={() => insert(0, '')}
                        >
                          {plus.icon}
                        </Button>
                      </div>
                      <div>
                        <FieldArray name="creativeUrl">
                          {({ remove }) => (
                            <>
                              {values.creativeUrl.map((url:any, index:number) => (
                                <div key={index} className="media-url-list">
                                  <div>
                                    <Label>Crop {values.creativeUrl.length - index}</Label>
                                    <Field
                                      as={Input}
                                      type="url"
                                      name={`creativeUrl.${index}`}
                                      placeholder="Enter URL"
                                    />
                                  </div>
                                  {values.creativeUrl.length > 1 && (
                                    <Button
                                      type="button"
                                      color="danger"
                                      onClick={() => remove(index)}
                                    >
                                      {deleteIcon.icon}
                                    </Button>
                                  )}
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </FormGroup>
                  )}
                </FieldArray>
              </TabPane>
              <TabPane tabId="2">
                <FormGroup noMargin>
                  {imageUpload.imagePreviews?.length === 0 && (
                    <ImageUpload
                      imageUpload={imageUpload}
                      accept={"image/*"}
                      isDisabled={imageUpload.selectedImages?.length >= 4}
                    />
                  )}
                  {imageUpload.imagePreviews.map((preview: any, index: any) => (
                    <div
                      key={index}
                      className="images-item"
                      style={{
                        backgroundColor: preview.isPrimary ? "#52a9ff" : "",
                      }}
                    >
                      {preview?.path ? (
                        <>
                          <ProtectedImage value={preview?.path} />
                        </>
                      ) : (
                        <img
                          src={preview.url}
                          alt={`preview-${index}`}
                          onClick={() =>
                            imageUpload.handleSetPrimaryImage(index)
                          }
                          width={25}
                          height={25}
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                          }}
                        />
                      )}

                      <button
                        className="remove-box"
                        onClick={() => imageUpload.handleRemoveImage(index)}
                      >
                        {crossIcon.icon}
                      </button>
                    </div>
                  ))}
                </FormGroup>
              </TabPane>
            </TabContent>
            <div className="d-flex justify-content-end">
              <Button type="submit">
                {activeTab === "1" ? "Submit URL" : "Submit"}
              </Button>
            </div>
          </Form>
        }}
      </Formik>
    </div>
  );
};
