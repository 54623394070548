import { Button } from "reactstrap";
import PageLayout from "../../../components/layout/page";
import { DataView } from "../../../components/table/table";
import { downloadIcon, filterIcon } from "../../../constant/icons";
import { useApi } from "../../../helper/apiHook";
import { useState } from "react";
// import { LeadReportFilter } from "./filter";
import { saveAs } from 'file-saver';
import moment from "moment";
import ExcelJS from 'exceljs';
import { getFormattedDate } from "../../../utils/dateUtils";
import { columns } from "./columns";
import { BookingAPI } from "../../booking";
import { MountingReportFilter } from "./filter";

export default function MountingReportList() {
    const [filterValues, setFilterValues] = useState<any>({});
    const api = useApi("/mountings/mountingReport", true, filterValues);
    const bookingApi = BookingAPI();

    const [filter, setFilter] = useState<boolean>(false);
    const handleShowFilter = () => {
        setFilter(!filter);
    };
    const today = moment().format('DD-MM-YYYY');

    const exportToExcel = async () => {
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Define the headers (removed 'ID' and added 'Client Name')
        const headers = [
            { header: 'Booking No', key: 'bookingNo', width: 15 },
            { header: 'Task No', key: 'taskNo', width: 30 },
            { header: 'QTY', key: 'qty', width: 30 },
            { header: 'Rate', key: 'rate', width: 20 },
            { header: 'Assignee Name', key: 'assigneeName', width: 25 },
            { header: 'Date', key: 'date', width: 30 },
            { header: 'Size', key: 'size', width: 30 },
            { header: 'Mounting Status', key: 'status', width: 15 },
        ];

        // Add the header row with styles
        worksheet.columns = headers;

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000' }, // black background
            };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        // Map your API data and remove the 'id', add 'clientName'
        const data = api?.data.map((item: any) => ({
            bookingNo: item.bookingNo,
            taskNo: item.taskNo,
            qty: item.mediaItem?.qty,
            size: `${item?.mediaItem?.height} * ${item?.mediaItem?.width}`,
            rate: item.mediaItem?.rateAgency,
            assigneeName: item?.assignee?.name,
            status: item.status,
            date: item?.fromDate ? getFormattedDate(item?.fromDate) : "",
        }));

        // Add the data rows
        data.forEach((item: any) => {
            worksheet.addRow(item);
        });

        // Generate buffer for Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob and trigger the download
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `MountingReport${today}.xlsx`);
    };

    const bookingNoList = Array.isArray(bookingApi?.data)
        ? bookingApi?.data
            .map((name: any) => ({ value: name?.bookingNo, label: name?.bookingNo }))
        : [];

    return (
        <>
            <div className="d-flex justify-content-end align-items-end" style={{ paddingRight: '1rem' }}>
                <div className="head-btn-box">
                    <Button color="secondary" type="button" outline onClick={exportToExcel}>
                        {downloadIcon.icon}
                        Download
                    </Button>
                    <Button
                        color="secondary"
                        type="button"
                        outline
                        onClick={handleShowFilter}
                    >
                        {filterIcon.icon}
                        Filter
                    </Button>
                </div>
            </div>
            <PageLayout
                View={
                    <>
                        <DataView
                            columns={columns({ api })}
                            rows={api.data}
                            currentPage={api.currentPage}
                            rowCount={api.rowCount}
                            limit={api.limit}
                            updatePagination={api.updatePagination}
                        />
                    </>
                }
            />
            {filter &&
                <MountingReportFilter
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    onCloseFilter={handleShowFilter}
                    bookingNoList={bookingNoList}
                />
            }
        </>
    );
}
