import { DataType } from "ka-table"
import { TableColumn } from "../../../components/table/table"
import { ActionButton } from "../../../components/buttons/actionButtons"
import { DELETE, UPDATE } from "../../../constant/commonConst"
import { confirmBox } from "../../../components/toast"

export const columns = (actions: any): TableColumn[] => {
    return [
        {
            key: 'id',
            title: 'id',
            dataType: DataType.Number,
            visible: false
        },
        {
            key: 'name',
            title: 'Landlord Business/Name',
            dataType: DataType.String
        },
        {
            key: 'contactPerson',
            title: 'Contact Person Name',
            dataType: DataType.String
        },
        {
            key: 'contactPersonMobile',
            title: 'Mobile No.',
            dataType: DataType.String
        }, {
            key: 'contactPersonDesignation',
            title: 'Designation',
            dataType: DataType.String
        }, {
            key: 'type',
            title: 'type',
            dataType: DataType.String
        },
        {
            key: 'edit',
            title: '',
            hideHeaderFilter: false,
            component: ActionButton,
            callBack: async (row) => {
                if (row.status === UPDATE) {
                    
                    const data = {
                        id: row.data.id,
                        contactPerson: row.data?.contactPerson,
                        contactPersonEmail: row.data?.contactPersonEmail,
                        contactPersonMobile: row.data?.contactPersonMobile,
                        contactPersonDesignation: row.data?.contactPersonDesignation,
                        customInfo: JSON.parse(row.data?.customInfo) || [],
                        description: row.data?.description,
                        name: row.data?.name,
                        type: row.data?.type,
                    }
                    actions.openAddEditFrom(data)
                } else if (row.status === DELETE) {
                    const response = await confirmBox("you want to delete this landlord?");
                    if (response) {
                        actions.api.removeValue(row.data.id);
                    }
                }
            },
            style: {
                textAlign: 'center',
            }
        },
    ]
}
export const typeList = [
    { value: "PRIVATE", label: "Private" },
    { value: "GOVERNMENT", label: "Government" },
];