import { useState } from "react";
import { DetailControl } from "../../components/buttons/detail";
import { EditControl } from "../../components/buttons/edit";
import { FollowUpControl } from "../../components/buttons/followUp";
import { RemoveControl } from "../../components/buttons/remove";
import { StatusChangeControl } from "./statusChanges";
import { UPDATE } from "../../constant/commonConst";
import {
  DropdownToggle,
  Dropdown,
  Badge,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export const ActionButtonView = (props: any) => {
  return (
    <div className="action_btn table-action">
      {/* <FollowUpControl {...props} /> */}

      {/* <DetailControl  {...props} /> */}
      <EditControl {...props} />
      <RemoveControl {...props} />
    </div>
  );
};

export const StatusChangeView = (props: any) => {
  return <StatusChangeControl {...props} />;
};
export const StatusDropDown = (props: any) => {
  const { column, rowData } = props;
  const statusOptions = [
    {
      label: "Open",
      value: "open",
      color: "warning",
    },
    {
      label: "Assigned",
      value: "assigned",
      color: "primary",
    },
    {
      label: "Close",
      value: "close",
      color: "success",
    },
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleSelect = (status: any) => {
    column.callBack({ data: status.value, rowData, status: UPDATE });
    setDropdownOpen(false);
  };
  return (
    <>
      <Dropdown
        className="status-dropdown"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret className="text-dark">
          <Badge
            color={
              statusOptions.find((option) => option.value === rowData.status)
                ?.color
            }
            className="text-dark"
          >
            {
              statusOptions.find((option) => option.value === rowData.status)
                ?.label
            }
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {statusOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => {
                handleSelect(option);
              }}
            >
              <Badge color={option.color} className="text-dark">
                {option.label}
              </Badge>
            </DropdownItem>
          ))}{" "}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
