// ProtectedImage.js
import React, { useState, useEffect, useRef } from "react";
import { getValueByKey } from "../../utils/generalUtils";
import { API_URL, fetcher, invokeApi } from "../../helper/api";
import { GET } from "../../constant/commonConst";
import moment from "moment";

const ProtectedImage = ({ className, value, callBack }: any) => {
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    const fetchImage = async () => {
      if (value) {
        try {
          const response = await invokeApi(
            GET,
            API_URL + value,
            undefined,
            undefined,
            {
              responseType: "blob", // Ensure we get the response as a Blob
            }
          );
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageUrl(reader.result as string); // Set the Base64 string as image URL
          };
          reader.readAsDataURL(response); // Convert Blob to Base64
        } catch (error) {

        }
      }
    };

    fetchImage();
  }, [value]);

  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Protected"
          // style={{width: "100px", height: "100px" }}
          className={className}
          onClick={(props) => {
            if (callBack) { callBack(props) }
          }}
        />
      ) : (
        <h4 className="skeleton" style={{ width: "100px", height: "100px" }}>
          {/* loading */}
        </h4>
      )}
    </>
  );
};

export default ProtectedImage;


export const CanvasImage = ({ url, locationLive, callBack }: any) => {
  const canvasRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [canvasDataUrl, setCanvasDataUrl] = useState('');

  useEffect(() => {

    const canvas: any = canvasRef.current;

    if (!canvas) return; // Exit if canvasRef is not attached yet

    const ctx = canvas.getContext('2d');
    const img = new Image();

    // Set the image source
    img.src = url;

    img.onload = () => {
      canvas.width = img.width;  // Set canvas width to the image width
      canvas.height = img.height; // Set canvas height to the image height
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const rectHeight = canvas.height * 0.14; // 12% of canvas height for the background rectangle
      const rectWidth = canvas.width * 0.9; // 90% of canvas width
      const rectX = canvas.width * 0.05; // 5% margin from the left side
      const rectY = canvas.height - rectHeight - 15; // Positioned 15px above the bottom edge
    
      // Set styles for text
      ctx.fillStyle = "rgba(0, 0, 0, 0.7)"; // Semi-transparent background
      // ctx.fillRect(15, canvas.height - 130, canvas.width - 30, 110); // Background rectangle
      ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

      const fontSize = Math.floor(canvas.height * 0.03);

      ctx.fillStyle = "white"; // Text color
      ctx.font = `${fontSize}px Arial`;


      // Format the date and time
      const formattedDateTime = moment().format('DD/MM/YY hh:mm A [GMT] Z');
      // Add text details
      const locationDetails = [
        `Lat ${locationLive?.latitude}°`,
        `Long ${locationLive?.longitude}°`,
        formattedDateTime
      ];
      const lineSpacing = fontSize * 1.4;
      // Draw each line of text
      locationDetails.forEach((line, index) => {
        ctx.fillText(line, rectX + 20, rectY + fontSize + 10 + (index * lineSpacing));
        // ctx.fillText(line, 40, canvas.height - 80 + (index * 20)); // Positioning the text
      });
      setImageLoaded(true);

      // Generate the data URL from the canvas
      const dataUrl = canvas.toDataURL('image/png');
      setCanvasDataUrl(dataUrl); // Store the data URL in state

      // Convert the data URL to a Blob
      const byteString = atob(dataUrl.split(',')[1]);
      const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString }); 
      const fileName = locationLive?.latitude + 'image.jpg';
      const fileWithBlob = new File([blob], fileName, { type: mimeString }); // Create a File object
      // Callback function to handle the Blob
      callBack(fileWithBlob); // Pass the File object to the callback
    };

    // Cleanup function to avoid memory leaks
    return () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas if necessary
    };
  }, [url]); // Dependency array includes the image URL

  return (
    <>
      <canvas
        ref={canvasRef}
        // width={25}
        // height={25}
        style={{
          width: "auto",
          height: '100%',
          objectFit: "cover",
          display: 'none'
        }}
      />
      {imageLoaded && (
        <>
          <img src={canvasDataUrl} alt="Canvas" style={{ width: 'auto', height: '100%' }} />
        </>
      )}
    </>
  );
};
