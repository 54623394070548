import { Badge, Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import { Field, FieldArray, Form, Formik } from "formik";
import PageLayout from "../../../components/layout/page";
import { mediaItemSchema } from "./validation";
import TextBox from "../../../components/common/textBox";
import Dropdown from "../../../components/common/dropdown";
import apiHelper, { useUpload } from "./helper";
import { useEffect, useState } from "react";
import ImageUpload from "./imageInput";
import { API_URL, invokeApi } from "../../../helper/api";
import { uuid } from "../../../utils/generalUtils";
import DatePicker from "react-datepicker";
import { getDate } from "../../../utils/dateUtils";
import { confirmBox, message } from "../../../components/toast";
import {
  clickIcon,
  crossIcon,
  deleteIcon,
  file,
  LinkIcon,
  plus,
  searchIcon,
  viewIcon,
} from "../../../constant/icons";
import ProtectedImage from "../../../components/common/image";
import GoogleMapsLink, {
  GoogleMapsStartLink,
} from "../route/component/GoogleMapsLink ";
import { TooltipItem } from "../../../components/buttons/tooltip";
import { useApi } from "../../../helper/apiHook";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import ModalView from "../../../components/modal";
import { AddUpdateAgency } from "../agency/addUpdate";
import { AddUpdateLandlord } from "../landlord/addUpdate";
import { AddUpdateLocation } from "../location/addUpdate";
import { GOOGLE_MAPS_API_KEY } from "../../auth/constant";
import { AddUpdateRoute } from "../route/addUpdate";
import { GET } from "../../../constant/commonConst";
const AddUpdateItem = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  let mediaName = useApi("/mediaItems/itemId", false, undefined, {
    revalidateIfStale: true,
  });
  const imageUpload = useUpload(4);
  const [model, setModel] = useState("");

  const toggleModel = (model: string) => {
    setModel(model);
  };
  const [selectedType, setSelectedType] = useState("HORDINGS");
  const [selectedLocation, setSelectedLocation] = useState({
    stateId: value?.stateId ? value.stateId : "",
    cityId: value?.cityId ? value.cityId : "",
    zoneId: value?.zoneId ? value.zoneId : "",
    locationId: value?.locationId ? value.locationId : "",
  });
  const {
    stateList,
    cityList,
    zoneList,
    locationList,
    landlordList,
    mediaTypeList,
    mediaLitList,
    mediaSeriesList,
    routeList,
    agencyList,
    agencyData,
    locationData,
    landlordData,
    routeData,
  } = apiHelper(selectedLocation);

  const [fileUploads, setFileUploads] = useState<
    { id: string; file: File | null }[]
  >([]);
  const [mediaItemLandlordIds, setMediaItemLandlordIds] = useState<number[]>(
    []
  );
  const [routeSingleData, setRouteSingleData] = useState<any>(null);
  let zoneData = {
    label: "",
    value: "",
    description: "",
  };
  if (selectedLocation?.zoneId) {
    zoneData = zoneList.find((x: any) => x.value === selectedLocation.zoneId);
  }
  const locationListData =
    locationList.find((x: any) => x.value === selectedLocation.locationId)
      ?.description || "";
  useEffect(() => {
    if (value.image) {
      imageUpload.setImagePreviews(value.image);
    }
    if (value.mediaType) {
      setSelectedType(value.mediaType);
    }
  }, [value]);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const file = event.currentTarget.files
      ? event.currentTarget.files[0]
      : null;

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      const id = uuid();
      setFileUploads((prev: any) => [...prev, { id, file }]);
      setFieldValue(`landlordInfo.${index}.pdfId`, id);
      setFieldValue(`landlordInfo.${index}.pdfFile`, file.name);
      setFieldValue(`landlordInfo.${index}.pdfUrl`, fileUrl);
    }
  };
  const handleFileRemove = (
    fileId: string,
    index: number,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFileUploads((prev) =>
      prev.filter((fileUpload) => fileUpload.id !== fileId)
    );
    setFieldValue(`landlordInfo.${index}.pdfFile`, null);
    setFieldValue(`landlordInfo.${index}.pdfUrl`, null);
    // Update mediaItemLandlordIds state with the removed fileId
  };

  const handleSubmit = (values: any) => {
    const isPrimaryImageSelected = imageUpload.imagePreviews.some(
      (preview) => preview.isPrimary
    );
    if (!isPrimaryImageSelected) {
      message("error", "Please select at least one image before submitting.");
      return;
    }
    const allValue = {
      ...values,
      name: values?.name
        ? values.name
        : mediaName.data?.length > 0
        ? mediaName.data[0]
        : "",
    };
    const formData = new FormData();

    for (const key in allValue) {
      if (allValue[key] !== null && allValue[key] !== undefined) {
        if (key !== "landlordInfo") {
          if (key !== "image") {
            formData.append(key, allValue[key]);
          }
        } else {
          formData.append(key, JSON.stringify(allValue[key]));
        }
      }
    }
    imageUpload.selectedImages.forEach((file, index) => {
      if (imageUpload.imagePreviews[index].id) {
        formData.append(`${imageUpload.imagePreviews[index].id}`, file);
      }
    });
    if (mediaItemLandlordIds?.length > 0) {
      formData.append(
        `mediaItemLandlordIds`,
        JSON.stringify(mediaItemLandlordIds)
      );
    }
    formData.append(`image`, JSON.stringify(imageUpload.imagePreviews));
    fileUploads.forEach(({ id, file }: any) => {
      formData.append(`${id}`, file);
    });

    if (values.id) {
      api.updateValue(formData).then((result: any) => {
        if (result?.status) {
          closeAddUpdate();
        }
      });
    } else {
      api.create(formData).then((result: any) => {
        if (result?.status) {
          closeAddUpdate();
        }
      });
    }
  };
  const [searchedData, setSearchedData] = useState<any>("");
  const searchLocation = (latitude: string, longitude: string) => {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.results) && data.results.length > 0) {
          setSearchedData({
            name: data.results[0].formatted_address,
            address_components: data.results[0].address_components,
            lng: +longitude,
            lat: +latitude,
          });
          toggleModel("location");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getMediaPrefix = async (key: any, setFieldValue: any) => {
    const mediaPrefixresponse = await invokeApi(GET, `/agencies/${key?.alias}`);
    if (mediaPrefixresponse?.status) {
      setFieldValue("name", mediaPrefixresponse?.data);
    }
  };
  const handleSelectLocation = (values: any, setFieldValue: any) => {
    if (values?.latitude && values?.longitude) {
      const isFound = locationList?.find((location: any) => {
        // Convert values to numbers
        const locationLatitude = parseFloat(location.data.latitude);
        const locationLongitude = parseFloat(location.data.longitude);
        const valuesLatitude = parseFloat(values.latitude);
        const valuesLongitude = parseFloat(values.longitude);
        return (
          locationLatitude === valuesLatitude &&
          locationLongitude === valuesLongitude
        );
      });

      if (isFound) {
        setFieldValue("latitude", isFound?.data?.latitude);
        setFieldValue("longitude", isFound?.data?.longitude);
        setFieldValue("cityId", isFound?.data?.cityId);
        setFieldValue("stateId", isFound?.data?.city?.stateId);
        setFieldValue("locationId", isFound?.value);
        setSelectedLocation({
          ...selectedLocation,
          stateId: isFound?.data?.city?.stateId,
          cityId: isFound?.data?.cityId,
          locationId: isFound?.value,
        });
      } else {
        searchLocation(values.latitude, values.longitude);
      }
    }
  };
  const [viewMode, setViewMode] = useState(false)
  return (
    <PageLayout
      View={
        <>
          {model === "agency" ? (
            <AddUpdateAgency
              closeAddUpdate={() => {
                toggleModel("");
              }}
              api={agencyData}
              value={{
                name: "",
                GSTINNo: "",
                PANNo: "",
                websiteLink: "",
                stateId: "",
                cityId: "",
                contactPerson: "",
                contactEmail: "",
                contactMobile: "",
                address: "",
              }}
            />
          ) : (
            <Formik
              initialValues={value}
              onSubmit={async (values: any, actions: any) => {
                if (values?.landlordId) {
                  handleSubmit(values);
                } else {
                  const response = await confirmBox(
                    "Would you like to proceed without a landlord?"
                  );
                  if (response) {
                    handleSubmit(values);
                  }
                }
              }}
            >
              {({ values, isSubmitting, setFieldValue }) => {
                return (
                  <>
                    <Form className="media-item">
                      {model === "location" && (
                        <ModalView
                          modalView={model === "location"}
                          modalName={"Add Location"}
                          onModalDismiss={() => setModel("")}
                          customClass={"add-location"}
                        >
                          <AddUpdateLocation
                            closeAddUpdate={(value: any) => {
                              toggleModel("");
                              if (value) {
                                setFieldValue("cityId", value?.cityId);
                                setFieldValue("stateId", value?.stateId);
                                setFieldValue("latitude", value?.latitude);
                                setFieldValue("longitude", value?.longitude);
                                setFieldValue("locationId", value?.locationId);
                                setSelectedLocation({
                                  ...locationList,
                                  cityId: value?.cityId,
                                  stateId: value?.stateId,
                                  locationId: value?.locationId,
                                });
                              }
                            }}
                            api={locationData}
                            SelectedLocationView={searchedData}
                            viewMode={viewMode}
                            setSearchedData={setSearchedData}
                            value={{
                              name: searchedData.name,
                              description: searchedData.name,
                            }}
                          />
                        </ModalView>
                      )}
                      {model === "landLoard" && (
                        <>
                          <ModalView
                            modalView={model === "landLoard"}
                            modalName={"Add LandLoard"}
                            onModalDismiss={() => setModel("")}
                            customClass={"add-location"}
                            modalsize="xl"
                          >
                            <AddUpdateLandlord
                              closeAddUpdate={(value: any) => {
                                toggleModel("");
                                if (value) {
                                  setFieldValue("landlordId", value);
                                }
                              }}
                              api={landlordData}
                              value={{
                                name: "",
                                type: "",
                                contactPerson: "",
                                contactPersonMobile: "",
                                contactPersonEmail: "",
                                contactPersonDesignation: "",
                                description: "",
                              }}
                            />
                          </ModalView>
                        </>
                      )}
                      {model === "route" && (
                        <ModalView
                          modalView={model === "route"}
                          modalName={
                            routeSingleData ? "View Route" : "Add Route"
                          }
                          onModalDismiss={() => {
                            setModel("");
                            setRouteSingleData(null);
                          }}
                          customClass={"add-location"}
                          modalsize="lg"
                        >
                          <AddUpdateRoute
                            closeAddUpdate={(value: any) => {
                              toggleModel("");
                              if (value) {
                                setFieldValue("routeId", value?.data?.id);
                                setFieldValue(
                                  "cityId",
                                  value?.data?.cityId ? value?.data?.cityId : ""
                                );
                                setFieldValue(
                                  "stateId",
                                  value?.data?.stateId
                                    ? value?.data?.stateId
                                    : ""
                                );
                                setFieldValue(
                                  "endLatitude",
                                  value?.data?.endLatitude
                                    ? value?.data?.endLatitude
                                    : ""
                                );
                                setFieldValue(
                                  "endLongitude",
                                  value?.data?.endLongitude
                                    ? value?.data?.endLongitude
                                    : ""
                                );
                                setFieldValue(
                                  "startLatitude",
                                  value?.data?.startLatitude
                                    ? value?.data?.startLatitude
                                    : ""
                                );
                                setFieldValue(
                                  "startLongitude",
                                  value?.data?.startLongitude
                                    ? value?.data?.startLongitude
                                    : ""
                                );
                              }
                            }}
                            api={routeData}
                            viewDisabled={routeSingleData ? true : false}
                            searchedData={searchedData}
                            setSearchedData={setSearchedData}
                            value={{
                              cityId: routeSingleData?.cityId.toString() ?? "",
                              endLandmarkDescription:
                                routeSingleData?.endLandmarkDescription ?? "",
                              endLatitude: routeSingleData?.endLatitude ?? "",
                              endLongitude: routeSingleData?.endLongitude ?? "",
                              startLandmarkDescription:
                                routeSingleData?.startLandmarkDescription ?? "",
                              startLatitude:
                                routeSingleData?.startLatitude ?? "",
                              startLongitude:
                                routeSingleData?.startLongitude ?? "",
                              stateId:
                                routeSingleData?.stateId.toString() ?? "",
                            }}
                          />
                        </ModalView>
                      )}
                      <div className="media-form-id">
                        Media ID
                        <Badge color="primary">
                          {values?.name
                            ? values.name
                            : mediaName.data?.length > 0 &&
                              mediaName?.data?.[0]}
                        </Badge>
                      </div>
                      <Card>
                        <Row>
                          <Col md={4} className="mb-3">
                            <Dropdown
                              fieldName="mediaTypeId"
                              labelName="Media Type"
                              options={mediaTypeList}
                              setFieldValue={setFieldValue}
                              values={values}
                              isClearable={true}
                              selectedValue={values.mediaTypeId}
                              callback={(data: any) => {
                                if (selectedType === "HORDINGS") {
                                  setFieldValue(`qty`, 1);
                                }
                                setSelectedType(data?.type ? data.type : "");
                              }}
                            />
                          </Col>
                          {/* <Col md={3} className="mb-3">
                    <TextBox
                      fieldName={"name"}
                      placeholder={"Media Id"}
                      className={"form-control"}
                      type={"text"}
                      labelName={
                        selectedType === "KIOS" ? "Media Route Id" : "Media Id"
                      }
                      value={value}
                    />
                  </Col> */}
                          <Col md={4} className="mb-3">
                            <Dropdown
                              fieldName="mediaLitId"
                              labelName="Media Lit"
                              isClearable={true}
                              options={mediaLitList}
                              setFieldValue={setFieldValue}
                              values={values}
                              selectedValue={values.mediaLitId}
                            />
                          </Col>
                          <Col md={4} className="mb-3">
                            <div className="field-add-box">
                              <Dropdown
                                fieldName="agencyId"
                                labelName="Agency"
                                gorupClass="w-100"
                                options={agencyList}
                                isClearable={true}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectedValue={values.agencyId}
                                callback={async (value: any) => {
                                  if (!value) {
                                    setFieldValue("name", "");
                                    return;
                                  }
                                  await getMediaPrefix(
                                    value?.data,
                                    setFieldValue
                                  );
                                }}
                              />
                              <Button
                                type="button"
                                color="secondary"
                                onClick={() => toggleModel("agency")}
                              >
                                {plus.icon}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {selectedType !== "KIOS" && (
                            <>
                              <Col md={2} className="mb-3">
                                <Dropdown
                                  fieldName="stateId"
                                  labelName="State"
                                  options={stateList}
                                  isClearable={true}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  callback={(data: any) => {
                                    if (!data) {
                                      setFieldValue("cityId", "");
                                      setFieldValue("locationId", "");
                                      setFieldValue("latitude", "");
                                      setFieldValue("longitude", "");
                                      setSelectedLocation({
                                        ...selectedLocation,
                                        stateId: "",
                                        cityId: "",
                                        locationId: "",
                                      });
                                      return;
                                    }
                                    setSelectedLocation({
                                      ...selectedLocation,
                                      stateId: data?.value,
                                    });
                                  }}
                                  selectedValue={
                                    values?.stateId ? values.stateId : ""
                                  }
                                />
                              </Col>
                              <Col md={2} className="mb-3">
                                <Dropdown
                                  fieldName="cityId"
                                  labelName="City"
                                  options={cityList}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  isClearable={true}
                                  callback={(data: any) => {
                                    if (!data) {
                                      setFieldValue("locationId", "");
                                      setFieldValue("latitude", "");
                                      setFieldValue("longitude", "");
                                      setSelectedLocation({
                                        ...selectedLocation,
                                        cityId: "",
                                        locationId: "",
                                      });
                                      return;
                                    }
                                    setSelectedLocation({
                                      ...selectedLocation,
                                      cityId: data?.value,
                                    });
                                  }}
                                  selectedValue={
                                    values?.cityId ? values.cityId : ""
                                  }
                                />
                              </Col>
                            </>
                          )}

                          {selectedType !== "KIOS" && (
                            <Col md={4} className="mb-3">
                              <div className="field-add-box">
                                <Dropdown
                                  fieldName="locationId"
                                  labelName="Location"
                                  options={locationList}
                                  gorupClass="w-100"
                                  isClearable={true}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  callback={(data: any) => {
                                    if (data) {
                                      setFieldValue(
                                        "latitude",
                                        data?.data?.latitude
                                      );
                                      setFieldValue(
                                        "longitude",
                                        data?.data?.longitude
                                      );
                                      setFieldValue(
                                        "cityId",
                                        data?.data?.cityId
                                      );
                                      setFieldValue(
                                        "stateId",
                                        data?.data?.city?.stateId
                                      );
                                      setSelectedLocation({
                                        ...selectedLocation,
                                        stateId: data?.data?.city?.stateId,
                                        cityId: data?.data?.cityId,
                                        locationId: data?.value,
                                      });
                                    } else {
                                      setFieldValue("latitude", "");
                                      setFieldValue("longitude", "");
                                    }
                                  }}
                                  selectedValue={
                                    values?.locationId ? values.locationId : ""
                                  }
                                />
                                {/* {values.locationId && locationData && (
                          <TooltipItem
                            description={locationData}
                            id={values.locationId}
                          />
                        )} */}
                                <Button
                                  type="button"
                                  // className="add-landlord-btn"
                                  color="secondary"
                                  onClick={() => {
                                    toggleModel("location");
                                    setSearchedData("");
                                    setViewMode(false)
                                  }}
                                >
                                  {plus.icon}
                                </Button>
                              </div>
                            </Col>
                          )}
                          {selectedType !== "KIOS" && (
                            <>
                              <Col md={4} className="mb-3 media-route-box">
                                <div className="field-add-box">
                                  <TextBox
                                    fieldName={"latitude"}
                                    placeholder={"latitude"}
                                    className={"form-control"}
                                    type={"text"}
                                    labelName={"Latitude - Longitude"}
                                    value={value}
                                    callBack={(e: any) => {
                                      setFieldValue("latitude", e);
                                      // handleSelectLocation(values, setFieldValue)
                                    }}
                                  />
                                  <TextBox
                                    fieldName={"longitude"}
                                    placeholder={"Enter longitude"}
                                    gorupClass={"align-self-end"}
                                    className={"form-control"}
                                    type={"text"}
                                    labelName={"Latitude - Longitude"}
                                    callBack={(e: any) => {
                                      setFieldValue("longitude", e);
                                      // handleSelectLocation(values, setFieldValue)
                                    }}
                                    value={value}
                                  />
                                  {values.latitude && values.longitude && (
                                    <GoogleMapsStartLink
                                      startLat={values.latitude}
                                      endLng={values.longitude}
                                    />
                                  )}
                                  {values.latitude && values.longitude && (
                                    <Button
                                      onClick={() =>{
                                        setViewMode(true)
                                        handleSelectLocation(
                                          values,
                                          setFieldValue
                                        )
                                      }}
                                    >
                                      {searchIcon.icon}
                                    </Button>
                                  )}
                                </div>

                                {/* {values.latitude && (
                              <h5>
                                <Badge style={{ fontWeight: "400" }}>
                                  {values.latitude}
                                </Badge>
                              </h5>
                            )}
                            {values.longitude && (
                              <h5>
                                {" "}
                                <Badge style={{ fontWeight: "400" }}>
                                  {values.longitude}
                                </Badge>
                              </h5>
                            )} */}
                              </Col>

                              {/* <Col>
                        <FormGroup>
                          <Label>Longitude: &nbsp;</Label>
                          
                        </FormGroup>
                      </Col> */}
                            </>
                          )}
                        </Row>

                        {selectedType === "KIOS" && (
                          <Row>
                            <Col md={4} className="mb-3 media-route-box">
                              <div className="field-add-box">
                                <Dropdown
                                  fieldName="routeId"
                                  labelName="Route"
                                  options={routeList}
                                  setFieldValue={setFieldValue}
                                  gorupClass="w-100"
                                  values={values}
                                  selectedValue={values.routeId}
                                  isClearable={true}
                                  callback={(data: any) => {
                                    setFieldValue(
                                      "latitude",
                                      data?.data?.latitude
                                        ? data.data.latitude
                                        : ""
                                    );
                                    setFieldValue(
                                      "longitude",
                                      data?.data?.longitude
                                        ? data.data.longitude
                                        : ""
                                    );
                                    setFieldValue(
                                      "cityId",
                                      data?.data?.cityId ? data.data.cityId : ""
                                    );
                                    setFieldValue(
                                      "stateId",
                                      data?.data?.stateId
                                        ? data.data.stateId
                                        : ""
                                    );
                                    setFieldValue(
                                      "endLatitude",
                                      data?.data?.endLatitude
                                        ? data.data.endLatitude
                                        : ""
                                    );
                                    setFieldValue(
                                      "endLongitude",
                                      data?.data?.endLongitude
                                        ? data.data.endLongitude
                                        : ""
                                    );
                                    setFieldValue(
                                      "startLatitude",
                                      data?.data?.startLatitude
                                        ? data.data.startLatitude
                                        : ""
                                    );
                                    setFieldValue(
                                      "startLongitude",
                                      data?.data?.startLongitude
                                        ? data.data.startLongitude
                                        : ""
                                    );
                                  }}
                                />
                                {values.startLatitude && values.endLatitude && (
                                  <GoogleMapsLink
                                    startLat={values.startLatitude}
                                    startLng={values.startLongitude}
                                    endLat={values.endLatitude}
                                    endLng={values.endLongitude}
                                  />
                                )}
                                {values.startLatitude && values.endLatitude ? (
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      const findRouteData =
                                        routeData?.data?.data &&
                                        routeData?.data?.data.length > 0 &&
                                        routeData?.data?.data.find(
                                          (element: any) =>
                                            element.id == values.routeId
                                        );
                                      setRouteSingleData(findRouteData ?? null);
                                      toggleModel("route");
                                    }}
                                  >
                                    {viewIcon.icon}
                                  </Button>
                                ) : (
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      toggleModel("route");
                                    }}
                                  >
                                    {plus.icon}
                                  </Button>
                                )}
                              </div>
                            </Col>
                            {selectedType === "KIOS" && (
                              <Col className="mb-3 zone-detail-box">
                                <div className="field-add-box">
                                  <Dropdown
                                    fieldName="zoneId"
                                    labelName="Zone"
                                    gorupClass="w-100"
                                    options={zoneList}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    callback={(data: any) => {
                                      setSelectedLocation({
                                        ...selectedLocation,
                                        zoneId: data.value,
                                      });
                                    }}
                                    selectedValue={values.zoneId}
                                  />
                                  {values.zoneId && (
                                    <TooltipItem
                                      description={zoneData?.description || ""}
                                      id={values.zoneId}
                                    />
                                  )}
                                </div>
                              </Col>
                            )}
                            <Col>
                              <Dropdown
                                fieldName="mediaSeriesId"
                                labelName="Media Series"
                                options={mediaSeriesList}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectedValue={values.mediaSeriesId}
                              />
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col md={4} className="mb-3">
                            <TextBox
                              fieldName={"direction"}
                              placeholder={"Enter direction"}
                              className={"form-control"}
                              type={"text"}
                              labelName={"City/Location/Direction"}
                              value={value}
                            />
                          </Col>
                          <Col md={2}>
                            <TextBox
                              fieldName={"width"}
                              placeholder={"Enter width"}
                              className={"form-control"}
                              type={"text"}
                              labelName={"Width (Sq.Ft)"}
                              value={value}
                            />
                          </Col>
                          <Col md={2}>
                            {" "}
                            <TextBox
                              fieldName={"height"}
                              placeholder={"Enter height"}
                              className={"form-control"}
                              type={"text"}
                              labelName={"Height (Sq.Ft)"}
                              value={value}
                            />
                          </Col>
                          <Col md={2}>
                            <TextBox
                              fieldName={`rateAgency`}
                              placeholder={"Enter Agency rate"}
                              className={"form-control"}
                              gorupClass={"col"}
                              type={"text"}
                              labelName={"Agency Rate"}
                              value={value}
                            />
                          </Col>

                          {values.width && values.height && (
                            <Col>
                              <FormGroup>
                                <Label>Total Sq.Ft Size </Label>
                                <h5>
                                  <Badge style={{ fontWeight: "400" }}>
                                    {values.height * values.width}
                                  </Badge>
                                </h5>
                              </FormGroup>
                            </Col>
                          )}

                          <Col md={2}>
                            <TextBox
                              fieldName={"qty"}
                              placeholder={"Enter quantity"}
                              className={"form-control"}
                              type={"number"}
                              labelName={
                                selectedType === "KIOS"
                                  ? "Pol Kios Quantity"
                                  : "Quantity"
                              }
                              value={values}
                              isDisabled={selectedType === "HORDINGS"}
                            />
                          </Col>
                        </Row>
                      </Card>
                      <FieldArray name="landlordInfo">
                        {({ push, remove }) => (
                          <Card className="landlord-card">
                            <Row>
                              <Col md="12">
                                <div className="landlord-head">
                                  <div className="field-add-box">
                                    <Dropdown
                                      fieldName="landlordId"
                                      labelName="Landlord"
                                      options={landlordList}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      isClearable={true}
                                      selectedValue={values.landlordId}
                                    />
                                    <Button
                                      type="button"
                                      className="add-landlord-btn"
                                      color="secondary"
                                      onClick={() => toggleModel("landLoard")}
                                    >
                                      {plus.icon}
                                    </Button>
                                  </div>
                                  {values.landlordId && (
                                    <Button
                                      type="button"
                                      className="add-landlord-btn"
                                      color="secondary"
                                      outline
                                      onClick={() =>
                                        push({
                                          id: "",
                                          rateLandlord: "",
                                          startDate: null,
                                          endDate: null,
                                        })
                                      }
                                    >
                                      {plus.icon} Add Landlord Info
                                    </Button>
                                  )}
                                </div>
                              </Col>
                              {values.landlordInfo?.length > 0 && (
                                <Col
                                  md={12}
                                  className="landlord-data-scroll small-scroll"
                                >
                                  {values.landlordInfo?.map(
                                    (landlord: any, index: any) => (
                                      <Card>
                                        <Row
                                          key={index}
                                          className="align-items-end"
                                        >
                                          <TextBox
                                            fieldName={`landlordInfo.${index}.rateLandlord`}
                                            placeholder={"Landlord rate"}
                                            className={"form-control"}
                                            gorupClass={"col"}
                                            type={"text"}
                                            labelName={"Landlord Rate"}
                                            value={value}
                                          />

                                          <FormGroup
                                            noMargin
                                            className={"form-group col"}
                                          >
                                            <Label
                                              className="form-label"
                                              htmlFor={"bookingStartDate"}
                                            >
                                              Start Date
                                              <sup className="text-danger required">
                                                *
                                              </sup>
                                            </Label>
                                            <Field
                                              component={DatePicker}
                                              placeholderText={"DD/MM/YYYY"}
                                              dateFormat={"dd/MM/yyyy"}
                                              className="form-control"
                                              name={`landlordInfo.${index}.startDate`}
                                              selected={getDate(
                                                landlord.startDate
                                              )}
                                              menuPortalTarget={document.body}
                                              onChange={(date: any) => {
                                                setFieldValue(
                                                  `landlordInfo.${index}.startDate`,
                                                  date
                                                );
                                              }}
                                            />
                                          </FormGroup>
                                          <FormGroup
                                            noMargin
                                            className={"form-group col"}
                                          >
                                            <Label
                                              className="form-label"
                                              htmlFor={`landlordInfo.${index}.endDate`}
                                            >
                                              End Date
                                              <sup className="text-danger required">
                                                *
                                              </sup>
                                            </Label>
                                            <Field
                                              component={DatePicker}
                                              placeholderText={"DD/MM/YYYY"}
                                              dateFormat={"dd/MM/yyyy"}
                                              className="form-control"
                                              name={`landlordInfo.${index}.endDate`}
                                              selected={getDate(
                                                landlord.endDate
                                              )}
                                              menuPortalTarget={document.body}
                                              onChange={(date: any) => {
                                                setFieldValue(
                                                  `landlordInfo.${index}.endDate`,
                                                  date
                                                );
                                              }}
                                            />
                                          </FormGroup>
                                          <Col className="add-landloard-btn-box">
                                            {landlord.pdfFile ||
                                            landlord.docUrl ? (
                                              // <div
                                              //   className="card-body"
                                              //   style={{ marginTop: 8 }}
                                              // >
                                              //   <a
                                              //     href={
                                              //       landlord.pdfUrl || API_URL + landlord.docUrl
                                              //     }
                                              //     target="_blank"
                                              //     rel="noopener noreferrer"
                                              //   >
                                              //     {landlord.pdfFile || landlord.docUrl}
                                              //   </a>
                                              //   <button
                                              //     type="button"
                                              //     onClick={() =>
                                              //       handleFileRemove(
                                              //         landlord.id,
                                              //         index,
                                              //         setFieldValue
                                              //       )
                                              //     }
                                              //     style={{
                                              //       background: "red",
                                              //       color: "white",
                                              //       border: "none",
                                              //       borderRadius: "50%",
                                              //       width: 24,
                                              //       height: 24,
                                              //       cursor: "pointer",
                                              //       marginLeft: 8,
                                              //     }}
                                              //   >
                                              //     X
                                              //   </button>
                                              // </div>

                                              <div className="dropzone">
                                                <div className="attachment-data-list">
                                                  <div className="attachment-data">
                                                    <a
                                                      href={
                                                        landlord.pdfUrl ||
                                                        API_URL +
                                                          landlord.docUrl
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      title={
                                                        landlord.pdfFile ||
                                                        landlord.docUrl
                                                      }
                                                      className="file-box"
                                                    >
                                                      {file.icon}
                                                      <p>
                                                        {" "}
                                                        {landlord.pdfFile ||
                                                          landlord.docUrl}
                                                      </p>
                                                    </a>
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="remove-box"
                                                      onClick={() =>
                                                        handleFileRemove(
                                                          landlord.id,
                                                          index,
                                                          setFieldValue
                                                        )
                                                      }
                                                      title="Remove"
                                                    >
                                                      {crossIcon.icon}
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <Button className="btn-attachment">
                                                {LinkIcon.icon} Add Attachment
                                                <input
                                                  type="file"
                                                  accept="application/pdf"
                                                  onChange={(event) =>
                                                    handleFileChange(
                                                      event,
                                                      index,
                                                      setFieldValue
                                                    )
                                                  }
                                                />
                                              </Button>
                                              // <input
                                              //   type="file"
                                              //   accept="application/pdf"
                                              //   onChange={(event) =>
                                              //     handleFileChange(event, index, setFieldValue)
                                              //   }
                                              // />
                                            )}
                                            <Button
                                              type="button"
                                              color="danger"
                                              className="btn-remove"
                                              onClick={() => {
                                                if (landlord.id) {
                                                  setMediaItemLandlordIds(
                                                    (prev) => [
                                                      ...prev,
                                                      landlord.id,
                                                    ]
                                                  );
                                                }
                                                remove(index);
                                              }}
                                            >
                                              {deleteIcon.icon}
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Card>
                                    )
                                  )}
                                </Col>
                              )}
                            </Row>
                          </Card>
                        )}
                      </FieldArray>
                      <Card className="media-images">
                        <div className="media-image-head">
                          <h3>Media Images</h3>
                          <div className="doubleclick_note">
                            <p>
                              <span>{clickIcon.icon}</span> Click and select
                              Primary Image
                            </p>
                          </div>
                        </div>
                        <Row>
                          {imageUpload.selectedImages?.length < 4 && (
                            <Col md="3">
                              <FormGroup>
                                <ImageUpload
                                  imageUpload={imageUpload}
                                  accept={"image/*"}
                                  isDisabled={
                                    imageUpload.selectedImages?.length >= 4
                                  }
                                />
                              </FormGroup>
                            </Col>
                          )}

                          <Col md="9">
                            <div className="images-list">
                              {imageUpload.imagePreviews.map(
                                (preview: any, index: any) => (
                                  <div
                                    key={index}
                                    className="images-item"
                                    style={{
                                      backgroundColor: preview.isPrimary
                                        ? "#FFEE00"
                                        : "",
                                    }}
                                  >
                                    {preview?.path ? (
                                      <>
                                        <ProtectedImage
                                          value={preview?.path}
                                          callBack={() =>
                                            imageUpload.handleSetPrimaryImage(
                                              index
                                            )
                                          }
                                        />
                                      </>
                                    ) : (
                                      <img
                                        src={preview.url}
                                        alt={`preview-${index}`}
                                        onClick={() =>
                                          imageUpload.handleSetPrimaryImage(
                                            index
                                          )
                                        }
                                        width={25}
                                        height={25}
                                        style={{
                                          width: 100,
                                          height: 100,
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}

                                    <button
                                      className="remove-box"
                                      onClick={() => {
                                        imageUpload.handleRemoveImage(index);
                                      }}
                                    >
                                      {crossIcon.icon}
                                    </button>
                                  </div>
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card>
                      <Col lg="12" className="d-flex justify-content-end gap-2">
                        <Button
                          color="secondary"
                          onClick={() => closeAddUpdate()}
                          type="button"
                          outline
                        >
                          Cancel
                        </Button>
                        <Button color="secondary" type="submit">
                          {value?.id > 0 ? "Update" : "Submit"}
                        </Button>
                      </Col>
                    </Form>
                  </>
                );
              }}
            </Formik>
          )}
        </>
      }
    />
  );
};
export { AddUpdateItem };
