import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import AddCellTable from "../../../components/table/addCellTable";
import { DataType } from "ka-table";
import { ActionButtons, DateView, InputCell, MaterialID, RouteID } from "../actions";

//Flex banner printing and mounting pole kiosk
export default function FpmpkTable({ mediaInventory, bookingStartDate, setFieldValue, isEditMode }: any) {
  const columns = [
    {
      key: "routeId",
      title: "Route ID",
      style: { width: "13vw" },
      dataType: DataType.String,
      component: RouteID
    },
    { key: "zonedesc", title: "Zone Id", dataType: DataType.String, style: { width: "9vw" } },
    { key: "mediaType", title: "Media Type", dataType: DataType.String, style: { width: "13vw" } },
    { key: "mediaSeries", title: "Media Series", dataType: DataType.String, style: { width: "13vw" } },
    { key: "size", title: "size", dataType: DataType.String, style: { width: "8vw" } },
    { key: "sqft", title: "SQ.FT", dataType: DataType.String, style: { width: "6vw" } },
    {
      key: "qty", title: "Qty", dataType: DataType.Number,
      component: InputCell,
      style: { width: "7vw" }
    },
    {
      key: "materialId",
      title: "material",
      dataType: DataType.String,
      component: MaterialID,
      style: { width: "13vw" }
    },
    { key: "rate", title: "Rate per Pole Kiosk", dataType: DataType.Number, style: { width: "9vw" }, component: InputCell, },
    // { key: "startDate", title: "Start Date",
    //   dataType: DataType.Date,
    //   valueConvert:DateView,
    //   style: { width: "6vw" }  },
    // { key: "endDate", title: "End Date",
    //   dataType: DataType.Date,
    //   valueConvert:DateView,
    //   style: { width: "6vw" }

    //  },
    { key: "amount", title: "Amount", dataType: DataType.Number, style: { width: "7vw" } },
    {
      key: "addColumn",
      title: "",
      component: ActionButtons,
      style: { width: "5vw", textAlign: 'center' }

    },
  ];
  const dbId = "FPMPK"
  return (
    <>
      <AccordionItem>
        <AccordionHeader targetId={dbId}>
          FLEX BANNER PRINTING AND MOUNTING POLE KIOSK
        </AccordionHeader>
        <AccordionBody accordionId={dbId}>
          <AddCellTable
            bookingStartDate={bookingStartDate}
            groupCode={dbId}
            updateFieldValue={setFieldValue}
            columns={columns} isEditMode={isEditMode}
            rows={mediaInventory}
          />
        </AccordionBody>
      </AccordionItem>
    </>
  );
}
