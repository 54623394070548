import { DataType } from "ka-table"
import { TableColumn } from "../../../components/table/table"
import { ActionButton } from "../../../components/buttons/actionButtons"
import { DELETE, UPDATE } from "../../../constant/commonConst"
import { confirmBox } from "../../../components/toast"
import { getFormattedDate } from "../../../utils/dateUtils"

export const columns = (actions: any): TableColumn[] => {
    return [
        {
            key: 'id',
            title: 'id',
            dataType: DataType.Number,
            visible: false
        },
        {
            key: 'businessName',
            title: 'business Name',
            dataType: DataType.String
        },
        {
            key: 'bookingDate',
            title: 'Booking Date',
            dataType: DataType.String,
            valueConvert: (rowData: any) => {
                return getFormattedDate(rowData.bookingDate)
            }
        },
        {
            key: 'bookingNo',
            title: 'booking No',
            dataType: DataType.String
        },
        {
            key: 'totalAmount',
            title: 'Total Booking Amount',
            dataType: DataType.String
        },
        {
            key: 'recivedAmount',
            title: 'Recived Amount',
            dataType: DataType.String
        },
        {
            key: 'balance',
            title: 'balance',
            dataType: DataType.String
        },
    ]
}
