import * as Yup from "yup";
export const UserMobMountingImageSchema = Yup.object().shape({
  mountingDate: Yup.string().required("Mounting Date is Required"),
  mountingTime: Yup.string().required("Mounting Time is Required"),
  regularShotImage: Yup.string().required("Regular Shot Image is Required"),
  longShotImage: Yup.string().required("Long Shot Image is Required"),
  shortShortImage: Yup.string().required("Short Shot Image is Required"),
  shortGeoShotImage: Yup.string().required(
    "Short with Geo Shot Image is Required"
  ),
});
