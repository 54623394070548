import { useState, useEffect, useRef } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  cameraIcon,
  crossIcon,
  leftSquareArrow,
  LocationIcon,
  user,
} from "../../constant/icons";
import CardImg from "../../assets/images/poster-img.png";
import { useApi } from "../../helper/apiHook";
import SelectStatus from "./components/SelectStatus";
import { invokeApi } from "../../helper/api";
import { GET } from "../../constant/commonConst";
import { Form, Formik } from "formik";
import { useUpload } from "../masters/items/helper";
import ProtectedImage, { CanvasImage } from "../../components/common/image";
import { UserMobMountingImageSchema } from "./components/UsermobMountingImageSchema";
import { getFormattedDate, getTime } from "../../utils/dateUtils";
import UserLayout from "../../components/layout/UserLayout";
import moment from "moment";
import { getStatusActiveTab } from "../../utils/generalUtils";

export const GetAllMounting = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/mountings/userMounting", paging, undefined, dataLoad) };
};
export default function TaskDtl() {
  const [singleMountingState, setSingleMounting] = useState<any>(null);
  const [status, setStatus] = useState<string>("");
  const location = useLocation();
  const { activeTab } = location.state;
  console.log(activeTab, "activeTab");
  const mountingList = GetAllMounting(true);
  const regularshot = useUpload(1);
  const monitoringshot = useUpload(1);
  const longshot = useUpload(1);
  const shortshot = useUpload(1);
  const shortGeoShot = useUpload(1);
  const getParams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getDetailsOfMounting();
  }, [getParams?.id]);

  const getDetailsOfMounting = async () => {
    const mountings = await invokeApi(GET, `/mountings/${getParams?.id}`);
    if (mountings?.status) {
      setSingleMounting(
        mountings?.data?.data && mountings?.data?.data.length > 0
          ? mountings?.data?.data
          : null
      );
    }
  };
  const apiData =
    singleMountingState && singleMountingState?.length > 0
      ? singleMountingState[0]
      : null;
  useEffect(() => {
    if (singleMountingState && singleMountingState.length > 0) {
      setStatus(singleMountingState[0]?.status);
    }
  }, [singleMountingState]);
  const jsonParse = apiData?.mountingShot
    ? JSON.parse(apiData?.mountingShot)
    : null;
  const jsonParseMoutingImage = apiData?.mountingImages
    ? JSON.parse(apiData?.mountingImages)
    : null;

  const [locationLive, setLocation] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  useEffect(() => {
    // Check for permission status
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        // If permission is granted, fetch location
        getLocation();
      } else if (result.state === "prompt") {
        // Request permission and fetch location if granted
        getLocation();
      } else {
        // Permission denied
        setError("Location access denied");
      }

      // Watch for permission state changes
      result.onchange = () => {
        if (result.state === "granted") {
          getLocation();
        } else {
          setError("Location access denied");
        }
      };
    });

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
          },
          (error) => {
            setError("Unable to retrieve location");
          }
        );
      } else {
        setError("Geolocation is not supported by this browser");
      }
    };
  }, []);
  console.log(activeTab, "activeTab");
  return (
    <>
      <div className="front-page">
        <div className="container">
          <UserLayout />
          <Button
            onClick={() => {
              navigate("/mob-mounting", {
                state: {
                  from: getStatusActiveTab(status),
                  activeBooking: getParams?.id, // Pass the ID here
                },
              });
            }}
            className="hamburger-btn p-0"
          >
            {leftSquareArrow.icon}
          </Button>
          <Formik
            initialValues={{
              mountingDate: apiData?.fromDate
                ? getFormattedDate(apiData?.fromDate, "YYYY-MM-DD")
                : "",
              mountingTime: getTime(apiData?.mountingTime) ?? "",
              regularShotImage:
                jsonParse && jsonParse.length > 0
                  ? jsonParse.find(
                      (element: any) => element.tagname == "regularShot"
                    )?.url
                  : "",
              longShotImage:
                jsonParse && jsonParse.length > 0
                  ? jsonParse.find(
                      (element: any) => element.tagname == "longShot"
                    )?.url
                  : "",
              shortShortImage:
                jsonParse && jsonParse.length > 0
                  ? jsonParse.find(
                      (element: any) => element.tagname == "shortShot"
                    )?.url
                  : "",
              shortGeoShotImage:
                jsonParse && jsonParse.length > 0
                  ? jsonParse.find(
                      (element: any) => element.tagname == "shortGeoShot"
                    )?.url
                  : "",
              monitoringShotImage:
                jsonParse && jsonParse.length > 0
                  ? jsonParse.find(
                      (element: any) => element.tagname == "monitoringShot"
                    )?.url
                  : "",
              mountingShot: apiData?.mountingShot
                ? JSON.parse(apiData?.mountingShot)
                : [],
            }}
            enableReinitialize
            validateOnMount
            validationSchema={UserMobMountingImageSchema}
            onSubmit={async (values: any, { resetForm }) => {
              const formData = new FormData();
              formData.append(
                "fromDate",
                new Date(values?.mountingDate).toISOString()
              );
              formData.append("mountingTime", values?.mountingTime);
              formData.append("id", values.id ?? "");
              formData.append(`regularShot`, values?.regularShotImage);
              formData.append(`longShot`, values?.longShotImage);
              formData.append(`shortShot`, values?.shortShortImage);
              formData.append(`shortGeoShot`, values?.shortGeoShotImage);
              formData.append(`monitoringShot`, values?.monitoringShotImage);
              // formData.append(`regularShot`, regularshot?.selectedImages[0]);
              // formData.append(`longShot`, longshot?.selectedImages[0]);
              // formData.append(`shortShot`, shortshot?.selectedImages[0]);
              // formData.append(`shortGeoShot`, shortGeoShot?.selectedImages[0]);
              formData.append(
                `mountingShot`,
                JSON.stringify(values?.mountingShot)
              );
              formData.append(`mountingShotlatitude`, locationLive?.latitude);
              formData.append(`mountingShotlongitude`, locationLive?.longitude);
              mountingList.updateValue(formData).then((result: any) => {
                if (result?.status) {
                  navigate("/mob-mounting", {
                    state: { from: getStatusActiveTab(status) },
                  });
                }
              });
            }}
          >
            {({ values, handleChange, errors, ...rest }) => {
              const formikObj = { ...rest, values, errors };
              return (
                <Form>
                  <Row className="mt-3 ">
                    <Col lg={6} md={6} sm={12}>
                      <div className="task-img">
                        {jsonParseMoutingImage ? (
                          <ProtectedImage value={jsonParseMoutingImage?.url} />
                        ) : (
                          <img src={CardImg} alt="Poster Image" />
                        )}
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      {console.log(apiData, "apiData") as any}
                      <div className="task-detail">
                        <div className="content-head-box">
                          <div>
                            {/* <h3>Craft media Gandhinagar</h3> */}
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              className="d-flex gap-2"
                            >
                              <span>{LocationIcon.icon}</span>{" "}
                              {apiData?.mediaItem?.routeId
                                ? apiData?.mediaItem?.route
                                    ?.startLandmarkDescription
                                : apiData?.mediaItem?.location?.name}
                            </a>
                          </div>
                          <div className="inner-badge-data">
                            <span className="assigny-box">
                              <span>{user.icon}</span>
                              {apiData?.assignee?.name}
                            </span>
                            <span className="task-number">
                              Task no: {apiData?.taskNo}
                            </span>
                            <span className="poster-size-box">
                              BookingNo: {`${apiData?.bookingNo}`}
                            </span>
                            <span className="poster-size-box">
                              {`${apiData?.mediaItem?.width} * ${apiData?.mediaItem?.height}`}{" "}
                              ft
                            </span>
                            <span className="poster-qty">
                              Qty : {apiData?.mediaItem?.qty ?? 0}
                            </span>
                          </div>
                        </div>
                        {/* <p className="detail-content">Lorem ipsum dolor sit amet consectetur adipiscing elit, convallis imperdiet neque pulvinar porta orci, phasellus aptent suspendisse at id natoque. Lacinia aliquet praesent magna blandit hac ligula nunc sociosqu, velit viverra nullam nibh sodales morbi mattis habitant, dis congue tristique pellentesque porta netus montes. Justo praesent tempor senectus facilisi accumsan nullam fusce faucibus aenean,</p> */}
                        <div className="content-action">
                          <div className="status-time-box">
                            <FormGroup noMargin>
                              <Label>Mounting Date</Label>
                              <input
                                type="date"
                                className="form-control date-picker"
                                placeholder={"DD/MM/YYYY"}
                                data-date-format="DD MM YYYY"
                                value={
                                  values?.mountingDate
                                    ? moment(values?.mountingDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""
                                }
                                onChange={(event: any) => {
                                  rest?.setFieldValue(
                                    "mountingDate",
                                    new Date(event.target.value)
                                  );
                                  rest?.setFieldValue(
                                    "mountingTime",
                                    moment(event.target.value)
                                  );
                                }}
                              />
                              {/* <Field                            
                              component={DatePicker}
                              placeholderText={"DD/MM/YYYY"}
                              dateFormat={"dd/MM/yyyy"}
                              className="form-control"
                              name="mountingDate"
                              selected={values?.mountingDate ? getDate(values?.mountingDate):""}
                              onChange={(date: any) => {
                                rest?.setFieldValue("mountingDate", date);
                              }}
                            /> */}
                              {/* <Input
                                id="exampleSelect"
                                name="mountingDate"
                                type="date"
                                value={values?.mountingDate}
                                onChange={handleChange}
                              /> */}
                              {errors?.mountingDate && (
                                <small className="validation-class">
                                  {errors?.mountingDate as string}
                                </small>
                              )}
                            </FormGroup>
                            {/* <FormGroup noMargin>
                              <Label>Mounting Time</Label>
                              <Input
                                id="exampleSelect"
                                name="mountingTime"
                                type="time"
                                value={values?.mountingTime}
                                onChange={handleChange}
                              />
                              {
                                errors?.mountingTime && <small className="validation-class">{errors?.mountingTime as string}</small>
                              }
                            </FormGroup> */}
                            <SelectStatus
                              api={mountingList}
                              value={
                                singleMountingState &&
                                singleMountingState?.length > 0 &&
                                singleMountingState[0]
                              }
                              label
                              callBack={(val: any) => {
                                setStatus(val);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="task-capture-box">
                    <ImageUploader
                      title="Regular shot"
                      name="regularShotImage"
                      locationLive={locationLive}
                      fields="regularShot"
                      value={apiData}
                      imageUploader={regularshot}
                      formik={formikObj}
                    />
                    <ImageUploader
                      title="Long shot"
                      name="longShotImage"
                      locationLive={locationLive}
                      fields="longShot"
                      value={apiData}
                      imageUploader={longshot}
                      formik={formikObj}
                    />
                    <ImageUploader
                      title="Short shot"
                      name="shortShortImage"
                      locationLive={locationLive}
                      fields="shortShot"
                      value={apiData}
                      imageUploader={shortshot}
                      formik={formikObj}
                    />
                    <ImageUploader
                      title="Short with GEO shot"
                      name="shortGeoShotImage"
                      locationLive={locationLive}
                      fields="shortGeoShot"
                      value={apiData}
                      imageUploader={shortGeoShot}
                      formik={formikObj}
                    />
                    {activeTab !== "waiting-for-creative" && (
                      <ImageUploader
                        title="Monitoring  shot"
                        name="monitoringShotImage"
                        locationLive={locationLive}
                        fields="monitoringShot"
                        value={apiData}
                        imageUploader={monitoringshot}
                        formik={formikObj}
                      />
                    )}
                  </Row>
                  {activeTab !== "mounted" && (
                    <div className="submit-box">
                      <Button
                        color="secondary"
                        type="submit"
                        onClick={() => {
                          rest?.setFieldValue("id", apiData.id);
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export const ImageUploader = (props: any) => {
  const jsonParse = props?.value?.mountingShot
    ? JSON.parse(props?.value?.mountingShot)
    : null;
  const canvasRef = useRef(null);
  useEffect(() => {
    if (jsonParse && jsonParse.length > 0) {
      jsonParse.forEach((element: any) => {
        if (element.tagname == props?.fields) {
          props?.imageUploader.setImagePreviews([
            {
              path: element.url ?? "",
              id: "undefined",
              isPrimary: false,
            },
          ]);
          props?.formik?.setFieldValue(props?.name, element.url);
        }
      });
      props?.formik.setFieldValue("mountingShot", jsonParse);
    }
  }, [jsonParse && jsonParse.length > 0]);

  return (
    <Col md={6} lg={4} sm={6} xs={6} xl={3}>
      <h3>{props?.title}</h3>
      <div className="user-upload-image">
        <label className="dropzone-container">
          <div className="file-icon">{cameraIcon.icon}</div>
          <div className="dropzone-title">Click and Upload your Image</div>
        </label>
        <input
          type="file"
          capture="environment"
          name={props.name}
          accept={"image/*"}
          onChange={(e: any) => {
            if (e.target.files) {
              props?.imageUploader.handlefileChange(e);
            }
            // props?.formik?.setFieldValue(props?.name, e.target.files[0]);
            // props?.imageUploader.handlefileChange(e);
            // props?.formik?.setFieldValue(props?.name,e.files[0].value)
          }}
        />
        {props?.imageUploader?.imagePreviews &&
        props?.imageUploader?.imagePreviews?.length > 0 ? (
          <div
            className="images-item"
            style={{
              overflow: "hidden",
            }}
          >
            {props?.imageUploader?.imagePreviews[0] ? (
              props?.imageUploader?.imagePreviews[0]?.path ? (
                <ProtectedImage
                  value={props?.imageUploader?.imagePreviews[0]?.path}
                  callBack={() => {}}
                />
              ) : (
                <>
                  <CanvasImage
                    url={props?.imageUploader?.imagePreviews[0]?.url}
                    locationLive={props.locationLive}
                    callBack={(url: any) => {
                      props?.formik?.setFieldValue(props?.name, url);
                    }}
                  />

                  {/* <img
                  src={props?.imageUploader?.imagePreviews[0]?.url}
                  alt={`preview-${0}`}
                  width={25}
                  height={25}
                  style={{
                    // width: 100,
                    // height: 100,
                    objectFit: "cover",
                  }}
                    /> */}
                </>
              )
            ) : null}

            <button
              className="remove-box"
              onClick={() => {
                props?.imageUploader?.handleRemoveImage(0);
                props?.formik?.setFieldValue(props?.name, "");
                props?.formik?.setFieldError(
                  props?.name,
                  props?.formik?.errors[props?.name] ?? ""
                );
                const removeElementfromMountingShot = props?.value?.mountingShot
                  ? JSON.parse(props?.value?.mountingShot)
                  : [];
                const newMountingShot =
                  removeElementfromMountingShot &&
                  removeElementfromMountingShot.length > 0
                    ? removeElementfromMountingShot.filter(
                        (element: any) => element.tagname !== props.fields
                      )
                    : [];
                props?.formik?.setFieldValue("mountingShot", newMountingShot);
              }}
            >
              {crossIcon.icon}
            </button>
          </div>
        ) : null}
        {props?.formik?.errors[props?.name] && (
          <small className="validation-class">
            {props?.formik?.errors[props?.name]}
          </small>
        )}
      </div>
    </Col>
  );
};
