import { DataType } from "ka-table";
import {
  Booked,
  DELETE,
  Download,
  DUPLICATE,
  GENRATE,
  GET,
  POST,
  SEND,
  UPDATE,
} from "../../constant/commonConst";
import { confirmBox } from "../../components/toast";
import { TableColumn } from "../../components/table/table";
import { getParshData } from "../../utils/generalUtils";
import { ActionButton } from "./action";
import { useApi } from "../../helper/apiHook";
import { API_URL, invokeApi } from "../../helper/api";
import { Code } from "../../components/buttons/code";
import GeneratePdf from "./pdfGenerate/generatePdf";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "prososalNo",
      title: "prososal No.",
      dataType: DataType.String,
      component: Code,
    },
    {
      key: "client.businessName",
      title: "Client Name",
      dataType: DataType.String,
    },
    {
      key: "contactPerson",
      title: "contact Person",
      dataType: DataType.String,
    },
    {
      key: "contactPersonEmailId",
      title: "Email Address",
      dataType: DataType.String,
    },
    {
      key: "contactPersonMobileNo",
      title: "Mobile No.",
      dataType: DataType.String,
    },

    {
      key: "status",
      title: "status",
      hideHeaderFilter: false,
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        switch (rowData?.status?.toLowerCase()) {
          case "pending":
            return "Pending";
          case "generate_proposal":
            return "Generate Proposal";
          case "send_mail":
            return "Email Sended";
        }
      },
    },
    {
      key: "edit",
      title: "",
      hideHeaderFilter: false,
      disabledGeneratebtn: actions.generateId,
      disabledemailSendingbtn: actions.emailSending,
      component: ActionButton,
      callBack: async (row) => {
        const value = row.data;
        if (row.status === UPDATE) {
          actions.openAddEditFrom({ ...row.data });
        } else if (row.status === DELETE) {
          const response = await confirmBox(
            "you want to delete this Proposal?"
          );
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        } else if (row.status === DUPLICATE) {
          const mediaInventoryManipulation =
            value?.mediaInventory && value?.mediaInventory.length > 0
              ? value?.mediaInventory.map((element: any) => {
                delete element.createBy;
                delete element.createDate;
                delete element.updateBy;
                delete element.updateDate;
                return {
                  ...element,
                };
              })
              : [];
          const values = {
            description: value?.description || "",
            leadId: value?.leadId || null,
            clientId: value?.clientId || null,
            contactPerson: value?.contactPerson || "",
            contactPersonEmailId: value?.contactPersonEmailId || "",
            contactPersonMobileNo: value?.contactPersonMobileNo || "",
            contactPersonDesignation: value?.contactPersonDesignation || "",
            proposalDocUrl: value?.proposalDocUrl || "",
            mediaInventory: mediaInventoryManipulation,
            // mediaDescription: value?.mediaDescription
            //   ? getParshData(value?.mediaDescription)
            //   : [],
            status: value?.status || "",
            parentProposalId: null,
          };
          const response = await confirmBox(
            "you want to duplicate this proposal ?"
          );
          if (response) {
            actions.api.create(values);
          }
        } else if (row.status === SEND) {
          const response = await confirmBox("you want to send this proposal ?");
          if (response) {
            actions.sendCb(value?.id);
            await invokeApi(POST, `/proposals/send/${value.id}`);
            actions.api.mutate();
            actions.sendCb(0);
          }
        } else if (row.status === GENRATE) {
          const response = await confirmBox("you want to genarate propsel?");
          if (response) {
            actions.cb1(value?.id);
            const response = await invokeApi(
              GET,
              `/proposals/generate/${value.id}`,
              undefined,
              undefined,
              { responseType: "blob" }
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `proposal-${value.prososalNo}.ppt`); // Specify the file name
            document.body.appendChild(link);
            link.click();
            link.remove();
            actions.api.mutate();
            actions.cb1(0);
          }
        } else if (row.status === Booked) {
          actions.bookedCb(value?.id);
        } else if (row.status === Download) {
          if (value?.proposalDocUrl) {
            const response = await invokeApi(
              GET,
              `${value?.proposalDocUrl}`,
              undefined,
              undefined,
              { responseType: "blob" }
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `proposal-${value.prososalNo}.ppt`); // Specify the file name
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      },
      style: {
        textAlign: "center",        
      },
    },
  ];
};
