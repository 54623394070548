import { Row, Col } from 'reactstrap'
import { AppLogo } from '../../constant/logo'
import { useAuth } from '../../components/auth'
import { Navigate } from 'react-router-dom'
import sliderImg01 from '../../assets/images/auth-slider/imge_01.webp'
import sliderImg02 from '../../assets/images/auth-slider/imge_02.webp'
import sliderImg03 from '../../assets/images/auth-slider/imge_03.webp'
import sliderImg04 from '../../assets/images/auth-slider/imge_04.webp'
import sliderImg05 from '../../assets/images/auth-slider/imge_05.webp'
import sliderImg06 from '../../assets/images/auth-slider/imge_06.webp'
import sliderImg07 from '../../assets/images/auth-slider/imge_07.webp'
import sliderImg08 from '../../assets/images/auth-slider/imge_08.webp'
import sliderImg09 from '../../assets/images/auth-slider/imge_09.webp'
import sliderImg10 from '../../assets/images/auth-slider/imge_10.webp'
import sliderImg11 from '../../assets/images/auth-slider/imge_11.webp'
import { ROLE } from '../../constant/commonConst'



export const AuthLayout = (props:any) => {
    const auth:any=useAuth();
    let role = localStorage.getItem("role") ? localStorage?.getItem("role") : "";

    if(auth.user)
    {
        if(role ==ROLE.MOUNTINGUSER){
           return <Navigate to='/mob-mounting'/>
        }else{
            return <Navigate to='/home'/>
        }
    }
    return (
        <div className="login-page theme_01">
            <Row>
                <Col className="login-form">
                    <div className="login-main small-scroll">
                        <div className="loging-head">
                            {/* <img alt="Logo" src={AppLogo.small} /> */}
                            {AppLogo.small}
                        </div>
                        <div className="loging-content">
                            <h2>{props.Title}</h2>
                            <p>{props.SubTitle}</p>
                            {props.children}            
                        </div>
                        <div className="text-center copy-right w-100">
                            <p>Copyright © {new Date().getFullYear()} by Craft Media All Rights Reserved</p>
                        </div>
                    </div>
                </Col>
                <Col className="login-img">
                    <div className='auth-info'>
                        <h2>Give your <span>customers</span> reason to feel <span>connected</span> to your brand</h2>
                    </div>
                    <div className="image-track track-one">
                        <img src={sliderImg01} alt="Image 1" />
                        <img src={sliderImg02} alt="Image 2" />
                        <img src={sliderImg03} alt="Image 3" />
                        <img src={sliderImg04} alt="Image 4" />
                    </div>
                    <div className="image-track track-two">
                        <img src={sliderImg05} alt="Image 1" />
                        <img src={sliderImg06} alt="Image 2" />
                        <img src={sliderImg07} alt="Image 3" />
                        <img src={sliderImg08} alt="Image 4" />
                    </div>
                    <div className="image-track track-three">
                        <img src={sliderImg09} alt="Image 1" />
                        <img src={sliderImg10} alt="Image 2" />
                        <img src={sliderImg11} alt="Image 3" />
                        <img src={sliderImg01} alt="Image 4" />
                    </div>
                </Col>
            </Row>
        </div>
    )
}