import {
  Accordion,
  Button,
  Col,
  Row,
} from "reactstrap";
import { Form, Formik } from "formik";
import { validationSchema } from "./validation";
import PageLayout from "../../components/layout/page";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import Details from "../booking/component/details";
import SoahTable from "../booking/component/soah";
import PoahTable from "../booking/component/poah";
import FpmpkTable from "../booking/component/fpmpk";
import FbphTable from "../booking/component/fbph";
import FbpTable from "../booking/component/fbm";
import SoapkTable from "../booking/component/soapk";
import { useApi } from "../../helper/apiHook";
import { Booking, MediaInventory } from "../booking/dto";
import { uuid } from "../../utils/generalUtils";
import { Proposal } from "./dto";
import { GUJARAT_GST_CODE } from "../../constant/commonConst";
import { message } from "../../components/toast";



const AddUpdateProposal = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const dtlAPI = useApi("/proposals", false, {
    id: value.id
  })
  useEffect(() => {
    dtlAPI.mutate()
  }, [])

  const [open, setOpen] = useState<any>("1");
  const toggle = (id: number | any) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };
  return (
    <PageLayout
      View={
        !dtlAPI.isLoading &&
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            id: dtlAPI.data.id,
            leadId: dtlAPI?.data?.leadId,
            clientId: dtlAPI.data.clientId,
            contactPersonEmailId: dtlAPI.data.contactPersonEmailId,
            contactPersonMobileNo: dtlAPI.data.contactPersonMobileNo,
            contactPerson: dtlAPI.data.contactPerson,
            bookingStartDate: new Date(),
            description: dtlAPI.data.description,
            proposalNo: dtlAPI.data.proposalNo,
            proposalId: dtlAPI.data.proposalId,
            status: dtlAPI.data.status,
            billingAddress: dtlAPI.data?.client?.billingAddress,
            mediaInventory: (!Array.isArray(dtlAPI.data?.mediaInventory) ? [] : dtlAPI.data?.mediaInventory as any[])?.map((item: MediaInventory) => {
              let endDate: Date = item.endDate ? new Date(item.endDate) : new Date();
              let bookingStartDate: Date = dtlAPI.data.bookingStartDate ? new Date(dtlAPI.data.bookingStartDate) : new Date();

              // Calculate the difference in milliseconds and then convert to days
              let differenceInDays = Math.floor((endDate.getTime() - bookingStartDate.getTime()) / (1000 * 60 * 60 * 24));
              return {
                ...item,
                day: differenceInDays,
                endDate: endDate,
                tempRowId: uuid()
              }
            })
          } as Proposal}

          onSubmit={(values: any, actions: any) => {
            const isMediaItemIdValid = values.mediaInventory.every(
              (item: any) => item.hasOwnProperty('mediaItemId') && item.mediaItemId !== null && item.mediaItemId !== ''
            );
            if (!isMediaItemIdValid) {
              message("error", "Please select media item for each inventory")
            } else {
              values.mediaInventory = values.mediaInventory.map((x: any) => {
                let { tempRowId, agencyAmount, city, mediaLitType, mediaSeries, zonedesc, location, bookingId, sqft, size, day, updateDate, updateBy, createDate, createBy, disableQty, ...mediaInventoryItem } = x
                return mediaInventoryItem
              })
              const { tempRowId, bookingNo, bookingStartDate, billingAddress, GSTIN, ...valuesX } = values

              if (values.id) {
                api.updateValue(valuesX).then((result: any) => {
                  
                  if (result?.status) {
                    closeAddUpdate();
                  }
                });
              } else {
                delete valuesX.inventoryIds;
                api.create(valuesX).then((result: any) => {
                  if (result?.status) {
                    closeAddUpdate();
                  }
                });
              }
            }
          }
          }


        >
          {({ setFieldValue, values }) => {
            const SubTotalAmount = values.mediaInventory.reduce((acc, item) => acc + (item.amount || 0), 0);
            const igstAmount = SubTotalAmount * 0.18;
            const cgstAmount = SubTotalAmount * 0.09;
            const sgstAmount = SubTotalAmount * 0.09;
            const isGujaratGstNo = values?.GSTIN ? values?.GSTIN?.substring(-2) == GUJARAT_GST_CODE ? true : false : false

            return <Form className="form-main add-booking">
              <Details
                proposal={true}
                setFieldValue={setFieldValue}
                values={values} />
              <Accordion open={open} toggle={toggle}>
                <SoahTable
                  bookingStartDate={values.bookingStartDate}
                  setFieldValue={setFieldValue}
                  mediaInventory={values.mediaInventory}
                />
                <PoahTable
                  bookingStartDate={values.bookingStartDate}
                  setFieldValue={setFieldValue}
                  mediaInventory={values.mediaInventory}
                />
                <FbphTable
                  bookingStartDate={values.bookingStartDate}
                  setFieldValue={setFieldValue}
                  mediaInventory={values.mediaInventory}
                />
                <FbpTable
                  bookingStartDate={values.bookingStartDate}
                  setFieldValue={setFieldValue}
                  mediaInventory={values.mediaInventory}
                />
                <SoapkTable
                  bookingStartDate={values.bookingStartDate}
                  setFieldValue={setFieldValue}
                  mediaInventory={values.mediaInventory}
                />
                <FpmpkTable
                  bookingStartDate={values.bookingStartDate}
                  setFieldValue={setFieldValue}
                  mediaInventory={values.mediaInventory}
                />
              </Accordion>
              <Row className="add-booking-footer">
                <Col>
                  <div className="total-amount">
                    <label>Amount: {SubTotalAmount?.toFixed(2)}</label>
                    {isGujaratGstNo ? <label>IGST<strong> (18%):</strong> {igstAmount?.toFixed(2)}</label> : <>
                      <label>CGST<span>(9%):</span> {cgstAmount?.toFixed(2)}</label>
                      <label>SGST<span>(9%):</span> {sgstAmount?.toFixed(2)}</label>
                    </>}
                    <label>Total amount :<strong> {isGujaratGstNo ? (SubTotalAmount + igstAmount)?.toFixed(2) : (SubTotalAmount + cgstAmount + sgstAmount)?.toFixed(2)}</strong></label>
                  </div>
                </Col>
                <Col lg="12" className="d-flex justify-content-end gap-2">
                  <Button
                    color="secondary"
                    outline
                    type="button"
                    onClick={closeAddUpdate}
                  >
                    Cancel
                  </Button>
                  <Button color="secondary" solid type="submit">
                    {value?.id > 0 ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </Form>
          }}
        </Formik>
      }
    />
  );
};

export { AddUpdateProposal };
