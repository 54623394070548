import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { Header } from "./header";
import { LoaderContextType, useLoader } from "../loader";
import { Loader } from "./loader";
import { useEffect, useState } from "react";

export const Layout = () => {
  const { isLoading } = useLoader();
  const [backButton, setBackButton] = useState<boolean | null>(null);
  const location = useLocation()
  useEffect(() => {
    const path = location.pathname !== "/unMounting" && location.pathname !== "/mounting" && location.pathname !== "/printing";

    if (backButton && path) {
      setBackButton(false);
    }
  }, [backButton, location.pathname]);

  return (
    <>
      {isLoading && <Loader></Loader>}
      <main className="theme-01">
        <Aside></Aside>
        <main className="main">
          <Header
            back={{ backButton: backButton, setBackButton: setBackButton }}
          ></Header>
          <Outlet context={{ backButton: backButton, setBackButton: setBackButton }} />
          <Footer></Footer>
        </main>
      </main>
    </>
  );
};
