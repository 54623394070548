import { NavLink, useLocation } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import {
  bookingIcon,
  businessIcon,
  clientIcon,
  company,
  dashboard,
  landlordIcon,
  leadIcon,
  masters,
  mountingIcon,
  printingIcon,
  receiptIcon,
  roleIcon,
  user,
} from "./icons";
import { useEffect, useState } from "react";
import { getRole } from "../utils/generalUtils";
import { ROLE } from "./commonConst";
interface subMenu {
  name: string;
  route: string;
  icon: any;
}
interface Menu {
  name: string;
  route: string;
  icon: any;
  subMenu?: subMenu[];
}

let MenuList: Menu[] = [
  {
    name: "Dashboard",
    route: "/home",
    icon: dashboard.icon,
  },
  {
    name: "Master",
    route: "",
    icon: masters.icon,
    subMenu: [
      {
        name: "Media Item",
        route: "/mediaItem",
        icon: "",
      },
      {
        name: "Location",
        route: "/location",
        icon: "",
      },
      {
        name: "Route",
        route: "/route",
        icon: "",
      },
      {
        name: "Zone",
        route: "/zone",
        icon: "",
      },
      {
        name: "Media Type",
        route: "/mediaType",
        icon: "",
      },
      {
        name: "Series",
        route: "/mediaSeries",
        icon: "",
      },
      {
        name: "Media Lit",
        route: "/mediaLit",
        icon: "",
      },
      {
        name: "Industry",
        route: "/industry",
        icon: "",
      },
      {
        name: "City",
        route: "/city",
        icon: "",
      },
      // {
      //   name: "State",
      //   route: "/state",
      //   icon: "",
      // },

      // {
      //   name: "Agency",
      //   route: "/agency",
      //   icon: "",
      // },

      // { name: "client", route: "/client", icon: "" },
      // { name: "Landlord", route: "/landlord", icon: "" },
      {
        name: "Material",
        route: "/material",
        icon: "",
      },
      {
        name: "PPT Settings",
        route: "/pptSettings",
        icon: "",
      },
    ],
  },
  {
    name: "Agency",
    route: "/agency",
    icon: company.icon,
  },
  { name: "Landlord", route: "/landlord", icon: landlordIcon.icon },
  { name: "Client", route: "/client", icon: clientIcon.icon },

  { name: "User", route: "/user", icon: user.icon },
  // { name: "Role", route: "/role", icon: roleIcon.icon },
  { name: "Lead", route: "/leadGeneration", icon: leadIcon.icon },
  {
    name: "Business Proposal",
    route: "/businessProposal",
    icon: businessIcon.icon,
  },
  {
    name: "Booking",
    route: "",
    icon: bookingIcon.icon,
    subMenu: [
      { name: "Booking", route: "/booking", icon: bookingIcon.icon },
      { name: "Printing", route: "/printing", icon: printingIcon.icon },
      { name: "Mounting", route: "/mounting", icon: mountingIcon.icon },
      { name: "Unmounting", route: "/unMounting", icon: mountingIcon.icon },
      // { name: "Recipt Payment", route: "/receipt", icon: receiptIcon.icon },
    ],
  },
  {
    name: "Recipt/Payment",
    route: "/payment",
    icon: bookingIcon.icon,
  },
  {
    name: "Report",
    route: "",
    icon: bookingIcon.icon,
    subMenu: [
      { name: "Booking", route: "/bookingReport", icon: bookingIcon.icon },
      { name: "Business Proposal", route: "/businessquotation", icon: printingIcon.icon },
      { name: "lead", route: "/replead", icon: leadIcon.icon },
      { name: "ledger", route: "/ledgerReport", icon: mountingIcon.icon },
      { name: "Media Inventory", route: "/mediaReport", icon: receiptIcon.icon },
      { name: "mounting", route: "/mountingReport", icon: mountingIcon.icon },
      { name: "unmounting", route: "/unmountingReport", icon:  mountingIcon.icon },
      { name: "printing", route: "/printingreport", icon: printingIcon.icon },
      { name: "receipt Payment", route: "/receiptreport", icon: receiptIcon.icon },
    ],
  },
];

export const GetMenuList = () => {
  const [showMasters, setShowMasters] = useState("");
  const [menuList,setMenuList]=useState(MenuList);
  const role = getRole();
  const toggle = (data: string) => {
    if (showMasters === "") {
      setShowMasters(data);
    } else {
      setShowMasters("");
    }
  };
  const location = useLocation();
  useEffect(() => {
    let found = false;
    if(role == ROLE.PRINTINGUSER){
      setMenuList([ { name: "Printing", route: "/printing", icon: printingIcon.icon }]);
    }else{
      setMenuList(MenuList);
    }
    menuList.forEach((item) => {
      if (item.subMenu) {
        item.subMenu.forEach((subItem) => {
          if (subItem.route === location.pathname) {
            setShowMasters(item.name);
            found = true;
          }
        });
      }
    });
    if (!found) {
      setShowMasters("");
    }
  }, [location.pathname,role,MenuList]);

  return (
    <>
      {menuList.map((item) => {
        if (item.subMenu) {
          return (
            <NavItem
              key={item.name}
              className={`dropdown ${
                showMasters === item.name ? "show active" : ""
              }`}
            >
              <button
                onClick={() => toggle(item.name)}
                className={`nav-link btn-link w-100 ${
                  showMasters === item.name ? "active" : ""
                }`}
              >
                <span>{item.icon}</span>
                {item.name}
              </button>
              {showMasters === item.name && (
                <Nav vertical className="has-child h-auto dropdown-menu show">
                  {item.subMenu.map((subItem) => (
                    <NavItem key={subItem.route}>
                      <NavLink
                        to={subItem.route}
                        className={
                          location.pathname === subItem.route
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        {subItem.icon && <span>{subItem.icon}</span>}
                        {subItem.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              )}
            </NavItem>
          );
        } else {
          return (
            <NavItem
              key={item.route}
              onClick={() => {
              }}
            >
              <NavLink to={item.route} className="nav-link">
                {item.icon && <span>{item.icon}</span>}
                {item.name}
              </NavLink>
            </NavItem>
          );
        }
      })}
    </>
  );
};

export const PaymentModeSelect=[
  {
    label:"Cash",
    value:"cash"
  },{
    label:"Cheque",
    value:"cheque"
  }
]

export const VendorType=[
 {
    label:"Agency",
    value:"agency"
  }, {
    label:"Landlord",
    value:"landlord"
  },
]