import { NavLink, useLocation } from 'react-router-dom'
import { Collapse, Nav, NavItem } from 'reactstrap'

const NavigationMenu = (props:any) => {
    const location = useLocation()
    const splitLocation = location?.pathname.split("-");

  return (
    <Collapse isOpen={props?.isOpen} navbar>
              <Nav className="me-auto" navbar>
                <NavItem>
                  <NavLink className={`${splitLocation.length > 0 && splitLocation[1] == "mounting"  ? "active":""}`} to="/mob-mounting">Mounting</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={`${splitLocation.length > 0 && splitLocation[1] == "unmounting"  ? "active":""}`} to="/mob-unmounting">Un-Mounting</NavLink>
                </NavItem>
              </Nav>              
            </Collapse>
  )
}

export default NavigationMenu