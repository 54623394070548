import PageLayout from "../../../components/layout/page";
import { DataView } from "../../../components/table/table";
import { useApi } from "../../../helper/apiHook";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import { AddUpdateLandlord } from "./addUpdate";
import { Button } from "reactstrap";
import { plus } from "../../../constant/icons";
import { columns } from "./columns";
import { useEffect } from "react";

export const LandlordApi = (paging?:boolean, dataLoad: any = {}) => {
  return { ...useApi("/landlords",paging, undefined, dataLoad) };
};

export default function Landlord() {
  const api = LandlordApi(true);
  useEffect(() => {
    api.mutate();
  }, []);
  const AddUpdate = useAddUpdate();
  return AddUpdate.viewAddUpdate ? (
    <PageLayout
    View={
    <AddUpdateLandlord
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      value={AddUpdate.currentValue}
    />
    }/>
  ) : (
    <PageLayout
      item={
        <Button
          color="secondary"
          onClick={() =>
            AddUpdate.openAddEditFrom({
              description: "",
              name: "",
              contactPerson: "",
              contactPersonEmail: "",
              contactPersonMobile: "",
              contactPersonDesignation: "",
              customInfo: [],
              type:""
            })
          }
        >
          {plus.icon}Add New
        </Button>
      }
      View={
        <DataView
          columns={columns({ api, openAddEditFrom: AddUpdate.openAddEditFrom })}
          rows={api.data}
          updatePagination={api.updatePagination}
          currentPage={api.currentPage}
          rowCount={api.rowCount}
          limit={api.limit}
        />
      }
    />
  );
}
