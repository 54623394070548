import { Button, Col } from "reactstrap";
import { Form, Formik } from "formik";
import PageLayout from "../../../components/layout/page";
import { stateSchema } from "./validation";
import TextBox from "../../../components/common/textBox";
import Dropdown from "../../../components/common/dropdown";
import { StateApi } from "../state";

const AddUpdateCity = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const stateData = StateApi(false, {revalidateIfStale: true});

  let stateList =
    stateData?.data?.data?.length > 0
      ? stateData.data.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
      : [];

  return (
    <Formik
      validationSchema={stateSchema}
      initialValues={value}
      onSubmit={(values: any, actions: any) => {
        if (values.id) {
          api.updateValue(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        } else {
          api.create(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form className="form-main">
          <Dropdown
            fieldName="stateId"
            labelName="State"
            options={stateList}
            setFieldValue={setFieldValue}
            gorupClass={"mb-3"}
            values={values}
            isRequired={true}
            selectedValue={values.stateId}
          />
          <TextBox
            fieldName={"name"}
            placeholder={"Enter state name"}
            className={"form-control"}
            type={"text"}
            labelName={"City name"}
            value={value}
            isRequired={true}
          />
          <Col lg="12" className="d-flex justify-content-end gap-2">
            <Button color="secondary" outline   type="button" onClick={() => closeAddUpdate()}>
              Cancel
            </Button>
            <Button color="secondary" solid type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
export { AddUpdateCity };
