import Select from "react-select";
import { UPDATE } from "../../../../constant/commonConst";

const UnmountingStatusOptions = [
    {
        label: "Un-mounting is Pending",
        value: "un-mounting-pending",
        color: "primary",
    },
    { label: "Un-mounting", value: "un-mounting", color: "success" },
];

export const UnMountingStatusFilterDropDown = (props: any) => {
    const { column, rowData } = props;

    // Handle the selection of a status option
    const handleSelect = (selectedOption: any) => {
        if (selectedOption) {
            column.callBack({ data: selectedOption.value, rowData, status: UPDATE });
        } else {
            // Clear selection
            column.callBack({ data: null, rowData, status: UPDATE });
        }
    };

    // Find the currently selected status option
    const selectedStatus = UnmountingStatusOptions.find(option => option.value === rowData.status);

    return (
        <Select
            options={UnmountingStatusOptions}
            value={selectedStatus ? { label: selectedStatus.label, value: selectedStatus.value } : null}
            onChange={handleSelect}
            isClearable
            formatOptionLabel={(option: any) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {option.label}
                </div>
            )}
        />
    );
};
