import { Badge } from "reactstrap";
import { DetailControl } from "../../components/buttons/detail";
import { EditControl } from "../../components/buttons/edit";
import { FollowUpControl } from "../../components/buttons/followUp";
import { RemoveControl } from "../../components/buttons/remove";
// import { StatusChangeControl } from "statusChanges";

export const ActionButtonView = (props: any) => {
  return (
    <div className="action_btn table-action">
      {/* <FollowUpControl {...props} /> */}

      {/* <DetailControl  {...props} /> */}
      <EditControl {...props} />
      <RemoveControl {...props} />
    </div>
  );
};

// export const StatusChangeView = (props: any) => {
//   return (
//     <StatusChangeControl {...props} />
//   )
// };

export const paymentModeView = (props: any) => {
  const {rowData} = props;
  return (
    //other is check-mode classname
    <Badge className={`${rowData?.paymentMode == "cash" ? "cash-mode":"check-mode"}`}> 
      {rowData?.paymentMode == "cash"?"Cash":"Cheque"}
    </Badge>
  )
};