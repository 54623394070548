import axios, { AxiosRequestConfig } from "axios";
import { message } from "../components/toast";
import { getValueByKey } from "../utils/generalUtils";
import { GET } from "../constant/commonConst";
import { LoaderContextType } from "../components/loader";

export const API_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3637"
    : "https://uat-craftmedia.hcshub.in/craft-api";
    // : "https://craftmedia.hcshub.in/craft-api";
const axiosInstance = axios.create({
  baseURL: API_URL,
});
axiosInstance.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${JSON.parse(
    getValueByKey("token")!
  )}`;
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});
axiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      let token: any = getValueByKey("token");
      window.localStorage.clear();
      if (token) {
        token = JSON.parse(token);
        window.location.href = `/`;
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
export const invokeApi = async (
  method: string,
  url: string,
  data?: any,
  loader?: LoaderContextType | undefined,
  config?: AxiosRequestConfig
): Promise<any> => {
  const { loading, setLoading } = loader || {};
  let response: any = {};
  try {
    if (setLoading) {
      setLoading((prev: any) => ({ ...prev, [url.slice(1) + method]: true })); // Show loader
    }
    response = await axiosInstance({
      method,
      url,
      ...(method === "get" ? { params: data } : { data: data }),
      ...config,
    });

    return response.data;
  } catch (error: any) {
    response = error.response;
  } finally {
    if (setLoading) {
      setLoading((prev: any) => ({ ...prev, [url.slice(1) + method]: false }));
    }
    if (response?.data?.message && response?.data?.message?.length > 0) {
      message(
        response?.data?.status ? "success" : "error",
        response?.data?.message
      );
    }
    if (setLoading) {
      setLoading((prev: any) => {
        if (prev[url.slice(1) + method] === false) {
          delete prev[url.slice(1) + method];
        }
        return prev;
      });
    }

    return response?.data;
  }
};

export const fetcher = (
  url: string,
  body: object = {},
  loading: LoaderContextType | undefined
) => invokeApi(GET, url, body, loading).then((res) => res.data);
