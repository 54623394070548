import { DataType } from "ka-table"
import { TableColumn } from "../../../components/table/table"
import { ActionButton } from "../../../components/buttons/actionButtons"
import { DELETE, UPDATE } from "../../../constant/commonConst"
import { confirmBox } from "../../../components/toast"

export const columns = (actions: any): TableColumn[] => {
    return [
        {
            key: 'id',
            title: 'id',
            dataType: DataType.Number,
            visible: false
        },
        {
            key: 'name',
            title: 'Name',
            dataType: DataType.String
        },
        // {
        //     key: 'description',
        //     title: 'description',
        //     dataType: DataType.String
        // },

        {
            key: 'edit',
            title: '',
            hideHeaderFilter: false,
            component: ActionButton,
            callBack: async (row) => {
                if (row.status === UPDATE) {
                    actions.openAddEditFrom({
                        id: row.data.id,
                        name: row.data.name,
                        description: row.data.description,
                      })
                } else if (row.status === DELETE) {

                    const response = await confirmBox("you want to delete this media series?");
                    if (response) {
                        actions.api.removeValue(row.data.id);
                    }
                }
            },
            style: {
                textAlign: 'center',
            }
        },
    ]
}
