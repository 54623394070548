import AddCellTable from "../../../components/table/addCellTable";
import ModalView from "../../../components/modal";
import { RemoveControl } from "../../../components/buttons/remove";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { DateEdit } from "../actions";
import { uuid } from "../../../utils/generalUtils";

export default function TableExtent({
  mediaInventory,
  bookingStartDate,
  setFieldValue,
  isModal,
  setIsModal,
  dbId,
  columns,
}: any) {

  let NewLocalData = [];
  if (Array.isArray(mediaInventory)) {
    
    NewLocalData = JSON.parse(JSON.stringify(mediaInventory))
    NewLocalData=NewLocalData.filter((x:any)=>x.groupCode==dbId)
    NewLocalData = NewLocalData?.map((item: any) => {
      if (Array.isArray(item?.subMediaInventory) && item?.subMediaInventory.length > 0) {
        let retnItem = item?.subMediaInventory[0]
        retnItem.tempRowId = item.tempRowId
        retnItem.height = item.height
        retnItem.width = item.width
        retnItem.disableQty = item.disableQty
        retnItem.size = item.size
        retnItem.city = item.city
        retnItem.location = item.location
        retnItem.locationId = item.locationId
        retnItem.cityId = item.cityId
        return retnItem;
      }
      return item;
    })
  }

 
  const [localData, setLocalData] = useState<any>(isModal?.data ? isModal?.data?.subMediaInventory : NewLocalData);
  const [deleteItem, setDeleteItem] = useState<any>([]);
  // useEffect(() => {
  //   const updateDates = (item: any) => {
  //     const endDate = new Date(item.endDate);
  //     const startDate = endDate ? new Date(endDate.getTime() + 24 * 60 * 60 * 1000) : item.startDate; // +1 day
  //     const newEndDate = endDate ? new Date(endDate.getTime() + 31 * 24 * 60 * 60 * 1000) : item.endDate; // +31 days
  //     return {
  //       ...item,
  //       startDate: startDate.toISOString(),
  //       endDate: newEndDate.toISOString(),
  //     };
  //   };
  //   const updatedMediaInventory = localData.map(updateDates)
  //   setLocalData(updatedMediaInventory);
  // }, [localData?.length > 0, isModal?.data]);

  const deleteValue = (recod: any) => {
    const tempRowIdToRemove = recod?.data?.tempRowId;
    // Ensure tempRowId is available before proceeding
    if (tempRowIdToRemove) {
      // Create a new state by filtering out the item to delete
      setLocalData((prevTempData: any[]) => {
        // Filter the items to exclude the one with the matching tempRowId
        const updatedTempData = prevTempData.filter((element: any) => element.tempRowId !== tempRowIdToRemove);
        // Return a new array with the filtered data to update the state
        return [...updatedTempData];
      });

      setDeleteItem((prevTempData: any[]) => {
        return [...prevTempData, recod?.data?.id];
      });
    }
  };
  const changeValue = (value: any, rowData: any, key: string) => {
    setLocalData((prevTempData: any[]) => {
      const findValueChange = prevTempData && prevTempData.length > 0 &&
        prevTempData.map((element: any) => {
          if (element?.tempRowId == rowData?.tempRowId) {
            if (["startDate", "endDate"].includes(key)) {
              try {
                const startDate = new Date(element.startDate);
                const endDate = new Date(element.endDate);
                const diffDays = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
                element.day = diffDays;
              } catch {

              }

            }
            return {
              ...element,
              [key]: value,
            };
          } else {
            return element;
          }
        });
      return findValueChange;
    });
  };
  const newColumns =
    columns &&
    columns.length > 0 &&
    columns.map((element: any) => {
      if (element?.key == "addColumn") {
        return {
          ...element,
          key: "actions",
          title: "Actions",
          component: RemoveControl,
          style: { width: "3vw", textAlign: "center" },
          callBack: deleteValue,
        };
      }
      if (element?.showDatepicker) {
        return {
          ...element,
          component: DateEdit,
          style: { width: "14vw" },
          callback: changeValue,
        };
      }
      if (element?.isEditable) {
        return {
          ...element,
          isExtentEditable: true,
          callback: changeValue,
        };
      }
      if (!element?.isDisabled) {
        return {
          ...element,
          isDisabled: true,
          callback: changeValue,
        };
      }
      return element;
    });

  // const localDataresult =localData?.length > 0? localData.flatMap((item: any) => {
  //       const { subMediaInventory, ...mainItem } = item;

  //       if (subMediaInventory?.length > 0) {
  //         return [...(subMediaInventory || [])];
  //       }
  //       return [mainItem];
  //     }): [];

  // const highestEndDates = localDataresult.reduce((acc: any, current: any) => {
  //   const existing = acc.find((item: any) => item.mediaItemId === current.mediaItemId);
  //   if (!existing) {
  //     acc.push(current);
  //   } else {
  //     if (new Date(current.endDate) > new Date(existing.endDate)) {
  //       existing.endDate = current.endDate;
  //       existing.qty = current.qty;
  //     }
  //   }
  //   return acc;
  // }, []);
  const saveExtentsValue = () => {
    if (Array.isArray(mediaInventory) && mediaInventory.length > 0) {
      let newMediaInventory = JSON.parse(JSON.stringify(mediaInventory));
      newMediaInventory = newMediaInventory.map((element: any) => {
        const findTempidRow = localData && localData.length > 0 ? localData.find((elementemprow: any) => elementemprow.tempRowId == element.tempRowId) : null;
        if (findTempidRow) {
          let newElement = { ...findTempidRow };
          delete newElement.subMediaInventory;
          delete newElement.isHaveSubMediaInventory;
          delete newElement.id;
          delete newElement.disableQty;
          if (Array.isArray(element.subMediaInventory) && element.subMediaInventory?.length > 0) {
            element.subMediaInventory.push({
              ...newElement,
              tempRowId: uuid(),
            });
          } else {
            let oldelement = { ...element };
            delete oldelement.subMediaInventory;
            delete oldelement.isHaveSubMediaInventory;
            element.isHaveSubMediaInventory = true;
            delete newElement.disableQty;
            element.subMediaInventory = [
              { ...newElement, tempRowId: uuid() },
              oldelement,
            ];
          }
        }

        return element;
      });
      setFieldValue("mediaInventory", newMediaInventory);
      setIsModal({
        ...isModal,
        open: false,
      });
      return;
    }
    setFieldValue("mediaInventory", mediaInventory);

    setIsModal({
      ...isModal,
      open: false,
    });
  };

  const updateExtentvalue = () => {
    if (Array.isArray(mediaInventory) && mediaInventory.length > 0) {
      let mediaInventoryCopy = JSON.parse(JSON.stringify(mediaInventory));

      const findTempidRow = mediaInventoryCopy.find((elementemprow: any) => elementemprow.tempRowId === isModal?.data?.tempRowId);
      const updateMediaInventory = mediaInventoryCopy.map((element: any) => {
        if (element?.tempRowId === findTempidRow?.tempRowId) {
          // const result =
          //   localData && localData.length > 0
          //     ? localData.reduce(
          //         (acc: any, num: any) => {
          //           const startDate = new Date(num.startDate);
          //           const endDate = new Date(num.endDate);
          //           if (!acc.minStartDate || startDate < acc.minStartDate) {
          //             acc.minStartDate = startDate;
          //           }
          //           if (!acc.maxEndDate || endDate > acc.maxEndDate) {
          //             acc.maxEndDate = endDate;
          //           }
          //           const diffTime = acc.maxEndDate - acc.minStartDate;
          //           const diffDays = Math.ceil(
          //             diffTime / (1000 * 60 * 60 * 24)
          //           );
          //           acc.qty += num?.qty;
          //           acc.amount += num?.amount;
          //           acc.rate = acc.amount / acc.qty;
          //           return { ...acc, diffDays };
          //         },
          //         {
          //           qty: 0,
          //           day: 0,
          //           rate: 0,
          //           minStartDate: null,
          //           maxEndDate: null,
          //           amount: 0,
          //         }
          //       )
          //     : null;
          const result = localData && localData.length > 0 ? localData.map((num: any) => {
            const startDate: any = new Date(num.startDate);
            const endDate: any = new Date(num.endDate);
            const diffTime = endDate - startDate;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return {
              qty: num.qty,
              rate: num.rate,
              amount: num.amount,
              minStartDate: startDate,
              maxEndDate: endDate,
              diffDays,
            };
          }) : null;

          return {
            ...findTempidRow,
            qty: result?.qty,
            rate: result?.rate,
            day: result?.diffDays,
            // startDate: result?.minStartDate
            //   ? new Date(result.minStartDate).toISOString()
            //   : null,
            // endDate: result?.maxEndDate
            //   ? new Date(result.maxEndDate).toISOString()
            //   : null,
            amount: result?.amount,
            subMediaInventory: localData,
            isHaveSubMediaInventory: true,
          };
        }
        return element; // Return the unmodified element
      }) || [];
      console.log(
        updateMediaInventory,
        "updateMediaInventoryupdateMediaInventory"
      );
      // Assuming you have a setFieldValue function to update state
      setFieldValue("mediaInventory", updateMediaInventory);
      setFieldValue("inventoryIds", deleteItem);
      // Close the modal
      setIsModal({
        ...isModal,
        open: false,
      });
    }
  };

  return (
    <>
      <ModalView
        modalView={isModal?.open}
        customClass="booking-extends"
        modalsize="xl"
        modalName={dbId}
        onModalDismiss={() =>
          setIsModal({
            ...isModal,
            open: false,
          })
        }
      >
        <AddCellTable
          bookingStartDate={bookingStartDate}
          groupCode={dbId}
          updateFieldValue={() => { }}
          columns={newColumns}
          setIsModal={setIsModal}
          rows={localData?.length > 0 ? localData : []}
        />
        <Button
          onClick={() => {
            if (isModal?.data?.isHaveSubMediaInventory) {
              updateExtentvalue();
            } else {
              saveExtentsValue();
            }
          }}
        >
          {isModal?.data?.isHaveSubMediaInventory
            ? "Update Extents"
            : "Save Extents"}
        </Button>
      </ModalView>
    </>
  );
}
