import { DataType } from "ka-table"
import { TableColumn } from "../../../components/table/table"
import { ActionButtonNewView } from "../../../components/buttons/actionButtons"
import { DELETE, UPDATE, VIEW } from "../../../constant/commonConst"
import { confirmBox } from "../../../components/toast"

export const columns = (actions: any): TableColumn[] => {
    return [
        {
            key: 'id',
            title: 'id',
            dataType: DataType.Number,
            visible: false
        }, {
            key: 'bookingNo',
            title: 'booking ID',
            dataType: DataType.String
        },
        {
            key: 'client.businessName',
            title: 'client Name',
            dataType: DataType.String
        },
        {
            key: 'user',
            title: 'Booking By',
            dataType: DataType.String,
            valueConvert: (row: any, rowData: any) => {
           return rowData.user?.name
            }
        },
        {
            key: 'bookedByPersonMobile',
            title: 'Mobile Number',
            dataType: DataType.String
        },
        {
            key: 'bookedByPersonEmail',
            title: 'Email ID',
            dataType: DataType.String
        },
        {
            key: 'edit',
            title: '',
            hideHeaderFilter: false,
            component: ActionButtonNewView,
            isBookingReport: actions.isBookingReport,
            callBack: async (row) => {
                if (row.status === UPDATE) {
                    actions.openAddEditFrom({ ...row.data })
                } else if (row.status === DELETE) {
                    const response = await confirmBox("you want to delete this Booking?");
                    if (response) {
                        actions.api.removeValue(row.data.id);
                    }
                } else if (row.status === VIEW) {
                    actions.view(`/booking/view`)
                } 
                
            },
            style: {
                textAlign: 'center',
            }
        },
    ]
}
