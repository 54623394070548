import PageLayout from "../../../components/layout/page";
import { DataView } from "../../../components/table/table";
import { useApi } from "../../../helper/apiHook";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import { AddUpdateIndustry } from "./addUpdate";
import { Button } from "reactstrap";
import { plus } from "../../../constant/icons";
import { columns } from "./columns";
import { useEffect } from "react";
import ModalView from "../../../components/modal";

export const IndustryApi = (paging?:boolean, dataLoad: any = {}) => {
  return { ...useApi("/industry",paging, undefined, dataLoad) };
};

export default function Industry() {
  const api = IndustryApi(true);
  useEffect(() => {
    api.mutate();
  }, []);
  const AddUpdate = useAddUpdate();
  return  (<>
    <PageLayout
      item={
        <Button
          color="secondary"
          onClick={() =>
            AddUpdate.openAddEditFrom({
              name: "",
              description:""
            })
          }
        >
          {plus.icon}Add New
        </Button>
      }
      View={
        <DataView
          columns={columns({ api, openAddEditFrom: AddUpdate.openAddEditFrom })}
          rows={api.data}
          updatePagination={api.updatePagination}
          currentPage={api.currentPage}
          rowCount={api.rowCount}
          limit={api.limit}
        />
      }
    />
          {AddUpdate.viewAddUpdate && (
        <ModalView
          modalView={AddUpdate.viewAddUpdate}
          modalName={AddUpdate.currentValue?.id ? "Edit Industry" : "Add Industry"
          }
          onModalDismiss={AddUpdate.closeAddEditFrom}
        >
          <AddUpdateIndustry
            closeAddUpdate={AddUpdate.closeAddEditFrom}
            api={api}
            value={AddUpdate.currentValue}
          />
        </ModalView>
      )}
    </>
  );
}
