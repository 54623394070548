import PageLayout from "../../components/layout/page";
import { DataView } from "../../components/table/table";
import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { AddUpdateReceipt } from "./addUpdate";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { plus } from "../../constant/icons";
import { columns } from "./columns";
import { useEffect, useState } from "react";
import ModalView from "../../components/modal";
import Payment from "../Payment";

export const ReceiptApi = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/receipt", paging, undefined, dataLoad) };
};

export default function Receipt() {
  const api = ReceiptApi(true);
  useEffect(() => {
    api.mutate();
  }, []);
  const AddUpdate = useAddUpdate();
  const [activeTab, setActiveTab] = useState("Receipt");
  return (
    <>
      <Nav tabs className="receipt-tab">
        <NavItem>
          <NavLink className={activeTab ==="Receipt"? "active":" "} onClick={() => setActiveTab("Receipt")}>
            Receipt
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab==="Payment" ? "active":" "} onClick={() => setActiveTab("Payment")}>
            Payment
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="Receipt">
          {AddUpdate.viewAddUpdate ? (
            <PageLayout
              View={
                <AddUpdateReceipt
                  closeAddUpdate={AddUpdate.closeAddEditFrom}
                  api={api}
                  value={AddUpdate.currentValue}
                  currentPage={api.currentPage}
                  rowCount={api.rowCount}
                  limit={api.limit}
                />
              }
            />
          ) : (
            <>
              <PageLayout
                item={
                  <Button
                    color="secondary"
                    onClick={() =>
                      AddUpdate.openAddEditFrom({
                        name: "",
                        address: "",
                        cityId: null,
                        stateId: null,
                        GSTINNo: "",
                        websiteLink: null,
                        contactPerson: "",
                        contactMobile: "",
                        contactEmail: "",
                        PANNo: "",
                      })
                    }
                  >
                    {plus.icon}Add New
                  </Button>
                }
                View={
                  <DataView
                    columns={columns({
                      api,
                      openAddEditFrom: AddUpdate.openAddEditFrom,
                    })}
                    rows={api.data}
                    currentPage={api.currentPage}
                    rowCount={api.rowCount}
                    limit={api.limit}
                    updatePagination={api.updatePagination}
                  />
                }
              />
              {AddUpdate.viewAddUpdate && (
                <ModalView
                  modalView={AddUpdate.viewAddUpdate}
                  modalName={
                    AddUpdate.currentValue?.id ? "Edit Agency" : "Add Agency"
                  }
                  onModalDismiss={AddUpdate.closeAddEditFrom}
                >
                  <AddUpdateReceipt
                    closeAddUpdate={AddUpdate.closeAddEditFrom}
                    api={api}
                    value={AddUpdate.currentValue}
                  />
                </ModalView>
              )}
            </>
          )}
          ;{" "}
        </TabPane>
        <TabPane tabId="Payment"> <Payment /></TabPane>
      </TabContent>{" "}
    </>
  );
}
