import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  cameraIcon,
  crossIcon,
  imageIcon,
  leftSquareArrow,
  LocationIcon,
  logoutDuotone,
  uploadIcon,
  user,
} from "../../../constant/icons";
import { useApi } from "../../../helper/apiHook";
import SelectStatus from "./components/SelectStatus";
import { invokeApi } from "../../../helper/api";
import { GET } from "../../../constant/commonConst";
import { Field, Form, Formik } from "formik";
import { useUpload } from "../../masters/items/helper";
import ProtectedImage from "../../../components/common/image";
import { UserMobUnMountingImageSchema } from "./components/UsermobMountingImageSchema";
import { AppLogo } from "../../../constant/logo";
import CardImg from "../../../assets/images/poster-img.png";
import { getDate, getFormattedDate, getTime } from "../../../utils/dateUtils";
import DatePicker from "react-datepicker";
import UserLayout from "../../../components/layout/UserLayout";
import moment from "moment";
import { getStatusActiveTab } from "../../../utils/generalUtils";

export const GetAllMounting = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/unmountings/userUnMounting", paging, undefined, dataLoad) };
};
export default function UnMountingTaskDtl() {
  const [singleMountingState, setSingleMounting] = useState<any>(null);
  const mountingList = GetAllMounting(true);
  const [status, setStatus] = useState<string>("");

  const regularshot = useUpload(1);
  const longshot = useUpload(1);
  const shortshot = useUpload(1);
  const shortGeoShot = useUpload(1);
  const getParams = useParams();
  const navigate=useNavigate();
  useEffect(() => {
    getDetailsOfMounting();
  }, [getParams?.id]);
  const getDetailsOfMounting = async () => {
    const mountings = await invokeApi(
      GET,
      `/unmountings/${getParams?.id}`
    );
    if (mountings?.status) {
      setSingleMounting(
        mountings?.data?.data &&
          mountings?.data?.data.length > 0 ?
          mountings?.data?.data:null
      );
    }
  };
  const apiData =
    singleMountingState && singleMountingState?.length > 0
      ? singleMountingState[0]
      : null;
  const jsonParse = apiData?.unmountingShot ? JSON.parse(apiData?.unmountingShot):null;
  const mountingImageParse = apiData?.mediaItem?.Mounting && apiData?.mediaItem?.Mounting.length > 0 ? apiData?.mediaItem?.Mounting.find((element:any)=>element?.mountingImages):[];
  const jsonParseMoutingImage = mountingImageParse?.mountingImages ? JSON.parse(mountingImageParse?.mountingImages):null;
  return (
    <>
      <div className="front-page">
        <div className="container">
          <UserLayout />
          {/* content data */}
          <Button
          // onClick={() => {
          //   navigate("/mob-unmounting",{state:{from: getStatusActiveTab(status)}});
          // }}
          onClick={() => {
            navigate("/mob-unmounting", {
              state: {
                from: getStatusActiveTab(status),
                activeBooking: getParams?.id, // Pass the ID here
              },
            });
          }}
          className="hamburger-btn p-0"
        >
          {leftSquareArrow.icon}
        </Button>
          <Formik
            initialValues={{
              unmountingDate: apiData?.unmountingDate ? getFormattedDate(apiData?.unmountingDate,"YYYY-MM-DD") :"",
              unmountingTime: getTime(apiData?.unmountingTime) ?? "",
              regularShotImage: jsonParse && jsonParse.length > 0 ? jsonParse.find((element:any)=>element.tagname=="regularShot")?.url :  "",
              longShotImage: jsonParse && jsonParse.length > 0 ? jsonParse.find((element:any)=>element.tagname=="longShot")?.url :"",
              shortShortImage: jsonParse && jsonParse.length > 0?  jsonParse.find((element:any)=>element.tagname=="shortShot")?.url: "",
              shortGeoShotImage:  jsonParse && jsonParse.length > 0?  jsonParse.find((element:any)=>element.tagname=="shortGeoShot")?.url:  "",
              unmountingShot: apiData?.unmountingShot ? JSON.parse(apiData?.unmountingShot) : [],
            }}
            enableReinitialize
            validateOnMount
            validationSchema={UserMobUnMountingImageSchema}
            onSubmit={async(values:any,{resetForm}) => {
              const formData = new FormData();
              formData.append("unmountingDate", new Date(values?.unmountingDate)?.toISOString());
              formData.append("unmountingTime", values?.unmountingTime);
              formData.append("id", getParams.id ?? "");
              formData.append(`regularShot`, regularshot?.selectedImages[0]);
              formData.append(`longShot`, longshot?.selectedImages[0]);
              formData.append(`shortShot`, shortshot?.selectedImages[0]);
              formData.append(`shortGeoShot`, shortGeoShot?.selectedImages[0]);
              formData.append(`unmountingShot`, JSON.stringify(values?.unmountingShot));

              mountingList.updateValue(formData).then((result: any) => {
                if (result?.status) {
                  navigate("/mob-unmounting",{state:{from: getStatusActiveTab(status)}});
                }
              });
            }}
          >
            {({ values, handleChange,errors,...rest }) => {
              const formikObj ={...rest,values,errors};
              return (
                <Form>
                  <Row className="mt-4 ">
                    <Col lg={6} md={6} sm={12}>
                      <div className="task-img">
                         {
                          jsonParseMoutingImage ? <ProtectedImage value={jsonParseMoutingImage?.url}/>:<img src={CardImg} alt="Poster Image" />
                         }
                        
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <div className="task-detail">
                        <div className="content-head-box">
                          <div>
                            {/* <h3>Craft media Gandhinagar</h3> */}
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              className="d-flex gap-2"
                            >
                              <span>{LocationIcon.icon}</span>{" "}
                              {apiData?.mediaItem?.routeId
                                ? apiData?.mediaItem?.route
                                    ?.startLandmarkDescription
                                : apiData?.mediaItem?.location?.name}
                            </a>
                          </div>
                          <div className="inner-badge-data">
                            <span className="assigny-box">
                              <span>{user.icon}</span>{apiData?.assignee?.name}
                            </span>
                            <span className="task-number">
                              Task no: {apiData?.taskNo}
                            </span>
                            <span className="poster-size-box">
                              BookingNo: {`${apiData?.booking?.bookingNo}`}
                            </span>
                            <span className="poster-size-box">
                              {`${apiData?.mediaItem?.width} * ${apiData?.mediaItem?.height}`}{" "}
                              ft
                            </span>
                            <span className="poster-qty">
                              Qty : {apiData?.mediaItem?.qty ?? 0}
                            </span>
                          </div>
                        </div>
                        {/* <p className="detail-content">Lorem ipsum dolor sit amet consectetur adipiscing elit, convallis imperdiet neque pulvinar porta orci, phasellus aptent suspendisse at id natoque. Lacinia aliquet praesent magna blandit hac ligula nunc sociosqu, velit viverra nullam nibh sodales morbi mattis habitant, dis congue tristique pellentesque porta netus montes. Justo praesent tempor senectus facilisi accumsan nullam fusce faucibus aenean,</p> */}
                        <div className="content-action">
                          <div className="status-time-box">
                            <FormGroup noMargin>
                              <Label>UnMounting Date</Label>
                              <input type="date" className="form-control date-picker" placeholder={"DD/MM/YYYY"}  data-date-format="DD MM YYYY" value={values?.unmountingDate ? moment(values?.unmountingDate).format("YYYY-MM-DD"):""}   onChange={(event: any) => {
                                rest?.setFieldValue("unmountingDate", new Date(event.target.value));
                              }}/>
                              {/* <Field
                              component={DatePicker}
                              placeholderText={"DD/MM/YYYY"}
                              dateFormat={"dd/MM/yyyy"}
                              className="form-control"
                              name="unmountingDate"
                              selected={values?.unmountingDate ? getDate(values?.unmountingDate) : ""}
                              onChange={(date: any) => {
                                rest?.setFieldValue("unmountingDate", date);
                              }}                            
                              /> */}
                              {/* <Input
                                id="exampleSelect"
                                name="unmountingDate"
                                type="date"
                                value={values?.unmountingDate}
                                onChange={handleChange}
                              /> */}
                              {
                                errors?.unmountingDate && <small className="validation-class">{errors?.unmountingDate as string}</small>
                              }
                            </FormGroup>
                            <FormGroup noMargin>
                              <Label>UnMounting Time</Label>
                              <Input
                                id="exampleSelect"
                                name="unmountingTime"
                                type="time"
                                value={values?.unmountingTime}
                                onChange={handleChange}
                              />
                              {
                                errors?.unmountingTime && <small className="validation-class">{errors?.unmountingTime as string}</small>
                              }
                            </FormGroup>
                              <SelectStatus
                                api={mountingList}
                                value={
                                  singleMountingState &&
                                  singleMountingState?.length > 0 &&
                                  singleMountingState[0]
                                }
                                label
                                callBack={(val:any)=>{
                                  setStatus(val)
                                }}
                              />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="task-capture-box">
                    <ImageUploader title="Regular shot" name="regularShotImage" fields="regularShot" value={apiData} imageUploader={regularshot} formik={formikObj} />
                    <ImageUploader title="Long shot" name="longShotImage" fields="longShot" value={apiData} imageUploader={longshot} formik={formikObj} />
                    <ImageUploader title="Short shot" name="shortShortImage" fields="shortShot" value={apiData} imageUploader={shortshot} formik={formikObj} />
                    <ImageUploader title="Short with GEO shot" name="shortGeoShotImage" fields="shortGeoShot" value={apiData} imageUploader={shortGeoShot} formik={formikObj} />
                  </Row>
                    <div className="submit-box">
                      <Button color="secondary">Submit</Button>
                    </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export const ImageUploader = (props:any) => {
  const jsonParse = props?.value?.unmountingShot ? JSON.parse(props?.value?.unmountingShot):null;
  useEffect(()=>{
    if(jsonParse && jsonParse.length>0){
      jsonParse.forEach((element:any)=>{
        if(element.tagname == props?.fields){
          props?.imageUploader.setImagePreviews([
            {
              path: element.url ?? "",
              id: "undefined",
              isPrimary: false,
            },
          ]);
          props?.formik?.setFieldValue(props?.name,element.url);
        }
      })
      props?.formik.setFieldValue("unmountingShot",jsonParse)
    } 
  },[jsonParse && jsonParse.length>0])
  return (
    <Col md={6} lg={4} sm={6} xs={6} xl={3}>
      <h3>{props?.title}</h3>
      <div className="user-upload-image">
        <label className="dropzone-container">
          <div className="file-icon">{cameraIcon.icon}</div>
          <div className="dropzone-title">Click and Upload your Image</div>
        </label>
        <input
          type="file"
          capture="environment"
          name={props.name}
          accept={"image/*"}
          onChange={(e: any) => {
            props?.imageUploader.handlefileChange(e);
            props?.formik?.setFieldValue(props?.name,e.target.value)
          }}
        />
        {props?.imageUploader?.imagePreviews &&
        props?.imageUploader?.imagePreviews?.length > 0 ? (
          <div className="images-item">
            {props?.imageUploader?.imagePreviews[0] ? (
              props?.imageUploader?.imagePreviews[0]?.path ? (
                <ProtectedImage value={props?.imageUploader?.imagePreviews[0]?.path}  callBack={()=>{}}/>
              ) : (
                <img
                  src={props?.imageUploader?.imagePreviews[0]?.url}
                  alt={`preview-${0}`}
                  width={25}
                  height={25}
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "cover",
                  }}
                />
              )
            ) : null}

            <button
              className="remove-box"
              onClick={() => {
                props?.imageUploader?.handleRemoveImage(0);
                props?.formik?.setFieldValue(props?.name,"")
                props?.formik?.setFieldError(props?.name,props?.formik?.errors[props?.name] ?? "")
                const removeElementfromunMountingShot= props?.value?.unmountingShot ? JSON.parse(props?.value?.unmountingShot):[];
                const newunMountingShot = removeElementfromunMountingShot && removeElementfromunMountingShot.length > 0 ? removeElementfromunMountingShot.filter((element:any)=>element.tagname !== props.fields):[]
                props?.formik?.setFieldValue("unmountingShot",newunMountingShot)
              }}
            >
              {crossIcon.icon}
            </button>
          </div>
        ) : null}
        {props?.formik?.errors[props?.name] && <small className="validation-class">{props?.formik?.errors[props?.name]}</small>}
      </div>
    </Col>
  );
};
