// LoaderContext.js
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
} from "react";
export interface LoaderContextType {
  loading: { [key: string]: boolean };
  setLoading: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  isLoading?: boolean;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

  const isLoading = loading
    ? Object.values(loading).some((value) => value === true)
    : false;
  return (
    <LoaderContext.Provider value={{ loading, setLoading, isLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};
export const useLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
