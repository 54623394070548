import { Button } from 'reactstrap';
import PageLayout from '../../../components/layout/page';
import { useAddUpdate } from '../../../helper/addUpdateHook';
import { useApi } from '../../../helper/apiHook';

import { AddUpdateZone } from './addUpdate';
import { plus } from '../../../constant/icons';
import { columns } from './columns';
import { DataView } from '../../../components/table/table';
import { useEffect } from 'react';
import ModalView from '../../../components/modal';

export const ZoneApi = (paging?: boolean, dataLoad: any = {},) => {
  return { ...useApi("/zones",paging, undefined, dataLoad) };
};

export default function Zone() {
  const api = ZoneApi(true);
  const AddUpdate = useAddUpdate();
  useEffect(() => {
    api.mutate();
  }, []);
  return <>
    <PageLayout
      item={
        <Button
          color="secondary"
          onClick={() =>
            AddUpdate.openAddEditFrom({
              name: "",
              cityId: "",
              description:""
            })
          }
        >
          {plus.icon}Add New
        </Button>
      }
    View={
        <DataView
          columns={columns({ api, openAddEditFrom: AddUpdate.openAddEditFrom })}
          rows={api.data}
          updatePagination={api.updatePagination}
          currentPage={api.currentPage}
          rowCount={api.rowCount}
          limit={api.limit}
        />
      }
    />
    {AddUpdate.viewAddUpdate && (
      <ModalView
        modalView={AddUpdate.viewAddUpdate}
        modalName={AddUpdate.currentValue?.id ? "Edit Zone" : "Add Zone"}
        onModalDismiss={AddUpdate.closeAddEditFrom}
      >
        <AddUpdateZone
          closeAddUpdate={AddUpdate.closeAddEditFrom}
          api={api}
          value={AddUpdate.currentValue}
        />
      </ModalView>
    )}
  </>;
}
