import React from "react";
import { Button, Card, Col, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import moment from "moment";
import { crossIcon } from "../../constant/icons";
import { MaterialFilter, StatusFilterDropDown } from "./Components/filters";

// Define types for props
interface PrintingFilterProps {
    filterValues: {
        bookingNo?: string[];
        material?: string[];
        status?: string[];
        fromDate?: string | null;
        toDate?: string | null;
    };
    setFilterValues: (values: any) => void;
    onCloseFilter: () => void;
    bookingNoList: { value: string; label: string }[];
}

export const PrintingFilter: React.FC<PrintingFilterProps> = ({
    filterValues = {},
    setFilterValues,
    onCloseFilter,
    bookingNoList = [],
}) => {

    // Formik's onSubmit handler
    const handleSubmit = (values: any) => {
        setFilterValues({
            ...values,
            fromDate: values.fromDate ? moment(values.fromDate).format('DD-MM-YYYY') : null,
            toDate: values.toDate ? moment(values.toDate).format('DD-MM-YYYY') : null,
        });
        onCloseFilter();
    };

    return (
        <Card className="filter-box">
            <div className="d-flex justify-content-between">
                <h3>Filters</h3>
                <Button className="cancel_filter" color="link" onClick={onCloseFilter}>
                    {crossIcon.icon}
                </Button>
            </div>
            <Formik
                initialValues={{
                    bookingNo: filterValues.bookingNo || [],
                    material: filterValues.material || [],
                    status: filterValues.status || [],
                    fromDate: filterValues.fromDate ? moment(filterValues.fromDate, "DD-MM-YYYY").toDate() : null,
                    toDate: filterValues.toDate ? moment(filterValues.toDate, "DD-MM-YYYY").toDate() : null,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (
                    <Form className="form-row">
                        <Col md={3} className="mb-3">
                            <Label htmlFor="bookingNo">Booking No</Label>
                            <ReactSelect
                                name="bookingNo"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                    setFieldValue("bookingNo", selectedValues);
                                }}
                                isMulti
                                options={bookingNoList}
                                value={bookingNoList.filter((option: any) => values.bookingNo.includes(option.value))}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <Label htmlFor="material">Material</Label>
                            <MaterialFilter
                                selectedMaterials={values.material}
                                onChange={(selectedMaterials: string[]) => {
                                    setFieldValue("material", selectedMaterials);
                                }}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <Label htmlFor="status">Status</Label>
                            <StatusFilterDropDown
                                column={{ callBack: (data: any) => setFieldValue("status", [data.data]) }} // Ensure status is an array
                                rowData={{ status: values.status[0] }} // Assuming only one status selected for display
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <Label className="form-label">From Available Date</Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values.fromDate}
                                onChange={(selectedDate: any) => {
                                    setFieldValue("fromDate", selectedDate);
                                }}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <Label className="form-label">To Available Date</Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values.toDate}
                                onChange={(selectedDate: any) => {
                                    setFieldValue("toDate", selectedDate);
                                }}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button type="submit" color="primary">Apply Filters</Button>
                        </Col>
                    </Form>
                )}
            </Formik>
        </Card>
    );
};
