import { Button } from "reactstrap";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../../helper/apiHook";
import PageLayout from "../../../components/layout/page";
import { DataView } from "../../../components/table/table";
import { columns } from "./columns";
import { filterIcon } from "../../../constant/icons";
import BookingFilter from "./bookingFilter";

export const BookingAPI = (filterValues: any) => {
  return { ...useApi("/booking", true, filterValues) };
};

export default function BookingRep(props: any) {
  const [filter, setFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<any>({});
  const api = BookingAPI(filterValues);
  useEffect(() => {
    api.mutate();
  }, []);
  const navigate = useNavigate();
  const [searchparams, setSearchParams] = useSearchParams();
  const queryId = searchparams.get("id");

  useEffect(() => {
    if (queryId && api?.data?.length > 0) {
      const findIdExist =
        api.data &&
        api.data.length > 0 &&
        api.data.find((element: any) => element?.id == queryId);
      if (findIdExist) {
        searchparams.delete("id");
        setSearchParams(searchparams);
      }
    }
  }, [queryId, api.data]);
  const handleShowFilter = () => {
    setFilter(!filter);
  };
  return (
    <>
      <div
        className="d-flex justify-content-end align-items-end"
        style={{ paddingRight: "1rem" }}
      >
        <div className="head-btn-box">
          <Button
            color="secondary"
            type="button"
            outline
            onClick={handleShowFilter}
          >
            {filterIcon.icon}
            Filter
          </Button>
        </div>
      </div>
      <PageLayout
        View={
          <>
            <DataView
              columns={columns({
                api,
                view: navigate,
                isBookingReport: props.isBookingReport,
              })}
              rows={api.data}
              currentPage={api.currentPage}
              rowCount={api.rowCount}
              limit={api.limit}
              updatePagination={api.updatePagination}
            />
          </>
        }
      />
      {filter && (
        <BookingFilter
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          onCloseFilter={handleShowFilter}
        />
      )}
    </>
  );
}
