import { useState } from "react";
import { DataView } from "../../components/table/table";
import { innerColumns } from "./columns";
import ModalView from "../../components/modal";
// import { UploadImage } from "./addUpdate";
import { useApi } from "../../helper/apiHook";
import { UploadImage, ViewMounting } from "./addUpdate";
import { UserAPI } from "../user";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { Badge } from "reactstrap";

export const DetailsRow: React.FC<{ rowData: any; apiPrinting: any; setOpenDetailPrinting: any; openDetailPrinting: any }> = ({
  rowData,
  setOpenDetailPrinting,
  openDetailPrinting,
  apiPrinting
}) => {
  const [uploadImage, setUploadImage] = useState(false);
  const AddUpdate = useAddUpdate();
  const handleShowUploadImage = (uploadImageItem?: any) => setUploadImage(uploadImageItem);
  const handleHideUploadImage = (uploadImageItem?: any) => setUploadImage(false);
  const api = useApi(`/mountings/${rowData.bookingId}`, true, {}, { revalidateIfStale: true })
  return (
    <div className="details-row mt-3 d-inline-block w-100">
      {/* <ModalView
          modalView={openDetailPrinting?.bookingId == rowData?.bookingId}
          modalsize="xl"
          customClass="custom-size-modal-table"
          customClassBody="custom-size-modal-body"
          modalName={"Booking Id"}
          onModalDismiss={() =>{
            setOpenDetailPrinting(null)
          }}
        > */}
      <DataView
        columns={innerColumns({ api, openUpload: handleShowUploadImage, apiPrinting, openView: AddUpdate.openAddEditFrom, })}
        // detailsRow={(props: any) => <DetailsRow {...props} />}
        rows={api.data}
        currentPage={api.currentPage}
        rowCount={api.rowCount}
        limit={api.limit}
      />
      {/* </ModalView> */}
      {uploadImage && (
        <ModalView
          modalView={uploadImage}
          modalName={"Upload Image"}
          onModalDismiss={handleHideUploadImage}
        >
          <UploadImage
            uploadImage={uploadImage}
            onModalDismiss={handleHideUploadImage}
            api={apiPrinting}
            uploadImageApi={api}
            rowData={rowData}
            closeAddUpdate={handleShowUploadImage}
          />
        </ModalView>
      )}
      {AddUpdate.viewAddUpdate && (
        <ModalView
          modalView={AddUpdate.viewAddUpdate}
          modalName={<>
          View Mounting  
          {/* <Badge className="badges task-id">{AddUpdate.currentValue.taskNo}</Badge> */}
          </>}
          modalsize={"lg"}
          onModalDismiss={AddUpdate.closeAddEditFrom}
        >
          <ViewMounting
            closeAddUpdate={AddUpdate.closeAddEditFrom}
            api={api}
            value={AddUpdate.currentValue}
          />
        </ModalView>
      )}
    </div>
  );
};
