import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { getFormattedDate } from "../../../utils/dateUtils";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "createDate",
      title: "Date",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return getFormattedDate(rowData.createDate);
      },
    },
    {
      key: "name",
      title: "Agency Name",
      dataType: DataType.String,
    },
  
    {
      key: "BookingAmount",
      title: "Total Agency Amount",
      dataType: DataType.String,
    },
    {
      key: "PaymentAmount",
      title: "Payment Amount",
      dataType: DataType.String,
    },
    {
      key: "Balance",
      title: "balance",
      dataType: DataType.String,
    },
  ];
};
