import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import TextBox from "../../components/common/textBox";
import Dropdown from "../../components/common/dropdown";
import { validationSchema } from "./validation";
import DatePicker from "react-datepicker";
import { crossIcon, file, LinkIcon } from "../../constant/icons";
import { PaymentModeSelect } from "../../constant/menus";
import { BookingAPI } from "../booking";
import { getDate } from "../../utils/dateUtils";
import { useUpload } from "../masters/items/helper";
import { useEffect } from "react";
import ProtectedImage from "../../components/common/image";

const AddUpdateReceipt = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const booking = BookingAPI();
  const imageUpload = useUpload(4);
  let bookingList =
    booking?.data?.length > 0
      ? booking?.data.map((item: any) => {
          return { value: item.id, label: item.bookingNo };
        })
      : [];
      useEffect(() => {
        if (value.attachment) {
          const parseAttachement = value?.attachment ? JSON.parse(value?.attachment):null
          const setImagearray = parseAttachement && parseAttachement.length >0 && parseAttachement.map((element:any)=>{
            return {url:element?.url,path:element?.url,type:element.type,id:"undefined"}
          })
          imageUpload.setImagePreviews(setImagearray && setImagearray.length > 0 ?setImagearray:[]);
          imageUpload.setSelectedImages(setImagearray && setImagearray.length > 0 ?setImagearray:[])
        }

      }, [value]);
      const setAttachmentMethod=(index:number,setFieldValue:any,values:any)=>{
        const jsonparse = values.attachment ? JSON.parse(values.attachment):null;
        const deleteFileArray = values?.deletefiles && values?.deletefiles.length > 0 ? [...values.deletefiles]:[]
        const filterDeleteFile= jsonparse && jsonparse.length > 0 && jsonparse.find((ele:any,ind:any)=> ind == index)
        deleteFileArray.push(filterDeleteFile)
        const removeAttachment = jsonparse && jsonparse.length > 0 && jsonparse.filter((ele:any,ind:any)=> ind !== index)
        setFieldValue("attachment",JSON.stringify(removeAttachment))
        setFieldValue("deletefiles",deleteFileArray)
      }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        id:value?.id ?? "",
        bookingNo: value?.bookingId ?? "",
        personName: value?.personName ?? "",
        paymentDate: value?.paymentDate ? new Date(value?.paymentDate) : new Date(),
        amount: value?.amount ?? "",
        paymentMode:  value?.paymentMode ?? "",
        bankName:value?.bankName ?? "",
        chequeNo: value?.chequeNo ?? "",
        attachment:value?.attachment || null,
        deletefiles:[],
      }}
      onSubmit={(values: any, actions: any) => {
        values={
          ...values,
          amount:parseInt(values.amount),
          bookingId:values.bookingNo,
          paymentDate: values?.paymentDate?.toISOString()
        }
        delete values.bookingNo
        
        const formdata = new FormData();
          imageUpload.selectedImages.forEach((file, index) => {
            if (imageUpload.imagePreviews[index]?.id !== "undefined") {  
                formdata.append(`${imageUpload.imagePreviews[index].id}`, file);
             }
          });
          for (const key in values) {
            if (values[key] !== null && values[key] !== undefined) {
              if(key == "deletefiles"){
                formdata.append(key, JSON.stringify(values[key]));
              }else{
                formdata.append(key, values[key]);
              }
            }
          }
        if (value.id) {
          api.updateValue(formdata).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        } else {
          api.create(formdata).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {({ values, setFieldValue ,errors}) => {
       return <Form className="form-main receipt-form">
          <Card>
            <Row>
              <Col md={3}>
                <Dropdown
                  fieldName="bookingNo"
                  labelName="Booking ID"
                  options={bookingList}
                  setFieldValue={setFieldValue}
                  values={values}
                  selectedValue={values.bookingNo}
                />
              </Col>
              <Col md={3} className="mb-3">
                <TextBox
                  fieldName="personName"
                  placeholder="Enter name"
                  className="form-control"
                  gorupClass="mb-0"
                  type="text"
                  isRequired={true}
                  labelName="Person name"
                  value={values.personName}
                />
              </Col>
              <Col md={3}>
                <FormGroup noMargin>
                  <Label className="form-label" htmlFor={"paymentDate"}>
                    Payment Date
                    <sup className="text-danger required">*</sup>
                  </Label>
                  <Field
                    component={DatePicker}
                    placeholderText={"DD/MM/YYYY"}
                    dateFormat={"dd/MM/yyyy"}
                    className="form-control"
                    selected={getDate(values?.paymentDate ? values?.paymentDate : new Date())}
                    name="paymentDate"
                    onChange={(date: any) => {
                      setFieldValue("paymentDate", date);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <TextBox
                  fieldName="amount"
                  placeholder="Amount"
                  gorupClass="mb-0"
                  className="form-control"
                  type="text"
                  labelName="Amount"
                  value={value.amount}
                />
              </Col>
              <Col md={3}>
                <Dropdown
                  fieldName="paymentMode"
                  labelName="Payment mode"
                  options={PaymentModeSelect}
                  setFieldValue={setFieldValue}
                  values={values}
                  selectedValue={values.paymentMode}
                  callback={(clientVal:any)=>{
                    if(clientVal?.value == "cash"){
                        setFieldValue("bankName","")
                        setFieldValue("chequeNo","")
                    }
                  }}
                />
              </Col>
              {values.paymentMode == "cheque" ? (
                <>
                  <Col md={3}>
                    <TextBox
                      fieldName="bankName"
                      placeholder="Bank name"
                      gorupClass="mb-0"
                      className="form-control"
                      type="text"
                      labelName="Bank name"
                      value={values.paymentMode}
                    />
                  </Col>
                  <Col md={3}>
                    <TextBox
                      fieldName="chequeNo"
                      placeholder="ex : 01234567890"
                      className="form-control"
                      gorupClass="mb-0"
                      type="text"
                      labelName="Cheque number"
                      value={values.paymentMode}
                    />
                  </Col>{" "}
                </>
              ) : null}
            </Row>
          </Card>
          <Card>
            <div className="d-flex justify-content-between align-content-center">
              <h3>Receipt media</h3>
              <Button className="btn-attachment">
                {LinkIcon.icon} Add Attachment
                <input type="file" onChange={imageUpload.handlefileChange} />
              </Button>
            </div>
            <Row>
              <Col md="9">
                <div className="images-list">
                  {imageUpload?.imagePreviews &&
                    imageUpload?.imagePreviews.length > 0 &&
                    imageUpload.imagePreviews.map((element, index) => {
                      const isImage =
                        imageUpload.selectedImages.length > 0
                          ? imageUpload.selectedImages[index].type.startsWith(
                              "image/"
                            )
                          : false;
                        const filename = imageUpload.selectedImages.length > 0 ? imageUpload.selectedImages[index].name:""
                      return isImage ? (
                        <div className="images-item">
                          {
                            element?.path ? <ProtectedImage value={element?.path}  callBack={() =>{}} />: <img src={element?.url} alt={element?.id} />
                          }
                        
                          <button
                            className="remove-box"
                            onClick={() => {
                              imageUpload.handleRemoveImage(index)
                              setAttachmentMethod(index,setFieldValue,values)
                             
                            }}
                          >
                            {crossIcon.icon}
                          </button>
                        </div>
                      ) : (
                        <div className="images-item file-item">
                          <a
                            href="javascript:void(0);"
                            target="_blank"
                            rel="noopener noreferrer"
                            title=""
                            className="file-box"
                          >
                            <div className="icon-box">{file.icon}</div>
                            <p>{filename}</p>
                          </a>
                          <a
                            href="javascript:void(0);"
                            className="remove-box"
                            title="Remove"
                            onClick={() => {
                              imageUpload.handleRemoveImage(index)
                              setAttachmentMethod(index,setFieldValue,values)
                            }}
                          >
                            {crossIcon.icon}
                          </a>
                        </div>
                      );
                    })}
                </div>
                {/* <div className="dropzone">
                      <div className="attachment-data-list">
                        <div className="attachment-data">
                          <a
                            href="javascript:void(0);"
                            target="_blank"
                            rel="noopener noreferrer"
                            title=""
                            className="file-box"
                          >
                            {file.icon}
                            <p>
                              Filename
                            </p>
                          </a>
                          <a
                            href="javascript:void(0);"
                            className="remove-box"                                              
                            title="Remove"
                          >
                            {crossIcon.icon}
                          </a>
                        </div>
                      </div>
                    </div> */}
              </Col>
            </Row>
          </Card>
          <div className="d-flex justify-content-end gap-2">
            <Button
              color="secondary"
              outline
              type="button"
              onClick={() => closeAddUpdate()}
            >
              Cancel
            </Button>
            <Button color="secondary" solid type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </div>
        </Form>
      }}
    </Formik>
  );
};
export { AddUpdateReceipt };
