import AppMainLogo from '../assets/images/logo-sm.png'
import AppMainDarkLogo from '../assets/images/logo-sm.png'
import AppsmallLogo from '../assets/images/logo-sm.png'
import ProfileImage from '../assets/images/Avatar.png'

interface LogoSize{
    small:any,
    main:any,
    darkLogo: any,
}
const AppLogo:LogoSize={
    small:<img src={AppsmallLogo} alt="Brand Logo" className='logo-sm'/>,
    main:<img src={AppMainLogo} alt="Brand Logo" className='logo-main'/>,
    darkLogo:<img src={AppMainDarkLogo} alt="Brand Logo"/>,
}

const Profile=<img src={ProfileImage} alt='Profile Image' />

export {AppMainLogo, AppsmallLogo, AppLogo, Profile, AppMainDarkLogo}
