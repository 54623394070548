import { ErrorMessage, Field, useField } from "formik";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { uuid } from "../../utils/generalUtils";
interface textBoxProps {
  gorupClass?: string;
  labelName?: any;
  fieldName?: string;
  className?: string;
  labelClass?: string;
  spanClassName?: string;
  isRequired?: boolean;
  options?: any;
  values?: any;
  setFieldValue?: any;
  selectedValue?: any;
  callback?: any;
  icon?: any;
  disabled?: boolean;
  isClearable?: boolean;
  isCreatableSelect?: boolean;
}
export default function Dropdown(props: textBoxProps) {
  const [field, meta] = useField(props.fieldName || "name");
  const className = `${props.className || ""} ${
    meta.error ? "is-invalid" : ""
  }`;
  return (
    <FormGroup noMargin className={props.gorupClass || ""}>
      <Label className={props.labelClass || "form-label"} htmlFor={props.fieldName || "name"}>
        {props.labelName}
        {props.isRequired && (
          <span className={props.spanClassName || "text-danger required"}>
            {" "}
            *
          </span>
        )}
      </Label>
      {props.isCreatableSelect ? (
        <CreatableSelect
          {...field}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          name={props.fieldName || "name"}
          id={props.fieldName || "name"}//
          className={className} //form-control
          options={props?.options}
          isClearable={props.isClearable}
          onChange={(opt: any) => {
            if (props.callback) {
              props.callback(opt); // Invoke the callback if it is provided
            }
            props.setFieldValue(props.fieldName, opt ? opt.value : "");
          }}
          value={props?.options?.find((x:any)=>x.value==props.selectedValue)}
          isDisabled={props.disabled}
        />
      ) : (
        <Field
          {...field}
          component={Select}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          }}
          name={props.fieldName || "name"}
          id={props.fieldName || "name"}//{props.fieldName || "name"}
          className={className} //form-control
          options={props?.options}
          isClearable={props.isClearable}
          onChange={(opt: any) => {
            if (props.callback) {
              props.callback(opt);
            }
            props.setFieldValue(props.fieldName, opt ? opt.value : "");
          }}
          value={props?.options?.filter(
            (option: any) => option.value === props.selectedValue
          )}
          isDisabled={props.disabled}
        />
      )}

      <>
        {" "}
        {props?.icon ? <span>{props.icon}</span> : ""}{" "}
        <ErrorMessage
          name={props.fieldName || "name"}
          component="span"
          className="invalid-feedback"
        />
      </>
    </FormGroup>
  );
}
