import { Button } from "reactstrap";
import PageLayout from "../../../components/layout/page";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import { useApi } from "../../../helper/apiHook";
// import { AddUpdateCity } from "./addUpdate";
import { plus } from "../../../constant/icons";
import { columns } from "./columns";
import { DataView } from "../../../components/table/table";
import { useEffect } from "react";
import ModalView from "../../../components/modal";
import { AddUpdateRoute } from "./addUpdate";

export const RouteApi = (paging?: boolean, dataLoad: any = {}) => {
    return useApi("/routes", paging, undefined, dataLoad);
};
export default function RouteList() {
    const api = RouteApi(true);
    const AddUpdate = useAddUpdate();
    useEffect(() => {
        api.mutate();
    }, []);

    return (
        <>
            <PageLayout
                item={
                    <Button
                        color="secondary"
                        onClick={() =>
                            AddUpdate.openAddEditFrom({
                                name: null,
                                startLatitude: null, // Example latitude as integer
                                endLatitude: null, // Example latitude as integer
                                startLongitude: null, // Example longitude as integer
                                endLongitude: null, // Example longitude as integer
                                startLandmarkDescription: null,
                                endLandmarkDescription: null,
                            })
                        }
                    >
                        {plus.icon}Add New
                    </Button>
                }
                View={
                    <DataView
                        columns={columns({
                            api,
                            openAddEditFrom: AddUpdate.openAddEditFrom,
                        })}
                        rows={api.data}
                        currentPage={api.currentPage}
                        rowCount={api.rowCount}
                        limit={api.limit}
                        updatePagination={api.updatePagination}
                    />
                }
            />
            {AddUpdate.viewAddUpdate && (
                <ModalView
                    modalView={AddUpdate.viewAddUpdate}
                    modalName={AddUpdate.currentValue?.id ? "Edit Route" : "Add Route"}
                    onModalDismiss={AddUpdate.closeAddEditFrom}
                    modalsize={"lg"}
                >
                    <AddUpdateRoute
                        closeAddUpdate={AddUpdate.closeAddEditFrom}
                        api={api}
                        value={AddUpdate.currentValue}
                    />
                </ModalView>
            )}
        </>
    );
}
