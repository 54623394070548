import { DataType } from "ka-table";
import { TableColumn } from "../../components/table/table";
import { ActionButton } from "../../components/buttons/actionButtons";
import { DELETE, UPDATE } from "../../constant/commonConst";
import { confirmBox } from "../../components/toast";
import { StatusChangeView } from "../leadGeneration/action";
import { paymentModeView } from "./action";
import moment from "moment";
export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "bookingId",
      title: "Booking ID",
      dataType: DataType.String,
      valueConvert: (value: any, row: any) => {
        return row?.Booking?.bookingNo;
      },
    },
    {
      key: "personName",
      title: "Person Name",
      dataType: DataType.String,
    },
    // {
    //   key: "PANNo",
    //   title: "PAN No",
    //   dataType: DataType.String,
    // },
    {
      key: "paymentDate",
      title: "Payment Date",
      dataType: DataType.String,
      valueConvert: (value: any, row: any) => {
        return moment(value).format("DD/MM/YYYY");
      },
    },
    {
      key: "amount",
      title: "Amount",
      dataType: DataType.String,
    },
    // {
    //   key: "GSTINNo",
    //   title: "Payment Mode",
    //   dataType: DataType.String,
    // },
    {
      key: "status",
      title: "Payment Mode",
      hideHeaderFilter: false,
      dataType: DataType.String,
      component: paymentModeView,
      style: {
        textAlign: "center",
        cursor: "pointer",
      },
    },
    {
      key: "edit",
      title: "",
      hideHeaderFilter: false,
      component: ActionButton,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            bookingId: row.data.bookingId,
            personName: row.data.personName || "",
            paymentDate: row.data.paymentDate || null,
            amount: row.data.amount || null,
            paymentMode: row.data.paymentMode || null,
            bankName: row?.data?.bankName || null,
            chequeNo: row?.data?.chequeNo || null,
            attachment: row?.data?.attachment || null,
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox("you want to delete this receipt?");
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
