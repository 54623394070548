import { useEffect, useState } from "react";
import { DataView } from "../../components/table/table";
import { innerColumns } from "./columns";
import ModalView from "../../components/modal";
import { UploadImage } from "../printing/Components/uploadImage";
import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { AddUpdatePrinting } from "./addUpdate";
import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";

export const DetailsRow = ({
  rowData, apiPrinting,setOpenDetailPrinting,openDetailPrinting
}:any) => {
  const [uploadImage, setUploadImage] = useState(false);
  const handleShowUploadImage = (uploadImage:any) => setUploadImage(uploadImage);
  const api = useApi(`/printingtasks/${rowData.bookingId}`, true, {}, { revalidateIfStale: true })
  const AddUpdate = useAddUpdate();
  return (
    <div className="details-row mt-3 d-inline-block w-100">
       {/* <ModalView
          modalView={openDetailPrinting?.id == rowData?.id}
          modalsize="xl"
          customClass="custom-size-modal-table"
          customClassBody="custom-size-modal-body"
          modalName={"Booking Id"}
          onModalDismiss={() =>{
            setOpenDetailPrinting(null)
          }}
        > */}
          <DataView
            columns={innerColumns({ api, openUpload: handleShowUploadImage, apiPrinting, openAddEditFrom: AddUpdate.openAddEditFrom })}
            detailsRow={(props: any) => <DetailsRow {...props} />}
            rows={api.data}
            currentPage={api.currentPage}
            rowCount={api.rowCount}
            limit={api.limit}
          />
        {/* </ModalView> */}
      {uploadImage && (
        <ModalView
          modalView={uploadImage}
          modalName={"Upload Image"}
          onModalDismiss={() =>setUploadImage(false)}
        >
          <UploadImage
            uploadImage={uploadImage}
            onModalDismiss={handleShowUploadImage}
            api={apiPrinting}
            uploadImageApi={api}
            rowData={rowData}
            closeAddUpdate={handleShowUploadImage}
          />
        </ModalView>
      )}

      {AddUpdate.viewAddUpdate && (
        <ModalView
          modalView={AddUpdate.viewAddUpdate}
          modalName={"View Printing"}
          modalsize={"lg"}
          customClass={"printing-view-modal"}
          onModalDismiss={AddUpdate.closeAddEditFrom}
        >
          <AddUpdatePrinting
            closeAddUpdate={AddUpdate.closeAddEditFrom}
            api={api}
            value={AddUpdate.currentValue}
          />
        </ModalView>
      )}
    </div>
  );
};
