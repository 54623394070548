import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Dropdown from "../../../components/common/dropdown";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import TextBox from "../../../components/common/textBox";
import { ClientApi } from "../../masters/client";
import { getDate } from "../../../utils/dateUtils";
import { call, email, plus, user } from "../../../constant/icons";
import { Booking } from "../dto";
import { ProposalAPI } from "../../businessProposal";
import ModalView from "../../../components/modal";
import { AddUpdateClient } from "../../masters/client/addUpdate";
import { Proposal } from "../../businessProposal/dto";
import { LeadApi } from "../../leadGeneration";
import { components } from "react-select";
import "./custom-option.css";
import { MediaItemsApi } from "../../masters/items";
import { uuid } from "../../../utils/generalUtils";
import { UserAPI } from "../../user";

const CustomOption = (props: any) => {
  const { leadNo, contactPerson, client } = props.data;

  return (
    <components.Option {...props}>
      <div className="custom-option">
        <div className="lead-no">Lead No: {leadNo}</div>
        <div className="contact-person">Contact: {contactPerson}</div>
        <div className="business-name">Business: {client.businessName}</div>
      </div>
    </components.Option>
  );
};

// Custom SingleValue component to show only leadNo when selected
const CustomSingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      {props.data.leadNo}
    </components.SingleValue>
  );
};

export default function Details(props: {
  setFieldValue: any;
  values: any | Booking | Proposal;
  proposal: Boolean;
}) {
  const clientData = ClientApi(false, { revalidateIfStale: true });
  const userData = UserAPI(false);
  const listData = props.proposal ? LeadApi(false) : ProposalAPI(false);
  const [client, setClient] = useState(false);
  let clientList =
    clientData?.data?.data?.length > 0
      ? clientData?.data.data?.map((item: any) => {
        return { value: item.id, label: item.businessName, client: item };
      })
      : [];
  let userList =
    userData?.data?.data?.length > 0
      ? userData?.data.data?.map((item: any) => {
        return { value: item.id, label: item.name, data: item };
      })
      : [];

  let ListForSubItem =
    listData?.data?.data?.length > 0
      ? listData?.data.data?.map((item: any) => {
        return {
          value: item.id,
          label: props.proposal ? item?.leadNo : item?.prososalNo,
          lead: item,
        };
      })
      : [];
  const [currentClient, setCurrentClient] = useState<any>(null);
  let body = undefined;
  const mediaItemsData = MediaItemsApi(false, undefined, body);
  return (
    <>
      <Row className="highlighet-bg booking-head-form mb-3">
        {client && (
          <>
            <ModalView
              modalsize={"xl"}
              modalView={client}
              modalName={"Add Client"}
              onModalDismiss={() => setClient(false)}
            >
              <AddUpdateClient
                closeAddUpdate={() => {
                  setClient(false);
                }}
                api={clientData}
                value={{
                  businessName: "",
                  GSTIN: "",
                  IndustryId: "",
                  PANNo: "",
                  contactPerson: "",
                  contactPersonDesignation: "",
                  contactPerson_emailId: "",
                  contactPersonMobileNo: "",
                  billingAddress: "",
                }}
              />
            </ModalView>
          </>
        )}
        <Col lg="4">
          <Row>
            <Col lg="12">
              <div className="d-flex gap-2 w-100 align-items-end mb-3">
                <FormGroup noMargin className={"form-group client-box w-100"}>
                  <Dropdown
                    fieldName="clientId"
                    labelName={"Client"}
                    options={clientList}
                    setFieldValue={props.setFieldValue}
                    values={props.values}
                    selectedValue={props.values.clientId}
                    isClearable={true}
                    callback={(clientVal: any) => {
                      if (clientVal) {
                        // Check if the selected client matches the client associated with the selected proposal
                        const selectedProposal = !listData?.data?.data
                          ? undefined
                          : listData?.data?.data?.find(
                            (proposal: { id: any }) =>
                              proposal.id === props.values.proposalId
                          );
                        const selectedClientId = clientVal.client.id;

                        // If there's a proposal and it has a clientId, compare it
                        if (
                          selectedProposal &&
                          selectedProposal.clientId !== selectedClientId
                        ) {
                          // Clear the Proposal ID if the client does not match
                          props.setFieldValue("proposalId", null);
                        }
                        setCurrentClient(clientVal);
                        props.setFieldValue(
                          props.proposal
                            ? "contactPersonEmailId"
                            : "bookedByPersonEmail",
                          clientVal.client.contactPerson_emailId
                        );
                        props.setFieldValue(
                          props.proposal
                            ? "contactPersonMobileNo"
                            : "bookedByPersonMobile",
                          clientVal.client.contactPersonMobileNo
                        );
                        props.setFieldValue(
                          props.proposal
                            ? "contactPerson"
                            : "bookedByPersonName",
                          clientVal.client.contactPerson
                        );
                        props.setFieldValue(
                          "billingAddress",
                          clientVal.client.billingAddress
                        );
                        props.setFieldValue("GSTIN", clientVal.client.GSTIN);
                      } else {
                        if (!props?.values?.proposalId) {
                          setCurrentClient(null);
                          props.setFieldValue(
                            props.proposal
                              ? "contactPersonEmailId"
                              : "bookedByPersonEmail",
                            ""
                          );
                          props.setFieldValue(
                            props.proposal
                              ? "contactPersonMobileNo"
                              : "bookedByPersonMobile",
                            ""
                          );
                          props.setFieldValue(
                            props.proposal
                              ? "contactPerson"
                              : "bookedByPersonName",
                            ""
                          );
                          props.setFieldValue("billingAddress", "");
                          props.setFieldValue("GSTIN", "");
                        }
                      }
                    }}
                  />
                </FormGroup>
                <Button
                  type="button"
                  solid
                  color="secondary"
                  className="py-2"
                  onClick={() => {
                    setClient(true);
                  }}
                >
                  {plus.icon}
                </Button>
              </div>
            </Col>
            <Col lg="12">
           <Dropdown
                fieldName={props.proposal ? "leadId" : "proposalId"}
                labelName={props.proposal ? "Lead" : "Proposal"}
                className="form-select"
                disabled={props.proposal ? false : (props.values.clientId) ? false : true}
                gorupClass="form-group mb-3"
                options={props.proposal ? ListForSubItem : (props.values.clientId) ? ListForSubItem.filter((item:any) => item.lead.clientId === props.values.clientId) : []}
                setFieldValue={props.setFieldValue}
                values={props.values}
                selectedValue={
                  props.proposal ? props.values.leadId : props.values.proposalId
                }
                isClearable={true}
                // disabled={values.leadId}
                callback={(clientVal: any) => {
                  if (clientVal) {
                    setCurrentClient({
                      ...clientVal,
                      client: {
                        ...clientVal.client,
                        GSTIN: clientVal?.lead?.client?.GSTIN ?? "",
                      },
                    });
                    if (!props.values?.clientId) {
                      const selectedClient = clientList.find(
                        (client: { value: any }) =>
                          client.value === clientVal?.lead?.clientId
                      );
                      props.setFieldValue("clientId", selectedClient.value);
                    }
                    props.setFieldValue(
                      props.proposal
                        ? "contactPersonEmailId"
                        : "bookedByPersonEmail",
                      clientVal?.lead?.contactPersonEmailId ?? ""
                    );
                    props.setFieldValue(
                      props.proposal
                        ? "contactPersonMobileNo"
                        : "bookedByPersonMobile",
                      clientVal?.lead?.contactPersonMobileNo ?? ""
                    );
                    props.setFieldValue(
                      props.proposal ? "contactPerson" : "bookedByPersonName",
                      clientVal?.lead?.contactPerson ?? ""
                    );
                    // props.setFieldValue(
                    //       "GSTIN",
                    //       clientVal?.lead?.client?.GSTIN??""
                    // );
                    if (!props.proposal) {
                      const filter =
                        listData?.data?.data &&
                        listData?.data?.data?.length > 0 &&
                        listData?.data?.data?.find(
                          (element: any) => element.id == clientVal.value
                        );
                      const manipulateMediaInventory =
                        filter?.mediaInventory &&
                        filter?.mediaInventory.length > 0 &&
                        filter?.mediaInventory.map((media: any) => {
                          let findItem = mediaItemsData?.data?.data?.find(
                            (x: any) => x.id == media?.mediaItemId
                          );
                          let endDate: Date = media.endDate
                            ? new Date(media.endDate)
                            : new Date();
                          let bookingStartDate: Date = listData.data
                            .bookingStartDate
                            ? new Date(listData.data.bookingStartDate)
                            : new Date();

                          // Calculate the difference in milliseconds and then convert to days
                          let differenceInDays = Math.floor(
                            (endDate.getTime() - bookingStartDate.getTime()) /
                            (1000 * 60 * 60 * 24)
                          );
                          if (findItem) {
                            return {
                              ...media,
                              size: `${findItem.width} X ${findItem.height}`,
                              height: findItem?.height,
                              width: findItem?.width,
                              sqft: findItem.width * findItem.height,
                              qty: media.qty ? media?.qty : findItem.qty,
                              rate: media?.rate
                                ? media?.rate
                                : findItem.rateAgency,
                              location: findItem?.location?.name,
                              city: findItem?.city?.name,
                              tempRowId: uuid(),
                              day: differenceInDays,
                              endDate: endDate,
                            };
                          } else {
                            return {
                              ...media,
                              tempRowId: uuid(),
                              day: differenceInDays,
                              endDate: endDate,
                            };
                          }
                        });
                      props.setFieldValue("mediaInventory", [
                        ...manipulateMediaInventory,
                      ]);
                    }
                    props.setFieldValue(
                      "description",
                      clientVal?.lead?.description
                    );
                  } else {
                    props.setFieldValue("mediaInventory", []);
                    props.setFieldValue("description", "");
                    if (!props?.values?.proposalId) {
                      setCurrentClient(null);
                      props.setFieldValue(
                        props.proposal
                          ? "contactPersonEmailId"
                          : "bookedByPersonEmail",
                        ""
                      );
                      props.setFieldValue(
                        props.proposal
                          ? "contactPersonMobileNo"
                          : "bookedByPersonMobile",
                        ""
                      );
                      props.setFieldValue(
                        props.proposal ? "contactPerson" : "bookedByPersonName",
                        ""
                      );
                      props.setFieldValue("billingAddress", "");
                      props.setFieldValue("GSTIN", "");
                    }
                  }
                }}
              />
            </Col>
            <Col lg="6">
              <TextBox
                fieldName={props.proposal ? "proposalNo" : "bookingNo"}
                placeholder={props.proposal ? "Proposal No." : "Booking No."}
                className="form-control"
                type="text"
                isDisabled={true}
                labelName={props.proposal ? "Proposal No" : "Booking No"}
                value={props.values.bookingNo}
              />
            </Col>
            {!props.proposal && (
              <>
                <Col lg="6">
                  <div className="d-flex gap-2 w-100 align-items-end mb-3">
                    <FormGroup className={"form-group mb-0"}>
                      <Label
                        className="form-label"
                        htmlFor={"bookingStartDate"}
                      >
                        Booking Start Date
                        <sup className="text-danger required">*</sup>
                      </Label>
                      <Field
                        component={DatePicker}
                        placeholderText={"DD/MM/YYYY"}
                        dateFormat={"dd/MM/yyyy"}
                        className="form-control"
                        name="bookingStartDate"
                        selected={getDate(props.values.bookingStartDate)}
                        onChange={(date: any) => {
                          props.setFieldValue("bookingStartDate", date);
                        }}
                      />
                    </FormGroup>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col lg="4">
          <Row className="booking-head-border">
            <Col lg="12" className="client-box">
              <TextBox
                fieldName={"billingAddress"}
                placeholder={"Enter Bill address"}
                className={"form-control mb-3"}
                type={"textarea"}
                labelName={
                  currentClient ? (
                    <>
                      Bill Address
                      <span className="gst-no-box">
                        GST No. {currentClient?.client?.GSTIN}
                      </span>
                    </>
                  ) : (
                    "Bill Address"
                  )
                }
                rows={"3"}
                value={props.values.billingAddress}
              />
            </Col>
            <Col lg="12">
              <TextBox
                fieldName="description"
                placeholder="Enter description"
                className="form-control"
                type={"textarea"}
                labelName="Description"
                rows={"3"}
                value={props.values.description}
              />
            </Col>
            {!props.proposal && (
              <Col lg="12" className="mt-3">
                <div className="d-flex gap-2 w-100 align-items-end mb-3">
                  <FormGroup noMargin className={"form-group client-box w-100"}>
                    <Dropdown
                      fieldName="userId"
                      labelName={"Booking By"}
                      options={userList}
                      setFieldValue={props.setFieldValue}
                      values={props.values}
                      selectedValue={props.values.userId}
                      isClearable={true}
                    />
                  </FormGroup>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col lg="4">
          <Row className="booking-contact-info">
            <h3>Contact Info</h3>
            <Col lg="12">
              {" "}
              <div className="contact-info-inner">
                <TextBox
                  fieldName={
                    props.proposal ? "contactPerson" : "bookedByPersonName"
                  }
                  placeholder="Enter booked by person name"
                  className="form-control mb-3"
                  type="text"
                  // labelName="Booked by Person Name"
                  value={
                    props.proposal
                      ? props.values.contactPerson
                      : props.values.bookedByPersonName
                  }
                />
                <span>{user.icon}</span>
              </div>
            </Col>
            <Col lg="12">
              <div className="contact-info-inner">
                <TextBox
                  fieldName={
                    props.proposal
                      ? "contactPersonMobileNo"
                      : "bookedByPersonMobile"
                  }
                  placeholder="Enter booked by person mobile"
                  className="form-control mb-3"
                  type="text"
                  // labelName="Booked by Person Mobile"
                  value={
                    props.proposal
                      ? props.values.contactPersonEmailId
                      : props.values.bookedByPersonMobile
                  }
                />
                <span>{call.icon}</span>
              </div>
            </Col>
            <Col lg="12">
              <div className="contact-info-inner">
                <TextBox
                  fieldName={
                    props.proposal
                      ? "contactPersonEmailId"
                      : "bookedByPersonEmail"
                  }
                  placeholder="Enter booked by person email"
                  className="form-control"
                  type="email"
                  // labelName="Booked by Person Email"
                  value={
                    props.proposal
                      ? props.values.contactPersonEmailId
                      : props.values.bookedByPersonEmail
                  }
                />
                <span>{email.icon}</span>
              </div>
            </Col>
          </Row>
        </Col>
        {/* <Col>
          <FormGroup className={"form-group"}>
            <Label className="form-label" htmlFor={"BookingDate"}>
              Booking Date
              <sup className="text-danger required">*</sup>
            </Label>
            <Field
              component={DatePicker}
              placeholderText={"DD/MM/YYYY"}
              dateFormat={"dd/MM/yyyy"}
              className="form-control"
              name="bookingDate"
              selected={new Date()}
              disabled={true}
            />
          </FormGroup>
        </Col> */}
        {/* <Col>
          <Label className="form-label d-block">Status:</Label>
          New Booking
        </Col> */}
      </Row>
    </>
  );
}
