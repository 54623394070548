import PageLayout from "../../components/layout/page";
import MountingReportList from "./mountingReport";

export default function MountingReport() {
    return (
        <PageLayout className="dashboard-inner-page"
            View={
                <>
                    <MountingReportList />
                </>
            }
        />
    );
}
export {}