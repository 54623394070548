import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { getFormattedDate } from "../../../utils/dateUtils";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "paymentDate",
      title: "Date",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData.paymentDate ? getFormattedDate(rowData.paymentDate) : "";
      },
    },
    {
      key: "name",
      title: "Landlord Name",
      dataType: DataType.String,
    },
    {
      key: "rateLandlord",
      title: "Total Landloard Amount",
      dataType: DataType.String,
    },
    {
      key: "amount",
      title: "Payment Amount",
      dataType: DataType.String,
    },
    {
      key: "Balance",
      title: "balance",
      dataType: DataType.String,
    },
  ];
};
