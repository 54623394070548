import { Button } from "reactstrap";
import PageLayout from "../../../components/layout/page";
import { DataView } from "../../../components/table/table";
import { downloadIcon, filterIcon, searchIcon } from "../../../constant/icons";
import { columns } from "./columns";
import { useApi } from "../../../helper/apiHook";
import { useState } from "react";
import { LeadReportFilter } from "./filter";
import { saveAs } from 'file-saver';
import moment from "moment";
import ExcelJS from 'exceljs';
import { getFormattedDate } from "../../../utils/dateUtils";
export default function LeadReportList() {
    const [filterValues, setFilterValues] = useState<any>({});
    const api = useApi("/leadReport", true, filterValues);
    const [filter, setFilter] = useState<boolean>(false);
    const handleShowFilter = () => {
        setFilter(!filter);
    };
    const today = moment().format('DD-MM-YYYY');

    const exportToExcel = async () => {
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Define the headers (removed 'ID' and added 'Client Name')
        const headers = [
            { header: 'Lead Number', key: 'leadNo', width: 15 },
            { header: 'Date', key: 'date', width: 30 },
            { header: 'Client Name', key: 'clientName', width: 30 },
            { header: 'Contact Person', key: 'contactPerson', width: 20 },
            { header: 'Contact Person Mobile', key: 'contactPersonMobileNo', width: 25 },
            { header: 'Contact Person Email', key: 'contactPersonEmailId', width: 30 },
            { header: 'Contact Person Designation', key: 'contactPersonDesignation', width: 30 },
            { header: 'Assigned Name', key: 'leadBy', width: 15 },
            { header: 'Description', key: 'description', width: 90 },
            { header: 'Status', key: 'status', width: 15 },
        ];

        // Add the header row with styles
        worksheet.columns = headers;

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000' }, // black background
            };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        // Map your API data and remove the 'id', add 'clientName'
        const data = api?.data.map((item: any) => ({
            leadNo: item.leadNo,
            date: getFormattedDate(item?.createDate),
            description: item.description || '',
            contactPerson: item.contactPerson || '',
            contactPersonEmailId: item.contactPersonEmailId || '',
            contactPersonMobileNo: item.contactPersonMobileNo || '',
            contactPersonDesignation: item.contactPersonDesignation || '',
            status: item.status || '',
            clientName: item?.client?.businessName ? item?.client?.businessName : '', // Added client name here
            leadBy: item.leadByUser?.name || '' // Safely handle missing leadByUser
        }));

        // Add the data rows
        data.forEach((item: any) => {
            worksheet.addRow(item);
        });

        // Generate buffer for Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob and trigger the download
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `leadReport${today}.xlsx`);
    };

    return (
        <>
            <div className="d-flex justify-content-end align-items-end" style={{ paddingRight: '1rem' }}>
                <div className="head-btn-box">
                    <Button color="secondary" type="button" outline onClick={exportToExcel}>
                        {downloadIcon.icon}
                        Download
                    </Button>
                    <Button
                        color="secondary"
                        type="button"
                        outline
                        onClick={handleShowFilter}
                    >
                        {filterIcon.icon}
                        Filter
                    </Button>
                </div>
            </div>
            <PageLayout
                // item={
                //   <Button
                //     color="secondary"
                //     onClick={() =>
                //       AddUpdate.openAddEditFrom({
                //         ClientId: null,
                //         // agencyId: null,
                //         // name: "",
                //         description: "",
                //         contactPerson: "",
                //         contactPersonEmailId: "",
                //         contactPersonMobileNo: "",
                //         leadBy:""
                //         // contactPersonDesignation: "",
                //         // leadBy: null,
                //         // status: "",
                //       })
                //     }
                //   >
                //     {plus.icon}Add New
                //   </Button>
                // }
                View={
                    <>
                        <DataView
                            columns={columns({ api })}
                            rows={api.data}
                            updatePagination={api.updatePagination}
                            currentPage={api.currentPage}
                            rowCount={api.rowCount}
                            limit={api.limit}
                            // updatePagination={api.updatePagination}
                        />
                    </>
                }
            />
            {filter &&
                <LeadReportFilter
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    onCloseFilter={handleShowFilter} />
            }
        </>
    );
}
