import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import AddCellTable from "../../../components/table/addCellTable";
import { DataType } from "ka-table";
import { ActionButtons, InputCell, MaterialID, MediaItem } from "../actions";


export default function FbphTable({ mediaInventory, bookingStartDate, setFieldValue, isEditMode }: any) {
  const dbId = "FBPH"
  const columns = [
    {
      key: "mediaItemId",
      title: "Media Item ID",
      style: { width: "15vw" },
      dataType: DataType.String,
      component: MediaItem,
    },
    { key: "city", title: "City", dataType: DataType.String, style: { width: "9vw" } },
    { key: "location", title: "Location", dataType: DataType.String, style: { width: "9vw" } },
    {
      key: "width",
      title: "Width",
      dataType: DataType.Number,
      component: InputCell,
      style: { width: "8vw" }
    },
    {
      key: "height", title: "Height",
      dataType: DataType.Number,
      component: InputCell,
      style: { width: "8vw" }
    },
    { key: "sqft", title: "Sq.ft", dataType: DataType.String, style: { width: "6vw" } },
    {
      key: "qty", title: "Qty",
      dataType: DataType.Number,
      component: InputCell,
      style: { width: "7vw" }
    },
    {
      key: "materialId",
      title: "material",
      dataType: DataType.String,
      component: MaterialID,
      style: { width: "13vw" }
    },
    {
      key: "rate", title: "Rate Per SQ.FT",
      dataType: DataType.Number,
      component: InputCell,
      style: { width: "9vw" }
    },
    {
      key: "amount",
      title: "Amount",
      dataType: DataType.String,
      style: { width: "7vw" }
    },
    {
      key: "addColumn",
      title: "",
      component: ActionButtons,
      style: { width: "5vw", textAlign: 'center' }
    },
  ];
  const [isModalOpen, setIsModal] = useState({ open: false, data: null });
  return (
    <>
      <AccordionItem>
        <AccordionHeader targetId="FBPH">
          FLEX BANNER PRINTING HOARDING
        </AccordionHeader>
        <AccordionBody accordionId={dbId}>
          <AddCellTable
            bookingStartDate={bookingStartDate}
            groupCode={dbId}
            updateFieldValue={setFieldValue}
            columns={columns}
            rows={mediaInventory} isEditMode={isEditMode}
            setIsModal={setIsModal}
          />
        </AccordionBody>
      </AccordionItem>
    </>
  );
}
