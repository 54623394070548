import React from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../helper/apiHook";
import { Badge, Card, Col, Row } from "reactstrap";
import PageLayout from "../../../components/layout/page";
import { getParshData } from "../../../utils/generalUtils";
import { file } from "../../../constant/icons";
import commonImg from '../../../assets/images/auth-slider/imge_01.webp'

export default function MediaView() {
  const { id } = useParams();
  const { data } = useApi(`/mediaItems/${id}`);


  let bookingCalendar = [];
  if (data?.bookingCalendar) {
    bookingCalendar = getParshData(data?.bookingCalendar);
  }
  let acceptBookingCalendar = [];
  if (data?.acceptBookingCalendar) {
    acceptBookingCalendar = getParshData(data?.acceptBookingCalendar);
  }
  let rateCalendar = [];
  if (data?.rateCalendar) {
    rateCalendar = getParshData(data?.rateCalendar);
  }
  let LANDLORDRateCalendar = [];
  if (data?.LANDLORDRateCalendar) {
    LANDLORDRateCalendar = getParshData(data?.LANDLORDRateCalendar);
  }

  return (
    <PageLayout
      View={
        <div className="media-item-view">       
          <Card>
            <div className="d-flex justify-content-between align-items-start">
              <h3>Media Data</h3>   
              <p>Media ID : <Badge color="primary" className="media-id">{data?.name ? data.name : ""}</Badge></p>
            </div>
            <ul className="media-head-list">                                            
                {/* <li>
                  <div className="media-list-item">
                    <span>Media ID</span>
                    <strong></strong>
                  </div>
                </li> */}
                <li>
                  <div className="media-list-item">
                    <span>Media Type</span>
                    <strong>{data?.mediaTypeId ? data.mediaTypeId : ""}</strong>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>Media Lit</span>
                    <strong>{data?.mediaLitId ? data.mediaLitId : ""}</strong>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>Media Series</span>
                    <strong>{data?.mediaSeriesId ? data.mediaSeriesId : ""}</strong>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>State</span>
                    <strong>{data?.stateId ? data.stateId : ""}</strong>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>City</span>
                    <strong>{data?.cityId ? data.cityId : ""}</strong>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>Zone</span>
                    <strong>{data?.zoneId ? data.zoneId : ""}</strong>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>Location</span>
                    <strong>{data?.locationId ? data.locationId : ""}</strong>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>Latitude - Longitude</span>
                    <Badge>{data?.latitude ? data.latitude : ""}</Badge>
                    <Badge>{data?.longitude ? data.longitude : ""}</Badge>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>Width X Height</span>
                    <strong>{data?.width ? data.width : ""} X {data?.height ? data.height : ""}</strong>
                  </div>
                </li>
                <li>
                  <div className="media-list-item">
                    <span>Quantity</span>
                    <strong>{data?.qty ? data.qty : ""}</strong>
                  </div>
                </li>                
            </ul>                
          </Card>                     

          <Card>
            <div className="d-flex justify-content-between align-items-center">
              <h3>Landlord Info</h3>   
              <div>
                <Badge className="landlord-id">{data?.landlordId ? data.landlordId : ""}</Badge>
              </div>
            </div>
            <Row className="media-landlord-list">   
              <Col className="ps-2 pe-2">
                <div className="media-landlord-item">
                    <span>Landlord Rate</span>
                    <strong>23456</strong>
                </div>
              </Col>
              <Col className="ps-2 pe-2">
                <div className="media-landlord-item">
                    <span>Agency Rate</span>
                    <strong>23456</strong>
                </div>
              </Col>
              <Col className="ps-2 pe-2">
                <div className="media-landlord-item">
                    <span>Start - End Date</span>
                    <strong>08-08-2024 to 14-08-2024</strong>
                </div>
              </Col>
              <Col className="ps-2 pe-2">
                <div className="media-landlord-item">
                    <span>Attachment</span>
                    <span className="attachment-box">{file.icon} filename</span>
                </div>
              </Col>
            </Row>  
          </Card>

          <Card>
            <h3>Media Images</h3>
            <div className="media-images-list">
              <div className="images-item primary-item">                                                                                     
                <img src={commonImg} alt="Media Image"/>                                                        
              </div>  
              <div className="images-item">                                                                                     
                <img src={commonImg} alt="Media Image"/>                                                        
              </div>  
              <div className="images-item">                                                                                     
                <img src={commonImg} alt="Media Image"/>                                                        
              </div>  
              <div className="images-item">                                                                                     
                <img src={commonImg} alt="Media Image"/>                                                        
              </div>  
            </div>
          </Card>

          {/* <ListGroup flush>                                    
            <ListGroupItem>
              Image URL: {data?.image ? data.image : ""}
            </ListGroupItem>           
            <ListGroupItem>
              Booking Calendar:
              <ListGroup>
                {bookingCalendar &&
                  bookingCalendar.map(
                    (
                      item: {
                        clientID: number;
                        bookingID: number;
                        startDate: string;
                        endDate: string;
                      },
                      index: number
                    ) => (
                      <ListGroupItem key={index}>
                        Client ID: {item.clientID} <br />
                        Booking ID: {item.bookingID}
                        <br /> Start Date: {getFormattedDate(item.startDate)}
                        <br /> End Date: {getFormattedDate(item.endDate)}
                      </ListGroupItem>
                    )
                  )}
              </ListGroup>
            </ListGroupItem>
            <ListGroupItem>
              Accept Booking Calendar:
              <ListGroup>
                {acceptBookingCalendar.map(
                  (
                    item: { startDate: string; endDate: string },
                    index: number
                  ) => (
                    <ListGroupItem key={index}>
                      Start Date: {getFormattedDate(item.startDate)}
                      <br /> End Date: {getFormattedDate(item.endDate)}
                    </ListGroupItem>
                  )
                )}
              </ListGroup>
            </ListGroupItem>
            <ListGroupItem>
              Rate Calendar:
              <ListGroup>
                {rateCalendar.map(
                  (
                    item: { startDate: string; endDate: string; rate: number },
                    index: number
                  ) => (
                    <ListGroupItem key={index}>
                      Start Date: {getFormattedDate(item.startDate)}
                      <br /> End Date: {getFormattedDate(item.endDate)} <br />
                      Rate: {item.rate}
                    </ListGroupItem>
                  )
                )}
              </ListGroup>
            </ListGroupItem>
            <ListGroupItem>
              Landlord Rate Calendar:
              <ListGroup>
                {LANDLORDRateCalendar.map(
                  (
                    item: { startDate: string; endDate: string; rate: number },
                    index: number
                  ) => (
                    <ListGroupItem key={index}>
                      Start Date: {getFormattedDate(item.startDate)}
                      <br /> End Date: {getFormattedDate(item.endDate)}
                      <br />
                      Rate: {item.rate}
                    </ListGroupItem>
                  )
                )}
              </ListGroup>
            </ListGroupItem>   
            <li>
                  <div className="media-list-item">
                    <span>Quantity</span>
                    <strong>{data?.balanceQty ? data.balanceQty : ""}</strong>
                  </div>
                </li>         
          </ListGroup> */}
        </div>
      }
    />
  );
}
