import { useEffect, useState } from "react";
import { DataType } from "ka-table";
import PageLayout from "../../components/layout/page";
import { DataView, TableColumn } from "../../components/table/table";
import { Button } from "reactstrap";
import { downloadIcon, filterIcon } from "../../constant/icons";
import { useApi } from "../../helper/apiHook";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { ReceiptFilter } from "./ReceiptFilter";
import moment from "moment";
import { ClientApi } from "../masters/client";
import { LandlordApi } from "../masters/landlord";
import { AgencyApi } from "../masters/agency";
import ExcelJS from "exceljs";
import { getFormattedDate } from "../../utils/dateUtils";

export const receiptColumns = (actions: any): TableColumn[] => {
    return [
        {
            key: "DATE",
            title: "DATE",
            dataType: DataType.String,
            // valueConvert: (value: string) => getFormattedDate(value)
        },
        {
            key: "CLIENT",
            title: "CLIENT",
            dataType: DataType.String,
        },
        {
            key: "AGENCY",
            title: "AGENCY",
            dataType: DataType.String,
        },
        {
            key: "LANDLORD",
            title: "LANDLORD",
            dataType: DataType.String,
        },
        {
            key: "TRANSACTION TYPE",
            title: "TRANSACTION TYPE",
            dataType: DataType.String,
        },
        {
            key: "CASH / BANK",
            title: "CASH / BANK",
            dataType: DataType.String,
        },
        {
            key: "RECEIPT",
            title: "RECEIPT",
            dataType: DataType.String,
        },
        {
            key: "PAYMENT",
            title: "PAYMENT",
            dataType: DataType.String,
        },
    ];
};

export default function Report() {
    const [filter, setFilter] = useState<boolean>(false);
    const [filterValues, setFilterValues] = useState<any>({});
    const [rows, setRows] = useState<any[]>([]);
    const [filteredRows, setFilteredRows] = useState<any[]>([]);
    const clientApi = ClientApi(false);
    const landlordApi = LandlordApi(false);
    const agencyApi = AgencyApi(false);
    const api = useApi("/receipt/receiptReport", true, filterValues);
    // Fetch data initially or when filterValues change
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = api.data || [];

                const mappedData = Array.isArray(data)
                    ? data.map((item: any) => ({
                        DATE: moment(item.paymentDate).format("DD/MM/YYYY"),
                        CLIENT: item.personName,
                        AGENCY: item?.agency ? item?.agency?.name : "",
                        LANDLORD: item?.landlord ? item?.landlord?.name : "",
                        "TRANSACTION TYPE": item.transactionType,
                        "CASH / BANK": item.paymentMode,
                        RECEIPT: item?.receiptAmount ?? "-",
                        PAYMENT: item?.paymentAmount ?? "-",
                    }))
                    : [];

                setRows(mappedData);
                setFilteredRows(mappedData); // Initially set filtered rows as all data
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [api.data]);

    // Apply filters to the rows
    // useEffect(() => {
    //     // const applyFilters = () => {
    //     //     let filtered = [...rows];

    //     //     if (filterValues.personNames && filterValues.personNames.length > 0) {
    //     //         filtered = filtered.filter(row =>
    //     //             filterValues.personNames.includes(row['CLIENT / AGENCY / LANDLORD'])
    //     //         );
    //     //     }
    //     //     if (filterValues.transactionType) {
    //     //         filtered = filtered.filter(row =>
    //     //             row['TRANSACTION TYPE'] === filterValues.transactionType
    //     //         );
    //     //     }
    //     //     if (filterValues.paymentMode) {
    //     //         filtered = filtered.filter(row =>
    //     //             row['CASH / BANK'] === filterValues.paymentMode
    //     //         );
    //     //     }
    //     //     if (filterValues.fromDate) {
    //     //         filtered = filtered.filter(row =>{
    //     //             const dateMoment = moment(row["DATE"], "DD/MM/YYYY");
    //     //             const unixTimestamp = dateMoment.unix();
    //     //             return  unixTimestamp >= moment(filterValues.fromDate).unix()
    //     //         });
    //     //     }
    //     //     if (filterValues.toDate) {
    //     //         filtered = filtered.filter(row =>{
    //     //             const dateMoment = moment(row["DATE"], "DD/MM/YYYY");
    //     //             const unixTimestamp = dateMoment.unix();
    //     //             return unixTimestamp <= moment(filterValues.toDate).unix()
    //     //         });
    //     //     }
    //     //     setFilteredRows(filtered);
    //     // };
    //     // applyFilters();
    //    // api.mutate()
    // }, [filterValues, rows]);

    const handleShowFilter = () => {
        setFilter(!filter);
    };
    const today = moment().format("DD-MM-YYYY");
    // const exportToExcel = async () => {
    //     const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    //     saveAs(blob, `Receipt_Payment_Report_${today}.xlsx`);
    // };
    const exportToExcel = async () => {
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        // Define the headers based on the receiptColumns structure
        const headers = [
            { header: "DATE", key: "DATE", width: 15 },
            { header: "CLIENT", key: "CLIENT", width: 30 },
            { header: "AGENCY", key: "AGENCY", width: 30 },
            { header: "LANDLORD", key: "LANDLORD", width: 30 },
            { header: "TRANSACTION TYPE", key: "TRANSACTION TYPE", width: 25 },
            { header: "CASH / BANK", key: "CASH / BANK", width: 25 },
            { header: "RECEIPT", key: "RECEIPT", width: 15 },
            { header: "PAYMENT", key: "PAYMENT", width: 15 },
        ];
        // Add the header row with styles
        worksheet.columns = headers;

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White text
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "000" }, // black background
            };
            cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Map your API data and remove the 'id', add 'clientName'
        const data = api?.data.map((item: any) => {
            return {
                DATE: moment(item.paymentDate).format("DD/MM/YYYY"),
                CLIENT: item.personName,
                AGENCY: item?.agency ? item?.agency?.name : "",
                LANDLORD: item?.landlord ? item?.landlord?.name : "",
                "TRANSACTION TYPE": item.transactionType,
                "CASH / BANK": item.paymentMode,
                RECEIPT: item?.receiptAmount ?? "-",
                PAYMENT: item?.paymentAmount ?? "-",
            }
        });

        // Add the data rows
        data.forEach((item: any) => {
            worksheet.addRow(item);
        });

        // Generate buffer for Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob and trigger the download
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Receipt_Payment_Report_${today}.xlsx`);
    };
    // Extract unique person names for the filter dropdown
    const personList = Array.isArray(api?.data)
        ? api?.data
            .filter((val: any) => val.transactionType !== "payment")
            .map((name: any) => {
                return { value: name?.personName, label: name?.personName };
            })
        : [];

    const landlordlist = Array.isArray(landlordApi?.data?.data)
        ? landlordApi?.data?.data.map((name: any) => ({
            value: name?.id,
            label: name?.name,
        }))
        : [];
    const agencylist =
        agencyApi?.data?.data && agencyApi?.data?.data.length > 0
            ? agencyApi?.data?.data.map((name: any) => ({
                value: name?.id,
                label: name?.name,
            }))
            : [];
    return (
        <>
            <div
                className="d-flex justify-content-end align-items-end"
                style={{ paddingRight: "1rem" }}
            >
                <div className="head-btn-box">
                    <Button
                        color="secondary"
                        type="button"
                        outline
                        onClick={exportToExcel}
                    >
                        {downloadIcon.icon}
                        Download
                    </Button>
                    <Button
                        color="secondary"
                        type="button"
                        outline
                        onClick={handleShowFilter}
                    >
                        {filterIcon.icon}
                        Filter
                    </Button>
                </div>
            </div>
            <PageLayout
                View={
                    <DataView
                        columns={receiptColumns({ api: {} })}
                        rows={filteredRows}
                        currentPage={api.currentPage}
                        rowCount={api.rowCount}
                        limit={api.limit}
                        updatePagination={api.updatePagination}
                    />
                }
            />
            {filter && (
                <ReceiptFilter
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    onCloseFilter={handleShowFilter}
                    personList={personList} // Pass personList to the filter component
                    landlordlist={landlordlist}
                    agencylist={agencylist}
                />
            )}
        </>
    );
}
