import {useEffect, useState } from "react";
import { CustomInfoType } from "./types";


export const useAddCustomeInfo = (value:any) => {
  const [customInfo, setCustomInfo] = useState<CustomInfoType[]>([]);
  useEffect(() => {
      if (value.customInfo?.length > 0) {
          setCustomInfo(value.customInfo);
      } else {
          setCustomInfo([])
      }
  }, [value.customInfo]);

  // Handle label and value change
  const handleChange = (
      index: number,
      target: EventTarget & HTMLInputElement
  ) => {
      const newCustomInfo = [...customInfo];
      if (target.name === "key") {
          newCustomInfo[index].key = target.value;
      } else if (target.name === "value") {
          newCustomInfo[index].value = target.value;
      }
      setCustomInfo(newCustomInfo);
  };
  const deleteCustomInfo = (index: number) => {
    const newCustomInfo = [...customInfo];
    newCustomInfo.splice(index, 1);
    setCustomInfo(newCustomInfo);
  };
  // Add a new custom info field
  const addCustomInfo = () => {
      setCustomInfo([...customInfo, { key: "", value: "" }]);
  };
  return { customInfo, setCustomInfo , handleChange, addCustomInfo, deleteCustomInfo};
};


