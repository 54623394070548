import React from "react";
import { DataView } from "../../../components/table/table";
import { columns, landloardColumns } from "../columns";

export default function Landloard({ rowData }: any) {
const landloard = rowData.data.data
  return (
    <div>
      <DataView
        offPagination={true}
        offSearch={true}
        columns={landloardColumns({ landloard })}
        rows={landloard}
      />
    </div>
  );
}
