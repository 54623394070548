export const INVALID_EMAIL = "Invalid email format.";
export const EMAIL = "Email is required.";
export const PASSWORD_MIN_LENGTH = "Password must be at least 8 characters.";
export const PASSWORD_REQUIRED = "Password is required.";
export const CONFIRM_PASSWORD_REQUIRED = "Confirm Password is required.";

export const CONFIRM_PASSWORD_MATCH_REQUIRED =
  "Confirm Password Should match to Password.";

export const USERNAME_REQUIRED = "Username is required.";
export const NAME_REQUIRED = "Name is required.";
export const STATE_REQUIRED = "State is required.";
export const ROLE_REQUIRED = "Role is required.";
export const AGENCY_REQUIRED = "Agency name is required.";
export const CITY_REQUIRED = "City is required.";
export const ALIAS_REQUIRED = "Alias is required.";
export const ALIAS_MAX_REQUIRED = "Alias must be at most 3 characters.";

export const ZONE_REQUIRED = "Zone id is required.";
export const LOCATION_REQUIRED = "Location is required.";
export const INDUSTRY_REQUIRED = "Industry is required.";
export const SERIES_REQUIRED = "Series name is required.";
export const LIT_REQUIRED = "Lit name is required.";
export const MEDIA_REQUIRED = "Media name is required.";
export const DESCRIPTION_REQUIRED = "Description is required.";
export const LANDLORD_REQUIRED = "Landlord name is required.";
export const BUSINESS_NAME_REQUIRED = "Business name is required.";
export const CONTACT_PERSON_REQUIRED = "Contact person is required.";
export const EMAIL_REQUIRED = "Email is required.";
export const EMAIL_INVALID = "Invalid email address.";
export const MOBILE_REQUIRED = "Mobile number is required.";
export const MOBILE_INVALID =
  "Invalid mobile number. Please enter a valid number.";
export const MOBILE_MIN_DIGITS = "Mobile number must be at least 10 digits.";
export const MOBILE_MAX_DIGITS = "Mobile number must be at most 10 digits.";
export const DESIGNATION_REQUIRED = "Contact person designation is required.";
export const BILLING_ADDRESS_REQUIRED = "Billing address is required.";
export const GSTIN_REQUIRED = "GSTIN is required.";
export const GSTIN_INVALID = "Invalid GSTIN Number.";
export const BUSINESS_TYPE_REQUIRED = "Business type is required.";
export const MEDIA_ITEM_REQUIRED = "Media item is required.";
export const NAME_MIN_LENGTH = "Name must be at least 2 characters.";
export const NAME_MAX_LENGTH = "Name must be at most 50 characters.";
export const LATITUDE_REQUIRED = "Latitude is required.";
export const LATITUDE_NUMBER = "Latitude must be a number.";
export const LONGITUDE_REQUIRED = "Longitude is required.";
export const LONGITUDE_NUMBER = "Longitude must be a number.";
export const MEDIA_TYPE_REQUIRED = "Media Type is required.";
export const MEDIA_LIT_REQUIRED = "Media Lit is required.";
export const MEDIA_SERIES_REQUIRED = "Media Series is required.";
export const IMAGE_URL_REQUIRED = "Image URL is required.";
export const IMAGE_URL_INVALID = "Invalid Image URL format.";
export const WIDTH_REQUIRED = "Width is required.";
export const WIDTH_POSITIVE = "Width must be a positive number.";
export const WIDTH_INTEGER = "Width must be an integer.";
export const HEIGHT_REQUIRED = "Height is required.";
export const HEIGHT_POSITIVE = "Height must be a positive number.";
export const HEIGHT_INTEGER = "Height must be an integer.";
export const QUANTITY_REQUIRED = "Quantity is required.";
export const QUANTITY_POSITIVE = "Quantity must be a positive number.";
export const QUANTITY_INTEGER = "Quantity must be an integer.";
export const CLIENT_REQUIRED = "Client is required";
export const LEAD_NAME_REQUIRED = "Lead name is required";
export const LEAD_BY_REQUIRED = "Lead by is required";
export const CREATIVE_IMAGE_URL_REQUIRED = "Creative image URL is required";
export const SIZE_WIDTH_REQUIRED = "Size width is required";
export const SIZE_HEIGHT_REQUIRED = "Size height is required";
export const MEDIA_QTY_REQUIRED = "Media quantity is required";
export const RATE_REQUIRED = "Rate is required";
export const ADDRESS_REQUIRED = "Address is required.";
export const CONTACT_EMAIL_REQUIRED = "Contact email is required.";
export const CONTACT_EMAIL_INVALID = "Please enter a valid email address.";
export const CONTACT_MOBILE_INVALID = "Please enter a valid mobile number.";
export const CONTACT_PERSON_DESIGNATION_REQUIRED =
  "Contact person designation is required.";
export const PAN_REQUIRED = "PAN number is required.";
export const PAN_INVALID = "Invalid PAN number";

export const TYPE_REQUIRED = "Type is required.";
export const START_LATITUDE_REQUIRED = "Start latitude is required";
export const END_LATITUDE_REQUIRED = "End latitude is required";
export const START_LONGITUDE_REQUIRED = "Start longitude is required";
export const END_LONGITUDE_REQUIRED = "End longitude is required";
export const START_LANDMARK_REQUIRED = "Start landmark description is required";
export const END_LANDMARK_REQUIRED = "End landmark description is required";
export const ROUTE_REQUIRED = "Route id is required.";
export const MATERIAL_NAME_REQUIRED = "Name is required.";
export const MATERIAL_RATE_REQUIRED = "Rate is required.";

export const BOOKING_NO_REQUIRED = "Booking No is required.";
export const VENDOR_TYPE_REQUIRED = "Vendor Type is required.";
export const LANDLORD_AGENCY_ID_REQUIRED = "LandLord/Agency Name is required.";


export const PERSON_NAME_REQUIRED = "Person Name is required.";
export const PAYMENT_DATE_REQUIRED = "Payment Date is required.";
export const AMOUNT_REQUIRED = "Amount is required.";
export const PAYMENT_MODE_REQUIRED = "Payment Mode is required.";
export const BANK_NAME_REQUIRED = "Bank Name is required.";

export const CHEQUE_NO_REQUIRED = "Cheque No is required.";
