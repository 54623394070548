import { useApi } from "../../helper/apiHook";
import { AddUpdateRole } from "./addUpdate";
import { useEffect, useState } from "react";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import { editSolid } from "../../constant/icons";
import { RolePermissions } from "./permissions";

export const RoleAPI = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/role", paging, undefined, dataLoad) };
};
const RoleList = () => {
  const api = RoleAPI(false, { revalidateIfStale: true });
  const [selectedRole, setSelectedRole] = useState<any>({
    id: 1,
    name: "",
  });
  useEffect(() => {
    api.mutate();
  }, []);
  useEffect(() => {
    if (api.data && api.data?.length > 0) {
      const role = api.data.filter((x: any) => x.id === selectedRole.id);
      if (role) {
        setSelectedRole(role[0]);
      } else {
        setSelectedRole(api.data[0]);
      }
    }
  }, [api.data]);
  const clearInitialValues = () => {
    setSelectedRole({ ...selectedRole, initialValues: false });
  };
  return (
    <>
      <div className="inner-page">
        <div className="inner-wrapper">
          <div className="role-module">
            <AddUpdateRole
              clearInitialValues={clearInitialValues}
              api={api}
              initialValues={
                selectedRole.initialValues
                  ? selectedRole
                  : {
                    name: "",
                  }
              }
            />
            <div className="role-tabs">
              <Nav tabs>
                {api.data?.map((role: any) => {
                  return (
                    <NavItem>
                      <NavLink
                        className={selectedRole.id === role.id ? "active" : ""}
                        onClick={() => setSelectedRole(role)}
                      >
                        {role.name}
                      </NavLink>
                      {selectedRole.id === role.id && (
                        <Button
                          color="link"
                          className="m-0 p-0 "
                          onClick={() =>
                            setSelectedRole({
                              ...selectedRole,
                              initialValues: true,
                            })
                          }
                        >
                          {editSolid.icon}
                        </Button>
                      )}
                    </NavItem>
                  );
                })}
              </Nav>
              {selectedRole && (
                <RolePermissions
                  permissions={selectedRole.rolePermissions}
                  api={api}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleList;
