import { getFormattedDate } from "../../../utils/dateUtils";

// Function to generate rows with repeated data
export const generateRows = (data: any[], idType: string) => {
  return data.map((item, index) => {
    const formattedIndex = (index + 1).toString().padStart(3, "0");
    return {
      mediaid: item.mediaItem?.name,
      agencyid: item.mediaItem?.agency?.alias,
      routeid: item.routeId,
      zone: item.zoneId,
      mediatype: item.mediatype,
      city: item.mediaItem?.city?.name,
      location: item.mediaItem?.location?.name,
      size:`${item.width} * ${item.height}`,
      sqft: item.width * item.height,
      series: item.series,
      qty: item.qty,
      ratepermonth: item.rate,
      ratepersqft: item.rate,
      rateperpolekiosk: item.rate,
      material: item.material,
      stdate: item.startDate ? getFormattedDate(item.startDate): "N/A",
      enddate: item.endDate ? getFormattedDate(item.endDate): "N/A", 
      amount: item.amount,
      [idType]: formattedIndex // Add the unique ID for the specified idType
    };
  });
};
// export const generateRows = (count: any, idType: any) => {
//   console.log(count, "countcount")
//   const baseRow = {
//     mediaid: 'CM001',
//     agencyid: 'SO001',
//     routeid: 'R001',
//     zone: 'ahmedabad',
//     mediatype: 'newspaper',
//     city: 'New York',
//     location: 'The Landmark, kudasan, gandhinagar',
//     size: '20x10',
//     sqft: '200',
//     series: 'test',
//     qty: '1',
//     ratepermonth: '1000',
//     ratepersqft: '2000',
//     rateperpolekiosk: '1500',
//     material: '1',
//     stdate: '01-01-24',
//     enddate: '12-01-23',
//     amount: '12000'
//   };

//   const rows = Array.from({ length: count }, (_, i) => ({
//     ...baseRow,
//     [idType]: (i + 1).toString().padStart(3, '0') // Example: "001", "002", etc.
//   }));

//   console.log('Generated Rows:', rows);
//   return rows;
// };

// const firstRowCount = 5;
// const secondRowCount = 5;
// const thirdRowCount = 10;
// const fourRowCount = 1;
// const fiveRowCount = 2;
// const sixRowCount = 4;

// export const headerColumns = [
//   {
//     title: 'SALE OF SPACE FOR OUTDOOR ADVERTISEMENT HOARDING',
//     columns: ['MEDIA ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER MONTH', 'ST DATE', 'END DATE', 'AMOUNT'],
//     rows: firstRowCount > 0 ? generateRows(firstRowCount, 'mediaid') : []
//   },
//   {
//     title: 'PURCHASE OF SPACE FOR OUTDOOR ADVERTISEMENT HOARDING',
//     columns: ['AGENCY ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER MONTH', 'ST DATE', 'END DATE', 'AMOUNT'],
//     rows: secondRowCount > 0 ? generateRows(secondRowCount, 'agencyid') : []
//   },
//   {
//     title: 'FLEX BANNER PRINTING HOARDING',
//     columns: ['MEDIA ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER SQ FT', 'MATERIAL', 'AMOUNT'],
//     rows: thirdRowCount > 0 ? generateRows(thirdRowCount, 'agencyid') : []
//   },
//   {
//     title: 'FLEX BANNER MOUNTING',
//     columns: ['MEDIA ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER SQ FT', 'AMOUNT'],
//     rows: fourRowCount > 0 ? generateRows(fourRowCount, 'agencyid') : []
//   },
//   {
//     title: 'SALE OF SPACE FOR OUTDOOR ADVERTISEMENT POLE KIOSK',
//     columns: ['ROUTE ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER MONTH', 'ST DATE', 'END DATE', 'AMOUNT'],
//     rows: fiveRowCount > 0 ? generateRows(fiveRowCount, 'agencyid') : []
//   },
//   {
//     title: 'FLEX BANNER PRINTING AND MOUNTING POLE KIOSK',
//     columns: ['MEDIA ID', 'ZONE', 'MEDIA TYPE', 'SIZE', 'SERIES', 'QTY', 'RATE PER POLE KIOSK', 'MATERIAL', 'AMOUNT'],
//     rows: sixRowCount > 0 ? generateRows(sixRowCount, 'mediaid') : []
//   }
// ];

// // Debugging: Ensure headerColumns is properly defined
// console.log('headerColumns:', headerColumns);
// headerColumns.forEach((section, index) => {
//   console.log(`Section ${index}:`, section);
//   console.log(`Columns:`, section.columns);
//   console.log(`Rows length:`, section.rows.length);
// });
