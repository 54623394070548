import * as Yup from 'yup';
import * as mess from '../../constant/message';

export const stateSchema = Yup.object().shape({
  // ClientId: Yup.mixed().nullable().required(mess.CLIENT_REQUIRED),
  // // agencyId: Yup.number().nullable().required(mess.AGENCY_REQUIRED),
  description: Yup.string().required(mess.LEAD_NAME_REQUIRED),
  contactPerson: Yup.string().required(mess.CONTACT_PERSON_REQUIRED),
  // contactPersonEmailId: Yup.string().email(mess.INVALID_EMAIL).required(mess.EMAIL_REQUIRED),
  contactPersonMobileNo: Yup.string().nullable().required(mess.MOBILE_REQUIRED).matches(/^\d{10}$/, mess.MOBILE_INVALID),
  // contactPersonDesignation: Yup.string().required(mess.DESIGNATION_REQUIRED),
  // leadBy: Yup.number().nullable().required(mess.LEAD_BY_REQUIRED),
  // mediaDescription: Yup.array().of(
  //   Yup.object().shape({
  //     stateId: Yup.number().nullable().required(mess.STATE_REQUIRED),
  //     cityId: Yup.number().nullable().required(mess.CITY_REQUIRED),
  //     zoneId: Yup.number().nullable().required(mess.ZONE_REQUIRED),
  //     locationId: Yup.number().nullable().required(mess.LOCATION_REQUIRED),
  //     mediaList: Yup.array().of(
  //       Yup.object().shape({
  //         mediaTypeId: Yup.string().required(mess.MEDIA_TYPE_REQUIRED),
  //         mediaLitId: Yup.string().required(mess.MEDIA_LIT_REQUIRED),
  //         mediaSeriesId: Yup.string().required(mess.MEDIA_SERIES_REQUIRED),
  //         imageUrl: Yup.string().required(mess.IMAGE_URL_REQUIRED),
  //         creativeImageUrl: Yup.string().required(mess.CREATIVE_IMAGE_URL_REQUIRED),
  //         sizeWdth: Yup.number().nullable().required(mess.SIZE_WIDTH_REQUIRED),
  //         sizeHeight: Yup.number().nullable().required(mess.SIZE_HEIGHT_REQUIRED),
  //         mediaQty: Yup.number().nullable().required(mess.MEDIA_QTY_REQUIRED),
  //         rate: Yup.number().nullable().required(mess.RATE_REQUIRED),
  //       })
  //     ),
  //   })
  // ),
});

export const followSchema = Yup.object().shape({

});