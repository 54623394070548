import { Button, CloseButton, Col, Label, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import PageLayout from "../../../components/layout/page";
import { clientSchema } from "./validation";
import TextBox from "../../../components/common/textBox";
import { IndustryApi } from "../industry";
import Dropdown from "../../../components/common/dropdown";
import { useAddCustomeInfo } from "../../../helper/customeInfoHook";

const AddUpdateClient = (props: any) => {
  const { api, value, closeAddUpdate, setCreatedClientId }: any = props;
  const industryData = IndustryApi(false, { revalidateIfStale: true });
  let industryList =
    industryData?.data?.data?.length > 0
      ? industryData.data.data.map((item: any) => {
          return { value: item.id, label: item.name };
        })
      : [];
  const addInfo = useAddCustomeInfo(value);

  return (
    <PageLayout
      View={
        <Formik
          validationSchema={clientSchema}
          initialValues={value}
          onSubmit={(values: any, actions: any) => {
            const value = {
              ...values,
              customInfo: addInfo.customInfo,
            };
            if (values.id) {
              api.updateValue(value).then((result: any) => {
                if (result?.status) {
                  closeAddUpdate();
                }
              });
            } else {
              api.create(value).then((result: any) => {
                if (result?.status) {
                  closeAddUpdate();
                  if (setCreatedClientId) {
                    setCreatedClientId(result?.data);
                  }
                }
              });
            }
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className="form-row">
              <Col lg={3} md={4} sm={2}>
                <TextBox
                  fieldName={"businessName"}
                  placeholder={"Enter business name"}
                  className={"form-control"}
                  type={"text"}
                  labelName={"Business Name"}
                  value={value}
                />
              </Col>
              {/* <Col lg="4">
                                <TextBox
                                    fieldName={"businessType"}
                                    placeholder={"Enter business type"}
                                    className={"form-control"}
                                    type={"text"}
                                    labelName={"Business Type"}
                                    value={value}
                                />
                            </Col> */}
              <Col lg={3} md={4} sm={2}>
                <TextBox
                  fieldName={"GSTIN"}
                  placeholder={"00-XXXXX0000X-0X0"}
                  className={"form-control"}
                  type={"text"}
                  labelName={"GSTIN"}
                  value={value}
                />
              </Col>
              <Col lg={3} md={4} sm={2}>
                <Dropdown
                  fieldName="IndustryId"
                  labelName="Industry"
                  options={industryList}
                  setFieldValue={setFieldValue}
                  values={value}
                  selectedValue={values.IndustryId}
                />
              </Col>
              <Col lg={3} md={4} sm={2}>
                <TextBox
                  fieldName="PANNo"
                  placeholder="Enter PAN number"
                  className="form-control"
                  type="text"
                  labelName="PAN No"
                  value={value.PANNo}
                />
              </Col>
              <Col lg={3} md={4} sm={2}>
                <TextBox
                  fieldName={"contactPerson"}
                  placeholder={"Enter contact person Name"}
                  className={"form-control"}
                  type={"text"}
                  labelName={"Contact Person Name"}
                  value={value}
                />
              </Col>
              <Col lg={3} md={4} sm={2}>
                <TextBox
                  fieldName={"contactPersonDesignation"}
                  placeholder={"Enter contact person designation"}
                  className={"form-control"}
                  type={"text"}
                  labelName={"Contact Person Designation"}
                  value={value}
                />
              </Col>
              <Col lg={3} md={4} sm={2}>
                <TextBox
                  fieldName={"contactPerson_emailId"}
                  placeholder={"name@example.com"}
                  className={"form-control"}
                  type={"email"}
                  labelName={"Contact Person Email"}
                  value={value}
                />
              </Col>
              <Col lg={3} md={4} sm={2}>
                <TextBox
                  fieldName={"contactPersonMobileNo"}
                  placeholder={"Enter contact person mobile number"}
                  className={"form-control"}
                  type={"text"}
                  labelName={"Contact Person Mobile No"}
                  value={value}
                />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <TextBox
                  fieldName={"billingAddress"}
                  placeholder={"Enter billing address"}
                  className={"form-control"}
                  type={"textarea"}
                  rows="4"
                  labelName={"Billing Address"}
                  value={value}
                />
              </Col>
              {/* <Col lg="12">
                                <div className="add-custom">
                                    <div className="d-flex gap-2 w-100 align-items-center justify-content-between mb-2 col">
                                        <Label className="mt-2">
                                            <b>Custom information </b>
                                        </Label>{" "}
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="ms-3"
                                            onClick={addInfo.addCustomInfo}
                                        >
                                            Add Custom Info
                                        </Button>
                                    </div>
                                    <Col lg="12">
                                        {addInfo.customInfo.map((data, index) => (
                                            <Row className="mb-2" key={index}>
                                                <Col className="pe-1">
                                                    <Field
                                                        id={`key${index}`}
                                                        name="key"
                                                        placeholder="key"
                                                        className="form-control"
                                                        type="text"
                                                        value={data.key}
                                                        onChange={(e: any) =>
                                                            addInfo.handleChange(index, e.target)
                                                        }
                                                    />
                                                </Col>
                                                <Col className="ps-1">
                                                    <Field
                                                        id={`value${index}`}
                                                        name="value"
                                                        placeholder="Value"
                                                        className="form-control"
                                                        type="text"
                                                        value={data.value}
                                                        onChange={(e: any) =>
                                                            addInfo.handleChange(index, e.target)
                                                        }
                                                    />
                                                </Col>
                                                <Col className="w-auto flex-none d-flex align-items-center ps-0">
                                                    <CloseButton
                                                        onClick={() => addInfo.deleteCustomInfo(index)}
                                                    />
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </div>
                            </Col> */}

              <Col
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-end gap-2"
              >
                <Button
                  color="secondary"
                  onClick={() => closeAddUpdate()}
                  type={"button"}
                  outline
                >
                  Cancel
                </Button>
                <Button color="secondary" type="submit">
                  {value?.id > 0 ? "Update" : "Submit"}
                </Button>
              </Col>
            </Form>
          )}
        </Formik>
      }
    />
  );
};
export { AddUpdateClient };
