function PageLayout(props: any) {
  return (
    <div className={`inner-page ${props?.className ? props?.className : ""}`}>
      <div className="inner-wrapper">
        {(props?.title || props?.item) && (
          <div className="section-head">
            <div>
              <h4>{props?.title}</h4>
            </div>
            <div className="header-action-item">
                {/* <p>Booking No:</p> */}
                  {props?.item}  
            </div>
          </div>
        )}
        {props?.View}
      </div>
    </div>
  );
}

export default PageLayout;

