import * as Yup from "yup";
export const UserMobUnMountingImageSchema = Yup.object().shape({
  unmountingDate: Yup.string().required("UnMounting Date is Required"),
  unmountingTime: Yup.string().required("UnMounting Time is Required"),
  regularShotImage: Yup.string().required("Regular Shot Image is Required"),
  longShotImage: Yup.string().required("Long Shot Image is Required"),
  shortShortImage: Yup.string().required("Short Shot Image is Required"),
  shortGeoShotImage: Yup.string().required(
    "Short with Geo Shot Image is Required"
  ),
});
