import { useApi } from "../../../helper/apiHook";
import { viewIcon } from "../../../constant/icons";
import { Button, Card, Col, Row } from "reactstrap";

export const UnMountingApi = (paging?: boolean, dataLoad: any = {},) => {
    return { ...useApi("/unmountings", paging, undefined, dataLoad) };
};

export default function UsermobUnMountingDataView(props: any) {
    const api = UnMountingApi(true);
    const { setActiveBooking } = props;

    return (
        <div style={{ marginTop: "45px" }}>
            <Row className="card-list">
                {api?.data?.map((item: any) => (
                    <Col md={6} lg={4} sm={12} xl={3}>
                        <Card className="cursor-pointer" key={item.bookingNo} >
                            <div className="card-content">
                                <div className="content-head-box">
                                    <h3 className="d-flex gap-2"> Booking Id: {item.bookingNo}</h3>
                                    <h3 className="d-flex gap-2"> Client Name: {item.businessName}</h3>
                                    <h3 className="d-flex gap-2"> Number Of UnMounting: {item.totalPosterQty}</h3>
                                    <h3 className="d-flex gap-2"> Status: {item.status || 'Pending'}</h3>
                                </div>
                                <div className="content-action">
                                    <Button color="secondary" type="button" outline onClick={() => setActiveBooking(item.bookingId)}>
                                        {viewIcon.icon}
                                        View
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
