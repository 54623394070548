import { ErrorMessage, Field, useField } from "formik";
import React from "react";
import { FormGroup, Label } from "reactstrap";

interface textBoxProps {
  gorupClass?: string;
  id?: string;
  labelName?: string | any;
  fieldName?: string;
  placeholder?: string;
  className?: string;
  type?: string;
  labelClass?: string;
  spanClassName?: string;
  isRequired?: boolean;
  rows?: string;
  value?: any;
  icon?: any;
  isDisabled?: boolean;
  callBack?: any;
}
export default function TextBox(props: textBoxProps) {
  const [field, meta] = useField(props.fieldName || "name");
  const className = `${props.className || "form-control"} ${meta.error ? "is-invalid" : ""
    }`;
  return (
    <FormGroup noMargin className={props.gorupClass || "form-group"}>
      {props.labelName && (
        <Label className="form-label" htmlFor={props.fieldName || "name"}>
          {props.labelName}
          {props.isRequired && <sup className="text-danger required">*</sup>}
        </Label>
      )}

      {props.type == "textarea" ? (
        <Field
          {...field}
          component="textarea"
          name={props.fieldName || "name"}
          className={className}
          placeholder={props.placeholder || "Enter Name"}
          rows={props.rows || "8"}
          disabled={props?.isDisabled}
        />
      ) : (
        <Field
          {...field}
          id={props.fieldName || "name"}
          name={props.fieldName || "name"}
          placeholder={props.placeholder || "Enter Name"}
          className={className}
          type={props.type || "text"}
          onChange={(e: any) => {
            if (props?.callBack) {
              props.callBack(e.target.value);
            }
            field.onChange(e)
          }}
          // value={props?.value}
          disabled={props?.isDisabled}
        />
      )}

      {meta.error && (
        <>
          {props?.icon ? <span>{props.icon}</span> : ""}{" "}
          <ErrorMessage
            name={props.fieldName || "name"}
            component="span"
            className="invalid-feedback"
          />
        </>
      )}
    </FormGroup>
  );
}
