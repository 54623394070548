import { Formik } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { MountingApi } from "../../mounting";
import { useEffect, useState } from "react";
import { useApi } from "../../../helper/apiHook";
import { invokeApi } from "../../../helper/api";
import { POST, PUT } from "../../../constant/commonConst";

const GetAllMounting = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/mountings/getAll", paging, undefined, dataLoad) };
};
const SelectStatus: React.FC<any> = (props) => {
  const [singleData, setSingleData] = useState<any>(null);
  const mounting = MountingApi(true);
  const apiMountingList = GetAllMounting(true);
  useEffect(() => {
    setSingleData(props?.value);
  }, [props?.value]);
  return (
    <Formik
      initialValues={{
        status: singleData?.status ?? "waiting-for-creative",
        id: singleData?.id ?? "",
      }}
      enableReinitialize={true}
      onSubmit={async (value) => {
        await invokeApi(PUT, "/mountings/userMounting", value).then(
          (result: any) => {
            if (result.status) {
              if (props?.api) {
                apiMountingList.mutate();
                if (props.callBack) {
                  props.callBack(value?.status);
                }
              }
            }
          }
        );
      }}
    >
      {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
        <FormGroup
          noMargin
          onClick={(e: any) => {
            if (e && e.stopPropagation) {
              e.stopPropagation();
            }
          }}
        >
          {props.label ? <Label>Status</Label> : null}
          <Input
            id="exampleSelect"
            name="status"
            type="select"
            value={values?.status}
            onChange={(e: any) => {
              setFieldValue("status", e.target.value);
              handleSubmit(e);
            }}
          >
            <option value={"waiting-for-creative"}>To do</option>
            <option value={"mounted"}>Completed</option>
            {/* <option value={"mounting-pending"}>Monitoring</option> */}

          </Input>
        </FormGroup>
      )}
    </Formik>
  );
};

export default SelectStatus;
