
const Page404 = () => {

    return (
        <>
            <h1>404 Welcome</h1>
        </>
    );
};

export default Page404;
