import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import { stateSchema } from "./validation";
import TextBox from "../../../components/common/textBox";
import { typeList } from "./columns";

interface imageType {
  id: number | string;
  isPrimary: Boolean;
  name: string;
  description: string;
  url: string;
  file: File | string;
}
const AddUpdateMediaType = (props: any) => {
  const { api, value, closeAddUpdate } = props;
 
  return (
    <Formik
      validationSchema={stateSchema}
      initialValues={value}
      onSubmit={(values: any, actions: any) => {
        if (values.id) {
          api.updateValue(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        } else {
          api.create(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {({ setFieldValue, values }) => (
        <Form className="form-main media-type-form">
          <TextBox
            fieldName={"name"}
            placeholder={"Media name"}
            className={"form-control"}
            isRequired={true}
            type={"text"}
            labelName={"Media name"}
            value={value}
          />
        <FormGroup noMargin tag="fieldset" className="media-radio">
            {/* <label>Type</label> */}
            {typeList.map((type) => (
              <FormGroup check key={type.value.trim()} >                
                <Field
                    type="radio"
                    name="type"
                    id={type.value.trim()}
                    className={"form-check-input"}
                    value={type.value.trim()}
                    checked={values.type === type.value.trim()}
                  />
                  <Label check htmlFor={type.value.trim()}>
                  <span>{type.icon}</span>                              
                  {type.label}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
          <TextBox
            fieldName={"description"}
            placeholder={"Enter description"}
            className={"form-control"}
            type={"textarea"}
            rows="4"
            labelName={"Description"}
            value={value}
          />
          <Col lg="12" className="d-flex justify-content-end gap-2">
            <Button
              color="secondary"
              outline
               type="button"
              onClick={() => closeAddUpdate()}
            >
              Cancel
            </Button>
            <Button color="secondary" solid type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
export { AddUpdateMediaType };
