import { useRef } from "react";
import { imageIcon } from "../../../constant/icons";

interface ImageUploadProps {
  accept: string;
  imageUpload: any;
  isMultipal?: string;
  isDisabled?: boolean;
  hideText?: boolean;
  callback?: any;
}

const ImageUpload = ({
  imageUpload,
  accept,
  isMultipal,
  isDisabled,
  hideText,
  callback
}: ImageUploadProps) => {
  const fileInputRef: any = useRef(null);
  return (
    <>
      {" "}
      <div className="dropzone">
        <label className="dropzone-container">
          <div className="file-icon">{imageIcon.icon}</div>
          <div className="dropzone-title">
            Drag and drop your Image or
            <span className="browse"> browse</span> your Image
            {hideText ? null : <span className="browse d-block mt-1">Select 4 Images</span>}
          </div>
        </label>
        <input
          ref={fileInputRef}
          type="file"
          accept={accept}
          multiple={isMultipal !== "1"}
          onChange={(e: any) => {
            const maxFileSizeMB = 5; // Maximum file size in MB
            const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;

            const files = e.target.files;
            const validFiles = [];

            for (let i = 0; i < files.length; i++) {
              if (files[i].size > maxFileSizeBytes) {
                alert(`File "${files[i].name}" is larger than ${maxFileSizeMB} MB.`);
                e.target.files = null;
                if (fileInputRef.current) {
                  fileInputRef.current.value = null
                }
                return;
              } else {
                validFiles.push(files[i]);
              }
            }

            imageUpload.handlefileChange(e); // Handle the valid files


            // imageUpload.handlefileChange(e)
            if (callback) {
              callback(e);
            }
          }}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default ImageUpload;
