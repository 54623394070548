import { useState } from "react";
import classnames from "classnames";
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useUpload } from "../masters/items/helper";
import PageLayout from "../../components/layout/page";

const BookingView = () => {
  const [activeTab, setActiveTab] = useState("1");
  const imageUpload = useUpload(1);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return <>
    <PageLayout
      View = {
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Booking
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Printing
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Mounting
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  Un-Mounting
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => {
                    toggle("5");
                  }}
                >
                  Receipt
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Card>
                    <div className="booking-view-head">
                      <div className="booking-name-box">
                        <div>
                          <h2>Jimmy doe</h2>
                          <p>GST No : <span>98745632105213</span></p>
                        </div>
                        <div>
                          <span className="date-box">Booking Start date : 27 Sep,2024</span>
                          <span className="printing-status-box">Printing : Include</span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </TabPane>
                <TabPane tabId="2"></TabPane>
                <TabPane tabId="3"></TabPane>
                <TabPane tabId="4"></TabPane>
                <TabPane tabId="5"></TabPane>
            </TabContent></>
        }
    />
  </>;
};
export default BookingView;
