import { Button, Col, Row } from "reactstrap";
import { Form, Formik } from "formik";
import PageLayout from "../../../components/layout/page";
import TextBox from "../../../components/common/textBox";
import Dropdown from "../../../components/common/dropdown";
import { columns } from "./columns";
import { useAddCustomeInfo } from "../../../helper/customeInfoHook";
import { validationMaterialSchema } from "./validation";

const AddUpdateMaterial = (props: any) => {
  const { api, value, closeAddUpdate }: any = props;
  return (
    <PageLayout
      View={
        <Formik
          validationSchema={validationMaterialSchema}
          initialValues={value}
          onSubmit={(values: any, actions: any) => {
            const value = {
              ...values,
              name: values?.name ?values.name:"",
              rate: values?.rate ?values.rate:"",
            };
            if (values.id) {
              api.updateValue(value).then((result: any) => {
                if (result?.status) {
                  closeAddUpdate();
                }
              });
            } else {
              api.create(value).then((result: any) => {
                if (result?.status) {
                  closeAddUpdate();
                }
              });
            }
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className="">
              <Row>
                <Col lg="6">
                  <TextBox
                    fieldName={"name"}
                    placeholder={"Enter name"}
                    className={"form-control"}
                    type={"text"}
                    isRequired={true}
                    labelName={"Name"}
                    value={value}
                  />
                </Col>
                <Col lg="6">
                  <TextBox
                    fieldName={"rate"}
                    placeholder={"Enter rate"}
                    className={"form-control"}
                    type={"number"}
                    isRequired={true}
                    labelName={"Rate PER SQ.FT "}
                    value={value}
                  />
                </Col>
              </Row>
              <Col lg="12" className="d-flex justify-content-end gap-2">
                <Button
                  color="secondary"
                  onClick={() => closeAddUpdate()}
                  type="button"
                  outline
                >
                  Cancel
                </Button>
                <Button color="secondary" type="submit">
                  {value?.id > 0 ? "Update" : "Submit"}
                </Button>
              </Col>
            </Form>
          )}
        </Formik>
      }
    />
  );
};
export { AddUpdateMaterial };
