import { DataType } from "ka-table";
import { TableColumn } from "../../components/table/table";
import { DELETE, UPDATE, VIEW } from "../../constant/commonConst";
import { confirmBox } from "../../components/toast";
import { ActionButton } from "../../components/buttons/actionButtons";
import {
  AssignUserDropDown,
  DateField,
  DetailsButton,
  LocationUrl,
  Status,
  StatusDropDown,
  UploadButton,
} from "./action";
import { DetailControl } from "../../components/buttons/detail";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    // {
    //   key: "id",
    //   // title: "show/Hide",
    //   dataType: DataType.String,
    //   component: DetailsButton,
    //   style: { width: "3%" },
    // },
    {
      key: "bookingNo",
      title: "booking Id",
      dataType: DataType.String,
    },
    {
      key: "businessName",
      title: "Client Name",
      dataType: DataType.String,
    },
    {
      key: "totalPosterQty",
      title: "Number of mounting",
      dataType: DataType.String,
    },

    {
      key: "status",
      title: "status",
      component: Status,
      dataType: DataType.String,
      style: { textAlign: "center" },
    },
    {
      key: "",
      title: "",
      //   component: StatusDropDown,
      component: DetailControl,
      callBack: async (row) => {
        if (row.status === VIEW) {
          actions.handleViewData(row.data);
        }
      },
      style: {
        textAlign: 'center',
      }
    },

    // {
    //   key: "",
    //   title: "",
    //   component: ActionButton,
    //   callBack: async (row) => {
    //     if (row.status === UPDATE) {
    //       actions.openAddEditFrom({
    //         id: row.data.id,
    //         name: row.data.name,
    //         stateId: row.data.stateId,
    //       });
    //     } else if (row.status === DELETE) {
    //       const response = await confirmBox("you want to delete this city?");
    //       if (response) {
    //         actions.api.removeValue(row?.data?.id);
    //       }
    //     }
    //   },
    //   style: {
    //     textAlign: "center",
    //   },
    // },
  ];
};

export const innerColumns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "id",
      title: "Creative Image",
      dataType: DataType.String,
      component: UploadButton,
      callBack: async (data) => {
        actions.openUpload(data);
      },
      style: { width: "10.8vw" },
    },
    {
      key: "taskNo",
      title: "Task Number",
      dataType: DataType.String,
    },
    {
      key: "mediaItemName",
      title: "Media ID",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData.mediaItem?.name;
      },
    },
    {
      key: "posterHeight",
      title: "Media Size",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData.mediaItem?.width + "*" + rowData.mediaItem?.height;
      },
    },
    {
      key: "mediaItem",
      title: "Media QTY",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData.mediaItem?.qty;
      },
    },
    {
      key: "locationurl",
      title: "Media Location link",
      dataType: DataType.String,
      component: LocationUrl,
    },
    {
      key: "fromDate",
      title: "Mounting Date & Time",
      dataType: DataType.String,
      component: DateField, // this is datepicker column
      style: { width: "10vw" },
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.api.mutate();
        }
      },
     
    },

    // {
    //     key: "creativeUrl",
    //     title: "Location detail",
    //     dataType: DataType.String,
    // },
    {
      key: "Assignee",
      title: "Assignee",
      component: AssignUserDropDown,
      dataType: DataType.String, //here selection box for select a user
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.api.mutate();
        }
      },
      style: { width: "8.5vw" },
    },


    // {
    //     key: "deliveryDate",
    //     title: "Delivery Date",
    //     dataType: DataType.String,
    // },
    // {
    //     key: "material",
    //     title: "material",
    //     dataType: DataType.String,
    // },

    {
      key: "status",
      title: "status",
      dataType: DataType.String,
      component: StatusDropDown,
      style: { textAlign: "center", width: "9vw" },
      //   component: DetailControl,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.api.status({
            id: row.rowData.id,
            status: row.data,
            bookingId: row.rowData.bookingId,
          });
          actions.apiPrinting.mutate();
        }
      },
    },
    // {
    //   key: "view",
    //   title: "",
    //   //   component: StatusDropDown,
    //   component: DetailControl,
    //   callBack: async (row) => {
    //     if (row.status === VIEW) {
    //       actions.openAddEditFrom(row.data);
    //     }
    //   },
    //   style: {
    //     textAlign: 'center',
    //   }
    // },
    {
      key: "action",
      title: "",
      //   component: StatusDropDown,
      component: DetailControl,
      callBack: async (row) => {
        if (row.status === VIEW) {
          actions.openView(row.data);
        }
      },
      style: {
        textAlign: 'center',
      }
    },
  ];
};
