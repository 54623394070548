import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Define types for context
interface AddUpdateContextType {
  viewAddUpdate: boolean;
  currentValue: any;
  openAddEditFrom: (value: any) => void;
  closeAddEditFrom: () => void;
}

// Create context with initial values
const AddUpdateContext = createContext<AddUpdateContextType | undefined>(undefined);

// Custom hook to use the context
export const useAddUpdate = () => {
  const context = useContext(AddUpdateContext);
  if (!context) {
    throw new Error("useAddUpdate must be used within an AddUpdateProvider");
  }
  return context;
};

// Provider component
export const LebalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [viewAddUpdate, setViewAddUpdate] = useState(false);
  const [currentValue, setCurrentValue] = useState<any>(null);
  const location = useLocation()

  const openAddEditFrom = (value: any) => {
    setCurrentValue(value);
    setViewAddUpdate(true);
  };

  const closeAddEditFrom = () => {
    setCurrentValue(null);
    setViewAddUpdate(false);
  };
  
  const contextValue: AddUpdateContextType = {
    viewAddUpdate,
    currentValue,
    openAddEditFrom,
    closeAddEditFrom,
  };
  useEffect(() => {
    closeAddEditFrom()
}, [location.pathname])
  return (
    <AddUpdateContext.Provider value={contextValue}>
      {children}
    </AddUpdateContext.Provider>
  );
};
