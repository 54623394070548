import React, { ReactNode } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface ModalViewProps {
    modalView: Boolean;
    customClass?: string;
    modalName: any;
    modalsize?: string;
    onModalDismiss: () => void;
    children?: ReactNode;
    customClassBody?:string;
}

const ModalView: React.FC<ModalViewProps> = (props) => {
    const { modalView, customClass, modalsize, modalName, onModalDismiss, children,customClassBody } = props;
    return (
        <Modal
            isOpen={modalView as boolean}
            className={customClass}
            // toggle={onModalDismiss}
            centered
            size={modalsize}
            scrollable={true}
        >
            <ModalHeader toggle={onModalDismiss}>
                {modalName}
            </ModalHeader>
            <ModalBody className={customClassBody}>{children}</ModalBody>
        </Modal>
    );
};

export default ModalView;
