import React, { useEffect, useState } from "react";
import { Badge, Button, Col, FormGroup, Label, Row } from "reactstrap";
import { extractLocationInfo } from "../../location/helper";
import TextBox from "../../../../components/common/textBox";
import { viewIcon } from "../../../../constant/icons";
import { GOOGLE_MAPS_API_KEY } from "../../../auth/constant";

export default function EndLatLon({
  setFieldValue,
  selectedLocation,
  values,
  id,
  autoCompleteRef,
  setQuery,
  query,
  fieldName,
  disabled,
}: any) {
  const [view,setView]=useState(true)
  useEffect(() => {
    if (selectedLocation) {
      const lat = selectedLocation.geometry?.location?.lat() || 0;
      const lng = selectedLocation.geometry?.location?.lng() || 0;

      const { city, state, zone } = extractLocationInfo(
        selectedLocation.address_components
      );
      setFieldValue("cityId", city);
      setFieldValue("stateId", state);
      setFieldValue("endLatitude", lat);
      setFieldValue("endLongitude", lng);
      setFieldValue("endLandmarkDescription", selectedLocation.vicinity);
    }
  }, [selectedLocation]);
  const searchLocation = (latitude: string, longitude: string) => {
    setView(false)
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
  
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.results) && data.results.length > 0) {
          const formattedAddress = data.results[0].formatted_address;
      
  
          const addressComponents = data.results[0].address_components;
  
          // Find the city (locality)
          const cityComponent = addressComponents.find((component: any) =>
            component.types.includes('locality')
          );
          
          // Find the state (administrative_area_level_1)
          const stateComponent = addressComponents.find((component: any) =>
            component.types.includes('administrative_area_level_1')
          );
  
          // Extract long_name or short_name from the city and state if they exist
          const cityName = cityComponent ? cityComponent.long_name : '';
          const stateName = stateComponent ? stateComponent.long_name : '';
          setFieldValue("cityId", cityName);
          setFieldValue("stateId", stateName);
          setFieldValue("endLandmarkDescription", formattedAddress);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  return (
    <>
      <Row id={`row${id}`}>
        <Col md={12}>
          <FormGroup id={`group${id}`}>
            <Col md={12}>
              <Label>End Latitude - Longitude</Label>
              <div className="d-flex gap-2 align-items-start">
                <Row>
                  <Col>
                    <TextBox
                      fieldName={"endLatitude"}
                      placeholder={"End Latitude"}
                      className={"form-control"}
                      type={"number"}
                      // labelName={"Route Id"}
                      value={values}
                      // isRequired={true}
                      // isDisabled={props?.viewDisabled}
                    />
                  </Col>
                  <Col>
                    <TextBox
                      fieldName={"endLongitude"}
                      placeholder={"End Longitude"}
                      className={"form-control"}
                      type={"number"}
                      // labelName={"Route Id"}
                      value={values}
                      // isRequired={true}
                      // isDisabled={props?.viewDisabled}
                    />
                  </Col>
                </Row>
                {values.endLatitude && values.endLongitude && (
                  <Button
                    type="button"
                    onClick={() => {
                      searchLocation(
                        values.endLatitude,
                        values.endLongitude
                      );
                    }}
                  >
                    {viewIcon.icon}
                  </Button>
                )}
              </div>
            </Col>
            {/* <SearchLocationInput
              id={`location${id}`}
              callback={(props: any) => {
                const lat = props.geometry?.location?.lat() || 0;
                const lng = props.geometry?.location?.lng() || 0;
                setFieldValue("endLatitude", lat);
                setFieldValue("endLongitude", lng);
              }}
              autoCompleteRef={autoCompleteEndRef}
              setSelectedLocation={setSelectedLocation}
            /> */}
           {view&&<div className="search-location-input">
              <label className="form-label">{fieldName}</label>
              <input
                ref={autoCompleteRef}
                id={fieldName || "name"}
                name={fieldName || "name"}
                className="form-control"
                onChange={(event) => setQuery(event.target.value)}
                placeholder="Search Places ..."
                value={query}
                disabled={disabled}
              />
            </div>} 
          </FormGroup>
        </Col>
        {/* {values.endLatitude && (
          <Col md={12}>
            <FormGroup>
              <Label>End Latitude - Longitude</Label>
              <div className="lat-long-badge">
                {values.endLatitude && <Badge>{values.endLatitude}</Badge>}
                {values.endLongitude && <Badge>{values.endLongitude}</Badge>}
              </div>
            </FormGroup>
          </Col>
        )} */}
      </Row>
    </>
  );
}
