import { Button } from "reactstrap";
import { columns } from "./columns";
import { useEffect } from "react";
import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import PageLayout from "../../components/layout/page";
import { plus } from "../../constant/icons";
import { DataView } from "../../components/table/table";
import { AddUpdateBooking } from "./addUpdate";
import { useNavigate, useSearchParams } from "react-router-dom";

export const BookingAPI = () => {
  return { ...useApi("/booking", true) };
};

export default function Booking(props: any) {
  const api = BookingAPI();
  useEffect(() => {
    api.mutate();
  }, []);
  const navigate = useNavigate();
  const [searchparams,setSearchParams] = useSearchParams();
  const AddUpdate = useAddUpdate();
  const queryId  = searchparams.get("id")

  useEffect(()=>{
    if(queryId && api?.data?.length > 0){
      const findIdExist = api.data && api.data.length > 0 && api.data.find((element:any)=>element?.id == queryId);
      if(findIdExist){
        AddUpdate.openAddEditFrom({...findIdExist});
        searchparams.delete("id")
        setSearchParams(searchparams)
      }
    }
  },[queryId,api.data])
  return AddUpdate.viewAddUpdate ? (
    <AddUpdateBooking
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      value={AddUpdate.currentValue}
    />
  ) : (
    <>
      <PageLayout
        item={
          <Button
            color="secondary"
            hidden={props.isBookingReport}
            onClick={() =>
              AddUpdate.openAddEditFrom({})
            }
          >
            {plus.icon}Add New
          </Button>
        }
        View={
          <>
            <DataView
              columns={columns({
                api,
                openAddEditFrom: AddUpdate.openAddEditFrom,
                view: navigate,
                isBookingReport: props.isBookingReport,
              })}
              rows={api.data}
              currentPage={api.currentPage}
              rowCount={api.rowCount}
              limit={api.limit}
              updatePagination={api.updatePagination}
            />
          </>
        }
      />
    </>
  );
}
