import * as Yup from 'yup';
import { INVALID_EMAIL, PASSWORD_MIN_LENGTH, MOBILE_INVALID, USERNAME_REQUIRED, NAME_REQUIRED, EMAIL_REQUIRED, MOBILE_REQUIRED, ROLE_REQUIRED, PASSWORD_REQUIRED } from '../../constant/message';

export const validationSchema = (isUpdate:boolean)=>{
  return Yup.object().shape({
  name: Yup.string().required(NAME_REQUIRED),
  userName: Yup.string().required(USERNAME_REQUIRED),
  emailId: Yup.string().email(INVALID_EMAIL).required(EMAIL_REQUIRED),
  mobileNo: Yup.string()
    .matches(/^[0-9]{10}$/, MOBILE_INVALID)
    .required(MOBILE_REQUIRED),
  roleId: Yup.string().required(ROLE_REQUIRED),
  password: isUpdate ? Yup.string().optional().min(6, PASSWORD_MIN_LENGTH):Yup.string().min(6, PASSWORD_MIN_LENGTH).required(PASSWORD_REQUIRED),
})
}