import { useEffect, useState } from "react";
import { CityApi } from "../city";
import { StateApi } from "../state";

export function extractLocationInfo(addressComponents: any) {
    let city = '';
    let state = '';
    let zone = '';

    addressComponents.forEach((component: any) => {
        if (component.types.includes('locality')) {
            city = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
        } else if (component.types.includes('sublocality')) {
            zone = component.long_name;
        }
    });

    return { city, state, zone };
}
export const useHelper = (props: any) => {
    const [SelectedLocation, setSelectedLocation] = useState<any>(props?.SelectedLocationView);
    const [location, setLocation] = useState<any>();
    const { api, value, closeAddUpdate } = props;
    useEffect(() => {
        if (value&&!props?.viewMode) {
            setLocation(value);
        }
    }, [value]);

    useEffect(() => {
        if(props?.SelectedLocationView){
            const lat = SelectedLocation?.lat || 0;
            const lng = SelectedLocation?.lng || 0;
            const { city, state, zone } = extractLocationInfo(
                SelectedLocation.address_components
            );
            setLocation({
                name: SelectedLocation.name,
                latitude: lat,
                longitude: lng,
                city: city,
                state: state,
                zone: zone,
            });
        }else if (SelectedLocation) {
            const lat = SelectedLocation.geometry?.location?.lat() || 0;
            const lng = SelectedLocation.geometry?.location?.lng() || 0;
            const { city, state, zone } = extractLocationInfo(
                SelectedLocation.address_components
            );
            setLocation({
                name: SelectedLocation.name,
                latitude: lat,
                longitude: lng,
                city: city,
                state: state,
                zone: zone,
            });
        }
    }, [SelectedLocation]);
    const cityData = CityApi(false, {revalidateIfStale: true});
    const stateData = StateApi(false, {revalidateIfStale: true});
    const handleSubmit = async (values: any, actions: any) => {
        const state = stateData.data?.data?.find(
            (state: any) =>
                state.name?.toLowerCase() === location.state?.toLowerCase()
        );
        let cityId;

        // Check if city already exists
        if (cityData?.data?.data?.length > 0) {
            const foundCity = cityData.data.data.find(
                (city: any) =>
                    city.name.trim().toLowerCase() === location.city.toLowerCase()
            );
            if (foundCity) {
                cityId = foundCity.id;
            }
        }
        // If city doesn't exist, create it
        if (!cityId) {
            const createdId: any = await cityData.create({
                name: location.city,
                stateId: state?.id,
            });
            if (createdId?.data) {
                cityId = createdId.data;
            }
        }
        let value = {
            latitude: location.latitude || null,
            longitude: location.longitude || null,
            name: location.name || null,
            zoneId: typeof values.zoneId === "number" ? location.zone : null,
            cityId: cityId || null,
            description: values.description || null,
        };
        if (cityId) {
            if (values?.id) {
                api
                    .updateValue({ ...value, id: values.id })
                    .then((result: any) => {
                        
                        if (result?.status) {
                            closeAddUpdate();
                        }
                    });
            } else {
                api.create(value).then((result: any) => {
                    if (result?.status) {
                        closeAddUpdate(result?.data);
                    }
                });
            }
        }
    }
    return {handleSubmit,SelectedLocation, setSelectedLocation, location}
}