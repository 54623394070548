import { DataType } from "ka-table";
import { TableColumn } from "../../components/table/table";
import { DELETE, UPDATE, VIEW } from "../../constant/commonConst";
import { confirmBox } from "../../components/toast";
import { LinkClickable } from "../../components/buttons/actionButtons";
import {
  DetailsButton,
  ImageShow,
  PrintingActionButton,
  Status,
  StatusDropDown,
  UploadButton,
} from "./action";
import { DetailControl } from "../../components/buttons/detail";
import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    // {
    //   key: "id",
    //   // title: "show/Hide",
    //   dataType: DataType.String,
    //   component: DetailsButton,
    //   style: { width: "3%" },
    // },
    {
      key: "bookingNo",
      title: "booking Id",
      dataType: DataType.String,
    },
    {
      key: "businessName",
      title: "Client Name",
      dataType: DataType.String,
    },
    {
      key: "totalPosterQty",
      title: "Number of printing",
      dataType: DataType.String,
    },

    {
      key: "status",
      title: "status",
      dataType: DataType.String,
      component: Status,
      style: { textAlign: "center" },
    },
    {
      key: "",
      title: "",
      //   component: StatusDropDown,
      component: DetailControl,
      callBack: async (row) => {
        if (row.status === VIEW) {
          actions.openAddEditFrom(row.data);
        }
      },
    },
    // {
    //   key: "",
    //   title: "",
    //   component: PrintingActionButton,
    //   callBack: async (row: any) => {
    //     console.log("row====>", row);
    //     if (row.status === VIEW) {
    //     }
    //   },
    //   style: {
    //     textAlign: "center",
    //   },
    // },
  ];
};

export const innerColumns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "id",
      title: "",
      dataType: DataType.String,
      component: UploadButton,
      callBack: async (data) => {
        actions.openUpload(data);
      },
      style: { width: "10.8vw" },
    },
    {
      key: "taskNo",
      title: "Task No",
      dataType: DataType.String,
    },
    {
      key: "creativeUrl",
      title: "Creative Url",
      dataType: DataType.String,
      // component: ImageShow,
      component: LinkClickable,
    },
    {
      key: "posterQty",
      title: "poster Qty",
      dataType: DataType.String,
    },
    {
      key: "posterHeight",
      title: "Size (Sq.Ft)",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData.posterWidth + "*" + rowData.posterHeight;
      },
    },
    // {
    //     key: "deliveryDate",
    //     title: "Delivery Date",
    //     dataType: DataType.String,
    // },
    {
      key: "material",
      title: "material",
      dataType: DataType.String,
      valueConvert: (data: any, rowData: any) => {
        return rowData?.material?.name;
      },
    },
    {
      key: "status",
      title: "status",
      dataType: DataType.String,
      component: StatusDropDown,
      style: { textAlign: "center", width: "9vw" },
      //   component: DetailControl,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.api.status({
            id: row.rowData.id,
            status: row.data,
            bookingId: row.rowData.bookingId,
          });
          actions.apiPrinting.mutate();
        }
      },
    },
    {
      key: "",
      title: "",
      //   component: StatusDropDown,
      component: DetailControl,
      callBack: async (row) => {
        if (row.status === VIEW) {
          actions.openAddEditFrom(row.data);
        }
      },
      // callBack: async (row) => {
      //     if (row.status === UPDATE) {
      //         actions.openAddEditFrom({
      //             id: row.data.id,
      //             name: row.data.name,
      //             stateId: row.data.stateId,
      //         });
      //     } else if (row.status === DELETE) {
      //         const response = await confirmBox("you want to delete this city?");
      //         if (response) {
      //             actions.api.removeValue(row.data.id);
      //         }
      //     }
      // },
      style: {
        textAlign: "center",
      },
    },
  ];
};
