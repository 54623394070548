export const UPDATE ='update';
export const REMOVE ='remove';
export const VIEW ='view'
export const DUPLICATE ='duplicate'
export const SEND ='send'
export const GENRATE ='genrate';
export const Booked ='booked';
export const Download ='download';



// API  methods
export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const PATCH = 'patch';
export const DELETE ='delete';

export const GUJARAT_GST_CODE ='24';

export const SECRET_KEY="craftmediasecretkey"

export const ROLE={
    MOUNTINGUSER:"mountuser",
    PRINTINGUSER:"printinguser"
}