import { Button, Card, Col, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import moment from "moment";
import { crossIcon } from "../../constant/icons";
import { getFormattedDate } from "../../utils/dateUtils";

export const ReceiptFilter = (props: any) => {
    const {
        filterValues = {},
        setFilterValues,
        onCloseFilter,
        personList = [], // Assuming this is a list of objects with value and label properties
        agencylist =[],
        landlordlist=[]

    } = props;

    // Define static options for transaction types and payment modes
    const transactionOptions = [
        { value: 'receipt', label: 'Receipt' },
        { value: 'payment', label: 'Payment' },
    ];

    const paymentModeOptions = [
        { value: 'cash', label: 'Cash' },
        { value: 'bank', label: 'Bank' },
    ];

    // Formik's onSubmit handler
    const handleSubmit = (values: any) => {
        setFilterValues({
            ...values,
            fromDate: values.fromDate ? moment(values.fromDate).format('DD-MM-YYYY') : null,
            toDate: values.toDate ? moment(values.toDate).format('DD-MM-YYYY') : null,
        });
        onCloseFilter();
    };
    return (
        <Card className="filter-box">
            <div className="d-flex justify-content-between">
                <h3>Filters</h3>
                <Button
                    className="cancel_filter"
                    color="link"
                    onClick={onCloseFilter}
                >
                    {crossIcon.icon}
                </Button>
            </div>
            <Formik
                initialValues={{
                    personNames: filterValues.personNames || [],
                    transactionType: filterValues.transactionType || '',
                    paymentMode: filterValues.paymentMode || '',
                    landlord_id:filterValues?.landlord_id || [],
                    agency_id:filterValues?.agency_id || [],
                    fromDate: filterValues.fromDate ? moment(filterValues.fromDate,"DD-MM-YYYY").toDate() : null,
                    toDate: filterValues.toDate ? moment(filterValues.toDate,"DD-MM-YYYY").toDate() : null,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (
                    <Form className="form-row">
                        <Col md={3} className="mb-3">
                            <label htmlFor="personNames">Client Name</label>
                            <ReactSelect
                                name="personNames"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                    setFieldValue("personNames", selectedValues);
                                }}
                                isMulti={true}
                                options={personList}
                                value={personList.filter((option: any) =>
                                    values.personNames.includes(option.value)
                                )}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <label htmlFor="agency_id">Agency Name</label>
                            <ReactSelect
                                name="agency_id"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                    setFieldValue("agency_id", selectedValues);
                                }}
                                isMulti={true}
                                options={agencylist}
                                value={agencylist.filter((option: any) =>{
                                    return values?.agency_id.includes(option.value)
                                })}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <label htmlFor="landlord_id">LANDLORD Name </label>
                            <ReactSelect
                                name="landlord_id"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                    setFieldValue("landlord_id", selectedValues);
                                }}
                                isMulti={true}
                                options={landlordlist}
                                value={landlordlist.filter((option: any) =>
                                    values.landlord_id.includes(option.value)
                                )}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <label htmlFor="transactionType">Transaction Type</label>
                            <ReactSelect
                                name="transactionType"
                                onChange={(selectedOption: any) => {
                                    setFieldValue("transactionType", selectedOption ? selectedOption.value : '');
                                }}
                                isClearable
                                options={transactionOptions}
                                value={transactionOptions.find(option => option.value === values.transactionType)}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <label htmlFor="paymentMode">Cash / Bank</label>
                            <ReactSelect
                                name="paymentMode"
                                onChange={(selectedOption: any) => {
                                    setFieldValue("paymentMode", selectedOption ? selectedOption.value : '');
                                }}
                                isClearable
                                options={paymentModeOptions}
                                value={paymentModeOptions.find(option => option.value === values.paymentMode)}
                            />
                        </Col>
                        <Col md={3}>
                            <Label className="form-label">From Available Date</Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values.fromDate}
                                onChange={(selectedDate: any) => {
                                    setFieldValue("fromDate", selectedDate);
                                }}
                                // minDate={moment().toDate()}
                            />
                        </Col>
                        <Col md={3}>
                            <Label className="form-label">To Available Date</Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values?.toDate}
                                onChange={(selectedDate: any) => {
                                    setFieldValue("toDate", selectedDate);
                                }}
                                // minDate={moment().toDate()}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button type="submit" color="primary">Apply Filters</Button>
                        </Col>
                    </Form>
                )}
            </Formik>
        </Card>
    );
};
