import { Button, Card, Col, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import moment from "moment";
import { crossIcon } from "../../../constant/icons";
import { useApi } from "../../../helper/apiHook";

export const LeadReportFilter = (props: any) => {
    const {
        filterValues = {},
        setFilterValues,
        onCloseFilter } = props;

    // Define static options for transaction types and payment modes
    const statusOptions = [
        {
            label: "Open",
            value: "open",
            color: "warning",
        },
        {
            label: "Assigned",
            value: "assigned",
            color: "primary",
        },
        {
            label: "Close",
            value: "close",
            color: "success",
        },
    ];

    const api = useApi('/clients')
    const clientData = api.data?.data?.length > 0
        ? api.data.data.map((x: any) => ({
            label: x.businessName,
            value: x.id,
        }))
        : [];

    // Formik's onSubmit handler
    const handleSubmit = (values: any) => {
        setFilterValues({
            ...values,
            startDate: values?.startDate ? moment(values?.startDate).format('DD-MM-YYYY') : null,
            endDate: values?.endDate ? moment(values?.endDate).format('DD-MM-YYYY') : null,
        });
        onCloseFilter();
    };

    return (
        <Card className="filter-box">
            <div className="d-flex justify-content-between">
                <h3>Filters</h3>
                <Button
                    className="cancel_filter"
                    color="link"
                    onClick={onCloseFilter}
                >
                    {crossIcon.icon}
                </Button>
            </div>
            <Formik
                initialValues={{
                    clientId: filterValues.clientId || null,
                    status: filterValues.status || null,
                    startDate: filterValues.startDate ? moment(filterValues.startDate).toDate() : null,
                    endDate: filterValues.endDate ? moment(filterValues.endDate).toDate() : null,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (
                    <Form className="form-row">
                        <Col md={3} className="mb-3">
                            <label htmlFor="selectClient">Select Client</label>
                            <ReactSelect
                                name="clientId"
                                onChange={(selectedOption: any) => {
                                    setFieldValue("clientId", selectedOption ? selectedOption.value : null);
                                }}
                                options={clientData}
                                isClearable
                                value={clientData.filter((option: any) =>
                                    option.value === values.clientId
                                )}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <label htmlFor="status">Status</label>
                            <ReactSelect
                                name="status"
                                onChange={(selectedOption: any) => {
                                    setFieldValue("status", selectedOption ? selectedOption.value : null);
                                }}
                                isClearable

                                options={statusOptions}
                                value={statusOptions.find(option => option.value === values.status)}
                            />
                        </Col>

                        <Col md={3}>
                            <Label className="form-label">Start Date</Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values.startDate}
                                onChange={(selectedDate: any) => {
                                    setFieldValue("startDate", selectedDate);
                                }}
                                // minDate={moment().toDate()}
                            />
                        </Col>
                        <Col md={3}>
                            <Label className="form-label">To Available Date</Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values.endDate}
                                onChange={(selectedDate: any) => {
                                    setFieldValue("endDate", selectedDate);
                                }}
                                minDate={values?.startDate}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button type="submit" color="primary">Apply Filters</Button>
                        </Col>
                    </Form>
                )}
            </Formik>
        </Card>
    );
};
