import { useState } from 'react'
import { useApi } from '../../../helper/apiHook';
import { TabPane } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import UnMountingDashboard, { MountingCardShow } from '.';
import UsermobUnMountingDataView from '../../mobileView/components/UsermobUnMountingDataView';

const UnMountingMobileView = () => {
    const location = useLocation();
    const { activeBooking } = location.state || {};
    const [selectedBooking, setSelectedBooking] = useState(activeBooking);

    return (
        <UnMountingDashboard>
            {["waiting-for-creative", "mounting-pending", "mounted"].map((element, index) => {
                return <TabPane tabId={(index + 1).toString()}>
                    {selectedBooking ? (
                        <MountingCardShow activeBooking={selectedBooking} setActiveBooking={setSelectedBooking} filter={element} />
                    ) : (
                        <UsermobUnMountingDataView setActiveBooking={setSelectedBooking} />
                    )}
                </TabPane>
            })}
        </UnMountingDashboard>
    )
}

export default UnMountingMobileView