import moment from "moment";
export const getFormattedDate = (date: string | Date, format?: string) => {
  const formattedDate = moment(date).format(format ?? "DD/MM/YYYY");
  return formattedDate ? formattedDate : "";
};
export const getDate = (value: any) => {
  try {
    if (value != undefined && value != null) {
      return new Date(value);
    }
  } catch (error) {
    console.log("dateError", error);
  }
  return null;
};

export const getTime = (value: any) => {
  if (value) {
    const time = moment(value, ["HH:mm"]).format("hh:mm");
    return time ? time : "";
  } else {
    return null;
  }
};
