import { useState } from "react";
import { DataView } from "../../components/table/table";
import { innerColumns } from "./columns";
import ModalView from "../../components/modal";
// import { UploadImage } from "./addUpdate";
import { useApi } from "../../helper/apiHook";
import { ViewUnmounting, UploadImage } from "./addUpdate";
import { useAddUpdate } from "../../helper/addUpdateHook";

export const DetailsRow: React.FC<{ rowData: any;apiPrinting: any;setOpenDetailPrinting:any;openDetailPrinting:any }> = ({
  rowData,
  setOpenDetailPrinting,
  openDetailPrinting,
  apiPrinting,
}) => {
  const [uploadImage, setUploadImage] = useState(false);
  const handleShowUploadImage = () => setUploadImage(!uploadImage);
  const api = useApi(`/unmountings/${rowData.bookingId}`, true, {}, { revalidateIfStale: true })
  const AddUpdate = useAddUpdate();
  return (
    <div className="details-row mt-3 d-inline-block w-100">
      {/* <ModalView
          modalView={openDetailPrinting?.bookingId == rowData?.bookingId}
          modalsize="xl"
          customClass="custom-size-modal-table"
          customClassBody="custom-size-modal-body"
          modalName={"Booking Id"}
          onModalDismiss={() =>{
            setOpenDetailPrinting(null)
          }}
        > */}
        <DataView
          columns={innerColumns({ api, openUpload: handleShowUploadImage, openAddEditFrom: AddUpdate.openAddEditFrom ,apiPrinting})}
          detailsRow={(props: any) => <DetailsRow {...props} />}
          rows={api.data}
          currentPage={api.currentPage}
          rowCount={api.rowCount}
          limit={api.limit}
        />
        {/* </ModalView> */}
      {uploadImage && (
        <ModalView
          modalView={uploadImage}
          modalName={"Upload Image"}
          onModalDismiss={handleShowUploadImage}
        >
          <UploadImage
            uploadImage={uploadImage}
            onModalDismiss={handleShowUploadImage}
            apiPrinting={apiPrinting}
          />
        </ModalView>
      )}
      {AddUpdate.viewAddUpdate && (
        <ModalView
          modalView={AddUpdate.viewAddUpdate}
          modalName={"View Unmounting"}
          modalsize={"lg"}
          onModalDismiss={AddUpdate.closeAddEditFrom}
        >
          <ViewUnmounting
            closeAddUpdate={AddUpdate.closeAddEditFrom}
            api={api}
            value={AddUpdate.currentValue}
          />
        </ModalView>
      )}
    </div>
  );
};
