import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  clientId:Yup.number().required("Kindly select client")
});

export const validationSchemaForPptSettings = Yup.object().shape({
  frontImage: Yup.string().required("Front Image is Required"),
  lastImage: Yup.string().required("Last Image is Required"),
  // uploadedBy: Yup.string().required("Uploaded By is Required"),
  uploadedDate: Yup.string().required("Uploaded Date is Required"),
});
