import * as Yup from 'yup';
import * as type from '../../../constant/message';

export const clientSchema = Yup.object().shape({
  name: Yup.string().required(type.LANDLORD_REQUIRED),
  contactPerson: Yup.string().required(type.CONTACT_PERSON_REQUIRED),
  type:Yup.string().required(type.TYPE_REQUIRED),
  contactPersonEmail: Yup.string().email(type.EMAIL_INVALID).required(type.EMAIL_REQUIRED),
  contactPersonMobile: Yup.number()
    .typeError(type.MOBILE_INVALID) // Custom error message for invalid number
    .required(type.MOBILE_REQUIRED)
    .integer(type.MOBILE_INVALID) // Ensures the number is an integer
    .positive(type.MOBILE_INVALID) // Ensures the number is positive
    .min(1000000000, type.MOBILE_MIN_DIGITS) // Example minimum length check for a 10-digit number
    .max(9999999999, type.MOBILE_MAX_DIGITS), // Example maximum length check for a 10-digit number
  contactPersonDesignation: Yup.string().required(type.DESIGNATION_REQUIRED),

});