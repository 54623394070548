import * as Yup from "yup";
import * as type from "../../../constant/message";

export const clientSchema = Yup.object().shape({
  businessName: Yup.string().required(type.BUSINESS_NAME_REQUIRED),
  // contactPerson: Yup.string().required(type.CONTACT_PERSON_REQUIRED),
  contactPerson_emailId: Yup.string()
    .email(type.EMAIL_INVALID)
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      type.EMAIL_INVALID
    )
    .required(type.EMAIL_REQUIRED),
  contactPersonMobileNo: Yup.string()
    .required(type.MOBILE_REQUIRED)
    .matches(/^\d{10}$/, type.MOBILE_INVALID),
  // contactPersonDesignation: Yup.string().required(type.DESIGNATION_REQUIRED),
  // billingAddress: Yup.string().required(type.BILLING_ADDRESS_REQUIRED),
  GSTIN: Yup.string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/,
      type.GSTIN_INVALID
    )
    .notRequired(),
  PANNo: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, type.PAN_INVALID)
    .notRequired(),
  // businessType: Yup.string().required(type.BUSINESS_TYPE_REQUIRED),
  IndustryId: Yup.string().required(type.INDUSTRY_REQUIRED),
});
