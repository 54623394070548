import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import AddCellTable from "../../../components/table/addCellTable";
import { DataType } from "ka-table";
import { ActionButtons, AgencyID, City, DateView, InputCell, Location, MediaItem, StatusDropDown } from "../actions";
import TableExtent from "./tableextent";
import { invokeApi } from "../../../helper/api";
import { PATCH } from "../../../constant/commonConst";


export default function PoahTable({ mediaInventory, bookingStartDate, setFieldValue, isEditMode }: any) {
  const [isModalOpen, setIsModal] = useState({ open: false, data: null });
  const columns = [
    {
      key: "mediaItemId",
      title: "Media Item ID",
      style: { width: "15vw" },
      dataType: DataType.String,
      component: MediaItem,
      isDisabled: false,
    },
    {
      key: "city",
      title: "City",
      style: { width: "9vw" },
      dataType: DataType.String,
    },
    {
      key: "location",
      title: "Location",
      dataType: DataType.String,
      style: { width: "9vw" }
    },
    {
      key: "size",
      title: "Size",
      dataType: DataType.String,
      style: { width: "8vw" }
    },
    {
      key: "sqft", title: "SQ.FT", dataType: DataType.String,
      style: { width: "6vw" }

    },
    {
      key: "qty", title: "Qty", dataType: DataType.Number,
      component: InputCell,
      style: { width: "7vw" },
      isEditable: true
    },
    {
      key: "rate", title: "Purchase rate Per Month", dataType: DataType.Number,
      component: InputCell,
      style: { width: "9vw" },
      isEditable: true
    },
    {
      key: "rate", title: "Rate per month", dataType: DataType.Number,
      component: InputCell,
      style: { width: "9vw" },
      isEditable: true
    },
    {
      key: "startDate", title: "Start Date",
      dataType: DataType.Date,
      component: DateView,
      style: { width: "6vw" },
      showDatepicker: true
    },
    {
      key: "endDate", title: "End Date", dataType: DataType.Date,
      showDatepicker: true,
      component: DateView,
      style: { width: "6vw" }
    },
    { key: "agencyAmount", title: "Purchase Amount", dataType: DataType.String, style: { width: "7vw" } },
    { key: "amount", title: "Booking Amount", dataType: DataType.String, style: { width: "7vw" } },
    {
      key: "status",
      title: "status",
      hideHeaderFilter: false,
      visible: mediaInventory?.[0]?.id ? true : false,
      dataType: DataType.String,
      component: StatusDropDown,
      callBack: async (row: any) => {
        await invokeApi(PATCH, "/mediaItems", {
          id: parseInt(row.rowData.mediaItemId),
          status: row.data,
        })
      },
      style: {
        textAlign: "center",
        cursor: "pointer",
      },
    },
    {
      key: "addColumn",
      title: "",
      component: ActionButtons,
      style: { width: "5vw", textAlign: 'center' },
      isEditable: false,
      callBack: (value: any) => {
        setIsModal({
          open: true,
          data: value
        })
      },
    },
  ];
  const dbId = "POAH"
  return (
    <>
      <AccordionItem>
        <AccordionHeader targetId="POAH">
          PURCHASE OF SPACE FOR OUTDOOR ADVERTISEMENT HOARDING
        </AccordionHeader>
        <AccordionBody accordionId={dbId}>
          <AddCellTable
            bookingStartDate={bookingStartDate}
            groupCode={dbId}
            updateFieldValue={setFieldValue}
            columns={columns}
            setIsModal={setIsModal}
            rows={mediaInventory}
            isEditMode={isEditMode}
          />
        </AccordionBody>
      </AccordionItem>
      {isModalOpen?.open && <TableExtent isEditable columns={columns} dbId={dbId} setIsModal={setIsModal} isModal={isModalOpen} mediaInventory={mediaInventory} bookingStartDate={bookingStartDate} setFieldValue={setFieldValue} />}
    </>
  );
}
