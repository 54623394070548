import { useMemo, useState, useEffect } from "react";
import React from 'react'; 
import { MaterialApi } from "../../masters/material";
import Select from "react-select";
import { Badge } from 'reactstrap';
import { UPDATE } from "../../../constant/commonConst";

// Define props for MaterialFilter
interface MaterialFilterProps {
    selectedMaterials: string[];
    onChange: (materials: string[]) => void;
}

export const MaterialFilter: React.FC<MaterialFilterProps> = ({ selectedMaterials, onChange }) => {
    const apiData = MaterialApi(false, {
        revalidateIfStale: true
    });

    const apiDataList = useMemo(
        () =>
            apiData?.data?.data?.length > 0
                ? apiData.data.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }))
                : [],
        [apiData]
    );

    // Update state when the selectedMaterials prop changes
    useEffect(() => {
        setValue(selectedMaterials);
    }, [selectedMaterials]);

    const [value, setValue] = useState<string[]>(selectedMaterials);

    const handleChange = (selectedOptions: any) => {
        const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
        setValue(selectedValues);
        onChange(selectedValues); // Notify parent component
    };

    return (
        <Select
            menuPlacement="auto"
            menuPortalTarget={document.body}
            isMulti
            value={apiDataList.filter((option: any) => value.includes(option.value))}
            options={apiDataList}
            onChange={handleChange}
        />
    );
};

// Define the status options outside the component for better performance
const statusOptions = [
  {
    label: "Waiting for creative",
    value: "waiting-for-creative",
    color: "warning",
  },
  {
    label: "Creative received",
    value: "creative-received",
    color: "primary",
  },
  {
    label: "Printing done",
    value: "printing-done",
    color: "success",
  },
];

export const StatusFilterDropDown = (props: any) => {
  const { column, rowData } = props;

  // Handle the selection of a status option
  const handleSelect = (selectedOption: any) => {
    if (selectedOption) {
      column.callBack({ data: selectedOption.value, rowData, status: UPDATE });
    }
  };

  // Find the currently selected status option
  const selectedStatus = statusOptions.find(option => option.value === rowData.status);

  return (
    <Select
      options={statusOptions}
      value={selectedStatus ? { label: selectedStatus.label, value: selectedStatus.value } : null}
      onChange={handleSelect}
      className="status-select"
      isClearable
      formatOptionLabel={(option: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Badge color={option.color} className="me-2">{option.label}</Badge>
        </div>
      )}
    />
  );
};
