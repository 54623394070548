import { useEffect, useState } from "react";
import { DataType } from "ka-table";
import PageLayout from "../../components/layout/page";
import { DataView, TableColumn } from "../../components/table/table";
import { Button } from "reactstrap";
import { downloadIcon, filterIcon } from "../../constant/icons";
import { useApi } from "../../helper/apiHook";
import { getFormattedDate } from "../../utils/dateUtils";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import BusinessProposalFilter from "./BusinessProposalFilter";
import { GUJARAT_GST_CODE } from "../../constant/commonConst";
import moment from "moment";
import { ClientApi } from "../masters/client";
import ExcelJS from 'exceljs';

export const BusinessQuotationColumns = (actions: any): TableColumn[] => {
    return [
        {
            key: "QUOTATION ID",
            title: "QUOTATION ID",
            dataType: DataType.String,
        },
        {
            key: "DATE",
            title: "DATE",
            dataType: DataType.String,
        },
        {
            key: "Client Name",
            title: "Client Name",
            dataType: DataType.String,
        },
        {
            key: "Person Name",
            title: "Person Name",
            dataType: DataType.String,
        },
        {
            key: "Person Contact",
            title: "Person Contact",
            dataType: DataType.String,
        },
        {
            key: "SubTotal",
            title: "SUBTOTAL",
            dataType: DataType.String,
        },
        {
            key: "Tax",
            title: "TAX",
            dataType: DataType.String,
        },
        {
            key: "Total Amount",
            title: "TOTAL AMOUNT",
            dataType: DataType.String,
        },
    ];
};

export default function BusinessQuotationReport() {
    const [filter, setFilter] = useState<boolean>(false);
    const [filterValues, setFilterValues] = useState<any>({});
    const [rows, setRows] = useState<any[]>([]);
    const [filteredRows, setFilteredRows] = useState<any[]>([]);
    const api = useApi("/proposals", true, filterValues);
    const clientApi = ClientApi(false);
    // Fetch data initially or when filterValues change
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = api.data || [];

                const mappedData = Array.isArray(data) ? data.map((item: any) => {
                    const SubTotalAmount = item.mediaInventory.reduce((acc: any, items: any) => acc + (items.amount || 0), 0);
                    const taxAmount = SubTotalAmount * 0.18;
                    return {
                        "QUOTATION ID": item?.prososalNo,
                        "DATE": moment(item?.createDate).format("DD/MM/YYYY"),
                        "Client Name": item?.client?.businessName,
                        "Person Name": item?.contactPerson,
                        "Person Contact": item?.contactPersonMobileNo,
                        "SubTotal": SubTotalAmount,
                        "Tax": taxAmount.toFixed(2),
                        "Total Amount": (SubTotalAmount + taxAmount).toFixed(2)
                    }
                }) : [];

                setRows(mappedData);
                setFilteredRows(mappedData); // Initially set filtered rows as all data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [api.data]);

    // Apply filters to the rows
    // useEffect(() => {
    //     const applyFilters = () => {
    //         let filtered = [...rows];

    //         if (filterValues.personNames && filterValues.personNames.length > 0) {
    //             filtered = filtered.filter(row =>
    //                 filterValues.personNames.includes(row['Client Name'])
    //             );
    //         }
    //         if (filterValues.quotation_id) {
    //             filtered = filtered.filter(row =>
    //                 filterValues.quotation_id.includes(row['QUOTATION ID'])
    //             );
    //         }
    //         if (filterValues.fromDate) {
    //             filtered = filtered.filter(row =>{
    //                 const dateMoment = moment(row["DATE"], "DD/MM/YYYY");
    //                 const unixTimestamp = dateMoment.unix();
    //                 return  unixTimestamp >= moment(filterValues.fromDate).unix()
    //             });
    //         }
    //         if (filterValues.toDate) {
    //             filtered = filtered.filter(row =>{
    //                 const dateMoment = moment(row["DATE"], "DD/MM/YYYY");
    //                 const unixTimestamp = dateMoment.unix();
    //                 return unixTimestamp <= moment(filterValues.toDate).unix()
    //             });
    //         }
    //         setFilteredRows(filtered);
    //     };
    //     applyFilters();
    // }, [filterValues, rows]);

    const handleShowFilter = () => {
        setFilter(!filter);
    };

    // const exportToExcel = async () => {
    //     const today = moment().format('DD-MM-YYYY');
    //     const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    //     saveAs(blob, `businessquotation_${today}.xlsx`);
    // };
    const today = moment().format('DD-MM-YYYY');
    const exportToExcel = async () => {
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Receipt Report');

        // Define the headers based on the receiptColumns structure
        const headers = [
            { header: 'QUOTATION ID', key: 'QUOTATION ID', width: 15 },
            { header: 'DATE', key: 'DATE', width: 15 },
            { header: 'Client Name', key: 'Client Name', width: 30 },
            { header: 'Person Name', key: 'Person Name', width: 25 },
            { header: 'Person Contact', key: 'Person Contact', width: 20 },
            { header: 'SubTotal', key: 'SubTotal', width: 20 },
            { header: 'Tax', key: 'Tax', width: 15 },
            { header: 'Total Amount', key: 'Total Amount', width: 20 },
        ];

        // Add the headers to the worksheet
        worksheet.columns = headers;

        // Style the header row (optional)
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }, // Black background
            };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        // Assuming 'filteredRows' is the data you want to export
        const data = api.data.map((item:any) => {

            const SubTotalAmount = item.mediaInventory?.reduce((acc: any, items: any) => acc + (items.amount || 0), 0);
            const taxAmount = SubTotalAmount * 0.18;
            return {
                "QUOTATION ID": item?.prososalNo,
                "DATE": moment(item?.createDate).format("DD/MM/YYYY"),
                "Client Name": item?.client?.businessName,
                "Person Name": item?.contactPerson,
                "Person Contact": item?.contactPersonMobileNo,
                "SubTotal": SubTotalAmount,
                "Tax": taxAmount.toFixed(2),
                "Total Amount": (SubTotalAmount + taxAmount).toFixed(2)
            }
        });

        // Add the data rows
        data.forEach((item:any) => {
            worksheet.addRow(item);
        });

        // Generate the Excel buffer and trigger download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Receipt_Payment_Report_${today}.xlsx`);
    };

    // Extract unique person names for the filter dropdown
    const personList = Array.isArray(clientApi?.data?.data)
        ? clientApi?.data?.data
            .map((name: any) => ({ value: name?.id, label: name?.contactPerson }))
        : [];
    const quotation_id = Array.isArray(rows)
        ? rows
            .map((item: any) => item['QUOTATION ID'])
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(name => ({ value: name, label: name }))
        : [];

    return (
        <>
            <div className="d-flex justify-content-end align-items-end" style={{ paddingRight: '1rem' }}>
                <div className="head-btn-box">
                    <Button color="secondary" type="button" outline onClick={exportToExcel}>
                        {downloadIcon.icon}
                        Download
                    </Button>
                    <Button
                        color="secondary"
                        type="button"
                        outline
                        onClick={handleShowFilter}
                    >
                        {filterIcon.icon}
                        Filter
                    </Button>
                </div>
            </div>
            <PageLayout
                View={
                    <DataView
                        columns={BusinessQuotationColumns({ api: {} })}
                        rows={filteredRows}
                        currentPage={api.currentPage}
                        rowCount={api.rowCount}
                        limit={api.limit}
                    />
                }
            />
            {filter && (
                <BusinessProposalFilter
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    onCloseFilter={handleShowFilter}
                    personList={personList} // Pass personList to the filter component
                    quotation_id={quotation_id}
                />
            )}
        </>
    );
}