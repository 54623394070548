import PageLayout from "../../components/layout/page";
import LeadReportList from "./leadReport";

export default function LeadReport() {
  return (
    <PageLayout className="dashboard-inner-page"
      View={
        <>
          <LeadReportList />
        </>
      }
    />
  );
}