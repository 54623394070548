import React from "react";
import { DataView } from "../../../components/table/table";
import { bookingColumns } from "../columns";
export default function Booking({ rowData }: any) {
  const booking = rowData.data.data;

  return (
    <div>
      <DataView
        offPagination={true}
        offSearch={true}
        columns={bookingColumns({ booking })}
        rows={booking}
      />
    </div>
  );
}
