import React from 'react';
import { routeIcon } from '../../../../constant/icons';

const GoogleMapsLink = ({ startLat, startLng, endLat, endLng }:any) => {
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${startLat},${startLng}&destination=${endLat},${endLng}&travelmode=driving`;

  return (
    <a href={googleMapsUrl} target="_blank" className='route-link' rel="noopener noreferrer">
       {routeIcon.icon}
       {/* View Route on Google Maps */}
    </a>
  );
};

export default GoogleMapsLink;

export const GoogleMapsStartLink = ({ startLat,  endLng }:any) => {
  const googleMapsUrl = `https://www.google.com/maps?q=${startLat},${endLng}`;

  return (
    <a href={googleMapsUrl} target="_blank" className='route-link' rel="noopener noreferrer">
      {routeIcon.icon}
      {/* View Route on Google Maps */}
    </a>
  );
};

