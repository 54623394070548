import * as Yup from "yup";
import * as message from "../../../constant/message";

export const loginSchema = Yup.object().shape({
  email: Yup.string().email(message.INVALID_EMAIL).required(message.EMAIL),
});

export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, message.PASSWORD_MIN_LENGTH)
    .required(message.PASSWORD_REQUIRED),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), ""],
      message.CONFIRM_PASSWORD_MATCH_REQUIRED
    )
    .required(message.CONFIRM_PASSWORD_REQUIRED),
});
