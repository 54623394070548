import { Formik, Form } from "formik";
import React, { useEffect } from 'react'
import TextBox from '../../../components/common/textBox';
import { Button, Col } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { leftSquareArrow } from "../../../constant/icons";
import { useAuth } from "../../../components/auth";
import { getValueByKey } from "../../../utils/generalUtils";
import { invokeApi } from "../../../helper/api";
import { POST } from "../../../constant/commonConst";
import {  ResetPasswordSchema } from "./validation";

export default function ResetPassword() {
    const auth = useAuth();
    const navigate = useNavigate();
    const param= useParams();
    useEffect(() => {
      if(param?.id){
        verifyToken(param?.id)
      }else{
        navigate("/");
      }
    }, [param?.id]);
    const handleSubmit = async (values: any) => {
      try {
        const resetPassword = await invokeApi(POST, "/auth/reset-password", {
          newPassword:values?.newPassword,
          token:param?.id
        });
        if(resetPassword?.status){
          navigate("/")
        }
      } catch (error) {
        console.error("Login failed", error);
      }
    };
    const verifyToken=async(token:string)=>{
      const verfiyTokenResponse = await invokeApi(POST, "/auth/verifyToken", {token});
      if (!verfiyTokenResponse?.status) {
        navigate("/")
      }
    }

    return (
      <>
        <Formik
          initialValues={{
            newPassword:"",
            confirmPassword:""
          }}
          validationSchema={ResetPasswordSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form className="form-horizontal form-login row">
            <TextBox
                id={"newPassword"}
                fieldName={"newPassword"}
                placeholder={"Enter New Password"}
                className={"form-control"}
                type={"password"}
                labelName={"New Password"}
                //   value={props?.value}
              />
              <TextBox
                id={"confirmPassword"}
                fieldName={"confirmPassword"}
                placeholder={"Enter New Confirm Password"}
                className={"form-control"}
                type={"password"}
                labelName={"Confirm Password"}
                //   value={props?.value}
              />
              <Col lg="12">
                <Button color="primary" block className="w-100" type="submit">
                  {"Submit"}
                </Button>
              </Col>
              <Col lg="12" className="text-center">
                <Link to="/forgot" className="pt-3 back-signin mx-auto">
                  {leftSquareArrow.icon} Forgot Password
                </Link>
              </Col>
            </Form>
          )}
        </Formik>
      </>
    );
}

