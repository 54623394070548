import { Button, Col } from "reactstrap";
import { Form, Formik } from "formik";
import PageLayout from "../../../components/layout/page";
import { industrySchema } from "./validation";
import TextBox from "../../../components/common/textBox";

const AddUpdateIndustry = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  return (
    <Formik
      validationSchema={industrySchema}
      initialValues={value}
      onSubmit={(values: any, actions: any) => {
        if (values.id) {
          api.updateValue(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        } else {
          api.create(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {(props) => (
        <Form className="form-main">
          <TextBox
            fieldName={"name"}
            placeholder={"Enter series name"}
            className={"form-control"}
            type={"text"}
            isRequired={true}
            labelName={"Series name"}
            value={value}
          />
          <TextBox
            fieldName={"description"}
            placeholder={"Enter description"}
            className={"form-control"}
            type={"textarea"}
            rows="4"
            labelName={"Description"}
            value={value}
          />
           <Col lg="12" className="d-flex justify-content-end gap-2">
            <Button color="secondary" outline   type="button" onClick={() => closeAddUpdate()}>
              Cancel
            </Button>
            <Button color="secondary" solid type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
export { AddUpdateIndustry };
