import React from 'react'
import { Button, Card, Col, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import moment from "moment";
import { crossIcon } from "../../constant/icons";
import { getDate } from '../../utils/dateUtils';

const BusinessProposalFilter = (props:any) => {
    const {
        filterValues = {},
        setFilterValues,
        onCloseFilter,
        personList = [], // Assuming this is a list of objects with value and label properties,
        quotation_id=[]
    } = props;

    // Formik's onSubmit handler
    const handleSubmit = (values: any) => {
        setFilterValues({
            ...values,
            fromDate: values.fromDate ? moment(values.fromDate).format('DD-MM-YYYY') : null,
            toDate: values.toDate ? moment(values.toDate).format('DD-MM-YYYY') : null,
        });
        onCloseFilter();
    };
    return (
        <Card className="filter-box">
            <div className="d-flex justify-content-between">
                <h3>Filters</h3>
                <Button
                    className="cancel_filter"
                    color="link"
                    onClick={onCloseFilter}
                >
                    {crossIcon.icon}
                </Button>
            </div>
            <Formik
                initialValues={{
                    client_id: filterValues.client_id || [],
                    quotation_id:filterValues?.quotation_id || [],
                    fromDate: filterValues?.fromDate ? moment(filterValues?.fromDate,"DD-MM-YYYY").toDate() : null,
                    toDate: filterValues?.toDate ? moment(filterValues?.toDate,"DD-MM-YYYY").toDate() : null,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => {
                  return  <Form className="form-row">
                        <Col md={3} className="mb-3">
                            <label htmlFor="quotation_id">QUOTATION ID</label>
                            <ReactSelect
                                name="quotation_id"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                    setFieldValue("quotation_id", selectedValues);
                                }}
                                isMulti={true}
                                options={quotation_id}
                                value={quotation_id.filter((option: any) =>
                                    values.quotation_id.includes(option.value)
                                )}
                            />
                        </Col>
                        <Col md={3} className="mb-3">
                            <label htmlFor="client_id">Client Name</label>
                            <ReactSelect
                                name="client_id"
                                onChange={(selectedOptions: any) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                    setFieldValue("client_id", selectedValues);
                                }}
                                isMulti={true}
                                options={personList}
                                value={personList.filter((option: any) =>
                                    values.client_id.includes(option.value)
                                )}
                            />
                        </Col>
                        <Col md={3}>
                            <Label className="form-label">From Available Date</Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values?.fromDate}
                                onChange={(selectedDate: any) => {
                                    setFieldValue("fromDate", selectedDate);
                                }}
                                // minDate={moment().toDate()}
                            />
                        </Col>
                        <Col md={3}>
                            <Label className="form-label">To Available Date</Label>
                            <Field
                                component={DatePicker}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                menuPortalTarget={document.body}
                                selected={values?.toDate}
                                onChange={(selectedDate: any) => {
                                    setFieldValue("toDate", selectedDate);
                                }}
                                // minDate={moment().toDate()}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button type="submit" color="primary">Apply Filters</Button>
                        </Col>
                    </Form>
                }}
            </Formik>
        </Card>
    );
}

export default BusinessProposalFilter