import {
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { useState } from "react";
import { GENRATE } from "../../constant/commonConst";
import { genrateIcon } from "../../constant/icons";

const GenrateControl = (props: any) => {
  const { column, rowData } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>

      <Button
        color="link"
        onClick={() => {
          column.callBack({ data: rowData, status: GENRATE });
        }}
        className={`${column?.disabledGeneratebtn == rowData?.id ? "text-muted":"text-dark"}`}
        disabled={column?.disabledGeneratebtn == rowData?.id}
        id="Generate"
      >
        {genrateIcon.icon}
      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="Generate"
        toggle={toggleTooltip}
      >
        Generate
      </UncontrolledTooltip>
    </>
  );
};

export { GenrateControl };
