import { Button, Col, Row } from "reactstrap";
import { Form, Formik } from "formik";
import PageLayout from "../../../components/layout/page";
import { clientSchema } from "./validation";
import TextBox from "../../../components/common/textBox";
import Dropdown from "../../../components/common/dropdown";
import { typeList } from "./columns";
import { useAddCustomeInfo } from "../../../helper/customeInfoHook";

const AddUpdateLandlord = (props: any) => {
  const { api, value, closeAddUpdate }: any = props;
  const addInfo = useAddCustomeInfo(value);
  return (
        <Formik
          validationSchema={clientSchema}
          initialValues={value}
          onSubmit={(values: any, actions: any) => {
            const value = {
              ...values,
              customInfo: addInfo.customInfo,
            };
            if (values.id) {
              api.updateValue(value).then((result: any) => {
                if (result?.status) {
                  closeAddUpdate();
                }
              });
            } else {
              api.create(value).then((result: any) => {
                if (result?.status) {
                  closeAddUpdate(result?.data);
                }
              });
            }
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className="">
              <Row>
                <Col lg='6'>
                  <TextBox
                    fieldName={"name"}
                    placeholder={"Enter landlord name"}
                    className={"form-control"}
                    type={"text"}
                    labelName={"Landlord Business/Name"}
                    value={value}
                  />
                </Col>
                <Col lg='6'>
                  <Dropdown
                    fieldName="type"
                    labelName="Type"
                    options={typeList}
                    setFieldValue={setFieldValue}
                    values={values}
                    selectedValue={values.type}
                  />
                </Col>
                <Col>
                  <TextBox
                    fieldName={"contactPerson"}
                    placeholder={"Enter contact person name"}
                    className={"form-control"}
                    type={"text"}
                    labelName={"Contact Person Name"}
                    value={value}
                  />
                </Col>
                <Col>
                  <TextBox
                    fieldName={"contactPersonMobile"}
                    placeholder={"Enter contact person mobile number"}
                    className={"form-control"}
                    type={"text"}
                    labelName={"Contact Person Mobile No"}
                    value={value}
                  />
                </Col>
                <Col>
                  <TextBox
                    fieldName={"contactPersonEmail"}
                    placeholder={"name@example.com"}
                    className={"form-control"}
                    type={"email"}
                    labelName={"Contact Person Email"}
                    value={value}
                  />
                </Col>
                <Col>
                  <TextBox
                    fieldName={"contactPersonDesignation"}
                    placeholder={"Enter contact person designation"}
                    className={"form-control"}
                    type={"text"}
                    labelName={"Contact Person Designation"}
                    value={value}
                  />
                </Col>
                <Col lg={12}>
                  <TextBox
                    fieldName={"description"}
                    placeholder={"Enter description"}
                    className={"form-control"}
                    type={"textarea"}
                    rows="4"
                    labelName={"Description"}
                    value={value}
                  /></Col>
              </Row>
              {/* <div className="add-custom">
                <div className="d-flex gap-2 w-100 align-items-center justify-content-between mb-2 col">
                  <Label className="mt-2">
                    <b>Custom information </b>
                  </Label>{" "}
                  <Button type="button" color="primary" onClick={addInfo.addCustomInfo}>
                    Add Custom Info
                  </Button>
                </div>
                <Col lg="12">
                  {addInfo.customInfo.map((data, index) => (
                    <Row className="mb-2" key={index}>
                      <Col className="pe-1">
                        <Field
                          id={`key${index}`}
                          name="key"
                          placeholder="key"
                          className="form-control"
                          type="text"
                          value={data.key}
                          onChange={(e: any) =>
                            addInfo.handleChange(index, e.target)
                          }
                        />
                      </Col>
                      <Col className="ps-1">
                        <div className="d-flex gap-2 align-items-center">
                          <Field
                            id={`value${index}`}
                            name="value"
                            placeholder="Value"
                            className="form-control"
                            type="text"
                            value={data.value}
                            onChange={(e: any) =>
                              addInfo.handleChange(index, e.target)
                            }
                          />
                          <CloseButton
                            onClick={() => addInfo.deleteCustomInfo(index)}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </div> */}

              <Col lg="12" className="d-flex justify-content-end gap-2">
                <Button color="secondary" onClick={() => closeAddUpdate()}   type="button"  outline>
                  Cancel
                </Button>
                <Button color="secondary" type="submit">
                  {value?.id > 0 ? "Update" : "Submit"}
                </Button>
              </Col>
            </Form>
          )}
        </Formik>
  );
};
export { AddUpdateLandlord };
