import React, { useEffect } from "react";
import { loginSchema } from "./validation";
import { LoginFormValues } from "./type";
import { Formik, Form } from "formik";
import { useAuth } from "../../../components/auth";
import { useNavigate } from "react-router";
import { getValueByKey, setValueByKey } from "../../../utils/generalUtils";
import TextBox from "../../../components/common/textBox";
import { Link } from "react-router-dom";
import { Button, Col } from "reactstrap";

const initialValues: LoginFormValues = {
  email: "",
  password: "",
};

const LoginForm: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const token = getValueByKey("token");
  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, [token]);
  const handleSubmit = async (values: LoginFormValues) => {
    try {
      await auth.login(values);
    } catch (error) {
      navigate("/home")
      console.error("Login failed", error);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form className="form-horizontal form-login row">
            <TextBox
              id={"email"}
              fieldName={"email"}
              placeholder={"Enter your email"}
              className={"form-control"}
              type={"text"}
              labelName={"Email"}
              //   value={props?.value}
            />
            <TextBox
              id={"password"}
              fieldName={"password"}
              placeholder={"Enter your Password"}
              className={"form-control"}
              type={"password"}
              labelName={"password"}
              //   value={props?.value}
            />
            <Col lg="12" className="text-end mb-3">
              <Link to="/forgot" className="pt-0 d-inline-block mx-auto">
                Forgot Password
              </Link>
            </Col>
            <Col lg="12">
              <Button color="primary" block className="w-100" type="submit">
                {"Submit"}
              </Button>
            </Col>
            
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
