import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import TextBox from "../../components/common/textBox";
import Dropdown from "../../components/common/dropdown";
import { validationSchema } from "./validation";
import DatePicker from "react-datepicker";
import { crossIcon, file, LinkIcon } from "../../constant/icons";
import { VendorType } from "../../constant/menus";
import { getDate } from "../../utils/dateUtils";
import { useUpload } from "../masters/items/helper";
import { useEffect } from "react";
import ProtectedImage from "../../components/common/image";
import { LandlordApi } from "../masters/landlord";
import { AgencyApi } from "../masters/agency";

const AddUpdatePayment = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const imageUpload = useUpload(4);
  const landlordapi  = LandlordApi(true);
  const agencyapi  = AgencyApi(true);
  const landlordAPidata = landlordapi?.data?.length > 0
  ? landlordapi?.data.map((item: any) => {
      return { value: item.id, label: item.name };
    })
  : [];
  const agecnyApidata = agencyapi?.data?.length > 0
    ? agencyapi?.data.map((item: any) => {
        return { value: item.id, label: item.name };
      })
    : [];

      useEffect(() => {
        if (value.attachment) {
          const parseAttachement = value?.attachment ? JSON.parse(value?.attachment):null
          const setImagearray = parseAttachement && parseAttachement.length >0 && parseAttachement.map((element:any)=>{
            return {url:element?.url,path:element?.url,type:element.type,id:"undefined"}
          })
          imageUpload.setImagePreviews(setImagearray && setImagearray.length > 0 ?setImagearray:[]);
          imageUpload.setSelectedImages(setImagearray && setImagearray.length > 0 ?setImagearray:[])
        }

      }, [value]);
      const setAttachmentMethod=(index:number,setFieldValue:any,values:any)=>{
        const jsonparse = values.attachment ? JSON.parse(values.attachment):null;
        const deleteFileArray = values?.deletefiles && values?.deletefiles.length > 0 ? [...values.deletefiles]:[]
        const filterDeleteFile= jsonparse && jsonparse.length > 0 && jsonparse.find((ele:any,ind:any)=> ind == index)
        deleteFileArray.push(filterDeleteFile)
        const removeAttachment = jsonparse && jsonparse.length > 0 && jsonparse.filter((ele:any,ind:any)=> ind !== index)
        setFieldValue("attachment",JSON.stringify(removeAttachment))
        setFieldValue("deletefiles",deleteFileArray)
      }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        id:value?.id ?? "",
        vendor_type: value?.vendor_type ?? "agency",
        landlord_agency_id: value?.vendor_type == "agency" ? value?.agency_id : value?.landlord_id,
        personName:value?.personName ??"",
        remark :value?.remark ?? "",
        paymentDate: value?.paymentDate ? new Date(value?.paymentDate) : new Date(),
        amount: value?.amount ?? "",
        attachment:value?.attachment || null,
        deletefiles:[],
      }}
      onSubmit={(values: any, actions: any) => {
        values={
          ...values,
          agency_id:values?.vendor_type == "agency" ? values?.landlord_agency_id :"",
          landlord_id:values?.vendor_type == "landlord" ? values?.landlord_agency_id :"",
          amount:parseInt(values.amount),
          paymentDate: values?.paymentDate?.toISOString()
        }
        
        const formdata = new FormData();
          imageUpload.selectedImages.forEach((file, index) => {
            if (imageUpload.imagePreviews[index]?.id !== "undefined") {  
                formdata.append(`${imageUpload.imagePreviews[index].id}`, file);
             }
          });
          for (const key in values) {
            if (values[key] !== null && values[key] !== undefined) {
              if(key == "deletefiles"){
                formdata.append(key, JSON.stringify(values[key]));
              }else{
                formdata.append(key, values[key]);
              }
            }
          }
        if (value.id) {
          api.updateValue(formdata).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        } else {
          api.create(formdata).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {({ values, setFieldValue ,errors}) => {
       return <Form className="form-main receipt-form">
          <Card>
            <Row>
              <Col md={3}>
                <Dropdown
                  fieldName="vendor_type"
                  labelName="Vendor Type"
                  options={VendorType}
                  setFieldValue={setFieldValue}
                  values={values}
                  isRequired={true}
                  selectedValue={values.vendor_type}
                />
              </Col>
              <Col md={3}>
                <Dropdown
                  fieldName="landlord_agency_id"
                  labelName={values.vendor_type == "landlord"?"Landlord Name":"Agency Name"}
                  options={values.vendor_type == "landlord" ? landlordAPidata :agecnyApidata}
                  setFieldValue={setFieldValue}
                  values={values}
                  isRequired={true}
                  selectedValue={values.landlord_agency_id}
                />
              </Col>
              <Col md={3} className="mb-3">
                <TextBox
                  fieldName="personName"
                  placeholder="Enter Person Name"
                  className="form-control"
                  gorupClass="mb-0"
                  type="text"
                  isRequired={true}
                  labelName="Person name"
                  value={values.personName}
                />
              </Col>
              <Col md={3}>
                <FormGroup noMargin>
                  <Label className="form-label" htmlFor={"paymentDate"}>
                    Payment Date
                    <sup className="text-danger required">*</sup>
                  </Label>
                  <Field
                    component={DatePicker}
                    placeholderText={"DD/MM/YYYY"}
                    dateFormat={"dd/MM/yyyy"}
                    className="form-control"
                    selected={getDate(values?.paymentDate ? values?.paymentDate : new Date())}
                    name="paymentDate"
                    onChange={(date: any) => {
                      setFieldValue("paymentDate", date);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <TextBox
                  fieldName="amount"
                  placeholder="Amount"
                  gorupClass="mb-0"
                  isRequired={true}
                  className="form-control"
                  type="text"
                  labelName="Amount"
                  value={value.amount}
                />
              </Col>
              <Col md={3}>
                  <TextBox
                    fieldName={"remark"}
                    placeholder={"Enter Remark"}
                    className={"form-control"}
                    type={"textarea"}
                    rows="4"
                    labelName={"Remark"}
                    value={value}
                  />
              </Col>
            </Row>
          </Card>
          <Card>
            <div className="d-flex justify-content-between align-content-center">
              <h3>Payment Attachment</h3>
              <Button className="btn-attachment">
                {LinkIcon.icon} Add Attachment
                <input type="file" onChange={imageUpload.handlefileChange} />
              </Button>
            </div>
            <Row>
              <Col md="9">
                <div className="images-list">
                  {imageUpload?.imagePreviews &&
                    imageUpload?.imagePreviews.length > 0 &&
                    imageUpload.imagePreviews.map((element, index) => {
                      const isImage =
                        imageUpload.selectedImages.length > 0
                          ? imageUpload.selectedImages[index].type.startsWith(
                              "image/"
                            )
                          : false;
                        const filename = imageUpload.selectedImages.length > 0 ? imageUpload.selectedImages[index].name:""
                      return isImage ? (
                        <div className="images-item">
                          {
                            element?.path ? <ProtectedImage value={element?.path}  callBack={() =>{}} />: <img src={element?.url} alt={element?.id} />
                          }
                        
                          <button
                            className="remove-box"
                            onClick={() => {
                              imageUpload.handleRemoveImage(index)
                              setAttachmentMethod(index,setFieldValue,values)
                             
                            }}
                          >
                            {crossIcon.icon}
                          </button>
                        </div>
                      ) : (
                        <div className="images-item file-item">
                          <a
                            href="javascript:void(0);"
                            target="_blank"
                            rel="noopener noreferrer"
                            title=""
                            className="file-box"
                          >
                            <div className="icon-box">{file.icon}</div>
                            <p>{filename}</p>
                          </a>
                          <a
                            href="javascript:void(0);"
                            className="remove-box"
                            title="Remove"
                            onClick={() => {
                              imageUpload.handleRemoveImage(index)
                              setAttachmentMethod(index,setFieldValue,values)
                            }}
                          >
                            {crossIcon.icon}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </Card>
          <div className="d-flex justify-content-end gap-2">
            <Button
              color="secondary"
              outline
              type="button"
              onClick={() => closeAddUpdate()}
            >
              Cancel
            </Button>
            <Button color="secondary" solid type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </div>
        </Form>
      }}
    </Formik>
  );
};
export { AddUpdatePayment };
