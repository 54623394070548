import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { getFormattedDate } from "../../../utils/dateUtils";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "booking",
      title: "booking Id",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        const bookingNo = rowData?.booking?.bookingNo || 0;
        return `${bookingNo}`;
      },
    },
    {
      key: "taskNo",
      title: "Task No",
      dataType: DataType.String,
    },
    {
      key: "qty",
      title: "QTY",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        const qty = rowData?.mediaItem?.qty || 0;
        return `${qty}`;
      },
    },
    {
      key: "mediaItem",
      title: "Size",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        const height = rowData?.mediaItem?.height || 0;
        const width = rowData?.mediaItem?.width || 0;
        return `${height} * ${width}`;
      },
    },
    {
      key: "rateAgency",
      title: "Rate",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        const rateAgency = rowData?.mediaItem?.rateAgency || 0;
        return `${rateAgency}`;
      },
    },
    // {
    //   key: "assignee.name",
    //   title: "Assignee Name",
    //   dataType: DataType.String,
    // },
    {
      key: "unmountingDate",
      title: "Date",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.unmountingDate ? getFormattedDate(rowData.unmountingDate) : "N/A"
      }
    },
    {
      key: "status",
      title: "Un-Mounting Status",
      dataType: DataType.String,
    },
  ];
};