import React, { createContext, useContext, useState, ReactNode } from "react";
import { LoginFormValues } from "../pages/auth/login/type";
import { getValueByKey, setRole, setValueByKey } from "../utils/generalUtils";
import { useNavigate } from "react-router-dom";
import { ForgotValues } from "../pages/auth/forgot/type";
import { invokeApi } from "../helper/api";
import { POST, ROLE } from "../constant/commonConst";
import moment from "moment";

interface currentUser {
  userName: string;
  token: string;
  permissionToken: string;
}

interface AuthContextType {
  user: currentUser | null;
  login: (value: LoginFormValues) => Promise<void>;
  forgot: (value: ForgotValues) => Promise<void>;
  logOut: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
const getLoginUser = (userOb: any): any | null => {
  try {
    if (!userOb) {
      return null;
    }       
    return {
      userName: userOb.userName,
      token: userOb.authToken,
      role:userOb?.role,
      permissionToken: userOb.permissionToken,
    } as any;
  } catch { }
  return null;
};
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<currentUser|null>(
    getLoginUser(
      getValueByKey("token")
        ? JSON.parse(getValueByKey("token")!)
        : null
    )
  );

  const login = async (value: LoginFormValues) => {
    const loginToke = await invokeApi(POST, "/auth/login", value);
    if (loginToke?.status && loginToke?.data) {
      setValueByKey("token", JSON.stringify(loginToke.data.authToken))
      setValueByKey("userId", loginToke.data.id)
      setValueByKey("username", loginToke.data.userName)
      setValueByKey("role", loginToke?.data?.role?.code)
      setValueByKey("role", loginToke?.data?.role?.code)
      setUser(getLoginUser(loginToke.data));
      const encryptRoleObject={
        token:loginToke.data.authToken,
        role:loginToke?.data?.role?.code,
        createDate:moment().unix(),
      }
      setRole(encryptRoleObject);
      if(loginToke?.data?.role?.code == ROLE.MOUNTINGUSER){
        navigate("/mob-mounting")
      }else if(loginToke?.data?.role?.code == ROLE.PRINTINGUSER){
        navigate("/printing")
      }else{
        navigate("/home")
      }
    }
  };

  const forgot = async (value: ForgotValues) => {
    const loginToke = await invokeApi(POST, "/auth/forgot-password", value);

    if (loginToke?.status && loginToke?.data) {
      navigate("/")
    }
  };

  const logOut = () => {
    setUser(null);
    localStorage.clear()
    navigate("/")
  };

  return (
    <AuthContext.Provider value={{ user, login, forgot, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
