import {
  Button,
  FormGroup,
  Input,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import { dublicateIcon } from "../../constant/icons";
import { useState } from "react";

const DuplicateControl = (props: any) => {
  const { column, rowData } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>
   
      <Button
        color="link"
        onClick={() => {
          column.callBack({ data: rowData, status: "duplicate" });
        }}
        className="text-dark"
        id="duplicate"
      >
        {dublicateIcon.icon}
      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="duplicate"
        toggle={toggleTooltip}
      >
        Duplicate
      </UncontrolledTooltip>
    </>
  );
};

export { DuplicateControl };
