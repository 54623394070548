import Swal, { SweetAlertIcon } from "sweetalert2";
import "animate.css";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const ToastConfirmBox = Swal.mixin({
  showDenyButton: true,
  confirmButtonText: "Yes",
  denyButtonText: `No`,
  backdrop: true,
  allowOutsideClick: false,
  allowEscapeKey: false,
});

const confirmBox = async (message: string, title: string = "Are you sure?") => {
  return await ToastConfirmBox.fire({
    title: title,
    text: message,
  }).then((result) => {
    return result.isConfirmed;
  });
};
const message = (
  type: SweetAlertIcon,
  message: string,
  isHtml: boolean = false
) => {
  Toast.fire({
    icon: type,
    title: isHtml ? undefined : message,
    html: isHtml ? message : undefined,
  });
};
const messageAlert = (message: string) => {
  Swal.fire({
    title: message,
    showClass: {
      popup: "animate__animated animate__tada",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
  });
};
const comingSoon = () => {
  Swal.fire({
    title: "This feature is coming soon",
    showClass: {
      popup: "animate__animated animate__tada",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
  });
};

const statusWithReson = (title: string, callBack?: any) => {
  return Swal.fire({
    title: title,
    input: "textarea",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    confirmButtonText: "Submit",
    showLoaderOnConfirm: true,
    preConfirm: async (inputValue) => {
      if (!inputValue) {
        Swal.showValidationMessage("Please provide a reason");
        return false;
      }
      try {
        await callBack(inputValue)
        return
      } catch (error: any) {
        Swal.showValidationMessage(error.message);
        return
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
    didOpen: () => {
      const titleElement = Swal.getTitle();
      if (titleElement) {
        titleElement.style.fontSize = "20px";
      }
    },
  }).then((result) => {
    if (result.isConfirmed) {
      return result.isConfirmed;
    }
  });
};

export { message, confirmBox, comingSoon, messageAlert, statusWithReson };
