import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { getFormattedDate } from "../../../utils/dateUtils";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "bookingNo",
      title: "booking Id",
      dataType: DataType.String,
    },
    {
      key: "taskNo",
      title: "Task No",
      dataType: DataType.String,
    },
    {
      key: "mediaItem",
      title: "QTY",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        const qty = rowData?.mediaItem?.qty || 0;
        return qty; // Displays the calculated size
      },
    },
    {
      key: "width",
      title: "Size",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        const height = rowData?.mediaItem?.height || 0;
        const width = rowData?.mediaItem?.width || 0;
        return `${height} * ${width}`; // Displays the calculated size
      },
    },
    {
      key: "rateAgency",
      title: "Rate",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return `${rowData?.mediaItem?.rateAgency}`; // Displays the calculated size
      },
    },
    // {
    //   key: "assignee.name",
    //   title: "Assignee Name",
    //   dataType: DataType.String,
    // },
    {
      key: "fromDate",
      title: "Date",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.fromDate ? getFormattedDate(rowData.fromDate) : "N/A"
      }
    },
    {
      key: "status",
      title: "Mounting Status",
      dataType: DataType.String,
    },
  ];
};