import {
  Button,
  FormGroup,
  Input,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import { sendIcon } from "../../constant/icons";
import { useState } from "react";
import { SEND } from "../../constant/commonConst";

const SendControl = (props: any) => {
  const { column, rowData } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>
   
      <Button
        color="link"
        onClick={() => {
          column.callBack({ data: rowData, status: SEND });
        }}
        disabled={column?.disabledemailSendingbtn == rowData?.id}
        className={`${column?.disabledemailSendingbtn == rowData?.id ? "text-muted":"text-dark"}`}
        id="Send"
      >
        {sendIcon.icon}
      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="Send"
        toggle={toggleTooltip}
      >
        Send
      </UncontrolledTooltip>
    </>
  );
};

export { SendControl };
