import { Badge, Button, Col, Form, Label, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import PageLayout from "../../components/layout/page";
import { plus, uploadIcon } from "../../constant/icons";
import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { AddUpdateProposal } from "./addUpdate";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/auth";
import { getValueByKey } from "../../utils/generalUtils";
import { jwtDecode } from "jwt-decode";

export const ProposalAPI = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/proposals", paging, undefined, dataLoad) };
};

export const PPtSettingsAPI = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/ppt-settings", paging, undefined, dataLoad) };
};

export default function BusinessProposal() {
  const api = ProposalAPI(true);
  const [generateId, setGenerateId] = useState(0);
  const [emailSending, setEmailSending] = useState(0);
  const [user, setUser] = useState<any>(null);
  useEffect(() => {
    api.mutate();
  }, []);
  const AddUpdate = useAddUpdate();
  const navigation = useNavigate();
  const auth = useAuth();
  useEffect(() => {
    const token = getValueByKey("token")
      ? JSON.parse(getValueByKey("token")!)
      : null;

    const decoded: any = token ? jwtDecode(token) : null;
    setUser({
      userId: decoded?.userId,
      name: decoded?.name,
    });
  }, [auth?.user?.token]);

  return AddUpdate.viewAddUpdate ? (
    <AddUpdateProposal
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      value={AddUpdate.currentValue}
    />
  ) : (
    <>
      <PageLayout
        item={
          <>
            <Button
              color="secondary"
              onClick={() =>
                AddUpdate.openAddEditFrom({
                  description: "",
                  leadId: null,
                  clientId: null,
                  contactPerson: "",
                  contactPersonEmailId: "",
                  contactPersonMobileNo: "",
                  contactPersonDesignation: "",
                  proposalDocUrl: "",
                  mediaDescription: [],
                  status: "",
                  parentProposalId: null,
                })
              }
            >
              {plus.icon}Add New
            </Button>
          </>
        }
        View={
          <>
            <DataView
              columns={columns({
                api,
                openAddEditFrom: AddUpdate.openAddEditFrom,
                generateId,
                cb1: (value: number) => {
                  setGenerateId(value);
                },
                sendCb: (value: number) => {
                  setEmailSending(value);
                },
                bookedCb: (value: number) => {
                  navigation(`/booking?id=${value}`);
                },
                emailSending,
              })}
              rows={api.data}
              updatePagination={api.updatePagination}
              currentPage={api.currentPage}
              rowCount={api.rowCount}
              limit={api.limit}
            />
          </>
        }
      />
    </>
  );
}
