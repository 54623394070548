import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { ActionButton } from "../../../components/buttons/actionButtons";
import { DELETE, UPDATE } from "../../../constant/commonConst";
import { confirmBox } from "../../../components/toast";
import { StatusChangeView } from "../../leadGeneration/action";
export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "name",
      title: "Name",
      dataType: DataType.String,
    },
    {
      key: "PANNo",
      title: "PAN No",
      dataType: DataType.String,
    },
    {
      key: "GSTINNo",
      title: "GST No",
      dataType: DataType.String,
    },
    {
      key: "websiteLink",
      title: "Website",
      dataType: DataType.String,
    },
    {
      key: "status",
      title: "status",
      hideHeaderFilter: false,
      dataType: DataType.String,
      component: StatusChangeView,
      callBack: async (row) => {
        const response = await confirmBox(
          "Do you want to change the status to close?"
        );
        if (response) {
          actions.api.status({id: row.id, status: row.status === "active" ?"inactive": "active"});
        }
      },
      style: {
        textAlign: "center",
        cursor: "pointer",
      },
    },
    {
      key: "edit",
      title: "",
      hideHeaderFilter: false,
      component: ActionButton,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            alias: row.data.alias,
            name: row.data.name,
            address: row.data.address || "",
            cityId: row.data.cityId || null,
            stateId: row.data.stateId || null,
            GSTINNo: row.data.GSTINNo || null,
            websiteLink: row.data.websiteLink || null,
            contactPerson: row.data.contactPerson || null,
            contactMobile: row.data.contactMobile || null,
            contactEmail: row.data.contactEmail || null,
            PANNo: row.data.PANNo || null,
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox("you want to delete this agency?");
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
