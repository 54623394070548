import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { ActionButton } from "../../../components/buttons/actionButtons";
import { DELETE, UPDATE } from "../../../constant/commonConst";
import { confirmBox } from "../../../components/toast";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "name",
      title: "Zone Id",
      dataType: DataType.String,
    }, 
    {
      key: "city.name",
      title: "City",
      dataType: DataType.String,
    },
    {
      key: "",
      title: "",
      component: ActionButton,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            name: row.data.name,
            cityId: row.data.cityId,
            description: row.data.description
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox("you want to delete this zone?");
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
