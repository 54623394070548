import PageLayout from "../../../components/layout/page";
import { DataView } from "../../../components/table/table";
import { useApi } from "../../../helper/apiHook";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import { AddUpdateClient } from "./addUpdate";
import { Button } from "reactstrap";
import { plus } from "../../../constant/icons";
import { columns } from "./columns";
import { useEffect } from "react";

export const ClientApi = (paging?:boolean, dataLoad: any = {}) => {
  return { ...useApi("/clients",paging, undefined, dataLoad) };
};

export default function Client() {
  const api = ClientApi(true);
  useEffect(() => {
    api.mutate();
  }, []);
  const AddUpdate = useAddUpdate();
  return AddUpdate.viewAddUpdate ? (
    <AddUpdateClient
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      value={AddUpdate.currentValue}
    />
  ) : (
    <PageLayout
      item={
        <>
        <Button
          color="secondary"
          // onClick={() => setIsModal(true) }
        >
          {plus.icon}Export Excel
        </Button> 
        <Button
          color="secondary"
          onClick={() =>
            AddUpdate.openAddEditFrom({
              businessName: "",
              contactPerson: "",
              contactPerson_emailId: "",
              contactPersonMobileNo: "",
              contactPersonDesignation: "",
              customInfo: [],
              billingAddress: "",
              GSTIN: "",
              // businessType: "",
              IndustryId: null,
            })
          }
        >
          {plus.icon}Add New
        </Button>
        </>
      }
      View={
        <DataView
          columns={columns({ api, openAddEditFrom: AddUpdate.openAddEditFrom })}
          rows={api.data}
          updatePagination={api.updatePagination}
          currentPage={api.currentPage}
          rowCount={api.rowCount}
          limit={api.limit}
        />
      }
    />
  );
}
