import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, FormGroup, Label, Row } from "reactstrap";
import TextBox from "../../../../components/common/textBox";
import { viewIcon } from "../../../../constant/icons";
import { GoogleMapsStartLink } from "./GoogleMapsLink ";
import { GOOGLE_MAPS_API_KEY } from "../../../auth/constant";

export default function StartLatLon({
  setFieldValue,
  values,
  id,
  autoCompleteRef,
  setQuery,
  query,
  selectedLocation,
  fieldName,
  disabled,
}: any) {
  const [view,setView]=useState(true)
  useEffect(() => {
    if (selectedLocation) {
      const lat = selectedLocation.geometry?.location?.lat() || 0;
      const lng = selectedLocation.geometry?.location?.lng() || 0;
      // setFieldValue("name", selectedLocation.name);
      setFieldValue("startLatitude", lat);
      setFieldValue("startLongitude", lng);
      setFieldValue("startLandmarkDescription", selectedLocation.vicinity);
    }
  }, [selectedLocation]);
  const searchLocation = (latitude: string, longitude: string) => {
    setView(false)
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.results) && data.results.length > 0) {
          setFieldValue(
            "startLandmarkDescription",
            data.results[0].formatted_address
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      <Row>
        <Col md={12}>
          <Label>Start Latitude - Longitude</Label>
          <div className="d-flex gap-2 align-items-start">
            <Row>
              <Col>
                <TextBox
                  fieldName={"startLatitude"}
                  placeholder={"Start Latitude"}
                  className={"form-control"}
                  type={"number"}
                  // labelName={"Route Id"}
                  value={values}
                  isRequired={true}
                  // isDisabled={props?.viewDisabled}
                />
              </Col>
              <Col>
                <TextBox
                  fieldName={"startLongitude"}
                  placeholder={"Start Longitude"}
                  className={"form-control"}
                  type={"number"}
                  // labelName={"Route Id"}
                  value={values}
                  isRequired={true}
                  // isDisabled={props?.viewDisabled}
                />
              </Col>
            </Row>
            {values.startLatitude && values.startLongitude && (
              <Button
                type="button"
                onClick={() => {
                  searchLocation(values.startLatitude, values.startLongitude);
                }}
              >
                {viewIcon.icon}
              </Button>
            )}
          </div>
        </Col>
        {/* {values.startLatitude && (
          <Col md={12}>
            <FormGroup>
              <Label>Start Latitude - Longitude</Label>
              <div className="lat-long-badge">
                {values.startLatitude && <Badge>{values.startLatitude}</Badge>}
                {values.startLongitude && (
                  <Badge>{values.startLongitude}</Badge>
                )}
              </div>
            </FormGroup>
          </Col>
        )} */}
       {view&&<Col md={12}>
          <FormGroup>
            <div className="search-location-input">
              <label className="form-label">{fieldName}</label>
              <input
                ref={autoCompleteRef}
                id={fieldName || "name"}
                name={fieldName || "name"}
                className="form-control"
                onChange={(event) => setQuery(event.target.value)}
                placeholder="Search places ..."
                value={query}
                disabled={disabled}
              />
            </div>
            {/* <SearchLocationInput
                            id="location1"
                            fieldName={"Search Start Location"}
                            callback={(props: any) => {
                                const lat = props.geometry?.location?.lat() || 0;
                                const lng = props.geometry?.location?.lng() || 0;
                                setFieldValue("name", props.name);
                                setFieldValue("startLatitude", lat);
                                setFieldValue("startLongitude", lng);
                            }}
                            setSelectedLocation={setSelectedLocation}
                        /> */}
          </FormGroup>
        </Col>}
      </Row>
    </>
  );
}
