import { useState } from "react";
import { ActionType, DataType, Table, useTableInstance } from "ka-table";
import { ChildComponents } from "ka-table/models";
import { TableColumn } from "./table";
import PagingPages from "./pagingPages";
import { uuid } from "../../utils/generalUtils";
import { Button, Col, Row, UncontrolledTooltip} from "reactstrap";
import { extendbookingIcon, LocationIcon, plus } from "../../constant/icons";
import { useLocation } from "react-router-dom";
const GetComponents = (columnsList?: TableColumn[], updateFieldValue?: any, rowAllData?: any, bookingStartDate?: any): ChildComponents | undefined => {
  if (columnsList && columnsList.length > 0) {
    const componentList = columnsList.filter(
      (x) => x.component || x.valueConvert || x.hideHeaderFilter
    );
    if (componentList && componentList.length > 0) {
      return {
        cellText: {
          content: (props: any) => {
            const columnFind = componentList.filter((x: any) => x.key === props.column.key)[0];
            if (columnFind) {
              if (columnFind.component) {
                return <columnFind.component {...props}

                />;
              } else if (columnFind.valueConvert) {
                return columnFind.valueConvert(
                  props.rowData[props.column.key],
                  props.rowData
                );
              }
            }
          },
        },
        cell: {
          content: (props: any) => {
            const columnFind = componentList.filter(
              (x: any) => x.key === props.column.key
            )[0];
            if (columnFind) {
              if (columnFind.component) {
                return <columnFind.component {...props}
                  updateFieldValue={updateFieldValue}
                  rowAllData={rowAllData}
                  bookingStartDate={bookingStartDate}
                />;
              } else if (columnFind.valueConvert) {
                return columnFind.valueConvert(
                  props.rowData[props.column.key],
                  props.rowData
                );
              }
            }
          },
        },
      };
    }
  }
  return undefined;
};


export const AddButton = (props: any) => {
  const isAddNew = ((Object.keys(props.rows).length) === 0)
  const [openTooltipId, setOpenTooltipId] = useState<string | null>(null);

  // Toggle function for tooltips
  const toggleTooltip = (tooltipId: string) => {
    if (openTooltipId === tooltipId) {
      setOpenTooltipId(null); // Close the tooltip if it's already open
    } else {
      setOpenTooltipId(tooltipId); // Open the specific tooltip
    }
  };  const { setFieldValue, rows, groupCode, bookingStartDate } = props;
  const table = useTableInstance();
  const path = useLocation();
  return (
    <div className="booking-action">
      <Button
        color="link"
        onClick={() => {
          let newUpdateRow: any[] = [];
          if (Array.isArray(rows)) {
            newUpdateRow = rows;
          }
          let tempDate = new Date(bookingStartDate)
          tempDate.setDate(tempDate.getDate() + 30)
          newUpdateRow.unshift({
            tempRowId: uuid(),
            groupCode: groupCode,
            day: 30,
            amount: 0,
            startDate:new Date(bookingStartDate).toISOString(),
            endDate: tempDate.toISOString()
          })
          setFieldValue("mediaInventory", newUpdateRow)
        }}
        className="text-primary"
        id="Addbooking"
      >
        {plus.icon}
      </Button>
      <UncontrolledTooltip
        isOpen={openTooltipId === "Addbooking"}
        target="Addbooking"
        toggle={() => toggleTooltip("Addbooking")}
      >
        Add Booking
      </UncontrolledTooltip>
      {
        ["SOAH", "POAH", "SOAPK"].includes(groupCode) && props?.setIsModal && path?.pathname !== "/businessProposal" ?
          <>
            {!isAddNew && (
              <>
                <Button
                  color="link"
                  onClick={() => {
                    props?.setIsModal({ open: true })
                  }}
                  className="text-primary"
                  id="Location"
                >
                  {extendbookingIcon.icon}
                </Button>
                <UncontrolledTooltip
                  isOpen={openTooltipId === "Location"}
                  target="Location"
                  toggle={() => toggleTooltip("Location")}
                >
                  Extend booking
                </UncontrolledTooltip>
              </>
            )}
          </>
          : null
      }

      {/* {path?.pathname !== "/businessProposal" && 
     <>
      <Button 
        type="button"
        className="text-primary"
        id="extendbooking"
        color="link"
        >
        {extendbookingIcon.icon}
      </Button>
      <UncontrolledTooltip
          isOpen={openTooltipId === "Extendbooking"}
          target="extendbooking"
          toggle={() => toggleTooltip("Extendbooking")}
        >
          Extend booking
        </UncontrolledTooltip>
      </>} */}
    </div>
  );
};

const AddCellTable = (props: any) => {
  const {
    columns,
    rows,
    updateMediaInventory,
    currentPage,
    onSelectionChange,
    rowCount,
    limit,
    striped = false,
    isLoading = false,
    hideRowHeader = false,
    keyId = "tempRowId",
    removeMediaRow,
    addNewMediaRow,
    updateFieldValue,
    groupCode,
    bookingStartDate,
    setIsModal
  } = props;
  let tableComponents: ChildComponents = {
    headCell: {
      content: (props) => {
        if (props.column.key === "addColumn") {
          return <AddButton
            bookingStartDate={bookingStartDate}
            rows={rows}
            setFieldValue={updateFieldValue}
            groupCode={groupCode}
            setIsModal={setIsModal}
          />;
        }
      },
    },
  };
  const components = GetComponents(columns, updateFieldValue, rows, bookingStartDate);
  if (components) {
    tableComponents = { ...tableComponents, ...components };
  }
  return (
    <div id={uuid()} className="add-row-demo">
      <Table
        columns={columns}
        data={rows?.filter((x: any) => x.groupCode == groupCode )}
        rowKeyField={"tempRowId"}
        childComponents={tableComponents}
      />
    </div>
  );
};

export default AddCellTable;
