import React, { useEffect, useRef, useState } from "react";
import { GOOGLE_API } from "../pages/auth/constant";
import { LocationIcon } from "../constant/icons";

declare global {
  interface Window {
    google: any;
  }
}

let autoComplete: any;

export const loadScript = (url: string, callback: () => void) => {
  let script: any = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchLocationInput: React.FC<any> = ({
  setSelectedLocation,
  callback,
  fieldName,
  searchedData,
  setSearchedData,
}) => {
  const [query, setQuery] = useState<string>("");
  const autoCompleteRef = useRef<HTMLInputElement>(null);

  const handleScriptLoad = (updateQuery: React.Dispatch<React.SetStateAction<string>>,
    autoCompleteRef: React.RefObject<HTMLInputElement>,selectDefault?: boolean) => {
    if (autoCompleteRef.current) {
      if (selectDefault) {
        try {
          const autocompleteService =new window.google.maps.places.AutocompleteService();
          const request = {input: autoCompleteRef.current.value,componentRestrictions: { country: "IN" },};
          autocompleteService.getPlacePredictions(request,
            (predictions: any, status: any) => {
              if (
                status === window.google.maps.places.PlacesServiceStatus.OK &&
                predictions &&
                predictions.length > 0
              ) {
                const firstPrediction = predictions[0];
                const placesService =
                  new window.google.maps.places.PlacesService(
                    autoCompleteRef.current
                  );
                placesService.getDetails(
                  { placeId: firstPrediction.place_id },
                  (place: any, status: any) => {
                    if (status) {
                      if (callback) {
                        callback(place);
                      }
                      setSelectedLocation(place);
                    }
                  }
                );
              }
            }
          );
        } catch (error) {
          console.log(error, "382419382419382419");
        }
      } else {
        autoComplete = new window.google.maps.places.Autocomplete(
          autoCompleteRef.current,
          {
            componentRestrictions: { country: "IN" },
          }
        );
        autoComplete.addListener("place_changed", () => {
          handlePlaceSelect(updateQuery);
        });
      }
    }
  };

  const handlePlaceSelect = async (updateQuery: React.Dispatch<React.SetStateAction<string>>) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address || "";
    updateQuery(query);
    if (callback) {
      callback(addressObject);
    }
    setSelectedLocation(addressObject);
  };

  useEffect(() => {loadScript(GOOGLE_API, () => handleScriptLoad(setQuery, autoCompleteRef));}, []);
  useEffect(() => {
    if (searchedData) {
      setQuery(searchedData);
      loadScript(GOOGLE_API, () =>handleScriptLoad(setQuery, autoCompleteRef, true));
    }
  }, [searchedData]);
  return (
    <div className="search-location-input">
      <label>{fieldName}</label>
      <div>
        {LocationIcon.icon}
        <input
          ref={autoCompleteRef}
          id={fieldName || "name"}
          name={fieldName || "name"}
          className="form-control"
          onChange={(event) => {
            setQuery(event.target.value)        
          }}
          placeholder="Search Places ..."
          value={query}
        />
      </div>
    </div>
  );
};

export default SearchLocationInput;
