import creativeImage from '../../assets/images/login_bg_04.jpg'
import { Badge, Card, Col, Row } from "reactstrap";
import ProtectedImage from '../../components/common/image';
import { useEffect, useState } from 'react';
import { API_URL, invokeApi } from '../../helper/api';
import { GET } from '../../constant/commonConst';


const AddUpdatePrinting = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const creativeUrlParse  = value?.creativeUrl ? JSON.parse(value?.creativeUrl):null;
  const imageUpload = creativeUrlParse && creativeUrlParse.length > 0 && creativeUrlParse.find((ele:any)=>ele.tagname == "image")
  const cropUrl = creativeUrlParse && creativeUrlParse.length > 0 && creativeUrlParse.filter((ele:any)=>ele.tagname !== "image")
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    const fetchImage = async () => {
      if (imageUpload?.url) {
        try {
          const response = await invokeApi(
            GET,
            API_URL + imageUpload?.url,
            undefined,
            undefined,
            {
              responseType: "blob", // Ensure we get the response as a Blob
            }
          );
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageUrl(reader.result as string); // Set the Base64 string as image URL
          };
          reader.readAsDataURL(response); // Convert Blob to Base64
        } catch (error) {
          
        }
      }
    };

    fetchImage();
  }, [imageUpload?.url]);
  return (
    <div className="printing-view">
      <div className="booking-num">
        <p>Booking No:</p>
        <Badge color="black" className="badge-num">
          {value.bookingNo}
        </Badge>
      </div>
      <div>
        <Row className="mb-3">
          <Col>
          {
            imageUrl ? <a href="#">
              <img  className="view-image" src={imageUrl} alt="" />
          </a>:   <img  className="view-image" src={creativeImage} alt="" />
          } 
          </Col>


          <Col>

          <Card className="multiple-url">
            <div className="d-flex justify-content-between align-items-center">
                <h5>Creative URL:</h5>   
            </div>
              <ol className="creative-url">
                {
                  cropUrl && cropUrl.length > 0 && cropUrl.map((cropurls:any,index:any)=>{
                    return <li> 
                    <a href={`${cropurls.url}`} target="_blank">
                       {`Crop${index+1}`}
                    </a>                                          
                  </li>
                  })
                }
              </ol>                
          </Card> 

          </Col>
        </Row>
          <Row>
            <Col>
            <Card> 
              <ul className="poster-list">
              <li>
                  <div className="poster-list-item">
                    <span> Task No:</span>
                    <strong>
                    <Badge className="badges">
                      {value.taskNo}
                    </Badge>
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Poster Height:</span>
                    <strong>{value.posterHeight}</strong>
                  </div>
                </li>    
                <li>
                  <div className="poster-list-item">
                    <span>Poster Width:</span>
                    <strong>{value.posterWidth}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Poster Quantity:</span>
                    <strong>{value.posterQty}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span> Material:</span>
                    <strong>{value.material?.name ? value.material.name:"" }</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Status:</span>
                    <strong>
                    <Badge className="badge bg-success rounded-pill">
                    {value.status !== null ? value.status : "N/A"}
                    </Badge></strong>
                  </div>
                </li>
              
{/*           
                <li>
                  <div className="poster-list-item">
                    <span> Create By:</span>
                    <strong>{value.createBy}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span> Update By:</span>
                    <strong>{value.updateBy}</strong>
                  </div>
                </li>

                <li>
                  <div className="poster-list-item">
                    <span>Assign To:</span>
                    <strong>{value.assignTo !== null ? value.assignTo : "N/A"}</strong>
                  </div>
                </li> */}
                {/* <li>
                  <div className="poster-list-item">
                    <span>Delivery Date:</span>
                    <strong>{value.deliveryDate !== null ? value.deliveryDate : "N/A"}</strong>
                  </div>
                </li>   */}
                {/* <li>
                  <div className="poster-list-item">
                    <span> Create Date:</span>
                    <strong>{value.createDate}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Update Date:</span>
                    <strong>{value.updateDate}</strong>
                  </div>
                </li>   */}
            </ul>                
            </Card> 

            </Col>
          </Row>

      </div>
    <div>
 





  
    </div>
      
      

      {/* <ul>
        <li>
          <strong>Assign To:</strong>{" "}
          {value.assignTo !== null ? value.assignTo : "N/A"}
        </li>
        <li>
          <strong>Booking No:</strong> {value.bookingNo}
        </li>
        <li>
          <strong>Create By:</strong> {value.createBy}
        </li>
        <li>
          <strong>Create Date:</strong> {value.createDate}
        </li>
        <li>
          <strong>Creative URL:</strong>{" "}
          {value.creativeUrl !== null && value.creativeUrl.length > 0
            ? JSON.parse(value.creativeUrl).map((url:any, index:any) => (
                <img
                  key={index}
                  src={url}
                  alt={`Creative Preview ${index + 1}`}
                  style={{
                    width: "50px",
                    height: "50px",
                    marginRight: "10px",
                  }}
                />
              ))
            : "N/A"}
        </li>
        <li>
          <strong>Delivery Date:</strong>{" "}
          {value.deliveryDate !== null ? value.deliveryDate : "N/A"}
        </li>

        <li>
          <strong>Material:</strong> {value.material?.name ? value.material.name:"" }
        </li>
        <li>
          <strong>Poster Height:</strong> {value.posterHeight}
        </li>
        <li>
          <strong>Poster Quantity:</strong> {value.posterQty}
        </li>
        <li>
          <strong>Poster Width:</strong> {value.posterWidth}
        </li>
        <li>
          <strong>Status:</strong>{" "}
          {value.status !== null ? value.status : "N/A"}
        </li>
        <li>
          <strong>Task No:</strong> {value.taskNo}
        </li>
        <li>
          <strong>Update By:</strong> {value.updateBy}
        </li>
        <li>
          <strong>Update Date:</strong> {value.updateDate}
        </li>
      </ul> */}
    </div>
  );
};
export { AddUpdatePrinting };
