import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { useEffect, useState } from "react";
import PageLayout from "../../components/layout/page";
import { columns } from "./columns";
import { DetailsRow } from "./details";
import { DataView } from "../../components/table/table";
import { useOutletContext } from "react-router-dom";

export const MountingApi = (paging?: boolean,  dataLoad: any = {}) => {
  return { ...useApi("/mountings", paging, dataLoad) };
};
export default function Mounting() {
  const api = MountingApi(true);
  const AddUpdate = useAddUpdate();
  const [openDetailPrinting, setOpenDetailPrinting] = useState<any>(null);
  const back: any = useOutletContext();
  useEffect(() => {
    api.mutate();
    back.setBackButton(false)
  }, []);
  useEffect(() => {
    if (back.backButton === null) {
      setOpenDetailPrinting(null);
    }
  }, [back.backButton])
  const handleViewData = (extendedEvent: any) => {
    back.setBackButton(true)
    setOpenDetailPrinting(extendedEvent);
  }
  return (
    <>
      <PageLayout
        item={
          openDetailPrinting?.bookingNo && (
            <span className="booking-id">
              {openDetailPrinting?.bookingNo
                ? openDetailPrinting?.bookingNo
                : ""}
            </span>
          )
        }
        // item={
        //   <Button
        //     color="secondary"
        //     onClick={() =>
        //       AddUpdate.openAddEditFrom({
        //         posterQty: null,
        //         creativeUrl: null,
        //         posterWidth: null,
        //         posterHeight: null,
        //         assignTo: null,
        //         status: "pending",
        //       })
        //     }
        //   >
        //     {plus.icon}Add New
        //   </Button>
        // }
        className={"booking-master-page"}
        View={
          openDetailPrinting?.bookingId ? (
            <div className="mount-row">
              <DetailsRow
                rowData={openDetailPrinting}
                apiPrinting={api}
                setOpenDetailPrinting={setOpenDetailPrinting}
                openDetailPrinting={openDetailPrinting}
              />
            </div>
          ) : (
            <>
              <DataView
                columns={columns({
                  api,
                  handleViewData: handleViewData
                })}
                // onRowDoubleClick={(event: any, extendedEvent: any) => {
                //   back.setBackButton(true)
                //   setOpenDetailPrinting(extendedEvent.childProps.rowData);
                // }}
                
                rows={api.data}
                currentPage={api.currentPage}
                rowCount={api.rowCount}
                limit={api.limit}
                updatePagination={api.updatePagination}
              />
              {/* <div className="doubleclick_note">
                <p>
                  <span>{clickIcon.icon}</span> Double click to view Data
                </p>
              </div> */}
            </>
          )
        }
      />
      {/* {openDetailPrinting?.bookingId && (
        <DetailsRow
          rowData={openDetailPrinting}
          apiPrinting={api}
          setOpenDetailPrinting={setOpenDetailPrinting}
          openDetailPrinting={openDetailPrinting}
        />
      )} */}

    </>
  );
}
