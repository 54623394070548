import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import AddCellTable from "../../../components/table/addCellTable";
import { DataType } from "ka-table";
import {
  ActionButtons,
  DateView,
  InputCell,
  MediaItem,
  NonEditableCell,
  StatusDropDown,
} from "../actions";
import TableExtent from "./tableextent";
import { invokeApi } from "../../../helper/api";
import { PATCH } from "../../../constant/commonConst";

export default function SoahTable({ mediaInventory, bookingStartDate, setFieldValue,isEditMode }: any) {
  const [isModalOpen, setIsModal] = useState({ open: false, data: null });

  const dbId = "SOAH";
  const columns = [
    {
      key: "mediaItemId",
      title: "Media Item ID",
      style: { width: "15vw" },
      dataType: DataType.String,
      component: MediaItem,
      isDisabled: false,
    },
    {
      key: "city",
      title: "City",
      dataType: DataType.String,
      component: NonEditableCell,
      style: { width: "9vw" },
    },
    {
      key: "location",
      title: "Location",
      dataType: DataType.String,
      component: NonEditableCell,
      style: { width: "9vw" },
    },

    {
      key: "size",
      title: "Size",
      dataType: DataType.String,
      component: NonEditableCell,
      style: { width: "8vw" },
    },
    {
      key: "sqft",
      title: "Sq.ft",
      dataType: DataType.String,
      component: NonEditableCell,
      style: { width: "6vw" },
    },
    {
      key: "qty",
      title: "Qty",
      dataType: DataType.String,
      style: { width: "7vw" },
      component: InputCell,
      isEditable: true,
    },
    {
      key: "day",
      title: "Day",
      dataType: DataType.Number,
      component: InputCell,
      style: { width: "7vw" },
      isEditable: true,
    },
    {
      key: "rate",
      title: "Booking rate per month",
      dataType: DataType.Number,
      component: InputCell,
      style: { width: "9vw" },
      isEditable: true,
    },
    {
      key: "startDate",
      title: "Start Date",
      dataType: DataType.Date,
      component: DateView,
      style: { width: "6vw" },
      showDatepicker: true,
    },
    {
      key: "endDate",
      title: "End Date",
      dataType: DataType.Date,
      component: DateView,
      style: { width: "6vw" },
      showDatepicker: true,
    },
    {
      key: "amount",
      title: "Amount",
      component: NonEditableCell,
      dataType: DataType.String,
      style: { width: "7vw" },
    },
    {
      key: "status",
      title: "status",
      hideHeaderFilter: false,
      visible: mediaInventory?.[0]?.id ? true : false,
      dataType: DataType.String,
      component: StatusDropDown,
      callBack: async (row: any) => {
        await invokeApi(PATCH, "/mediaItems", {
          id: parseInt(row.rowData.mediaItemId),
          status: row.data,
        })
      },
      style: {
        textAlign: "center",
        cursor: "pointer",
      },
    },
    {
      key: "addColumn",
      title: "",
      component: ActionButtons,
      style: { width: "7vw", textAlign: "center" },
      isEditable: false,
      callBack: (value: any) => {
        setIsModal({
          open: true,
          data: value,
        });
      },
    },
  ];
  return (
    <>
      <AccordionItem>
        <AccordionHeader targetId={dbId}>
          SALE OF SPACE FOR OUTDOOR ADVERTISEMENT HOARDING
        </AccordionHeader>
        <AccordionBody accordionId={dbId}>
          <AddCellTable
            bookingStartDate={bookingStartDate}
            groupCode={dbId}
            updateFieldValue={setFieldValue}
            columns={columns}
            rows={mediaInventory}
            setIsModal={setIsModal}
            isEditMode={isEditMode}
          />
        </AccordionBody>
      </AccordionItem>
      {isModalOpen?.open && <TableExtent
        isEditable
        columns={columns}
        dbId={dbId}
        setIsModal={setIsModal}
        isModal={isModalOpen}
        mediaInventory={mediaInventory}
        bookingStartDate={bookingStartDate}
        setFieldValue={setFieldValue}
      />}
    </>
  );
}
