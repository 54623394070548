import { DataType } from "ka-table";
import { TableColumn } from "../../components/table/table";
import { ActionButton } from "../../components/buttons/actionButtons";
import { DELETE, UPDATE } from "../../constant/commonConst";
import { confirmBox } from "../../components/toast";
import { StatusChangeView } from "../leadGeneration/action";
import { paymentModeView } from "./action";
import moment from "moment";
export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "vendor_type",
      title: "Vendor Type",
      dataType: DataType.String,
      valueConvert:(value: any, row: any)=>{
        return value.toUpperCase();
      }
    },
    {
      key: "landlord_agency_id",
      title: "Landlord/Agency Name",
      dataType: DataType.String,
      valueConvert:(value: any, row: any)=>{
        return row?.landlord ? row?.landlord?.name :row?.agency?.name
      }
    },
    {
      key: "personName",
      title: "Person Name",
      dataType: DataType.String,
    },
    {
      key: "paymentDate",
      title: "Payment Date",
      dataType: DataType.String,
      valueConvert: (value: any, row: any) => {
        return moment(value).format("DD/MM/YYYY");
      },
    },
    {
      key: "amount",
      title: "Amount",
      dataType: DataType.String,
    },
    {
      key: "edit",
      title: "",
      hideHeaderFilter: false,
      component: ActionButton,
      callBack: async (row) => {
        if (row.status === UPDATE) {
          actions.openAddEditFrom({
            id: row.data.id,
            vendor_type: row?.data?.vendor_type ?? "agency",
            landlord_id:  row?.data?.vendor_type == "landlord" ? row?.data?.landlord_id : "",
            agency_id:  row?.data?.vendor_type == "agency" ? row?.data?.agency_id :"",
            personName: row?.data?.personName ?? "",
            remark : row?.data?.remark ??  "",
            paymentDate: row?.data?.paymentDate ?? new Date(),
            amount:  row?.data?.amount ?? "",
            attachment:row?.data?.attachment || null,
          });
        } else if (row.status === DELETE) {
          const response = await confirmBox("you want to delete this receipt?");
          if (response) {
            actions.api.removeValue(row.data.id);
          }
        }
      },
      style: {
        textAlign: "center",
      },
    },
  ];
};
