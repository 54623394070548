import React from "react";
import { DataView } from "../../../components/table/table";
import { columns, mediaColumns } from "../columns";

export default function Media({ rowData }: any) {
  const media = rowData.data.data;
  const tabId = rowData.data.tabId;
  
  return (
    <div>
      <DataView
 columns={mediaColumns(media, tabId)} // Pass media and tabId as separate arguments
        rows={media}
        offPagination={true}
        offSearch={true}
        // updatePagination={api.updatePagination}
        // currentPage={api.currentPage}
        // rowCount={api.rowCount}
        // limit={api.limit}
      />
    </div>
  );
}
