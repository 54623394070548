import { Button, Col } from "reactstrap";
import { Form, Formik } from "formik";
import { stateSchema } from "./validation";
import TextBox from "../../../components/common/textBox";
import Dropdown from "../../../components/common/dropdown";

import { CityApi } from "../city";
import { useApi } from "../../../helper/apiHook";

const AddUpdateZone = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const cityData = CityApi(false, { revalidateIfStale: true });
  const zoneId = useApi("/zones/id", false, undefined, {
    revalidateIfStale: true,
  });
  let cityList =
    cityData?.data?.data?.length > 0
      ? cityData?.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
      : [];

  return (
    <Formik
      validationSchema={stateSchema}
      initialValues={{
        ...value,
        name: value?.name
          ? value.name
          : zoneId?.data?.length > 0
          ? zoneId.data[0]
          : "",
      }}
      enableReinitialize={true}
      onSubmit={(values: any, actions: any) => {
        if (values.id) {
          api.updateValue(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        } else {
          api.create(values).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form className="form-main">
          <Dropdown
            fieldName="cityId"
            labelName="City"
            options={cityList}
            setFieldValue={setFieldValue}
            gorupClass={"mb-3"}
            isRequired={true}
            values={values}
            selectedValue={values.cityId}
          />
          <TextBox
            fieldName={"name"}
            placeholder={"Enter zone Id"}
            className={"form-control"}
            type={"text"}
            isRequired={true}
            labelName={"Zone Id"}
            value={value}
          />
          <TextBox
            fieldName={"description"}
            placeholder={"Enter description"}
            className={"form-control"}
            type={"textarea"}
            isRequired={true}
            rows="4"
            labelName={"Description"}
            value={value}
          />
          <Col lg="12" className="d-flex justify-content-end gap-2">
            <Button
              color="secondary"
              outline
              type="button"
              onClick={() => closeAddUpdate()}
            >
              Cancel
            </Button>
            <Button color="secondary" solid type="submit">
              {value?.id > 0 ? "Update" : "Submit"}
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
export { AddUpdateZone };
