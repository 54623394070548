import React, { useEffect, useMemo, useState } from "react";
import { DataView } from "../../../components/table/table";
import { useApi } from "../../../helper/apiHook";
import { Button } from "reactstrap";
import { downloadIcon, filterIcon } from "../../../constant/icons";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { getFormattedDate } from "../../../utils/dateUtils";
import { columns } from "./columns";
import { AgencyReportFilter } from "./filter";
import { AgencyApi } from "../../masters/agency";

export default function AgencyList() {
    const [filterValues, setFilterValues] = useState<any>({});
    const [filter, setFilter] = useState<boolean>(false);
    const api = useApi("/agencies/agencyReport", true, filterValues);

    useEffect(() => {
        api.mutate();
    }, []);

    const handleShowFilter = () => {
        setFilter(!filter);
    };

    const exportToExcel = async () => {
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        // Define the headers
        const headers = [
            { header: "Agency Name", key: "name", width: 30 },
            { header: "Date", key: "date", width: 15 },
            { header: "Total Agency Amount", key: "BookingAmount", width: 20 },
            { header: "Payment Amount", key: "PaymentAmount", width: 20 },
            { header: "Balance", key: "Balance", width: 20 },
        ];

        // Add the header row with styles
        worksheet.columns = headers;

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White text
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "000000" }, // Black background
            };
            cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Map your API data
        const data = api.data.map((item: any) => ({
            name: item.name || "",
            date: item?.createDate ? getFormattedDate(item.createDate) : "",
            BookingAmount: item.BookingAmount || 0,
            PaymentAmount: item.PaymentAmount || 0,
            Balance: item.Balance || 0,
        }));
        // Add the data rows
        data.forEach((item: any) => {
            worksheet.addRow(item);
        });
        // Generate buffer for Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        // Create a Blob and trigger the download
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `agency_Report_${new Date().toISOString().slice(0, 10)}.xlsx`);
    };

    const agencyApiData = AgencyApi(false);
    const agencyApiDataList: any[] = useMemo(
        () => agencyApiData?.data?.data?.length > 0
            ? agencyApiData.data.data.map((item: any) => ({ value: item.name, label: item.name }))
            : [],
        [agencyApiData]
    );

    return (
        <>
            <div
                className="d-flex justify-content-end align-items-end"
                style={{ paddingRight: "1rem" }}
            >
                <div className="head-btn-box">
                    <Button
                        color="secondary"
                        type="button"
                        outline
                        onClick={exportToExcel}
                    >
                        {downloadIcon.icon}
                        Download
                    </Button>
                    <Button
                        color="secondary"
                        type="button"
                        outline
                        onClick={handleShowFilter}
                    >
                        {filterIcon.icon}
                        Filter
                    </Button>
                </div>
            </div>
            <div style={{ marginTop: "3rem" }}>
                <DataView
                    columns={columns({
                        api,
                    })}
                    rows={api.data}
                    updatePagination={api.updatePagination}
                    currentPage={api.currentPage}
                    rowCount={api.rowCount}
                    limit={api.limit}
                />
                {filter && (
                    <AgencyReportFilter
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                        onCloseFilter={handleShowFilter}
                        agencyApiDataList={agencyApiDataList}
                    />
                )}
            </div>
        </>
    );
}
