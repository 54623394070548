import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import { Code } from "../../../components/buttons/code";
import { getFormattedDate } from "../../../utils/dateUtils";


export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },

    {
      key: "leadNo",
      title: "Lead No",
      dataType: DataType.Number,
      component: Code,
      visible: true,
    },

    {
      key: "createDate",
      title: "Date",
      dataType: DataType.Number,
      valueConvert: (row: any, rowData: any) => {

        return getFormattedDate(rowData?.createDate);
      },

      visible: true,
    },
    {
      key: "clientName",
      title: "Client Name",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.client?.businessName ? rowData?.client?.businessName : "";
      },
    },
    {
      key: "contactPerson",
      title: "Contact Person Name",
      dataType: DataType.String,
    },
    {
      key: "contactPersonMobileNo",
      title: "Mobile No.",
      dataType: DataType.String,
    },
    {
      key: "leadBy",
      title: "Assigned Name",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.leadByUser?.name;
      },
    },
    {
      key: "description",
      title: "Lead Detail",
      dataType: DataType.String,
      style: {
        width: "20rem"
      }
    },

    {
      key: 'status',
      title: 'Lead Status',
      dataType: DataType.String,
      valueConvert: (row: any) => (row.status ? row.status.toUpperCase() : '')
    },
  ];
};
