import { DataType } from "ka-table";
import { DELETE, UPDATE, VIEW } from "../../constant/commonConst";
import { confirmBox, message, statusWithReson } from "../../components/toast";
import { TableColumn } from "../../components/table/table";
import { ActionButtonNewView } from "../../components/buttons/actionButtons";
import { CheckboxControl } from "../../components/buttons/switch";
import { StatusChangeView } from "../leadGeneration/action";
import { StatusChangeControl } from "../leadGeneration/statusChanges";
import { statusReasonChange } from "./actions";

export const columns = (actions: any): TableColumn[] => {
    return [
        {
            key: "id",
            title: "id",
            dataType: DataType.Number,
            visible: false,
        },
        {
            key: "bookingNo",
            title: "booking ID",
            dataType: DataType.String,
        },
        {
            key: "client.businessName",
            title: "client Name",
            dataType: DataType.String,
        },
        {
            key: "user",
            title: "Booking By",
            dataType: DataType.String,
            valueConvert: (row: any, rowData: any) => {
                return rowData.user?.name;
            },
        },
        {
            key: "bookedByPersonMobile",
            title: "Mobile Number",
            dataType: DataType.String,
        },
        {
            key: "bookedByPersonEmail",
            title: "Email ID",
            dataType: DataType.String,
        },

        {
            key: "status",
            title: "status",
            hideHeaderFilter: false,
            dataType: DataType.String,
            component:  statusReasonChange ,
            callBack: async (row) => {
                if (row.status) {
                    await statusWithReson(
                        "Why are you deactivating this booking?",
                        async (text: any) => {
                            await actions.api.status({
                                id: row.id,
                                status: row.status ? false : true,
                                reason: text, // Pass reason to the status update function if needed
                            });
                        }
                    );
                }
                else {
                    message("error", "You cannot activate a deactivated booking.")
                }
            },
            style: {
                textAlign: "center",
                cursor: "pointer",
            },
        },
        {
            key: "edit",
            title: "",
            hideHeaderFilter: false,
            component: ActionButtonNewView,
            isBookingReport: actions.isBookingReport,
            callBack: async (row) => {
                if (row.status === UPDATE) {
                    actions.openAddEditFrom({ ...row.data });
                } else if (row.status === DELETE) {
                    const response = await confirmBox("you want to delete this Booking?");
                    if (response) {
                        actions.api.removeValue(row.data.id);
                    }
                } else if (row.status === VIEW) {
                    actions.view(`/booking/view`);
                }
            },
            
            style: {
                textAlign: "center",
            },
        },
    ];
};
