import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Form, Formik, FieldArray, ErrorMessage } from "formik";
import { stateSchema } from "./validation";
import PageLayout from "../../components/layout/page";
import TextBox from "../../components/common/textBox";
import apiHelper from "./helper";
import Dropdown from "../../components/common/dropdown";
import CreatableSelect from "react-select/creatable";
import { AddUpdateClient } from "../masters/client/addUpdate";
import ModalView from "../../components/modal";
import Select from "react-select";
import { leftSquareArrow, plus } from "../../constant/icons";
import { useState } from "react";
import CustomDropDown from "../../components/common/dropdown";
import { UserAPI } from "../user";
const AddUpdateLead = (props: any) => {
  const { api, value, closeAddUpdate, addClient, setAddClient } = props;
  let inValues = value;
  const [createdClientId, setCreatedClientId] = useState<number>();
  const dropdown = apiHelper();
  const clientApi = dropdown.clientData;
  const userList = UserAPI(false);
  const convertOptions =
    userList?.data?.data &&
    userList?.data?.data?.length > 0 &&
    userList?.data?.data.map((user: any) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  return (
    <Formik
      validationSchema={stateSchema}
      initialValues={inValues}
      onSubmit={(values: any, actions: any) => {
        let value = {
          ...values,
          name: values.name ? values.name : "",
          contactPersonEmailId: values.contactPersonEmailId
            ? values.contactPersonEmailId
            : null,
          ClientId: values.ClientId ? values.ClientId : null,
          leadBy: values.leadBy ? values.leadBy : null,
        };
        if (value.id) {
          api.updateValue(value).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        } else {
          api.create(value).then((result: any) => {
            if (result?.status) {
              closeAddUpdate();
            }
          });
        }
      }}
    >
      {({ setFieldValue, values }) => {
        return (
          <>
            {addClient ? (
              <>
                <div className="d-flex align-items-center gap-2 mb-3">
                  <Button
                    color="secondary"
                    className="p-1"
                    onClick={() => setAddClient(!addClient)}
                  >
                    {leftSquareArrow.icon}
                  </Button>
                  <h4 className="mb-0">Add Client</h4>
                </div>
                <AddUpdateClient
                  closeAddUpdate={() => {
                    setAddClient(false);
                  }}
                  setCreatedClientId={setCreatedClientId}
                  api={clientApi}
                  value={{
                    businessName: values?.name?.label || "",
                    contactPerson: values?.contactPerson || "",
                    contactPerson_emailId: values?.contactPersonEmailId || "",
                    contactPersonMobileNo: values?.contactPersonMobileNo || "",
                    contactPersonDesignation: "",
                    leadBy: null,
                    customInfo: [],
                    billingAddress: "",
                    GSTIN: "",
                    businessType: "",
                    IndustryId: null,
                  }}
                />
              </>
            ) : (
              <Form>
                <Row>
                  <Col lg="6" className="">
                    <div className="d-flex gap-2 w-100 align-items-center">
                      <FormGroup className={"w-100"}>
                        <Label
                          className={props.labelClass || "form-label"}
                          htmlFor="name"
                        >
                          Prospect Client Name
                          <span className={"text-danger required"}> *</span>
                        </Label>
                        <Select
                          // {...field}
                          menuPlacement="auto"
                          menuPortalTarget={document.body}
                          name={"ClientId"}
                          id={"ClientId"}
                          className={"w-100"}
                          styles={{
                            menuPortal: (base: any) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          options={dropdown.clientList}
                          isClearable={true}
                          onChange={(opt: any) => {
                            setFieldValue("ClientId", opt ? opt.value : "");
                            setFieldValue(
                              "contactPerson",
                              opt ? opt.data.contactPerson : ""
                            );
                            setFieldValue(
                              "contactPersonEmailId",
                              opt ? opt.data.contactPerson_emailId : ""
                            );
                            setFieldValue(
                              "contactPersonMobileNo",
                              opt ? opt.data.contactPersonMobileNo : ""
                            );
                            if (opt) {
                              setFieldValue("name", "");
                            }
                          }}
                          onInputChange={(newValue: any) => {
                            if (newValue?.length > 0) {
                              setFieldValue("name", newValue);
                              setFieldValue("ClientId", null);
                            }
                          }}
                          value={
                            values.name
                              ? {
                                  label: values.name,
                                  value: values.name,
                                }
                              : values.ClientId
                              ? dropdown.clientList.find(
                                  (opt: any) => opt.value === values.ClientId
                                )
                              : ""
                          }
                        />
                      </FormGroup>
                      <Button
                        type="button"
                        className="py-2"
                        onClick={() => {
                          setAddClient(true);
                        }}
                      >
                        {plus.icon}
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6">
                    <CustomDropDown
                      fieldName="leadBy"
                      options={convertOptions}
                      labelName={"Assign To"}
                      setFieldValue={setFieldValue}
                      callback={() => {
                        setFieldValue("status", "assigned");
                      }}
                      values={values.leadBy}
                      selectedValue={values.leadBy}
                    />
                  </Col>
                  <Col>
                    <TextBox
                      fieldName={"contactPerson"}
                      placeholder={"Enter contact person"}
                      className={"form-control"}
                      isRequired={true}
                      type={"text"}
                      labelName={"Contact Person"}
                      value={value}
                    />
                  </Col>
                  <Col>
                    <TextBox
                      fieldName={"contactPersonEmailId"}
                      placeholder={"Enter contact person email"}
                      className={"form-control"}
                      type={"email"}
                      labelName={"Contact Person Email"}
                      value={value}
                    />
                  </Col>
                  <Col>
                    <TextBox
                      fieldName={"contactPersonMobileNo"}
                      placeholder={"Enter contact person mobile"}
                      className={"form-control"}
                      type={"tel"}
                      isRequired={true}
                      labelName={"Contact Person Mobile No."}
                      value={value}
                    />
                  </Col>
                </Row>
                <TextBox
                  fieldName={"description"}
                  placeholder={"Enter details"}
                  className={"form-control"}
                  type={"textarea"}
                  rows="4"
                  labelName={"Opportunity Details"}
                  value={value}
                />
                <Col lg="12" className="d-flex justify-content-end gap-2">
                  <Button
                    color="secondary"
                    outline
                    type="button"
                    onClick={closeAddUpdate}
                  >
                    Cancel
                  </Button>
                  <Button color="secondary" type="submit">
                    {value?.id > 0 ? "Update" : "Submit"}
                  </Button>
                </Col>
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export { AddUpdateLead };
