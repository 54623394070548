import Select from "react-select";
import { UPDATE } from "../../../constant/commonConst";

const MountingStatusOptions = [
    {
      label: "Waiting for creative",
      value: "waiting-for-creative",
      color: "warning",
    },
    {
      label: "Mounting pending",
      value: "mounting-pending",
      color: "primary",
    },
    { label: "Mounted", value: "mounted", color: "success" },
  ];

export const MountingStatusFilterDropDown = (props: any) => {
    const { column, rowData } = props;

    // Handle the selection of a status option
    const handleSelect = (selectedOption: any) => {
        if (selectedOption) {
            column.callBack({ data: selectedOption.value, rowData, status: UPDATE });
        } else {
            // Clear selection
            column.callBack({ data: null, rowData, status: UPDATE });
        }
    };

    // Find the currently selected status option
    const selectedStatus = MountingStatusOptions.find(option => option.value === rowData.status);

    return (
        <Select
            options={MountingStatusOptions}
            value={selectedStatus ? { label: selectedStatus.label, value: selectedStatus.value } : null}
            onChange={handleSelect}
            isClearable
            formatOptionLabel={(option: any) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {option.label}
                </div>
            )}
        />
    );
};
