import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider, useAuth } from "./components/auth";
import { RequireAuth } from "./components/requireAuth";
import LoginForm from "./pages/auth/login/login";
import ForgotForm from "./pages/auth/forgot/forgot";

import Page404 from "./pages/page404";
import {
  AppMainLogo,
  ForgotPasswordSubTitle,
  ForgotPasswordTitle,
  LogInSubTitle,
  LogInTitle,
  ResetPasswordSubTitle,
  ResetPasswordTitle,
} from "./pages/auth/constant";
import { AuthLayout } from "./pages/auth/auth";
import { LoaderProvider } from "./components/loader";
import ResetPassword from "./pages/auth/forgot/reset";
import { LebalProvider } from "./helper/addUpdateHook";

// import { LebalProvider } from "./helper/Lebalhelper";
import RouteAccess from "./components/routeAccess";
import React from "react";
const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
function App() {
  
  return (
    <AuthProvider>
      <LoaderProvider>
        <LebalProvider>
          <Routes>
            <Route element={<RequireAuth />}>
             <Route path="/*" element={<RouteAccess />} />
            </Route>
            <Route
              path="/reset-password/:id"
              element={
                <AuthLayout
                  Title={ResetPasswordTitle}
                  SubTitle={ResetPasswordSubTitle}
                  Image={AppMainLogo}
                >
                  <ResetPassword />
                </AuthLayout>
              }
            />
            <Route
              index
              element={
                <AuthLayout
                  Title={LogInTitle}
                  SubTitle={LogInSubTitle}
                  Image={AppMainLogo}
                >
                  <LoginForm />
                </AuthLayout>
              }
            />
            <Route
              path="/forgot"
              element={
                <AuthLayout
                  Title={ForgotPasswordTitle}
                  SubTitle={ForgotPasswordSubTitle}
                  Image={AppMainLogo}
                >
                  <ForgotForm />
                </AuthLayout>
              }
            />
            <Route path="*" element={<Page404 />} />
          

          </Routes>
        </LebalProvider>
      </LoaderProvider>
    </AuthProvider>
  );
}

export default App;
