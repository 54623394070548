import { ActionType, useTableInstance } from "ka-table";
import {
  Badge,
  Button,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import {
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MediaItemsApi } from "../masters/items";
import Select from "react-select";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  deleteIcon,
  downloadIcon,
  extendbookingIcon,
  hoardingIcon,
  printIcon,
} from "../../constant/icons";
import { uuid } from "../../utils/generalUtils";
import { AgencyApi } from "../masters/agency";
import {
  comingSoon,
  confirmBox,
  message,
  messageAlert,
} from "../../components/toast";
import { RouteApi } from "../masters/route";
import { CityApi } from "../masters/city";
import { LocationApi } from "../masters/location";
import { MaterialApi } from "../masters/material";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import { getDate } from "../../utils/dateUtils";
import moment from "moment";
import { StatusChangeControl } from "../leadGeneration/statusChanges";
import { UPDATE } from "../../constant/commonConst";

export const DateView = ({ rowData, value }: any) => {
  try {
    if (!value) {
      return "";
    }
    if (
      rowData.subMediaInventory?.length > 1 &&
      ["SOAH", "POAH", "SOAPK"].includes(rowData.groupCode)
    ) {
      return <Badge className="fw-light">Extanded</Badge>;
    } else {
      return moment(value).format("DD/MM/YYYY");
    }
  } catch { }
  return value;
};
export const DateEdit = (props: any) => {
  const { rowData, column } = props;
  return (
    <Field
      component={DatePicker}
      placeholderText={"DD/MM/YYYY"}
      dateFormat={"dd/MM/yyyy"}
      className="form-control"
      name={props?.column?.key}
      selected={
        rowData[props?.column?.key] ? getDate(rowData[props?.column?.key]) : ""
      }
      onChange={(val: any) => {
        if (column?.callback) {
          column?.callback(
            new Date(val).toISOString(),
            rowData,
            props?.column?.key
          );
        }
      }}
      portalId="root"
    // withPortal={true}
    />
  );
};
const RemoveControl = (props: any) => {
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  //--------------------------current Row----------------------------------------
  return (
    <>
      <Button
        color="link"
        onClick={async () => {
          const response = await confirmBox("you want to delete this row?");
          if (response) {
            const removeInventoryId =
              rowAllData &&
              rowAllData.length > 0 &&
              rowAllData.find(
                (element: any) => element.tempRowId == rowKeyValue
              );
            updateFieldValue(
              "mediaInventory",
              (rowAllData as any[]).filter((x) => x.tempRowId != rowKeyValue)
            );
            updateFieldValue(
              "inventoryIds",
              rowAllData?.inventoryIds && rowAllData?.inventoryIds.length > 0
                ? [...rowAllData.inventoryIds, removeInventoryId?.id]
                : [removeInventoryId?.id]
            );
          }
        }}
        className="text-danger delete-btn"
        id="removeTooltip"
      >
        {deleteIcon.icon}
      </Button>
    </>
  );
};
const CheckboxControl = (props: any) => {
  const {
    column,
    rowData,
    rowKeyValue,
    value,
    rowAllData,
    updateFieldValue,
    label,
    onChange,
    type,
    id,
  } = props;
  return (
    <FormGroup check className="media-radio">
      <Input type="checkbox" id={id} checked={value} onChange={onChange} />
      <Label check title={label} htmlFor={id}>
        {" "}
        {label === "Print" ? (
          <>{printIcon.icon}</>
        ) : label === "Mounting" ? (
          <>{hoardingIcon.icon}</>
        ) : (
          label
        )}
      </Label>
    </FormGroup>
    // <FormGroup check key={value} className="media-radio">
    //   <Input
    //     onChange={onChange}
    //     type="radio"
    //     name="type"
    //     id={value}
    //     className={"form-check-input"}
    //     checked={value}
    //   />
    //   <Label check htmlFor={value}>
    //     {/* <Label check> {}</Label> */}
    //     {label === "Print" ?  <span>{printIcon.icon}</span> : label === "Mounting"?  <span>{hoardingIcon.icon}</span> : <></>}

    //   </Label>
    // </FormGroup>
  );
};
export const ActionButtons = (props: any) => {
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;

  //--------------------------Include Print----------------------------------------
  let includePrint = (rowAllData as any[]).find(
    (x) => x.groupCode == "FBPH" && x.mediaItemId == rowData?.mediaItemId
  );
  includePrint =
    rowData?.mediaItemId > 0 ? (includePrint ? true : false) : false;
  //--------------------------Include Mounting----------------------------------------
  let includeMounting = (rowAllData as any[]).find(
    (x) => x.groupCode == "FBM" && x.mediaItemId == rowData?.mediaItemId
  );
  includeMounting =
    rowData?.mediaItemId > 0 ? (includeMounting ? true : false) : false;
  //--------------------------current Row----------------------------------------
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  return (
    <div className="plus-cell-button">
      {["POAH", "SOAH"].includes(rowData?.groupCode) && (
        <>
          <CheckboxControl
            id={`Print${rowKeyValue}`}
            label={`Print`}
            {...props}
            value={includePrint}
            onChange={async (e: any) => {
              if (e.target.checked) {
                if (!rowData?.mediaItemId || rowData?.mediaItemId <= 0) {
                  messageAlert("Please select a media item first.");
                  return;
                }
                let fullData = JSON.parse(JSON.stringify(currentRow));
                delete fullData.disableQty;
                (rowAllData as any[]).push({
                  ...fullData,
                  groupCode: "FBPH",
                  tempRowId: uuid(),
                });
                updateFieldValue("mediaInventory", rowAllData);
              }
            }}
          />
          <CheckboxControl
            id={`Mounting${rowKeyValue}`}
            label={`Mounting`}
            {...props}
            value={includeMounting}
            onChange={(e: any) => {
              if (e.target.checked) {
                if (!rowData?.mediaItemId || rowData?.mediaItemId <= 0) {
                  messageAlert("Please select a media item first.");
                  return;
                }
                let fullData = JSON.parse(JSON.stringify(currentRow));
                delete fullData.id;
                delete fullData.disableQty;
                (rowAllData as any[]).push({
                  ...fullData,
                  groupCode: "FBM",
                  tempRowId: uuid(),
                });
                updateFieldValue("mediaInventory", rowAllData);
              }
            }}
          />
        </>
      )}
      {rowData?.isHaveSubMediaInventory &&
        Array.isArray(rowData?.subMediaInventory) &&
        rowData?.subMediaInventory?.length > 0 ? (
        ["SOAH", "POAH", "SOAPK"].includes(rowData?.groupCode) ? (
          <Button
            color="link"
            onClick={() => {
              if (column?.callBack) {
                column?.callBack(rowData);
              }
            }}
            className="text-secondary location-button"
            id="addTooltip"
          >
            {/* {LocationIcon.icon} */}
            {extendbookingIcon.icon}
          </Button>
        ) : null
      ) : (
        <RemoveControl {...props} />
      )}
    </div>
  );
};

export const InputCell = (props: any) => {
  const {
    column,
    rowData,
    rowKeyValue,
    value,
    rowAllData,
    updateFieldValue,
    bookingStartDate,
  } = props;

  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  useEffect(() => {
    if (
      ["SOAPK", "SOAH", "FPMPK"].includes(rowData?.groupCode) &&
      ["qty", "rate", "day"].includes(column.key)
    ) {
      if (["day"].includes(column.key)) {
        let tempDate = new Date(bookingStartDate);
        if (currentRow.startDate) {
          tempDate = new Date(currentRow.startDate);
        }
        try {
          tempDate.setDate(tempDate.getDate() + Number(currentRow.day));
          currentRow.endDate = tempDate.toISOString
            ? tempDate?.toISOString()
            : tempDate;
        } catch { }
      }
      currentRow.amount =
        currentRow.qty * (currentRow.rate / 30) * Number(currentRow.day);
      if (isNaN(currentRow.amount)) {
        currentRow.amount = 0;
      } else {
        currentRow.amount = Number(currentRow.amount.toFixed(3));
      }
    } else if (["POAH"].includes(rowData?.groupCode)) {
      if (["width", "height"].includes(column.key)) {
        currentRow.sqft = currentRow.width * currentRow.height;
      } else if (["rate", "agencyRate", "qty"].includes(column.key)) {
        currentRow.amount = currentRow.rate * currentRow.qty;
        if (isNaN(currentRow.amount)) {
          currentRow.amount = 0;
        } else {
          currentRow.amount = Number(currentRow.amount.toFixed(3));
        }
        currentRow.agencyAmount = currentRow.agencyRate * currentRow.qty;
        if (isNaN(currentRow.agencyAmount)) {
          currentRow.agencyAmount = 0;
        } else {
          currentRow.agencyAmount = Number(currentRow.agencyAmount.toFixed(3));
        }
      }
    } else if (
      ["FBM", "FBPH"].includes(rowData?.groupCode) &&
      ["width", "height", "qty", "rate"].includes(column.key)
    ) {
      // if (rowData.groupCode === "FBM") {
      //   currentRow.rate = 2; // Fixed rate for FBM
      // }
      currentRow.sqft = currentRow.width * currentRow.height;
      currentRow.amount = currentRow.sqft * currentRow.qty * currentRow.rate;
      if (isNaN(currentRow.amount)) {
        currentRow.amount = 0;
      } else {
        currentRow.amount = Number(currentRow.amount.toFixed(3));
      }
    }
    if (!column?.isExtentEditable) {
      updateFieldValue("mediaInventory", rowAllData);
    }
  }, [value]);

  const inputValue =
    column.title === "Rate Per SQ.FT" && (value === undefined || value === null)
      ? 2
      : value;
  return (
    <>
      {["POAH", "SOAH"].includes(rowData?.groupCode) &&
        (rowData?.isHaveSubMediaInventory ||
          (rowData.disableQty && column.key == "qty")) ? (
        <NonEditableCell {...props} />
      ) : (
        <Input
          id={`${rowKeyValue}${column.key}`}
          value={inputValue}
          type="number"
          onChange={(e: any) => {
            currentRow[column.key] = Number(e.target.value);
            if (column?.isExtentEditable) {
              column?.callback(rowAllData);
            } else {
              updateFieldValue("mediaInventory", rowAllData);
            }
          }}
        />
      )}
    </>
  );
};
export const MediaItem = (props: any) => {
  const {
    column,
    rowData,
    rowKeyValue,
    value,
    rowAllData,
    updateFieldValue,
    bookingStartDate,
    isEditMode
  } = props;
  let body: any = { status: ["Available"] };
  if (isEditMode || rowData?.id) {
    body.status = ["Available", "Booked"]
  }
  // Check if groupCode is either "POAH" or "SOAH"
  if (["POAH", "SOAH"].includes(rowData?.groupCode)) {
    // Add hasAgency property based on groupCode
    body.hasAgency = rowData?.groupCode === "POAH";
  }
  let mediaItemsData = MediaItemsApi(false, { revalidateIfStale: true }, body);
  const findFBPH = ["FBPH", "FBM"].includes(rowData?.groupCode);
  if (findFBPH) {
    mediaItemsData = {
      ...mediaItemsData,
      data: {
        ...mediaItemsData,
        data:
          mediaItemsData?.data?.data && mediaItemsData?.data?.data?.length > 0
            ? mediaItemsData?.data?.data.filter(
              (element: any) => !element.routeId
            )
            : [],
      },
    };
  }
  let mediaItemsList: any[] = useMemo(() => {
    const getMediaList =
      mediaItemsData?.data?.data?.length > 0
        ? mediaItemsData.data.data.map((item: any) => {
          return { value: item.id, label: item.name, data: item };
        })
        : [];
    if (!column?.isDisabled && !rowData?.id) {
      const rowAllgetAllFilter =
        rowAllData && rowAllData.length > 0
          ? rowAllData.filter(
            (element: any) => element?.groupCode == rowData?.groupCode
          )
          : null;
      const filterMediaItem = getMediaList.filter((element: any) => {
        const findMediaisIncluded =
          Array.isArray(rowAllgetAllFilter) &&
          rowAllgetAllFilter.find((elements: any) => {
            return (
              elements?.mediaItemId?.toString().includes(element?.value) &&
              elements.groupCode == rowAllData?.groupCode
            );
          });
        return element?.value !== findMediaisIncluded?.mediaItemId;
      });
      return filterMediaItem;
    }
    return getMediaList;
  }, [mediaItemsData, rowAllData, value, column.isDisabled]);

  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = { tempRowId: rowKeyValue };
  }
  useEffect(() => {
    if (value) {
      let findItem = mediaItemsData?.data?.data?.find(
        (x: any) => x.id == value
      );
      if (findItem) {
        currentRow.disableQty = findItem?.mediaType?.type === "HORDINGS";
        currentRow.size = `${findItem.width} X ${findItem.height}`;
        currentRow.height = findItem.height;
        currentRow.width = findItem.width;
        currentRow.sqft = findItem.width * findItem.height;
        if (!column?.isDisabled) {
          if (!currentRow.qty) {
            currentRow.qty = findItem.qty;
          }
          if (!currentRow.rate) {
            currentRow.rate = findItem.rateAgency;
          }
        }
        // currentRow.startDate = bookingStartDate;
        currentRow.location = findItem?.location?.name;
        currentRow.city = findItem?.city?.name;
        updateFieldValue("mediaInventory", rowAllData);
      }
    }
  }, [value, mediaItemsData.isLoading, column]);

  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={
        value ? mediaItemsList.find((x: any) => x.value == value) : undefined
      }
      className={"form-select"} //form-control
      options={mediaItemsList}
      isDisabled={column?.isDisabled}
      // isClearable={column?.isDisabled}
      onChange={(opt: any) => {
        if (opt.data?.status === "Available") {
          currentRow.mediaItemId = opt?.value ? opt.value : undefined;
          if (!currentRow?.mediaItemId) {
            currentRow.size = ``;
            currentRow.height = ``;
            currentRow.width = ``;
            currentRow.sqft = ``;
            currentRow.location = ``;
            currentRow.city = ``;
          }
          updateFieldValue("mediaInventory", rowAllData);
          return;
        }
        messageAlert(
          "Sorry, this media item is already booked. please try with another one"
        );
        return;
        // currentRow.disableQty = opt.data?.mediaType?.type === "HORDINGS"
      }}
    />
  );
};
export const AgencyID = (props: any) => {
  const agencyApiData = AgencyApi(false);
  let agencyApiList: any[] = useMemo(
    () =>
      agencyApiData?.data?.data?.length > 0
        ? agencyApiData.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
        : [],
    [agencyApiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={agencyApiList.find((x: any) => x.value == value)}
      className={"form-select"} //form-control
      options={agencyApiList}
      // isClearable={true}
      onChange={(opt: any) => {
        currentRow.agencyId = opt.value;
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};

export const City = (props: any) => {
  const apiData = CityApi(false);
  let apiDataList: any[] = useMemo(
    () =>
      apiData?.data?.data?.length > 0
        ? apiData.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
        : [],
    [apiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={apiDataList.find((x: any) => x.value == value)}
      className={"form-select"} //form-control
      options={apiDataList}
      // isClearable={true}
      onChange={(opt: any) => {
        currentRow.cityId = opt?.value;
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};
export const Location = (props: any) => {
  const apiData = LocationApi(false);
  let apiDataList: any[] = useMemo(
    () =>
      apiData?.data?.data?.length > 0
        ? apiData.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
        : [],
    [apiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={apiDataList.find((x: any) => x.value == value)}
      className={"form-select"} //form-control
      options={apiDataList}
      // isClearable={true}
      onChange={(opt: any) => {
        currentRow.locationId = opt?.value;
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};
export const MaterialID = (props: any) => {
  const apiData = MaterialApi(false, {
    revalidateIfStale: true,
  });
  let apiDataList: any[] = useMemo(
    () =>
      apiData?.data?.data?.length > 0
        ? apiData.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name, data: item };
        })
        : [],
    [apiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  // Set the default value and rate when the component mounts
  useEffect(() => {
    if (apiDataList.length > 0 && !currentRow.materialId) {
      const firstItem = apiDataList[0];
      currentRow.materialId = firstItem.value;
      currentRow.rate = firstItem.data.rate; // Set the rate based on the first item
      updateFieldValue("mediaInventory", rowAllData);
    }
  }, [apiDataList, currentRow, updateFieldValue, rowAllData]);

  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"MaterialItem"}
      id={`${rowKeyValue}MaterialItem`}
      value={
        value ? apiDataList.find((x: any) => x.value == value) : apiDataList[0]
      } // Set first item as default
      className={"form-select"} //form-control
      options={apiDataList}
      onChange={(opt: any) => {
        currentRow.rate = opt?.data.rate;
        currentRow.materialId = opt?.value;
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};
export const RouteID = (props: any) => {
  const apiData = RouteApi(false, {
    revalidateIfStale: true,
  });
  let apiDataList: any[] = useMemo(
    () =>
      apiData?.data?.data?.length > 0
        ? apiData.data?.data
          ?.filter((element: any) => element?.MediaItem?.length > 0)
          .map((item: any) => {
            return { value: item.id, label: item.name };
          })
        : [],
    [apiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  useEffect(() => {
    if (value) {
      let findItem = apiData?.data?.data?.find((x: any) => x.id == value);
      if (
        Array.isArray(findItem?.MediaItem) &&
        findItem?.MediaItem.length > 0
      ) {
        if (!currentRow.qty) {
          if (findItem.totalBookedQty) {
            currentRow.qty = findItem.MediaItem[0].qty - findItem.totalBookedQty;
          } else {
            currentRow.qty = findItem.MediaItem[0].qty
          }
        }
        findItem = findItem.MediaItem[0];
        currentRow.mediaLitType = findItem?.mediaLitType?.name;
        currentRow.mediaType = findItem?.mediaType?.name;
        currentRow.zonedesc = findItem?.zone?.name;
        currentRow.mediaSeries = findItem?.mediaSeries?.name;
        currentRow.mediaItemId = findItem?.id;
        currentRow.size = `${findItem.width} X ${findItem.height}`;
        currentRow.height = findItem.height;
        currentRow.width = findItem.width;
        currentRow.sqft = findItem.width * findItem.height;

        if (!currentRow.rate) {
          currentRow.rate = findItem.rateAgency;
        }
        updateFieldValue("mediaInventory", rowAllData);
      }
    }
  }, [value, apiData.isLoading]);
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={apiDataList.find((x: any) => x.value == value)}
      className={"form-select"} //form-control
      options={apiDataList}
      // isClearable={true}
      onChange={(opt: any) => {
        currentRow.qty = "";
        currentRow.routeId = opt?.value;
        if (!currentRow?.routeId) {
          currentRow.mediaLitType = "";
          currentRow.mediaType = "";
          currentRow.zonedesc = "";
          currentRow.mediaSeries = "";
          currentRow.mediaItemId = "";
          currentRow.size = "";
          currentRow.height = "";
          currentRow.width = "";
          currentRow.sqft = "";
          currentRow.qty = "";
          currentRow.rate = "";
          currentRow.materialId = "";
        }
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};

export const NonEditableCell = (props: any) => {
  const { rowData, column } = props;
  const hasMultipleItems = rowData.subMediaInventory?.length > 1;
  if (column.key === "amount" && rowData.subMediaInventory?.length > 1) {
    const totalAmount = rowData.subMediaInventory?.reduce(
      (acc: number, current: any) => {
        acc += current?.amount || 0;
        return acc;
      },
      0
    );
    return (
      <span>
        {" "}
        {totalAmount ? Number(totalAmount.toFixed(3)) : rowData[column.key]}
      </span>
    );
  }
  if (column.key === "qty" && rowData.subMediaInventory?.length > 1) {
    const totalqty = rowData.subMediaInventory?.reduce(
      (acc: number, current: any) => {
        // Add the amount of the current item to the accumulator
        acc += current?.qty || 0;
        return acc;
      },
      0
    ); // Start with an initial sum of 0
    return <span> {totalqty ? totalqty : rowData[column.key]}</span>;
  }
  if (hasMultipleItems) {
    const showBadge =
      ["day", "rate", "endDate"].includes(column.key) &&
      ["SOAH", "POAH", "SOAPK"].includes(rowData.groupCode);
    return (
      <span>
        {showBadge ? (
          <Badge className="fw-light">Extended</Badge>
        ) : (
          rowData[column.key]
        )}
      </span>
    );
  }
  if (["sqft ", "rate"].includes(column.key)) {
    return <span> {rowData[column.key]?.toFixed(2)}</span>;
  }
  return <span>{rowData[column.key]}</span>;
};

export const DownloadPdf = (props: any) => {
  const { printPDF, tooltipOpen, toggleTooltip } = props;
  return (
    <>
      <Button
        type="button"
        color="link"
        onClick={printPDF}
        id="donwloadtooltip"
      >
        {downloadIcon.icon}
      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="donwloadtooltip"
        toggle={toggleTooltip}
      >
        Download Pdf
      </UncontrolledTooltip>
    </>
  );
};

export const statusReasonChange = (props: any) => {
  const { rowData } = props;
  return rowData?.reason ? (
    <>{rowData?.reason}</>
  ) : (
    <StatusChangeControl {...props} />
  );
};

export const StatusDropDown = (props: any) => {
  const { column, rowData, rowKeyValue, rowAllData } = props;
  const statusOptions = [
    {
      label: "Available",
      value: "Available",
      color: "success",
    },
    {
      label: "Booked",
      value: "Booked",
      color: "primary",
    },
  ];
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  let mediaItemsData = MediaItemsApi(false, { revalidateIfStale: true }, {});
  let findItem = mediaItemsData?.data?.data?.find(
    (x: any) => x.id == currentRow?.mediaItemId
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);


  const handleSelect = (status: any) => {
    column.callBack({ data: status.value, rowData, status: UPDATE });
    setDropdownOpen(false);
    mediaItemsData.mutate()
  };

  return (
    <>
      <Dropdown
        className="status-dropdown"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret className="text-dark">
          <Badge
            color={statusOptions.find((option) => option.value === findItem?.status)?.color}
            className="text-dark"
          >
            {statusOptions.find((option) => option.value === findItem?.status)?.label}
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {statusOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => {
                handleSelect(option);
              }}
            >
              <Badge color={option.color} className="text-dark">
                {option.label}
              </Badge>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
