import { AddUpdateUser } from "./addUpdate";
import { Button } from "reactstrap";
import { columns } from "./columns";
import { useEffect } from "react";
import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import PageLayout from "../../components/layout/page";
import { plus } from "../../constant/icons";
import { DataView } from "../../components/table/table";
import ModalView from "../../components/modal";

export const UserAPI = (paging?:boolean, dataLoad: any = {}) => {
  return { ...useApi("/users",paging, undefined, dataLoad) };
};

export default function User() {
  const api = UserAPI(true);
  useEffect(() => {
    api.mutate();
  }, []);
  const AddUpdate = useAddUpdate();
  return (
    <>
      <PageLayout
        item={
          <Button
            color="secondary"
            onClick={() =>
              AddUpdate.openAddEditFrom({
                name: "",
                emailId: "",
                mobileNo: "",
                userName:"",
                isDeactivated: false,
                roleId: "",
                password: "",
                // isUpdate:false
              })
            }
          >
            {plus.icon}Add New
          </Button>
        }
        View={
          <>
 
            <DataView
              columns={columns({
                api,
                openAddEditFrom: AddUpdate.openAddEditFrom,
              })}
              currentPage={api.currentPage}
              rowCount={api.rowCount}
              limit={api.limit}
              rows={api.data}
              updatePagination={api.updatePagination}
            />
          </>
        }
      />
      {AddUpdate.viewAddUpdate && (
        <ModalView
          modalView={AddUpdate.viewAddUpdate}
          modalsize='lg'
          modalName={
            AddUpdate.currentValue?.id ? "Edit User " : "Add User "
          }
          onModalDismiss={AddUpdate.closeAddEditFrom}
        >
          <AddUpdateUser
            closeAddUpdate={AddUpdate.closeAddEditFrom}
            api={api}
            value={AddUpdate.currentValue}
          />
        </ModalView>
      )}
    </>
  );
}
