import { Button, UncontrolledTooltip } from "reactstrap";
import { edit } from "../../constant/icons";
import { useState } from "react";

const EditControl = (props: any) => {
    const { column, rowData } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };
    return (
        <>
            <Button color="link" className="text-success" onClick={() => column.callBack({ data: rowData, status: "update" })} id="editTooltip">{edit.icon}</Button>
            <UncontrolledTooltip isOpen={tooltipOpen} target="editTooltip" toggle={toggleTooltip}>
                Edit
            </UncontrolledTooltip>
        </>
    );
};

export { EditControl };