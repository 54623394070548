import * as Yup from "yup";
import {
  ADDRESS_REQUIRED,
  AGENCY_REQUIRED,
  ALIAS_MAX_REQUIRED,
  ALIAS_REQUIRED,
  CITY_REQUIRED,
  CONTACT_EMAIL_INVALID,
  CONTACT_EMAIL_REQUIRED,
  CONTACT_PERSON_DESIGNATION_REQUIRED,
  CONTACT_PERSON_REQUIRED,
  GSTIN_REQUIRED,
  MOBILE_INVALID,
  MOBILE_MIN_DIGITS,
  MOBILE_REQUIRED,
  PAN_REQUIRED,
  STATE_REQUIRED,
} from "../../../constant/message";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(AGENCY_REQUIRED),
  address: Yup.string().required(ADDRESS_REQUIRED),
  GSTINNo: Yup.string().required(GSTIN_REQUIRED),
  contactPerson: Yup.string().required(CONTACT_PERSON_REQUIRED),
  contactEmail: Yup.string()
    .email(CONTACT_EMAIL_INVALID)
    .required(CONTACT_EMAIL_REQUIRED),
  contactMobile: Yup.string()
    .matches(/^[0-9]+$/, MOBILE_INVALID)
    .min(10, MOBILE_MIN_DIGITS)
    .max(15, MOBILE_INVALID)
    .required(MOBILE_REQUIRED),
  PANNo: Yup.string().required(PAN_REQUIRED),
  stateId: Yup.number().required(STATE_REQUIRED),
  cityId: Yup.number().required(CITY_REQUIRED),
  alias: Yup.string().max(3, ALIAS_MAX_REQUIRED).required(ALIAS_REQUIRED),
});
