import { DataType } from "ka-table"
import { TableColumn } from "../../../components/table/table"
import { ActionButton } from "../../../components/buttons/actionButtons"
import { DELETE, UPDATE } from "../../../constant/commonConst"
import { confirmBox } from "../../../components/toast"

export const columns = (actions: any): TableColumn[] => {
    return [
        {
            key: 'id',
            title: 'id',
            dataType: DataType.Number,
            visible: false
        },
        {
            key: 'businessName',
            title: 'business Name',
            dataType: DataType.String
        }, 
        {
            key: 'contactPerson',
            title: 'Name',
            dataType: DataType.String
        },
        {
            key: 'contactPerson_emailId',
            title: 'Email Id',
            dataType: DataType.String
        },
        {
            key: 'contactPersonDesignation',
            title: 'Designation',
            dataType: DataType.String
        }, 
        {
            key: 'GSTIN',
            title: 'GST',
            dataType: DataType.String
        },
        {
            key: 'edit',
            title: '',
            hideHeaderFilter: false,
            component: ActionButton,
            callBack: async (row) => {
                if (row.status === UPDATE) {
                    const data = {
                        id: row.data.id,
                        businessName: row.data?.businessName,
                        contactPerson: row.data?.contactPerson,
                        contactPerson_emailId: row.data?.contactPerson_emailId,
                        contactPersonMobileNo: row.data?.contactPersonMobileNo,
                        contactPersonDesignation: row.data?.contactPersonDesignation,
                        customInfo: JSON.parse(row.data?.customInfo) || {},
                        billingAddress: row.data?.billingAddress,
                        GSTIN: row.data?.GSTIN,
                        PANNo: row.data?.PANNo,
                        IndustryId: row.data?.IndustryId,
                    }
                    actions.openAddEditFrom(data)
                } else if (row.status === DELETE) {

                    const response = await confirmBox("you want to delete this client?");
                    if (response) {
                        actions.api.removeValue(row.data.id);
                    }
                }
            },
            style: {
                textAlign: 'center',
            }
        },
    ]
}
