import { uuid } from "../../utils/generalUtils";
import { MediaInventory } from "./dto";

export const mediaInventoryRow = (
  dtlAPI: any,
  filterDtlApi: any,
  mediaItemsData: any
): any => {
  (!Array.isArray(filterDtlApi) ? [] : (filterDtlApi as any[]))?.map(
    (item: MediaInventory) => {
      let endDate: Date = item.endDate ? new Date(item.endDate) : new Date();
      let bookingStartDate: Date = dtlAPI.data.bookingStartDate
        ? new Date(dtlAPI.data.bookingStartDate)
        : new Date();

      // Calculate the difference in milliseconds and then convert to days
      let differenceInDays = Math.floor(
        (endDate.getTime() - bookingStartDate.getTime()) / (1000 * 60 * 60 * 24)
      );
      const sameMediaItemId =
        dtlAPI.data?.mediaInventory && dtlAPI.data?.mediaInventory.length > 0
          ? dtlAPI.data?.mediaInventory.filter(
            (mediaitem: any) => mediaitem?.mediaItemId == item?.mediaItemId
          )
          : [];
      const result =
        sameMediaItemId && sameMediaItemId.length > 0
          ? sameMediaItemId.reduce(
            (acc: any, num: any) => {
              const startDate = new Date(num.startDate);
              const endDate = new Date(num.endDate);
              if (!acc.minStartDate || startDate < acc.minStartDate) {
                acc.minStartDate = startDate;
              }
              if (!acc.maxEndDate || endDate > acc.maxEndDate) {
                acc.maxEndDate = endDate;
              }
              const diffTime = acc.maxEndDate - acc.minStartDate;
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              acc.qty += num?.qty;
              acc.amount += num?.amount;
              acc.rate = acc.amount / acc.qty;
              return { ...acc, diffDays };
            },
            {
              qty: 0,
              day: 0,
              rate: 0,
              minStartDate: null,
              maxEndDate: null,
              amount: 0,
            }
          )
          : null;
      const addAdditionDetails =
        sameMediaItemId &&
        sameMediaItemId.length > 0 &&
        sameMediaItemId.map((addition: any) => {
          const findMediaItem =
            mediaItemsData?.data?.data &&
            mediaItemsData?.data?.data?.length > 0 &&
            mediaItemsData?.data?.data?.find(
              (element: any) => element.id == addition?.mediaItemId
            );
          return {
            ...addition,
            city: findMediaItem?.city?.name,
            size: `${findMediaItem?.width} X ${findMediaItem?.height}`,
            location: findMediaItem?.location?.name,
            sqft: findMediaItem?.width * findMediaItem?.height,
            day: result?.diffDays,
            tempRowId: uuid(),
          };
        });
      return {
        ...item,
        day: result?.diffDays ?? differenceInDays,
        qty: result?.qty ?? item?.qty,
        rate: result?.rate ?? item?.rate,
        endDate: result?.maxEndDate ?? endDate,
        subMediaInventory:
          sameMediaItemId.length !== 1 ? addAdditionDetails : [],
        isHaveSubMediaInventory: sameMediaItemId.length !== 1 ? true : false,
        tempRowId: uuid(),
      };
    }
  );
};
export const groupByMediaItemId = (dataBack: any[], dtlAPI: any, mediaItemsData: any) => {
  if (Array.isArray(dataBack)) {
    let data: any[] = JSON.parse(JSON.stringify(dataBack))
    let allUqItem = new Map()
    let finaleItems: any[] = []
    let newUpdates = data.map((item: any, index) => {
      const startDate = new Date(item.startDate);
      const endDate = new Date(item.endDate);
      const diffDays = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
      item.day = diffDays;
      let keyOut = `${item.groupCode}${item.mediaItemId}`
      if (!["SOAH", "POAH", "SOAPK"].includes(item.groupCode)) {
        keyOut = `${index}${keyOut}`
      }
      if (!allUqItem.has(keyOut)) {
        allUqItem.set(keyOut, item)
      }
      item.tempRowId = uuid()
      return item;
    })
    allUqItem.forEach((lastItem) => {
      if (["SOAH", "POAH", "SOAPK"].includes(lastItem.groupCode)) {
        let subItems = JSON.parse(JSON.stringify(newUpdates.filter(x => x.mediaItemId == lastItem.mediaItemId && x.groupCode == lastItem.groupCode)))

        if (subItems.length > 1) {
          lastItem.tempRowId = uuid()
          lastItem.subMediaInventory = subItems;
          lastItem.isHaveSubMediaInventory = true
        }
      }

      finaleItems.push(lastItem)
    })
    return finaleItems
  }
  return [];
};

export const findStateAndStateCode = (gstin: string) => {
  const stateCodeMapping: { [key: string]: string } = {
    "01": "Jammu and Kashmir",
    "02": "Himachal Pradesh",
    "03": "Punjab",
    "04": "Chandigarh",
    "05": "Uttarakhand",
    "06": "Haryana",
    "07": "Delhi",
    "08": "Rajasthan",
    "09": "Uttar Pradesh",
    "10": "Bihar",
    "11": "Sikkim",
    "12": "Arunachal Pradesh",
    "13": "Nagaland",
    "14": "Manipur",
    "15": "Mizoram",
    "16": "Tripura",
    "17": "Meghalaya",
    "18": "Assam",
    "19": "West Bengal",
    "20": "Jharkhand",
    "21": "Odisha",
    "22": "Chhattisgarh",
    "23": "Madhya Pradesh",
    "24": "Gujarat",
    "25": "Daman and Diu",
    "26": "Dadra and Nagar Haveli",
    "27": "Maharashtra",
    "28": "Karnataka",
    "29": "Goa",
    "30": "Kerala",
    "31": "Lakshadweep",
    "32": "Tamil Nadu",
    "33": "Puducherry",
    "34": "Andhra Pradesh",
    "35": "Telangana",
    "36": "Andaman and Nicobar Islands",
    "37": "Tamil Nadu",
    "38": "Lakshadweep",
    "39": "Other States",
    "40": "Rajasthan",
  };

  let state = "";
  let stateCode = "";
  if (gstin) {
    const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;
    if (gstinPattern.test(gstin)) {
      const code = gstin.slice(0, 2);
      state = stateCodeMapping[code];
      stateCode = code;
    }
    return { state, stateCode };
  } else {
    return { state, stateCode }
  }

};
