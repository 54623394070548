import { DataType } from "ka-table";
import { TableColumn } from "../../../components/table/table";
import Media from "../../dashboard/component/media";
import Booking from "../../dashboard/component/booking";
import Landloard from "../../dashboard/component/landloard";

export const columns = (): TableColumn[] => {

    return [
        {
            key: "id",
            title: "Media",
            dataType: DataType.Number,
            component: Media,
        }, {
            key: "booking",
            title: "Booking",
            dataType: DataType.Number,
            component: Booking
        },
        {
            key: "landloard",
            title: "Landloard",
            dataType: DataType.Number,
            component: Landloard
        },
    ]
}
