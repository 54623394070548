import PageLayout from "../../components/layout/page";
import BookingRep from "./booking/bookingRep";


export default function BookingReport() {
    return (
        <PageLayout className="dashboard-inner-page"
            
            View={
                <>
                    <BookingRep isBookingReport={true} />
                </>
            }
        />
    );
}