import { AddUpdateLead } from "./addUpdate";
import { Button } from "reactstrap";
import { columns } from "./columns";
import { useEffect, useState } from "react";
import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import PageLayout from "../../components/layout/page";
import { plus } from "../../constant/icons";
import { DataView } from "../../components/table/table";
import { ClientApi } from "../masters/client";
import ModalView from "../../components/modal";

export const LeadApi = (paging?:boolean, dataLoad: any = {}) => {
  return { ...useApi("/leads",paging, undefined, dataLoad) };
};

export default function LeadGeneration() {
  const api = LeadApi(true);

  const [leadFollow, setLeadFollow] = useState<number | boolean>(false);
  const handlaFollowUp = (id: number) => {
    if (leadFollow) {
      setLeadFollow(false);
    } else {
      setLeadFollow(id);
    }
  };
  useEffect(() => {
    api.mutate();
  }, []);
  const AddUpdate = useAddUpdate();
  const [addClient, setAddClient] = useState(false);


  return (
    <>
      <PageLayout
        item={
          <Button
            color="secondary"
            onClick={() =>
              AddUpdate.openAddEditFrom({
                ClientId: null,
                // agencyId: null,
                // name: "",
                description: "",
                // mediaDescription: [
                //   {
                //     stateId: null,
                //     cityId: null,
                //     zoneId: null,
                //     locationId: null,
                //     mediaList: [
                //       {
                //         mediaTypeId: "",
                //         mediaLitId: "",
                //         mediaSeriesId: "",
                //         imageUrl: "",
                //         creativeImageUrl: "",
                //         sizeWdth: null,
                //         sizeHeight: null,
                //         mediaQty: null,
                //         rate: null,
                //       },
                //     ],
                //   },
                // ],
                contactPerson: "",
                contactPersonEmailId: "",
                contactPersonMobileNo: "",
                leadBy:""
                // contactPersonDesignation: "",
                // leadBy: null,
                // status: "",
              })
            }
          >
            {plus.icon}Add New
          </Button>
        }
        View={
          <>
            <DataView
              columns={columns({
                api,
                openAddEditFrom: AddUpdate.openAddEditFrom,
                handlaFollowUp: handlaFollowUp,
              })}
              rows={api.data}
              updatePagination={api.updatePagination}
            />
          </>
        }
      />
      {AddUpdate.viewAddUpdate && (
        <ModalView
          modalView={AddUpdate.viewAddUpdate}
          customClass={"lead-modal"}
          modalsize={addClient ? "xl": "lg"}
          modalName={AddUpdate.currentValue?.id ? "Edit Lead" : "Add Lead"}
          onModalDismiss={()=>{
            setAddClient(false)
            AddUpdate.closeAddEditFrom()
          }}
        >
          <AddUpdateLead
            closeAddUpdate={AddUpdate.closeAddEditFrom}
            api={api}
            value={AddUpdate.currentValue}
            addClient={addClient}
            setAddClient={setAddClient}
          />
        </ModalView>
      )}
    </>
  );
}
